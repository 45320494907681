/* eslint-disable */
import React from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { authContext } from './../../../utils/context/AuthContext'
import { Button } from 'react-bootstrap'
import './protocolsButtons.scss'
import Config from 'app/config/config'

const GET_PROTOCOLS = gql`
    query getProtocols($chainId: Int) {
        getProtocols(chainId: $chainId) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            protocolRatesKey
            protocolInvestContract
            protocolRegistryContract
        }
    }
`

interface IProps {
    isDepositModal?: boolean
    refetch?: boolean
    changeDetected?: Function
    passProtocolList?: Function
}
const ProtocolsButtons: React.FC<IProps> = (props) => {
    // Context store
    const { auth: user, setAuthStatus } = React.useContext(authContext)
    // protocols
    const [protocols, setProtocols] = React.useState([])

    // Apollo client defination
    const client = useApolloClient()

    React.useEffect(() => {
        if (protocols.length === 0) {
            getProtocolsList()
        }
    }, [])

    React.useEffect(() => {
        getProtocolsList()
    }, [props.refetch])
    /**
     * Get list of all protocols
     */
    const getProtocolsList = () => {
        client
            .query({
                query: GET_PROTOCOLS,
                variables: { chainId: Config.chainId },
            })
            .then(({ data }: any) => {
                console.log('GET_PROTOCOLS data', data.getProtocols)
                if (data.getProtocols.length > 0) {
                    console.log(
                        'data.getProtocols[0].protocolKey',
                        data.getProtocols
                    )
                    // pass protocol data to dashboard which will then be used on chart
                    props.passProtocolList &&
                        props.passProtocolList(data.getProtocols)
                    if (user.protocol && user.protocol.protocolId) {
                        setProtocols(data.getProtocols)
                    } else {
                        user['protocol'] = data.getProtocols[0]
                        setAuthStatus(user)
                        setProtocols(data.getProtocols)
                    }
                }
            })
    }

    /**
     * On change protocol click
     */
    const protocolBtnClick = (protocol: any) => {
        console.log('old user protocol', user)
        console.log('selected  protocol', protocol)
        user.protocol = protocol
        setAuthStatus(user)
        setProtocols([...protocols])
        props.changeDetected && props.changeDetected()
    }

    return (
        <>
            {/* protocol buttons div */}
            <div
                className={
                    props.isDepositModal
                        ? 'w-100'
                        : 'protocol-balance-row graph-div'
                }
            >
                <div className="row justify-content-center">
                    {props.isDepositModal ? (
                        ''
                    ) : (
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <p className="medium-heading m-0">DeFi Protocols</p>
                            <div className="background-gradient">
                                Tap cards to switch protocols
                            </div>
                        </div>
                    )}

                    <div
                        className={
                            props.isDepositModal
                                ? 'col-12 col-lg-10 mt-3'
                                : 'col-12 mt-3'
                        }
                    >
                        <div
                            className={`row justify-content-center  ${
                                props.isDepositModal
                                    ? 'justify-content-lg-center '
                                    : 'justify-content-lg-start'
                            }`}
                        >
                            {/*  <div className={props.isDepositModal ? 'col-6 col-lg-5 mt-4 ' : 'col-8 col-lg-4 mt-4'}>
                                <Button className="w-100 btn-normal">All</Button>
                            </div> */}
                            {protocols.length > -1 &&
                                protocols.map((protocol: any, i: number) => {
                                    return (
                                        <div
                                            key={i}
                                            className={
                                                props.isDepositModal
                                                    ? 'col-6 col-lg-6 mt-4 '
                                                    : 'col-8 col-lg-4 mt-4 protocol-button'
                                            }
                                        >
                                            <Button
                                                className={
                                                    user.protocol &&
                                                    protocol.protocolId ===
                                                        user.protocol.protocolId
                                                        ? 'w-100 btn-gradient-shadow'
                                                        : 'w-100 btn-normal'
                                                }
                                                onClick={() =>
                                                    protocolBtnClick(protocol)
                                                }
                                            >
                                                {protocol.protocolIcon && (
                                                    <img
                                                        src={require(`./../../../assets/images/${protocol.protocolIcon}`)}
                                                        alt=""
                                                        className={`protocol-btn-image ${
                                                            protocol.protocolName ===
                                                                'yEarn' &&
                                                            'protocol-btn-yEarn-logo'
                                                        } ${
                                                            protocol.protocolName ===
                                                                'Dydx' &&
                                                            'protocol-btn-dydx-logo'
                                                        }`}
                                                    />
                                                )}
                                                {protocol.protocolName}
                                            </Button>
                                        </div>
                                    )
                                })}
                            <div className="col-8 col-lg-4 mt-4 protocol-button">
                                <Button className="w-100 btn-normal btn-coming-soon">
                                    COMING SOON
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProtocolsButtons
