export enum Protocol {
    dydx = 'dYdX',
    compound = 'Compound',
    fulcrum = 'Fulcrum',
    aave = 'Aave',
}
export enum ProtocolEnum {
    dydx,
    compound,
    fulcrum,
    aave,
}

export enum AccountType {
    MetaMask = 'metamask',
    Fortmatic = 'fortmatic',
    // Portis = 'portis',
    Ledger = 'ledger',
    Trezor = 'trezor',
    Torus = 'torus',
    Authereum = 'authereum',
    SquareLink = 'squarelink',
    WalletConnect = 'walletconnect',
}
