/* eslint-disable */
import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import GradientSVG from 'app/shared/components/Gradient/GradientSVG'
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import infoIcon from '../../../../assets/images/project/infoIcon.svg'
import './../../../../pages/staking/style.scss'
import useAllPairs from 'app/hooks/useAllPairs'
import { authContext } from 'app/utils/context/AuthContext'

interface IProps {
    startBlock: any
    currentBlockNumber: any
    yourLiquidity?: string | number
    rewardsPerBlock?: string
    blockNumber?: any
    blockTime?: any
    handleStakeOrUnstake?: any
    isLiquidityRewarding?: boolean
    totalRewardEarned: string
    endBlock: number
    blockDiff: number
    setOpenModal?: any
    showAmountModal?: Function
    show?: any
}
const LiquidityAnalytics: FC<IProps> = ({
    startBlock,
    currentBlockNumber,
    yourLiquidity,
    rewardsPerBlock,
    blockNumber,
    blockTime,
    handleStakeOrUnstake,
    isLiquidityRewarding,
    totalRewardEarned,
    endBlock,
    blockDiff,
    setOpenModal,
    show,
    showAmountModal,
}) => {
    const allPairs = useAllPairs()
    // Context store
    const { selectedPair, changePercent } = React.useContext(authContext)
    return (
        <div className="">
            <div className="row justify-content-between mb-5 mx-0">
                <div className="col-12 staking-reward-card pt-4">
                    <div className="d-flex justify-content-between w-100 wrap-items px-2 mt-2 mb-5">
                        <div>
                            <span className="liq-analytics-text ml-5">
                                Liquidity Staking Analytics
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img src={infoIcon} alt="Info" className="mr-2" />
                            {/* <span className='liq-rewards-text'>2X Rewards</span> */}
                        </div>
                    </div>
                    <GradientSVG
                        startColor="#1D3DE2"
                        midColor="#9C33DD"
                        endColor="#E50A9B"
                        idCSS={'progressGrandient'}
                        rotation={'90deg'}
                    />
                    <div className="col-12 d-flex justify-content- wrap-items mb-5">
                        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
                            <CircularProgressbarWithChildren
                                minValue={startBlock}
                                maxValue={endBlock}
                                value={blockDiff}
                                strokeWidth={6}
                                background
                                styles={{
                                    root: {
                                        width: '330px',
                                        alignSelf: 'center',
                                    },
                                    path: {
                                        stroke: `url(#progressGrandient)`,
                                        strokeLinecap: 'round',
                                        transition:
                                            'stroke-dashoffset 0.5s ease 0s',
                                        transformOrigin: 'center center',
                                    },
                                    trail: {
                                        stroke: 'transparent',
                                        strokeLinecap: 'round',
                                        transformOrigin: 'center center',
                                    },
                                    background: {
                                        fill: 'transparent',
                                    },
                                }}
                            >
                                <div className="staking-unlock-circle-box">
                                    <div className="d-flex align-items-center mb-4">
                                        <span className="rewards-unlock-text mr-2">
                                            {currentBlockNumber < startBlock ? (
                                                'Staking Starts In'
                                            ) : currentBlockNumber >
                                              endBlock ? (
                                                <h5 className="pt-1">
                                                    Staking Ended
                                                </h5>
                                            ) : (
                                                'Staking Ends In'
                                            )}
                                        </span>
                                        <img
                                            src={infoIcon}
                                            alt="ADD.xyz info"
                                        />
                                    </div>
                                    {currentBlockNumber < endBlock && (
                                        <div className="d-flex align-items-end mb-4">
                                            <span className="time-digit-text">
                                                {blockTime
                                                    ? blockTime.days
                                                    : ''}
                                            </span>
                                            <span className="time-unit-text">
                                                days
                                            </span>
                                            <span className="time-digit-text">
                                                {blockTime
                                                    ? blockTime.hours
                                                    : ''}
                                            </span>
                                            <span className="time-unit-text">
                                                hrs
                                            </span>
                                            <span className="time-digit-text">
                                                {blockTime
                                                    ? blockTime.minutes
                                                    : ''}
                                            </span>
                                            <span className="time-unit-text">
                                                min
                                            </span>
                                        </div>
                                    )}
                                    <div className="d-flex align-items-center mb-3">
                                        <span className="blocks-digit-text disclaimer-text">
                                            {blockNumber ? blockNumber : ''}
                                        </span>
                                        <span className="blocks-left-text ml-1 ">
                                            blocks left
                                        </span>
                                    </div>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="col-10 col-lg-7 mt-sm-2">
                            <div className="d-flex  balance-pills ">
                                <div className=" p-3 balance-box d-flex flex-column mr-2 mb-3 mx-s-0">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Total Liquidity</span>
                                    </div>
                                    <div className="balance-box-amount d-flex justify-content-between align-items-center">
                                        {/* <span>{`$ ${numberWithCommas(totalBalance.toFixed(3))}`}</span> */}
                                        <span>{yourLiquidity}</span>
                                        <span className="ml-3 apy-text ">
                                            <img
                                                src={require(`../../../../assets/icons/Shape.svg`)}
                                            />
                                            $1,380
                                        </span>
                                    </div>
                                </div>
                                <div className=" p-3 balance-box d-flex flex-column mx-2 mb-3 mx-s-0">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Pool Liquidity Dominance</span>
                                    </div>
                                    <div className="balance-box-amount d-flex align-items-center justify-content-between">
                                        <span>
                                            {' '}
                                            {changePercent.toFixed(8)}%
                                        </span>
                                        <span className="ml-3 apy-text">
                                            <img
                                                src={require(`../../../../assets/icons/Shape.svg`)}
                                            />
                                            $1,3
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex balance-pills ">
                                <div className=" p-3 balance-box d-flex flex-column  mb-3">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Reward per block</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        <span>
                                            {' '}
                                            {rewardsPerBlock
                                                ? `${rewardsPerBlock} ADD`
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className=" p-3 balance-box d-flex flex-column mx-3 mb-3 mx-s-0">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Total pool reward earned</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        <span>{totalRewardEarned} ADD</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-10 col-lg-3 d-flex flex-column align-content-center">
              <div className="">
                <div className="staking-analytics-black-card pl-4">
                  <span className="title mt-3">Your liquidity</span>
                  <span className="value my-4">{yourLiquidity}</span>
                </div>
              </div>
              <div className="">
                <div className="staking-analytics-black-card pl-4">
                  <span className="title mt-3">Rewards per block</span>
                  <span className="value my-4">
                    {rewardsPerBlock ? `${rewardsPerBlock} ADD` : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-10 col-lg-3 d-flex flex-column align-content-center">
              <div className="">
                <div className="staking-analytics-black-card pl-4">
                  <span className="title mt-3">Pool Liquidity Dominance</span>
                  <span className="value my-4">
                    {changePercent.toFixed(8)}%
                  </span>
                </div>
              </div>
              <div className="">
                <div className="staking-analytics-black-card pl-4">
                  <span className="title mt-3">Total Pool Rewards Earned</span>
                  <span className="value my-4">{totalRewardEarned} ADD</span>
                </div>
              </div>
            </div> */}
                    </div>
                    <div className="col-lg-5 col-xs-12 col-md-5 col-sm-12 text-center mt-n5 p-2 ">
                        {/* <Button
                            className="claim-reward-button mr-1"
                            onClick={() =>
                                // currentBlockNumber > endBlock
                                //     ? !isLiquidityRewarding
                                //         ? ''
                                //         : setOpenModal(4)
                                //     : handleStakeOrUnstake
                                setOpenModal(4)
                            }
                        >
                            <span>Stake</span>
                        </Button> */}
                        <Button
                            className={
                                isLiquidityRewarding
                                    ? `claim-reward-button ${
                                          isLiquidityRewarding ? 'disabled' : ''
                                      }`
                                    : 'claim-reward-button'
                            }
                            disabled={
                                isLiquidityRewarding &&
                                currentBlockNumber < endBlock
                            }
                            onClick={() =>
                                // currentBlockNumber > endBlock
                                //     ? !isLiquidityRewarding
                                //         ? ''
                                //         : setOpenModal(4)
                                //     : handleStakeOrUnstake
                                {
                                    setOpenModal(4)
                                    show(true)
                                }
                            }
                        >
                            <span>
                                {currentBlockNumber > endBlock
                                    ? 'Unstake'
                                    : isLiquidityRewarding
                                    ? 'Claiming Rewards'
                                    : 'Claim Rewards'}
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiquidityAnalytics
