/* eslint-disable */
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { provider } from 'web3-core'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

// assets
import Accordion from 'react-bootstrap/Accordion'
import newTabIcon from '../../../../assets/images/project/newTabIcon.svg'
import AmountModal from '../AmountModal'
import { Spinner } from 'react-bootstrap'

// utils
import { getContract, getWeb3 } from '../../../../utils/Utilities/erc20'
import {
    approve,
    allowance,
    getUserInfo,
    stakeOrUnstake,
    convertToNumber,
    getPendingReward,
    truncateDecimalPoints,
} from '../../../../utils/Utilities/transactionsUtils'
import { showNotification } from '../../../../utils/notifications/notificationService'

// constants
import Config from '../../../../config/config'
import useAllPairs from 'app/hooks/useAllPairs'
import { authContext } from 'app/utils/context/AuthContext'
interface IProps {
    title: string
    multiplier: string
    imageUrl: string
    apr: number
    earn: string
    tokenAddress: string
    pid: number
    address: string
    getRewardandBalanceAmount: any
    currentBlockNumber: any
    endBlock: any
    yourliquidity: any
    onBack: Function
}

const PoolCard = ({
    title,
    multiplier,
    imageUrl,
    apr,
    earn,
    tokenAddress,
    pid,
    address,
    getRewardandBalanceAmount,
    currentBlockNumber,
    yourliquidity,
    endBlock,
    onBack,
}: IProps) => {
    const [isHideDetails, setHideDetails] = useState(true)
    const [isApproved, setApproved] = useState(false)
    const [showAmountModal, setAmountModal] = useState(false)
    const [isStake, setStake] = useState(false)
    const [depositedAmount, setDepositedAmout] = useState<any>('')
    const [balance, setBalance] = useState<any>('')
    const [isApproving, setIsApproving] = useState(false)
    const [isClaming, setIsClaming] = useState(false)
    const [claimAmount, setClaimAmount] = useState(0)

    const context = useWeb3React<Web3Provider>()
    const { account, library } = context
    const allPairs = useAllPairs()
    // Context store
    const { selectedPair } = React.useContext(authContext)

    React.useEffect(() => {
        library && checkAllowance()
    }, [library])

    const checkAllowance = async () => {
        const masterChefContract = getContract(
            library.provider as provider,
            Config.masterchefAddress,
            true
        )

        const pendingReward = await getPendingReward(
            pid,
            masterChefContract,
            account
        )

        const converetdRewardAmount: number = convertToNumber(pendingReward)
        converetdRewardAmount > 0 &&
            setClaimAmount(Number(truncateDecimalPoints(converetdRewardAmount)))

        console.log('pendingReward***', convertToNumber(pendingReward))

        const lpContract = getContract(
            library.provider as provider,
            address,
            false
        )
        const allowanceAmount = await allowance(
            lpContract,
            Config.masterchefAddress,
            account
        )

        if (Number(allowanceAmount) > 0) {
            const masterChefContract = getContract(
                library.provider as provider,
                Config.masterchefAddress,
                true
            )
            const webInstance = getWeb3(library.provider as provider)
            let stakeAmount = await getUserInfo(
                pid,
                masterChefContract,
                account
            )

            const balance = webInstance.utils.fromWei(
                await lpContract.methods.balanceOf(account).call()
            )

            setBalance(balance)
            stakeAmount = webInstance.utils.fromWei(stakeAmount)

            setDepositedAmout(stakeAmount)
            setApproved(true)
        }
    }

    const handleStakeOrUnstake = async () => {
        try {
            setIsClaming(true)
            const masterchefContract = getContract(
                library.provider as provider,
                Config.masterchefAddress,
                true
            )
            const webInstance = getWeb3(library.provider as provider)
            const amountDeposited = webInstance.utils.toWei('0', 'ether')
            const tx = await stakeOrUnstake(
                masterchefContract,
                pid,
                amountDeposited,
                account,
                'deposit'
            )

            if (tx && tx.status) {
                showNotification(`${'deposit'} successful!`, 'success')
            }
            setIsClaming(false)
        } catch (e) {
            setIsClaming(false)
        }
    }

    const handleApprove = async () => {
        try {
            setIsApproving(true)
            const lpContract = getContract(
                library.provider as provider,
                address,
                false
            )
            const allowanceAmount = await allowance(
                lpContract,
                Config.masterchefAddress,
                account
            )
            const webInstance = getWeb3(library.provider as provider)
            const balance = webInstance.utils.fromWei(
                await lpContract.methods.balanceOf(account).call()
            )

            setBalance(balance)
            if (Number(allowanceAmount) <= 0) {
                const tx = await approve(
                    lpContract,
                    Config.masterchefAddress,
                    account
                )
                if (tx.status) {
                    checkAllowance()
                    setStake(true)
                    setApproved(true)
                    setAmountModal(true)
                } else {
                    // TODO: error msg
                }
            } else {
                checkAllowance()
                setApproved(true)
            }

            // const tx = await approve(contract, Config.masterchefAddress, account);
        } catch (e) {
            // user rejected tx or didn't go thru
            console.log(e)
        }
        setIsApproving(false)
    }

    return (
        <div className="p-4 pool-width">
            <div className="staking-card-round d-flex flex-column pool-div">
                <div className="d-flex align-items-center justify-content-center wrap-items mt-3 mb-4">
                    <div className="">
                        <img src={imageUrl} alt="ADD.xyz Logo" />
                    </div>
                    <div className="d-flex flex-column align-items-end wrap-items">
                        <span className="pool-title mr-2">
                            {title && title.split(' ')[0]} LP
                        </span>
                        {/* <span className="pool-multiplier">{multiplier}</span> */}
                    </div>
                </div>
                {/* <div className="">
          <div className="col-10 d-flex justify-content-between align-items-center mb-3">
            <span className="pool-detail-heading">Earn:</span>
            <span className="pool-detail-value">{earn}</span>
          </div>
        </div> */}
                <div className="col-12 d-flex justify-content-between align-items-start">
                    <text
                        className="pool-add-earn-text disclaimer-text w-100 flex-grow-1 "
                        style={{ fontSize: '20px' }}
                    >
                        Est. reward
                    </text>
                    <button
                        className="btn pool-card-btn flex-shrink-1 "
                        onClick={() => {
                            setStake(false)
                            // setAmountModal(!showAmountModal)
                        }}
                    >
                        Claim
                    </button>
                </div>
                <div className="col-12 d-flex justify-content-between mb-3">
                    <span className="pool-earn-value">{claimAmount}</span>
                    {currentBlockNumber < endBlock && (
                        <Button
                            disabled={!claimAmount || isClaming}
                            className={
                                isClaming
                                    ? `pool-claim-btn ${
                                          isClaming ? 'disabled' : ''
                                      }`
                                    : 'pool-claim-btn'
                            }
                            onClick={() => handleStakeOrUnstake()}
                        >
                            <span>{isClaming ? 'Claiming' : 'Claim'}</span>
                        </Button>
                    )}
                </div>
                <div className="col-12 mb-1 mt-4">
                    <span className="pool-subheader">{title} STAKED</span>
                </div>
                <div className="col-12">
                    <span className="pool-earn-value">{claimAmount}</span>
                </div>
                <div className="col-12 mb-1 mt-4">
                    <span className="pool-subheader">Total Liquidty</span>
                </div>
                <div className="col-12">
                    <span className="pool-earn-value">{yourliquidity}</span>
                </div>
                {isApproved ? (
                    <Button
                        className="btn-squared mt-3"
                        onClick={handleApprove}
                        disabled={!library}
                    >
                        {isApproving ? (
                            <Spinner
                                animation="border"
                                className="lending-modal-loader"
                            />
                        ) : (
                            <span>+ Add Stake</span>
                        )}
                    </Button>
                ) : (
                    <div className="col-12 d-flex justify-content-between mb-5">
                        <span className="pool-earn-value">
                            {depositedAmount
                                ? parseFloat(depositedAmount).toFixed(8)
                                : '0'}
                        </span>
                        <div className="d-flex">
                            <Button
                                className="pool-action-btn mr-2"
                                onClick={() => {
                                    setStake(false)
                                    setAmountModal(!showAmountModal)
                                    // onBack(false)
                                }}
                            >
                                <span>-</span>
                            </Button>
                            <Button
                                className="pool-action-btn"
                                onClick={() => {
                                    setStake(true)
                                    setAmountModal(!showAmountModal)
                                    // onBack(false)
                                }}
                            >
                                <span>+</span>
                            </Button>
                        </div>
                    </div>
                )}

                <div className="text-center mt-5">
                    <span
                        className="pool-view-uniswap-text"
                        onClick={() =>
                            window.open(`${Config.uniswapStakingUrl}`, '_blank')
                        }
                    >
                        View on Uniswap
                    </span>
                </div>
                {/* {!isApproved ? (
            <div className="col-12 d-flex justify-content-center mb-5">
              <Button
                disabled={!library}
                className={`pool-approve-btn ${isApproving ? "disabled" : ""}`}
                onClick={handleApprove}
              >
                <span>
                  {isApproving ? "Approving Contract" : "Approve Contract"}
                </span>
              </Button>
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-between mb-5">
              <span className="pool-earn-value">
                {depositedAmount ? parseFloat(depositedAmount).toFixed(8) : "0"}
              </span>
              <div className="d-flex">
                <Button
                  className="pool-action-btn mr-2"
                  onClick={() => {
                    setStake(false);
                    setAmountModal(!showAmountModal);
                  }}
                >
                  <span>-</span>
                </Button>
                <Button
                  className="pool-action-btn"
                  onClick={() => {
                    setStake(true);
                    setAmountModal(!showAmountModal);
                  }}
                >
                  <span>+</span>
                </Button>
              </div>
            </div>
          )} */}

                {/* <div className="pool-divider mb-4" /> */}
                {/* <div className="mb-4">
          <Accordion>
            <Accordion.Toggle
              as="div"
              eventKey="0"
              onClick={() => setHideDetails(!isHideDetails)}
            >
              <div className="d-flex justify-content-center">
                <span className="pool-detail-text align-text-center">
                  {isHideDetails ? "Details" : "Hide"}
                </span>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="w-100 mt-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="pool-detail-heading align">Deposit:</span>
                  <div className="d-flex align-items-center">
                    <span className="pool-detail-value mr-2">
                      {allPairs[selectedPair].name}
                    </span>
                    <img
                      src={newTabIcon}
                      alt="ADD.xyz Logo"
                      onClick={() =>
                        window.open(
                          `https://app.uniswap.org/#/add/v2/${allPairs[selectedPair].tokenAddress}/ETH`,
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="pool-detail-heading">Total Liquidity:</span>
                  <span className="pool-detail-value">
                    {allPairs[selectedPair].totalLiquidity}
                  </span>
                </div>
                <div>
                  <span
                    className="pool-view-uniswap-text"
                    onClick={() =>
                      window.open(`${Config.uniswapStakingUrl}`, "_blank")
                    }
                  >
                    View on Uniswap
                  </span>
                </div>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div> */}
            </div>
            {showAmountModal && (
                <AmountModal
                    header={isStake ? 'Stake' : 'Unstake'}
                    title={title}
                    balance={isStake ? balance : depositedAmount}
                    isVisible={showAmountModal}
                    setIsVisible={setAmountModal}
                    pid={pid}
                    transactionType={isStake ? 'deposit' : 'withdraw'}
                    checkAllowance={checkAllowance}
                    getRewardandBalanceAmount={getRewardandBalanceAmount}
                    onBack={onBack}
                />
            )}
        </div>
    )
}

export default PoolCard
