import React from 'react'

interface IProps {
    isActive: string
}
const OverviewIcon: React.FC<IProps> = ({ isActive }) => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        stroke={isActive ? '#0094FF' : '#A1A3A5'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.00002 0.545898C4.36364 0.545898 1.25819 2.8077 0 6.00045C1.25819 9.19316 4.36364 11.455 8.00002 11.455C11.64 11.455 14.7418 9.19316 16 6.00045C14.7418 2.8077 11.64 0.545898 8.00002 0.545898ZM8.00002 9.63679C5.99274 9.63679 4.36364 8.00769 4.36364 6.00041C4.36364 3.99314 5.99274 2.36407 8.00002 2.36407C10.0073 2.36407 11.6364 3.99317 11.6364 6.00045C11.6364 8.00772 10.0073 9.63679 8.00002 9.63679Z"
            stroke={isActive ? '#0094FF' : '#A1A3A5'}
        />
    </svg>
)

export default OverviewIcon
