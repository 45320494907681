const Config = {
    // proxyGraphQLUrl: 'https://api.v2.add.xyz/graphql', //for add.xyz on main net
    // proxyWSGraphQLUrl: 'wss://api.v2.add.xyz/graphql', //for add.xyz on main net
    proxyGraphQLUrl: 'https://apidev.beta.add.xyz/graphql', //for beta
    proxyWSGraphQLUrl: 'wss://apidev.beta.add.xyz/graphql', //for beta
    // proxyGraphQLUrl: "http://3.6.39.32:3000/graphql",
    // proxyWSGraphQLUrl: "ws://3.6.39.32:3000/graphql",
    tornadoUrl: 'https://tornado.beta.add.xyz/consumeNote',
    uniswapUrl: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
    // proxyGraphQLUrl: "http://192.168.2.7:4000/graphql",
    // proxyWSGraphQLUrl: "ws://192.168.2.7:4000/graphql",

    // proxyGraphQLUrl: "http://localhost:7000/graphql",
    // proxyWSGraphQLUrl: "ws://localhost:7000/graphql",
    chainIds: [42],
    squareLinkchainIds: [1],
    chainId: 42,
    chainName: 'mainnet',
    RPC_URL: [
        'https://mainnet.infura.io/v3/29f73f58517e4eb69f4cd52086f5a4f5',
        'https://mainnet.infura.io/v3/a4273886253a4c01b2e41cbfeb190ccd',
        'https://mainnet.infura.io/v3/da3b4a8d88d24ebf86b7dd3d81aeeae7',
    ],
    fortmaticKey: 'pk_live_68D532083EC94C0C',
    TrezorManifestEmail: 'abc@gmail.com',
    TrezorManifestUrl: 'some url',
    squarelink_id: '4e0751914026801ba013',
    portisKey: '739e0738-4aac-40a5-b0fe-08299dc83900',
    etherscanKovanUrl: 'https://kovan.etherscan.io/tx',
    etherscanMainUrl: 'https://etherscan.io/tx',
    ipGeolocationUrl:
        'https://ipgeolocation.abstractapi.com/v1/?api_key=703efa88ec7240e886105af3f3df3979',
    fakeFairishUrl: 'https://prelayer.v2.add.xyz/status',
    googleDriveFoldersUrl: 'https://drive.google.com/drive/folders/',
    pressKitDigitalAssetsId: '1odGgiogN9kOPlgiwlpCcOHICIIelJadT?usp=sharing',
    pressKitCompanyPitchId: '1AhCteMLBRxpEi0kbEJn47YNlwBLFtYt1?usp=sharing',
    pressKitProductScreensId: '16WmIGLQOwaTu6pOffLOffLqxALBLMfwx?usp=sharing',
    invisibleCaptchaSiteKey: '6LeFoXYUAAAAAHWf0V9wDZGs_oagYUxuA7y1BLKm',
    etherchainGasPriceUrl: 'https://www.etherchain.org/api/gasPriceOracle',
    contractAddress: '0x635d081fd8f6670135d8a3640e2cf78220787d56',
    masterchefAddress: '0x9aC9Ca16CBE6B10C1e0ca6f89d2EbA0389bDD2ba',
    airtableUrl: 'https://airtable.com/shrmp02tkqaUnZXmk',
    uniswapStakingUrl:
        'https://v2.info.uniswap.org/pair/0xe805730341d3f49dd404947ccb6f9bd6f6e978ce',
    ethRateApiUrl:
        'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR',
    etherScanAdd:
        'https://etherscan.io/token/0x9fbfed658919a896b5dc7b00456ce22d780f9b65',
    aboutUrl: 'https://about.add.xyz/',
    feedbackLink: 'https://airtable.com/shrmp02tkqaUnZXmk',
    governanceBaseUrl: 'https://governance-api.add.xyz',
}

export default Config
// "apollo-cache-inmemory": "^1.6.6",
// "@bit/mui-org.material-ui.slider": "^4.9.10",
//
