import React, { useState, useEffect } from 'react'

// components
import HowTo from '../../shared/components/Landing/HowTo'
import ModalComponent from './../../shared/components/Modal/ModalComponent'
import SubscribeEmail from './../home/sections/SubscribeEmail'

// contexts
import { authContext } from './../../utils/context/AuthContext'

// assets
import product from '../../assets/images/lending-home/product.png'
import protocols from '../../assets/images/lending-home/protocols.svg'
import checkmark from '../../assets/images/lending-home/checkmark.svg'
import relax from '../../assets/images/lending-home/relax.svg'
import mobile from '../../assets/images/lending-home/mobile.svg'

// styles
import './style.scss'

interface IProps {
    history: any
}
const LendingProduct: React.FC<IProps> = ({ history }) => {
    const { auth: user } = React.useContext(authContext)
    const [openModal, setOpenModal] = useState(-1)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const earnV3Features = [
        'No Platform Fee',
        '90% Reduction on Gas Fee',
        'Highest Interest Rates',
        'No Custodial and Locking',
        'Personal DeFi Dashboard',
        'Privacy on DeFi',
    ]
    const lendingItems = [
        {
            title: 'Highest Interest Rate',
            desc: 'Earn the highest interest returns rates from all crypto lending platforms on your crypto assets.',
        },
        {
            title: 'Most Powerful Wallet',
            desc: 'Make your wallet the most powerful wallet with access to all crypto lending platforms at once.',
        },
        {
            title: 'No Additional Fee',
            desc: 'No additional fee on your deposits and withdrawals. Pay the gas fee on Ethereum and that’s it. Nothing else!',
        },
        {
            title: 'No Custodial and Locking',
            desc: 'The complete ownership of your tokens lies in your hand. You control how and when you want to deposit and withdraw your funds.',
        },
        {
            title: 'Personal DeFi Dashboard',
            desc: 'Get complete analytics and insights on all your DeFi investments in a single place and strategize your returns better.',
        },
        {
            title: 'Privacy on DeFi',
            desc: 'Using next-generation on- and off-chain privacy protocols, we protect private your transactions from prying eyes.',
        },
    ]
    const earnInstructions = [
        {
            title: 'Connect your Wallet',
            desc: 'Connect your Metamask or other supported wallets to our platform',
        },
        {
            title: 'Deposit your Crypto',
            desc: 'Deposit supported cryptocurrencies and approve the transaction',
        },
        {
            title: 'Start Earning',
            desc: 'That’s it! You will start earning interest on your deposits',
        },
    ]

    const renderConnectWalletButton = () => (
        <>
            {!user.walletAddress && (
                <button onClick={() => setOpenModal(0)} className="primary-btn">
                    Connect your Wallet
                </button>
            )}
        </>
    )

    const howToHeader = () => (
        <>
            How To
            <br />
            Deposit and
            <br />
            <span>Earn Crypto?</span>
        </>
    )

    return (
        <div className="lending-page-ui">
            <h1 className="header">
                Earn up to 17% APY with our DeFi Lending Aggregator
            </h1>
            <p className="sub-header">
                All crypto lending protocols aggregated on a single platform,
                that’s where you earn the most on your crypto deposits are.
            </p>

            <div className="d-flex flex-column justify-content-center align-items-center">
                {renderConnectWalletButton()}
                {user.walletAddress && (
                    <button
                        onClick={() => history.push('/earn')}
                        className="primary-btn"
                    >
                        Start Earning
                    </button>
                )}
                <img
                    src={product}
                    alt="Product ScreenShot"
                    className="product-img img-fluid"
                />
            </div>

            <div className="earn-v3-card d-flex justify-content-between align-items-center">
                <div>
                    <img src={protocols} alt="Protocols" />
                </div>
                <div>
                    <p className="earn-v3">
                        +Earn v3 - Not your ordinary crypto lending platform
                    </p>
                    <div className="d-flex flex-wrap justify-content-between mb-4">
                        {earnV3Features.map((f) => (
                            <div className="earn-v3-feature">
                                <div
                                    key={f}
                                    className="d-flex align-items-center mx-auto"
                                >
                                    <img
                                        src={checkmark}
                                        alt="Checkmark"
                                        className="mr-2"
                                    />
                                    <p className="mb-0">{f}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="lending-cards-wrapper d-flex flex-wrap justify-content-center mt-5 mx-auto">
                {lendingItems.map((l) => (
                    <div className="lending-item-col">
                        <div className="lending-card">
                            <p className="lending-card-title">{l.title}</p>
                            <p className="lending-card-desc mb-0">{l.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex">
                <div className="fee-section d-flex flex-md-column justify-content-center align-items-center">
                    <div>
                        <p className="fee-title text-center">
                            Gas Fee Reduced by
                        </p>
                        <p className="fee-value text-center">90%</p>
                    </div>
                    <div>
                        <p className="fee-title text-center">Platform Fee</p>
                        <p className="fee-value text-center">0%</p>
                    </div>
                </div>
                <img src={relax} alt="" />
            </div>

            <div className="new-protocols-currencies-wrapper d-flex flex-column flex-md-row align-items-center">
                <div className="mr-md-2">
                    <h1 className="mb-4">New Protocols & Currencies</h1>
                    {renderConnectWalletButton()}
                </div>
                <div>
                    <img src={mobile} alt="" className="img-fluid ml-md-2" />
                </div>
            </div>

            <HowTo headerText={howToHeader()} instructions={earnInstructions} />

            <SubscribeEmail headerText="Subscribe and Start Earning!!" />

            {openModal === 0 && (
                <ModalComponent
                    visible
                    isWalletModal={openModal === 0 ? true : false}
                    onClose={() => setOpenModal(-1)}
                />
            )}
        </div>
    )
}

export default LendingProduct
