import React from 'react'

// components
import DepositLiquidity from './DepositLiquidity'
import WithdrawLiquidity from './WithdrawLiquidity'
import Swap from './Swap'
import Modal from 'react-modal'

// styles
import './style.scss'

// assets
import back from '../../../assets/images/project/back.png'
import AddStaking from './AddStaking'

// constants

const customStyles = {
    content: {
        backgroundColor: 'rgba(10, 10, 10, 0.7)',
        border: 'none',
        width: '60%',
        top: '50%',
        left: '50%',
        zIndex: 999,
        padding: '0px',
        height: '90%',

        transform: 'translate(-50%, -50%)',
    },
}

interface IProps {
    showDepositModal: boolean
    showWithdrawModal: boolean
    showSwapModal: boolean
    showAddStakingModal: boolean
    onBack: Function
    startBlock
    currentBlockNumber
    yourLiquidity?: any
    rewardsPerBlock?: any
    blockNumber?: any
    blockTime?: any
    totalRewardEarned: string
    getRewardandBalanceAmount: any
    endBlock: number
    blockDiff: number
}
const StakingModal: React.FC<IProps> = (props) => {
    const {
        startBlock,
        currentBlockNumber,
        yourLiquidity,
        rewardsPerBlock,
        blockNumber,
        blockTime,
        totalRewardEarned,
        getRewardandBalanceAmount,
        endBlock,
        blockDiff,
        onBack,
    } = props
    // const renderTitles = () => {
    //   let title: string;

    //   if (props.showDepositModal) {
    //     title = "Desposit Liquidity";
    //   } else if (props.showWithdrawModal) {
    //     title = "Withdraw Liquidity";
    //   } else if (props.showSwapModal) {
    //     title = "Swap";
    //   } else if (props.showAddStakingModal) {
    //     title = "Add Staking";
    //   }

    //   return title;
    // };

    return (
        <div className="add-xyz staking-modals-ui containerr">
            <div className="add-staking-header d-flex justify-content-center align-items-center">
                {/* <div
          className="staking-modal-back-action pointer d-flex align-items-center"
          onClick={() => props.onBack(false)}
        >
          <img src={back} alt="Back to" className="mr-3" />
          <span>Back</span>
        </div> */}
                <div
                    style={{ width: '15%' }}
                    onClick={() => props.onBack(false)}
                >
                    <img src={back} alt="<" className="pointer pr-3" />
                    <span className="pointer">Back</span>
                </div>

                {/* <div
                    className="pool-header text-center mx-auto"
                    onClick={() => props.onBack(false)}
                >
                    <span style={{ fontSize: '10px' }} className="mr-3">
                        <img src={back} alt="<" className="pointer pr-1" />
                        <span className="pointer">Back</span>
                    </span>
                    ADD STAKING
                </div> */}
                <div style={{ width: '15%' }} />
            </div>

            {/* <div className="staking-modal-bottom-gutter row justify-content-center">
        <div className="staking-modal-div mw-100 px-3"> */}
            {/* <div className="staking-modal-header">{renderTitles()}</div> */}

            {props.showDepositModal && <DepositLiquidity />}
            {props.showWithdrawModal && <WithdrawLiquidity />}
            {props.showSwapModal && <Swap />}
            {/* </div>
      </div> */}
            <div>
                {props.showAddStakingModal && (
                    <AddStaking
                        startBlock={startBlock}
                        currentBlockNumber={currentBlockNumber}
                        yourLiquidity={yourLiquidity}
                        rewardsPerBlock={rewardsPerBlock}
                        blockNumber={blockNumber}
                        blockTime={blockTime}
                        totalRewardEarned={totalRewardEarned}
                        getRewardandBalanceAmount={getRewardandBalanceAmount}
                        endBlock={endBlock}
                        blockDiff={blockDiff}
                        onBack={onBack}
                    />
                )}
            </div>
        </div>
    )
}

export default StakingModal
