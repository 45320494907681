import React, { useState } from 'react';
import "./Governance.scss";
import searchIcon from "./../../assets/icons/search-icon.svg";
import CoinCard from "./sections/CoinCard/CoinCard";
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import Config from 'app/config/config';
interface IProps {}
const Governance: React.FC<IProps> = () => {
  const [governance, setGovernance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  React.useEffect(() => {
    axios.get(`${Config.governanceBaseUrl}/admin/governance/list`).then((res) => {
      if (res && res.data) {
        const { code, data } = res.data;
        if (code === 200) {
          const result = data.filter(x => {
           return x.status
          });
          setGovernance(result);
          setFilteredData(result);
          setIsLoading(false);
        }
      }
    });
  }, []);
  
  const handleSearch = (event) => {
    setFilteredData(governance);
    let value = event.target.value.toLowerCase();
    let result = [];
    if (value) {
      result = filteredData.filter((data) => {
        if(data.name) {
          return data.name.toLowerCase().includes(value);
        }
      });
      setFilteredData(result);
    }
  }

   return (
    <>
    <div className="container main-div main-coin-list">
      <div className="row">
        <div className="col-xl-6 col-md-6 col-sm-12 col-xsm-12 d-flex flex-column">
          <div className="input-container">
            <input
              type="email"
              className="search-bar"
              placeholder="Search Coin"
              onChange={(event) =>handleSearch(event)}
            />
           <img className="search-icon" alt="" src={searchIcon} />
          </div>
        </div>
        <div className="col-xl-6 col-md-6 col-sm-12 col-xsm-12 d-flex flex-column">
         <div className="protocol-block">
           <h2 className="total-protocol-text">
             ({governance ? governance.length : 0} Protocol)
           </h2>
           <button className="add-protocol-button">
             + Add Protocol
           </button>
         </div>
        </div>
      </div>
    
      <div className="divider-block">
        <h2 className="divider-text">
          All Governance
        </h2>
      </div>
    
      <div className="row"> 
        { 
          isLoading && <div className="spinner-container">
                <Spinner animation="border" variant="primary" />
          </div> 
        }
        {
          !isLoading && filteredData && filteredData.map((coin) => {
              return coin.status && <div className="col-xl-3 col-md-4 col-sm-6 col-xsm-12 d-flex flex-column">
              {/* {coin.title === 'Fulcrum' && <div className="notify-container">
                <h2 className="notify-badge absolute-center">1</h2>
              </div>} */}
              <CoinCard governanceId={coin.contractAddress} logo={coin.logo} title={coin.name} text={coin.nickName}></CoinCard>
            </div> 
          })
       }
       {
          !isLoading && (!filteredData || (filteredData && filteredData.length === 0)) && 
          <h2 className="no-data-found">No record found</h2>
       }
      </div>
    </div>
    </>
  );
};
export default Governance;
