import { supportedPools } from './../config/constants'
import {
    formattedNum,
    formattedPercent,
} from 'app/utils/context/PairContext/functions'
import { usePairData } from 'app/utils/context/PairContext/PairData'

const getPools = () => {
    if (!supportedPools) {
        return []
    }

    let data = supportedPools.map(
        ({ pid, name, icon, address, multiplier, tokenAddress }) => {
            const {
                token0,
                token1,
                reserve0,
                reserve1,
                reserveUSD,
                trackedReserveUSD,
                oneDayVolumeUSD,
                volumeUSD,
                volumeChangeUSD,
                oneDayVolumeUntracked,
                volumeChangeUntracked,
                liquidityChangeUSD,
            } = usePairData(address)

            const totalLiquidity = reserveUSD
                ? formattedNum(reserveUSD, true)
                : formattedNum(trackedReserveUSD, true)

            const liquidityChange = formattedPercent(liquidityChangeUSD)

            // volume
            let volume = !!oneDayVolumeUSD
                ? formattedNum(oneDayVolumeUSD, true)
                : formattedNum(oneDayVolumeUntracked, true)

            const usingUtVolume = volumeUSD === 0 && !!volumeUSD
            const volumeChange = formattedPercent(
                !usingUtVolume ? volumeChangeUSD : volumeChangeUntracked
            )

            // get fees	  // get fees
            let fees =
                oneDayVolumeUSD || oneDayVolumeUSD === 0
                    ? usingUtVolume
                        ? formattedNum(oneDayVolumeUntracked * 0.003, true)
                        : formattedNum(oneDayVolumeUSD * 0.003, true)
                    : '-'

            return {
                pid,
                tokenAddress,
                name,
                address,
                icon,
                multiplier,
                totalLiquidity,
                liquidityChange,
                volume,
                volumeChange,
                fees,
                token0,
                token1,
                reserve0,
                reserve1,
            }
        }
    )

    return data
}

const useAllPairs = () => {
    const pools = getPools()
    return pools
}

export default useAllPairs
