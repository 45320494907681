import React from 'react'

// components
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { Button } from 'react-bootstrap'

// styles
import 'react-vertical-timeline-component/style.min.css'

// assets
import up from '../../../../assets/images/project/up.svg'

interface IProps {}
const Roadmap: React.FC<IProps> = () => {
    return (
        <div className="roadmap-section">
            <div className="row justify-content-center">
                <div className="col-9">
                    <p className="header">ROADMAP</p>

                    <VerticalTimeline>
                        <VerticalTimelineElement
                            className="milestone mb-0"
                            iconClassName="milestone-icon blue d-none"
                            position="right"
                        >
                            <div className="to-future d-flex align-items-center">
                                <img src={up} alt="up" className="mr-1" />{' '}
                                <span>To the future...</span>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone mt-3"
                            iconClassName="milestone-icon orange"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Feb 2021</div>
                            <div className="milestone-title">Swap</div>
                            <div className="milestone-subtitle orange">
                                Product
                            </div>
                            <p className="milestone-description">
                                Introduce Swap on V2 to allow for smooth
                                onboarding from Fiat to DeFi
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone"
                            iconClassName="milestone-icon blue"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Feb 2021</div>
                            <div className="milestone-title">
                                Staking, Mobile App
                            </div>
                            <div className="milestone-subtitle blue">
                                Product
                            </div>
                            <p className="milestone-description">
                                1. Enhance the Staking product to extend the
                                ability to integrate yield farming options for
                                the Platform
                                <br />
                                2. Mobile App for Android and Apple Store
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone left"
                            iconClassName="milestone-icon orange"
                            position="left"
                        >
                            <div className="milestone-date mb-1">Jan 2021</div>
                            <div className="milestone-title">
                                Aggregated Governance, Consumer Adoption
                            </div>
                            <div className="milestone-subtitle orange">
                                Product
                            </div>
                            <p className="milestone-description">
                                1. Due to release an one stop governance product
                                aggregating all DeFi governance in one place
                                <br />
                                2. Staking directly with ADD.xyz will allow for
                                <br />
                                3. Initiate product marketing and adoption for
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone"
                            iconClassName="milestone-icon blue"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Dec 2020</div>
                            <div className="milestone-title">
                                Audit for Platform V2 completed
                            </div>
                            <div className="milestone-subtitle blue">Audit</div>
                            <p className="milestone-description">
                                Sucessfully completed Audit for Platform V2
                                build
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone"
                            iconClassName="milestone-icon orange"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Dec 2020</div>
                            <div className="milestone-title">
                                Release Platform V2
                            </div>
                            <div className="milestone-subtitle orange">
                                Product
                            </div>
                            <p className="milestone-description">
                                1. Integrated Fulcrum, Aave, Curve, YFI to the
                                initial lending V1 build <br />
                                2. Introducing Privacy solution Bl3nd3r to the
                                V2 Platform
                                <br /> 3. Working on Mobile Money solution for
                                Africa
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone left"
                            iconClassName="milestone-icon blue"
                            position="left"
                        >
                            <div className="milestone-date mb-1">Jul 2020</div>
                            <div className="milestone-title">
                                Completed Token Sale for $1m
                            </div>
                            <div className="milestone-subtitle blue">
                                Token Raise
                            </div>
                            <p className="milestone-description">
                                1. Completed token sale with the Private round
                                and listing on 9 Exchanges
                                <br />
                                2. Expanded team to exeute on product design and
                                development
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone"
                            iconClassName="milestone-icon orange"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Mar 2020</div>
                            <div className="milestone-title">
                                3rd Party integrations
                            </div>
                            <div className="milestone-subtitle orange">
                                Product
                            </div>
                            <p className="milestone-description">
                                1. Create an SDK to be able to integrate with
                                3rd parties
                                <br />
                                2. Aggregated Insurance will be added to the
                                PLatform V2
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone"
                            iconClassName="milestone-icon blue"
                            position="right"
                        >
                            <div className="milestone-date mb-1">Jan 2020</div>
                            <div className="milestone-title">
                                Release Platform V1
                            </div>
                            <div className="milestone-subtitle blue">
                                Product
                            </div>
                            <p className="milestone-description">
                                1. Team hired to execute on Platform V1 build{' '}
                                <br />
                                2. Compound and dYdX protocols integrated -
                                Multiple Protocols. Multiple Currencies.,
                                Multiple Wallets
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="milestone left"
                            iconClassName="milestone-icon orange"
                            position="left"
                        >
                            <div className="milestone-date mb-1">Jul 2019</div>
                            <div className="milestone-title">
                                $500k Seed round closed
                            </div>
                            <div className="milestone-subtitle orange">
                                Token Raise
                            </div>
                            <p className="milestone-description">
                                1. Conceptualised ADD.xyz as the first DeFi
                                aggregator platform
                                <br />
                                2. Conduced an initial token raise - NGC became
                                a marquee investor
                            </p>
                        </VerticalTimelineElement>
                    </VerticalTimeline>

                    <div
                        className="d-flex align-items-center justify-content-center roadmap-go-to-top-action"
                        onClick={() =>
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                        }
                    >
                        <text>Go Back to Top</text>
                    </div>

                    <div className="roadmap-action d-flex align-items-center justify-content-center">
                        <text className="mr-3">
                            To see our roadmap in more detail, click the "Our
                            Roadmap" button
                        </text>
                        <Button
                            className="btn-gradient-round connect-wallet-btn w-auto m-0"
                            onClick={() =>
                                window.open(
                                    `https://about.add.xyz/fullstack/roadmap`
                                )
                            }
                        >
                            <span className="action">Our Roadmap</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap
