import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './TopTab.scss'

interface IProps {
    step: number
}
const TopTab: React.FC<IProps> = (props) => {
    // States
    const checkProgress = () => {
        if (props.step === 0) {
            return 10
        } else if (props.step === 1) {
            return 30
        } else if (props.step === 2) {
            return 50
        } else if (props.step === 3) {
            return 67
        } else if (props.step === 4) {
            return 100
        }
    }

    const checkBackgroundStyle = (val: number) => {
        if (props.step >= val)
            return 'linear-gradient(141.09deg, #46EFFA 9.98%, #2000E3 94.32%)'
        else return '#3A3D41'
    }

    const circleTextStyle = (val: number) => {
        if (props.step >= val) return 'rgba(255,255,255,1)'
        else return '#D0C9D6'
    }

    const bottomTextStyle = (val: number) => {
        if (props.step >= val) return 'rgba(255,255,255,1)'
        else return 'rgba(255,255,255,0.5)'
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                paddingBottom: 15,
            }}
        >
            <div className="contentDiv">
                <div style={{ width: '60%', position: 'absolute', top: 14 }}>
                    <ProgressBar
                        now={checkProgress()}
                        className="progressBar"
                    />
                </div>
                <div className="step-div">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <text
                            style={{
                                background: checkBackgroundStyle(0),
                                color: circleTextStyle(0),
                            }}
                            className="circle-div"
                        >
                            1
                        </text>
                        <text
                            style={{
                                color: bottomTextStyle(0),
                                fontWeight: props.step === 0 ? 1000 : 'normal',
                            }}
                            className="bottom-text"
                        >
                            Select Coin
                        </text>
                    </div>
                </div>

                <div className="step-div">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <text
                            style={{
                                background: checkBackgroundStyle(1),
                                color: circleTextStyle(1),
                            }}
                            className="circle-div"
                        >
                            2
                        </text>
                        <text
                            style={{
                                color: bottomTextStyle(1),
                                fontWeight: props.step === 1 ? 1000 : 'normal',
                            }}
                            className="bottom-text"
                        >
                            Amount
                        </text>
                    </div>
                </div>

                <div className="step-div">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <text
                            style={{
                                background: checkBackgroundStyle(2),
                                color: circleTextStyle(2),
                            }}
                            className="circle-div"
                        >
                            3
                        </text>
                        <text
                            style={{
                                color: bottomTextStyle(2),
                                fontWeight: props.step === 2 ? 1000 : 'normal',
                            }}
                            className="bottom-text"
                        >
                            Choose Mixture
                        </text>
                    </div>
                </div>

                <div className="step-div">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <text
                            style={{
                                background: checkBackgroundStyle(3),
                                color: circleTextStyle(3),
                            }}
                            className="circle-div"
                        >
                            4
                        </text>
                        <text
                            style={{
                                color: bottomTextStyle(3),
                                fontWeight: props.step === 3 ? 1000 : 'normal',
                            }}
                            className="bottom-text"
                        >
                            Deposit
                        </text>
                    </div>
                </div>
                <div className="step-div">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <text
                            style={{
                                background: checkBackgroundStyle(4),
                                color: circleTextStyle(4),
                            }}
                            className="circle-div"
                        >
                            5
                        </text>
                        <text
                            style={{
                                color: bottomTextStyle(4),
                                fontWeight: props.step === 4 ? 1000 : 'normal',
                            }}
                            className="bottom-text"
                        >
                            Confirmation
                        </text>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TopTab
