import React from 'react'
import './ModalComponent.scss'
import Modal from 'react-modal'
import WalletConnector from './../WalletConnector/WalletConnector'
import cross_light from './../../../assets/images/project/cross-light.png'
import DepositModal from '../DepositModal/DepositModal'
import WithdrawModal from '../WithdrawModal/WithdrawModal'
import StakingModals from '../Staking/index'

interface IProps {
    visible: boolean
    onClose: Function
    isWalletModal?: boolean
    isDepositModal?: boolean
    isWithdrawModal?: boolean
    pendingActivity?: any
    allActivities?: any
    withdrawData?: {
        protocolId: number
        tokenId: number
    }
    balancesList?: any
}

const customStyles = (isDepositModal: boolean, isWalletModal: boolean) => ({
    content: {
        width: '60%',
        top: '50%',
        left: '50%',
        zIndex: 999,
        padding: '0px',
        background: isWalletModal
            ? '#161825'
            : 'linear-gradient(144.05deg, #282A2E -69.07%, #101113 122.22%)',
        boxShadow:
            '0px 0px 50px rgba(0, 0, 0, 0.25), 0px 0px 50px rgba(2, 3, 3, 0.7)',
        border: 'none',
        borderRadius: isWalletModal ? '16px' : 'none',
        height: isDepositModal ? '80%' : isWalletModal ? '55%' : '70%',
        transform: 'translate(-50%, -50%)',
    },
})

const ModalComponent: React.FC<IProps> = (props) => {
    const [modalIsOpen, setIsOpen] = React.useState(props.visible)

    const closeModal = () => {
        setIsOpen(false)
        props.onClose()
    }

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Wallet Modal"
                style={customStyles(props.isDepositModal, props.isWalletModal)}
                shouldCloseOnOverlayClick={false}
                className="modal-style-lending"
            >
                {props.isWalletModal ? (
                    ''
                ) : (
                    <div className="d-flex float-right mt-4 mr-5">
                        <a
                            href="/#"
                            type="simpleQuery"
                            onClick={(e) => {
                                e.preventDefault()
                                closeModal()
                            }}
                        >
                            <img
                                src={cross_light}
                                alt="Light cross"
                                style={{ width: '15px' }}
                            />
                        </a>
                    </div>
                )}

                <div>
                    {props.isWalletModal ? (
                        <WalletConnector closeModal={closeModal} />
                    ) : (
                        ''
                    )}
                    {props.isDepositModal && (
                        <DepositModal
                            pendingActivity={props.pendingActivity}
                            allActivities={props.allActivities || []}
                        />
                    )}
                    {props.isWithdrawModal && (
                        <WithdrawModal
                            pendingActivity={props.pendingActivity}
                            withdrawData={props.withdrawData}
                            balancesList={props.balancesList}
                        />
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default ModalComponent
