/* eslint-disable */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './privacy.scss'
import cross_arrows from '../../assets/images/project/cross_arrows.png'
import info from '../../assets/images/project/info.png'
import ModalComponent from '../../shared/components/Modal/ModalComponent'
import DepositModal from '../../shared/components/Privacy/DepositModal/PrivacyDepositModal'
import WithdrawModal from '../../shared/components/Privacy/WithdrawModal/PrivacyWithdrawModal'
import WithdrawConfirmationModal from 'app/shared/components/Privacy/WithdrawModal/PrivacyWithdrawConfirmationModal'
import { connectWallet } from '../../utils/tornado/tornado'
import NavPills from '../../shared/components/Tabs/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { Dropdown } from 'react-bootstrap'
import Eth1 from '../../assets/images/project/eth_1.svg'
import Dai from '../../assets/images/project/Dai_1.svg'
import Vector from '../../assets/icons/Vector.png'
import ArrowUp from '../../assets/icons/arrow_up.svg'
import ArrowDown from '../../assets/icons/arrow_down.svg'
import { Slide, toast } from 'react-toastify'

import { Button, Form, Col } from 'react-bootstrap'
import Select from '../../shared/components/DropDown/Dropdown'
import Checkbox from 'app/shared/components/Checkbox/Checbox'
import CustomInput from 'app/shared/components/CustomInput/CustomInput'
import { CURRENCIES } from 'app/utils/enums/currencies'
import { config } from 'app/utils/tornado/config'
import moment from 'moment'
const WAValidator = require('wallet-address-validator')

import {
    processConfirmDeposit,
    generateSecret,
    getEthBalance,
    getERC20Balance,
} from 'app/utils/tornado/tornado'
import {
    parseNote,
    withDrawal,
    waitForTxReceipt,
    getRelayerServiceFee,
} from 'app/utils/tornado/tornado'

import {
    checkLocalStorage,
    checkLogin,
    getStoredUserAuth,
} from '../../utils/Utilities/contextUtils'
import { authContext } from '../../utils/context/AuthContext'
import Statistics from 'app/shared/components/Statistics/statistics'
import Config from '../../config/config'

interface IProps {}
const Privacy: React.FC<IProps> = () => {
    const notify = (msg: String) =>
        toast(msg, {
            position: 'top-right',
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
        })
    const DEAFULT_LOAD_MSG = 'Please confirm transaction in MetaMask...'

    // States
    const [showStatistics, setShowStatistics] = useState(false)
    const [showDepositModal, setShowDepositModal] = useState(false)
    const [showWithdrawModal, setShowWithdrawModal] = useState(false)
    const [showWithdrawConfirmationModal, setShowWithdrawConfirmationModal] =
        useState(false)
    const [clientInfo, setClientInfo] = useState<any>(false)
    const [amounts, setAmounts] = useState([
        {
            value: 0.1,
            isSelected: false,
        },
        {
            value: 0.5,
            isSelected: false,
        },
        {
            value: 1,
            isSelected: false,
        },
        {
            value: 10,
            isSelected: false,
        },
        {
            value: 100,
            isSelected: false,
        },
    ])
    const [toggleView, setToggleView] = useState('1')

    /**
     * openModal cases
     * === -1 // don't open
     * === 0 // open wallets modal
     */
    const [openModal, setOpenModal] = useState(-1)

    // Context store
    const { auth: user, setAuthStatus } = React.useContext(authContext)

    /**
     * Check if user is authenticated
     * if there is no authContext check local storage
     * if there is data in local storage then update context else open wallet modal;
     */
    React.useEffect(() => {
        if (!checkLogin(user)) {
            if (checkLocalStorage()) {
                setAuthStatus(getStoredUserAuth())
            }
        }
        connectWallet()
        getClientInfo()
    }, [])

    function statisticsHandler() {
        setShowStatistics(!showStatistics)
    }

    const getClientInfo = () => {
        axios.get(Config.ipGeolocationUrl).then((res) => {
            const data = {
                ipAddress: res.data.ip_address,
                city: res.data.city,
            }
            setClientInfo(data)
        })
    }

    const handleClick = (index) => {
        let newArr = [...amounts]
        console.log(newArr[index]['isSelected'])
        newArr[index]['isSelected'] = true
        setAmounts(newArr)
    }
    // -------------------- CHECK VALID DATA --------------

    const isValidData = () => {
        if (amount === 0) {
            return true
        } else {
            false
        }
    }

    // ------------------------- SELECT CURRENCY ---------------------

    const [selectedCoin, setSelectedCoin] = useState(CURRENCIES.ETH)
    const [coin, selectCoin] = useState<any>(CURRENCIES.ETH)

    function selectCoinHandler(type: CURRENCIES) {
        setBgColor(-1)
        setAmountError(false)
        if (type === CURRENCIES.ETH) {
            setSelectedCoin(CURRENCIES.ETH)
        } else if (type === CURRENCIES.DAI) {
            setSelectedCoin(CURRENCIES.DAI)
        }
    }
    //  ------------- SELECT AMOUNT ----------------

    const [bgColor, setBgColor] = useState<any>(-1)

    const Eth_AmountList = Object.keys(
        config.netId1.TORNADO.ETH.instanceAddress
    )
    const Dai_AmountList = Object.keys(
        config.netId1.TORNADO.DAI.instanceAddress
    )
    const [amountList, setAmountList] = useState(Eth_AmountList.sort())
    useEffect(() => {
        if (selectedCoin == CURRENCIES.ETH) {
            setAmountList(Eth_AmountList.sort())
        } else {
            setAmountList(Dai_AmountList.sort())
        }
    }, [selectedCoin])

    const [amount, setAmount] = useState(0)

    // ---------------- Amount OnSubmit --------------------
    const [amountError, setAmountError] = useState(false)

    async function verifyBalance() {
        const mixture = selectedMixture ? selectedMixture : 'TORNADO'

        let balance: number = 0
        try {
            if (selectedCoin === CURRENCIES.ETH) {
                balance = await getEthBalance(
                    user.walletAddress,
                    CURRENCIES.ETH
                )
            } else if (selectedCoin === CURRENCIES.DAI) {
                const contractAddress =
                    config[`netId${user.chainId}`][`${mixture}`][
                        `${selectedCoin}`
                    ].tokenAddress
                balance = await getERC20Balance(
                    user.walletAddress,
                    CURRENCIES.DAI,
                    contractAddress
                )
            }
        } catch (err) {
            console.log('error while verifying user balance ', err)
        }
        return balance
    }

    const [note, setNote] = useState('')
    let selectedMixture = 'BLENDER'

    async function enterAmountHandler() {
        const balance = await verifyBalance()
        console.log('balance is', balance)

        if (balance < amount) {
            setAmountError(true)

            return
        }
        setToggleView('2')

        const intialDepositState: any = await generateSecret(
            selectedCoin,
            amount,
            selectedMixture
        )

        // --------------------- SET NOTE ---------------------------

        if (intialDepositState) {
            setNote(intialDepositState.note)
        }
    }

    // ---------------------------- SEND DEPOSIT SUBMIT --------------------------
    const [checked, setChecked] = useState(false)
    const isBackedUpChecked = () => {
        if (checked) {
            return false
        } else {
            return true
        }
    }
    const [loaderMessage, setLoaderMessage] = useState(DEAFULT_LOAD_MSG)
    const [txHash, setTxHash] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [transactionsHistory, setTransactionsHistory] = useState<any>(false)

    // Store Transaction
    const storeTransaction = (txHash: string) => {
        const transactions = JSON.parse(
            localStorage.getItem(
                `${user.walletAddress}${selectedCoin}Transactions`
            )
        )

        const newTransaction = {
            amount,
            txHash,
            note,
            coin: selectedCoin,
            status: 'Unspent',
            timePassed: moment.now(),
        }

        let currentTransactions: any

        if (transactions) {
            currentTransactions = [...transactions]
            currentTransactions.unshift(newTransaction)
        } else {
            currentTransactions = [newTransaction]
        }

        setTransactionsHistory(currentTransactions)
        localStorage.setItem(
            `${user.walletAddress}${selectedCoin}Transactions`,
            JSON.stringify(currentTransactions)
        )
    }
    const [hash, setHash] = useState('')
    async function sendDepositHandler() {
        if (checked) {
            try {
                let tx = await processConfirmDeposit(note, selectedMixture)
                console.log(tx)
                if (tx?.status === true) {
                    setLoaderMessage('Trasaction Sent Successfuly')
                    setTxHash(tx.transactionHash)
                    console.log('hash', tx.transactionHash)
                    setHash(tx.transactionHash)
                    setTimeout(() => {
                        setLoading(false)
                    }, 2500)
                    storeTransaction(tx.transactionHash)
                    setToggleView('3')
                    timer()
                }
            } catch (error) {
                if (error.code === 4001) {
                    toast.error('Transaction Rejected From Metamask')
                }
                setLoading(false)
                setToggleView('1')
                setChecked(false)
                setAmount(0)
                setSelectedCoin(CURRENCIES.ETH)
                setBgColor(-1)
            }
        } else {
            notify('Please Check the Backed Up Note !')
            setChecked(false)
            setAmount(0)
            setSelectedCoin(CURRENCIES.ETH)
            setBgColor(-1)
        }
    }

    // Set Timer

    const [minute, setMinutes] = useState<string | number>()
    const [second, setSeconds] = useState<string | number>()

    const timer = () => {
        // Set the date we're counting down to
        var countDownDate = new Date().getTime() + 30 * 60000 //30 is for 30 minutes

        // Update the count down every 1 second
        var x = setInterval(function () {
            // Get today's date and time
            var now = new Date().getTime()

            // Find the distance between now and the count down date
            var distance = countDownDate - now

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24))
            var hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            var minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            )
            var seconds = Math.floor((distance % (1000 * 60)) / 1000)
            console.log(days, hours, minutes, seconds)
            setMinutes(minutes)
            setSeconds(seconds)

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x)
                console.log('time over')
            }
        }, 1000)
    }
    const checkTimer = () => {
        if (minute && second === 0) {
            return false
        } else {
            return true
        }
    }

    // ---------------------------------------------------- WTHDRAW TAB SUBMIT --------------------------------------------------
    const [address, setReceipentAddress] = useState<string>()
    const [parsedNote, setParsedNote] = useState({})
    const [noteError, setNoteError] = useState('')
    const [noteAmount, setNoteAmount] = useState('')
    const [currency, setCurrency] = useState('')
    const [addressError, setAddressError] = useState('')
    const [isValidAddress, setValidAddress] = useState(false)
    const [fee, setFee] = useState('')
    const [isWithdrawSuccessful, setWithdrawSuccessful] = useState(false)
    const [withdrawError, setWithdrawError] = useState('')

    const validateNote = async (note: string) => {
        console.log(note)
        setLoading(true)
        try {
            const parseItems = await parseNote(note)
            setParsedNote(parseItems)
            setNoteError('')
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setNoteError(err.toString())
            // TODO handle exceptions
        }
    }

    const validateAddress = (address: string) => {
        let check = WAValidator.validate(address, 'ethereum')
        if (!check) {
            setAddressError('Error: This is not a valid address')
        } else {
            setValidAddress(true)
            setAddressError('')
        }
    }

    // Check if Empty Function

    function isEmpty(obj: Object) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false
        }
        return true
    }

    const startWithdrawal = async () => {
        try {
            if (!isValidAddress) {
                setAddressError('Address is required')
            } else {
                setAddressError('')
            }
            console.log('note', note)
            if (!isEmpty(parsedNote)) {
                if (isValidAddress) {
                    // setWithdrawLoading(true)
                    setLoading(true)
                    const unConfirmedHash = await withDrawal(note, address)
                    if (unConfirmedHash) {
                        // TODO update Your trasnaction is broadcasted
                        setLoaderMessage('Your transaction is broadcasted')
                        const confimedTxDetails: any = await waitForTxReceipt(
                            unConfirmedHash
                        )
                        if (confimedTxDetails?.status === true) {
                            const fee = await getRelayerServiceFee()
                            fee && setFee(fee)
                            setTxHash(unConfirmedHash)

                            setNoteAmount(note.split('-')[2])
                            setCurrency(note.split('-')[1])
                            // TODO update Your trasnaction is Mined and sent Successfully
                            setLoaderMessage(
                                'Your trasnaction is Mined and sent Successfully'
                            )
                            setTimeout(() => {
                                setLoading(false)
                            }, 3500)
                            setWithdrawSuccessful(true)
                            setParsedNote({})
                            setValidAddress(false)
                            updateTransaction()
                        } else {
                            // TODO Trasnaction Failed, Please try again later
                            setLoading(false)
                            setWithdrawError(
                                'Trasnaction Failed, Please try again later'
                            )
                            console.log('Transaction Failed ')
                        }
                    } else {
                        throw new Error('Failed to sendTransaction')
                    }
                }
            } else {
                setNoteError('Note is required')
            }
        } catch (err) {
            setLoading(false)
            setWithdrawError(err.toString())
        }
    }

    // Update Transaction
    const updateTransaction = () => {
        const currency = note.split('-')[1]

        const transactions = JSON.parse(
            localStorage.getItem(`${user.walletAddress}${currency}Transactions`)
        )

        const index = transactions.findIndex((t: any) => t.note === note)

        if (index >= 0) {
            transactions[index].status = 'Spent'

            localStorage.setItem(
                `${user.walletAddress}${currency}Transactions`,
                JSON.stringify(transactions)
            )
        }
    }

    return (
        <div
            className="mainContainerMarginTop mb-5 px-3"
        >
            <div className=" main-div">
                {!showStatistics &&
                    !showDepositModal &&
                    !showWithdrawModal &&
                    !showWithdrawConfirmationModal && (
                        <>
                            <div className="">
                                {/* <text className="title-text grey-gradient privacy-heading">
              WELCOME <text className="text-gradient">TO BL3ND3R</text>
            </text> */}
                                <text
                                    className="privacy-subheading"
                                    style={{
                                        fontSize: '32px',
                                        lineHeight: '39.62px',
                                    }}
                                >
                                    Blender
                                </text>
                            </div>
                            <div className="d-flex mb-5 balance-pills">
                                <div className="p-3 balance-box d-flex flex-column flex-fill mr-3 mb-3">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Total ETH Deposited</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        {/* <span>{`$ ${numberWithCommas(totalBalance.toFixed(3))}`}</span> */}
                                        <span>{`$ 1,890,467.34`}</span>
                                        <span className="ml-3 apy-text">
                                            <img
                                                src={require(`./../../assets/icons/Shape.svg`)}
                                            />
                                            $1,890,467.34
                                        </span>
                                    </div>
                                </div>
                                <div className="p-3 balance-box d-flex flex-column mr-3 mb-3 flex-fill">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Unique Users</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        <span>{`$ 390.85`}</span>
                                        <span className="ml-3 apy-text">
                                            <img
                                                src={require(`./../../assets/icons/Shape.svg`)}
                                            />
                                            +590
                                        </span>
                                    </div>
                                </div>
                                <div className="p-3 balance-box d-flex flex-column mr-3 mb-3 flex-fill">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Total Deposits</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        <span>{`$ 1,890,467.34`}</span>
                                        <span className="ml-3 apy-text">
                                            <img
                                                src={require(`./../../assets/icons/Shape.svg`)}
                                            />
                                            $1,890,467.34
                                        </span>
                                    </div>
                                </div>
                                <div className="p-3 balance-box d-flex flex-column mr-3 mb-3 flex-fill">
                                    <div className="mb-2 balance-box-txt">
                                        <span>Total Amount Locked</span>
                                    </div>
                                    <div className="balance-box-amount">
                                        <span>{`$ 390.33`}</span>
                                        <span className="ml-3 apy-text">
                                            <img
                                                src={require(`./../../assets/icons/Shape.svg`)}
                                            />
                                            $1,890,467.34
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row justify-content-center pb-3 mb-5 privacy-subheading main-div">
            <span className="">
              <span className="text-gradient">BL3ND3R </span>
              allows you to make your transactions fully untraceable
            </span>
          </div> */}

                            <div className="row justify-content-start privacy-card-div m-0">
                                <div className="col-xl-4 col-lg-7 col-md-7 ">
                                    <p className="big-tile-content d-flex justify-content-center">
                                        Your finance deserves Privacy and
                                        Security
                                    </p>
                                </div>
                                <div className="col-xl-8 col-lg-3 col-md-3 ">
                                </div>
                                {/*  options-main-shadow */}
                                <div className="col-lg-6 col-md-6 col-sm-10 col-xs-11 p-5 options-main-div ">
                                    {amountError ? (
                                        <div className="text-left error-div">
                                            <svg
                                                width="17"
                                                height="16"
                                                viewBox="0 0 17 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clip-path="url(#clip0_17046_33377)">
                                                    <path
                                                        d="M8.38379 15.9944C12.8021 15.9944 16.3838 12.415 16.3838 7.99963C16.3838 3.58425 12.8021 0.00488281 8.38379 0.00488281C3.96551 0.00488281 0.383789 3.58425 0.383789 7.99963C0.383789 12.415 3.96551 15.9944 8.38379 15.9944Z"
                                                        fill="#FBFBFB"
                                                    />
                                                    <path
                                                        d="M10.9219 11.7734L12.1592 10.5361L5.84672 4.22368L4.60947 5.46092L10.9219 11.7734Z"
                                                        fill="#F85359"
                                                    />
                                                    <path
                                                        d="M5.84867 11.7763L12.1611 5.46387L10.9239 4.22662L4.61143 10.5391L5.84867 11.7763Z"
                                                        fill="#F85359"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_17046_33377">
                                                        <rect
                                                            width="16"
                                                            height="16"
                                                            fill="white"
                                                            transform="translate(0.383789)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            {'  '} Insufficent Balance
                                        </div>
                                    ) : null}
                                    {toggleView === '1' ? (
                                        <Tab.Container defaultActiveKey="first">
                                            <Nav
                                                variant="pills"
                                                defaultActiveKey="/first"
                                                className="justify-content-center"
                                            >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">
                                                        Deposit
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">
                                                        Withdraw
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className="mt-4">
                                                <Tab.Pane eventKey="first">
                                                    <div className="dropdown-wrapper d-flex justify-content-center">
                                                        <Select
                                                            options={[
                                                                {
                                                                    icon: Eth1,
                                                                    title: 'ETH',
                                                                    id: 1,
                                                                },
                                                                {
                                                                    icon: Dai,
                                                                    title: 'DaI',
                                                                    id: 2,
                                                                },
                                                            ]}
                                                            handleChange={
                                                                selectCoinHandler
                                                            }
                                                        />

                                                        {/* <Form>
                            <Form.Row>
                           
                
                              <Form.Group as={Col} className="inputs" controlId="formGridState">
                
                
                
                                <Form.Label style={{color:'#8B8CA7', fontSize:'16px'}}>Token</Form.Label>
                                <Form.Control className="input" as="select" defaultValue="eth">
                                <option value="h:464e" data-thumbnail="http://icons.iconarchive.com/icons/tinylab/android-lollipop-apps/24/Twitter-icon.png">Eth</option>
                
                                  <option>dai</option>
                                </Form.Control>
                              </Form.Group>
                            </Form.Row>
                          </Form> */}
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <Form.Label
                                                            style={{
                                                                color: '#8B8CA7',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            Amount
                                                        </Form.Label>{' '}
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center amounts-div">
                                                        {amountList.map(
                                                            (amount, index) => (
                                                                <div
                                                                    className=" mb-4"
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setAmount(
                                                                            parseFloat(
                                                                                amount
                                                                            )
                                                                        )
                                                                        setBgColor(
                                                                            index
                                                                        )
                                                                    }}
                                                                    onBlur={() =>
                                                                        setBgColor(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`deposit-pills ${
                                                                            bgColor ===
                                                                            index
                                                                                ? 'bg-blue'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {amount}{' '}
                                                                        {selectedCoin ===
                                                                        'ETH'
                                                                            ? 'ETH'
                                                                            : 'DAI'}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="d-flex justify-content-center mt-5">
                                                        <Button
                                                            className="btn-squared"
                                                            // onClick={() =>
                                                            //     setToggleView(
                                                            //         '2'
                                                            //     )
                                                            // }
                                                            disabled={isValidData()}
                                                            onClick={
                                                                enterAmountHandler
                                                            }
                                                        >
                                                            <span
                                                                className="p-3"
                                                                style={{
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                            >
                                                                Continue
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <CustomInput
                                                        label="Note"
                                                        placeholder="Please enter your note"
                                                        handleChange={(value) =>
                                                            setNote(value)
                                                        }
                                                        validateNote={(
                                                            value
                                                        ) => {
                                                            validateNote(value)
                                                        }}
                                                    />
                                                    <small className="text-red">
                                                        {noteError}
                                                    </small>
                                                    <CustomInput
                                                        label="Recipient Address"
                                                        placeholder="Please paste address here"
                                                        secondaryLabel="Donate"
                                                        handleChange={(value) =>
                                                            setReceipentAddress(
                                                                value
                                                            )
                                                        }
                                                        validateNote={(
                                                            value
                                                        ) => {
                                                            validateAddress(
                                                                value
                                                            )
                                                        }}
                                                    />
                                                    <small className="text-red">
                                                        {addressError}
                                                    </small>

                                                    <div className="d-flex justify-content-center mt-5">
                                                        <Button
                                                            className="btn-squared"
                                                            onClick={() =>
                                                                // setShowWithdrawConfirmationModal(
                                                                //     !showWithdrawConfirmationModal
                                                                // )
                                                                startWithdrawal()
                                                            }
                                                        >
                                                            <span
                                                                className="p-3"
                                                                style={{
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                            >
                                                                Withdraw
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    ) : toggleView === '2' ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <text
                                                    className="privacy-subheading"
                                                    style={{
                                                        fontSize: '24px',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    Confirm Details
                                                </text>
                                            </div>
                                            <text
                                                className="privacy-subheading"
                                                style={{
                                                    fontSize: '18px',
                                                    lineHeight: '50px',
                                                }}
                                            >
                                                Summary
                                            </text>
                                            <table className="table privacy-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="table-label p-0">
                                                            Amount
                                                        </td>
                                                        <td className="table-value">
                                                            {amount}{' '}
                                                            {selectedCoin}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-label p-0">
                                                            Service
                                                        </td>
                                                        <td className="table-value">
                                                            Bl3enD3r
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className="disclaimer-text">
                                                Disclaimer
                                            </p>
                                            <p
                                                className="big-tile-content"
                                                style={{
                                                    fontSize: '14px',
                                                    lineHeight: '25px',
                                                }}
                                            >
                                                Please backup your note. If you
                                                lose it, you won’t be able to
                                                get your deposit back.
                                            </p>

                                            <div
                                                className="deposit-pills"
                                                style={{
                                                    width: '420px',
                                                    maxWidth: '420px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {note && note}
                                                </p>
                                            </div>
                                            <Checkbox
                                                title="I’ve backed up the note"
                                                className="mt-4"
                                                checked={checked}
                                                handleChange={(value) =>
                                                    setChecked(!checked)
                                                }
                                            />
                                            <div className="d-flex justify-content-center mt-5">
                                                <Button
                                                    className="btn-squared btn-block"
                                                    // onClick={() =>
                                                    //     setToggleView('3')
                                                    // }
                                                    onClick={sendDepositHandler}
                                                    size="lg"
                                                    disabled={isBackedUpChecked()}
                                                >
                                                    <span
                                                        className="p-3"
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        Send Deposit
                                                    </span>
                                                </Button>
                                            </div>
                                        </>
                                    ) : toggleView === '3' ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <text
                                                    className="privacy-subheading"
                                                    style={{
                                                        fontSize: '24px',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    Deposit Details
                                                </text>
                                            </div>
                                            <text
                                                className="privacy-subheading"
                                                style={{
                                                    fontSize: '18px',
                                                    lineHeight: '50px',
                                                }}
                                            >
                                                Summary
                                            </text>

                                            <table className="table privacy-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="table-label">
                                                            Amount
                                                        </td>
                                                        <td className="table-value">
                                                            {amount}{' '}
                                                            {selectedCoin}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-label">
                                                            Service
                                                        </td>
                                                        <td className="table-value">
                                                            Bl3enD3r
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-label">
                                                            TX Hash
                                                        </td>
                                                        <td className="table-value">
                                                            {hash && hash}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-label">
                                                            Note
                                                        </td>
                                                        <td className="table-value d-flex justify-content-end align-items-center ">
                                                            {/* {note && note}{' '} */}
                                                            <input
                                                                type="password"
                                                                name=""
                                                                className="form-control"
                                                                value={
                                                                    note && note
                                                                }
                                                                id=""
                                                                style={{
                                                                    width: '200px',
                                                                    maxWidth:
                                                                        '200px',
                                                                    border: 'none',
                                                                }}
                                                            />
                                                            <img
                                                                src={Vector}
                                                                width="10"
                                                                alt=""
                                                                className="ml-1 p-0"
                                                            />{' '}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="deposit-pills d-flex justify-content-center bg-img">
                                                <p className="disclaimer-text">
                                                    {minute && minute}:
                                                    {second && second} Min left
                                                </p>
                                            </div>
                                            <p
                                                className="big-tile-content text-center p-3"
                                                style={{
                                                    fontSize: '14px',
                                                    lineHeight: '25px',
                                                }}
                                            >
                                                We recommmend that you wait for
                                                at least 30mins before
                                                withdrawal your token
                                            </p>

                                            <div className="d-flex justify-content-center mt-5">
                                                <Button
                                                    className="btn-squared btn-block"
                                                    size="lg"
                                                    disabled={checkTimer()}
                                                >
                                                    <span
                                                        className="p-3"
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        Withdraw
                                                    </span>
                                                </Button>
                                            </div>
                                            <div className="d-flex justify-content-center mt-3">
                                                <a className="text-blue">
                                                    See Liqiuidity Stats
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {/* <div className="row justify-content-center">
                <div className="">
                  <text
                    className="big-title-text"
                    style={{ fontSize: '28.4135px', lineHeight: '39px' }}
                  >
                    Deposit
                  </text>
                </div>
              </div> */}
                                    <div className="text-center">
                                        {/* <span>
                  Mix your coins to gain full anonymity on the Ethereum
                  Blockchain.
                  Click
                  <span className="font-weight-bold">“Deposit”</span> to start
                  the easy process.
                </span> */}
                                    </div>
                                    {/* <div className="d-flex justify-content-center">
                <Button
                  className="btn-gradient-round"
                  onClick={() => setShowDepositModal(!showDepositModal)}
                >
                  <span className="p-3" style={{ textTransform: 'capitalize' }}>
                    Deposit
                  </span>
                </Button>
              </div> */}
                                </div>
                                {/* <div className="col-lg-2 col-md-12 col-sm-12 row align-items-center justify-content-center mt-5 mb-5">
              <h3 className="lightText">OR</h3>
            </div>
            <div className="col-lg-4 col-md-8 justify-content-start p-5 options-main-div options-main-shadow">
              <div className="row justify-content-center">
                <div className="">
                  <text
                    className="big-title-text"
                    style={{ fontSize: '28.4135px', lineHeight: '39px' }}
                  >
                    Withdraw
                  </text>
                </div>
              </div>
              <div className="content">
                <span>
                  Already
                  <span className="text-gradient pr-1 pl-1">Bl3nd3d</span> your
                  coins? <span className="font-weight-bolder d-block">Get your backup note </span><span className="font-weight-bolder">ready</span> to withdraw your funds.
                </span>
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn-gradient-round"
                  onClick={() => setShowWithdrawModal(!showWithdrawModal)}
                >
                  <span className="p-3" style={{ textTransform: 'capitalize' }}>
                    Withdraw
                  </span>
                </Button>
              </div>
            </div> */}
                            </div>
                        </>
                    )}
                {openModal === 0 && (
                    <ModalComponent
                        visible={true}
                        isWalletModal={openModal === 0 ? true : false}
                        onClose={() => {
                            setOpenModal(-1)
                        }}
                    />
                )}
                {showDepositModal && (
                    <DepositModal
                        visible={showDepositModal}
                        setVisible={setShowDepositModal}
                        isStatistics={showStatistics}
                        setIsStatistics={setShowStatistics}
                        setWithdrawModal={setShowWithdrawModal}
                    />
                )}
                {showWithdrawModal && (
                    <WithdrawModal
                        visible={showWithdrawModal}
                        setVisible={setShowWithdrawModal}
                        isStatistics={showStatistics}
                        setIsStatistics={setShowStatistics}
                    />
                )}
                {showWithdrawConfirmationModal && (
                    <WithdrawConfirmationModal
                        visible={showWithdrawConfirmationModal}
                        setVisible={setShowWithdrawConfirmationModal}
                        isStatistics={showStatistics}
                        setIsStatistics={setShowStatistics}
                    />
                )}
                {showStatistics && (
                    <Statistics
                        visible={showStatistics}
                        setVisible={setShowStatistics}
                    />
                )}
                {/* <div className="mt-5 mb-5 col-lg-12">
        <div className="d-flex align-items-center justify-content-center footer-buttons">
          <div className="p-4 right-sub-footer row align-items-center">
            <div className="d-flex flex-column">
              <div className="pr-4 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <text className="anonymity-text">ANONYMITY SET</text>
                </div>
                <span style={{ fontSize: '12px', paddingLeft: '30px' }}>
                  Your IP{' '}
                  <span
                    style={{
                      paddingLeft: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    {clientInfo.ipAddress} {clientInfo.city}
                  </span>
                </span>
              </div>
            </div>
            {/* <div className="d-flex align-items-start justify-content-center">
              <img
                src={cross_arrows}
                alt="Select A Coin"
                className="pl-3 pt-1"
              />
            </div> *
          </div>
        </div>
      </div> */}
            </div>
        </div>
    )
}
export default Privacy
