import React from 'react'
import './QuestionAccordion.scss'
import icon from '../../../assets/icons/chevron-down.svg'

interface IProps {
    title?: string
}
const QuestionsAccordion: React.FC<IProps> = ({ title }) => {
    return (
        <>
            <div className="accordion" id="accordionExample">
                <div className="questions-heading ml-2">
                    <text className="questions-title">Got Questions?</text>
                    <text className="questions-subtitle">
                        We’ve got answers
                    </text>
                </div>
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                How is interest calculated{' '}
                                <img src={icon} width="10" alt="" />
                            </button>
                        </h2>
                    </div>

                    <div
                        id="collapseOne"
                        className="collapse "
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body px-5 p-5">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptate quod quaerat aut ut beatae
                            necessitatibus placeat quae voluptates asperiores
                            amet.
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                How safe is the Compound protocol?
                                <img src={icon} width="10" alt="" />
                            </button>
                        </h2>
                    </div>
                    <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body  px-5">
                            Some placeholder content for the second accordion
                            panel. This panel is hidden by default.
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                What protocol do you use for lending?
                                <img src={icon} width="10" alt="" />
                            </button>
                        </h2>
                    </div>
                    <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body  px-5">
                            And lastly, the placeholder content for the third
                            and final accordion panel. This panel is hidden by
                            default.
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="heading4">
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapse4"
                                aria-expanded="false"
                                aria-controls="collapse4"
                            >
                                How safe is the Compound protocol?
                                <img src={icon} width="10" alt="" />
                            </button>
                        </h2>
                    </div>
                    <div
                        id="collapse4"
                        className="collapse"
                        aria-labelledby="heading4"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body  px-5">
                            And lastly, the placeholder content for the third
                            and final accordion panel. This panel is hidden by
                            default.
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="heading5">
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapse5"
                                aria-expanded="false"
                                aria-controls="collapse5"
                            >
                                How safe is the Compound protocol?
                                <img src={icon} width="10" alt="" />
                            </button>
                        </h2>
                    </div>
                    <div
                        id="collapse5"
                        className="collapse"
                        aria-labelledby="heading5"
                        data-parent="#accordionExample"
                    >
                        <div className="card-body  px-5">
                            And lastly, the placeholder content for the third
                            and final accordion panel. This panel is hidden by
                            default.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionsAccordion
