const circomlib = require('circomlib')
const mimcsponge = circomlib.mimcsponge
const snarkjs = require('snarkjs')

const bigInt = snarkjs.bigInt

export class MimcSpongeHasher {
    hash(level: any, left: any, right: any) {
        return mimcsponge.multiHash([bigInt(left), bigInt(right)]).toString()
    }
}
