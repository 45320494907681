import React, { useState, useRef } from 'react'
import { gql, useMutation } from '@apollo/client'

// components
import ReCAPTCHA from 'react-google-recaptcha'

// utils
import { showNotification } from '../../../../utils/notifications/notificationService'

// config
import config from '../../../../config/config'

// assets
import Subscribe from '../../../../assets/images/home/subscribe.svg'

//eslint-disable-next-line
const emailRegex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface IProps {
    headerText: string
}
const DebitCard: React.FC<IProps> = ({ headerText }) => {
    const [email, setEmail] = useState({ value: '', error: '' })

    const recaptchaRef = useRef(null)

    const onChangeEmail = (value: string) => {
        const isValid = emailRegex.test(value)
        setEmail({ value, error: isValid ? '' : 'Invalid email address.' })
    }

    const SUBSCRIBE_EMAIL_MUTATION = gql`
        mutation subscribeEmail($email: String!, $captcha_key: String) {
            subscribeEmail(email: $email, captcha_key: $captcha_key)
        }
    `

    const [subscribeEmail] = useMutation(SUBSCRIBE_EMAIL_MUTATION)

    const executeReCaptcha = () => {
        email.value &&
            !email.error &&
            recaptchaRef.current.props.grecaptcha.execute()
    }

    const subscribe = (captchaKey: string) => {
        try {
            subscribeEmail({
                variables: {
                    email: email.value,
                    captcha_key: captchaKey,
                },
            })
                .then(() => {
                    showNotification('Subscribed to our Newsletter!', 'success')
                    setEmail({ value: '', error: '' })
                })
                .catch(() => {
                    showNotification(
                        `Failed to subscribe, please try again later.`,
                        'error'
                    )
                })
                .finally(() => recaptchaRef.current.props.grecaptcha.reset())
        } catch (e) {}
    }

    return (
        <div className="add-updates-wrapper mx-auto pb-5 mb-5">
            <p className="add-updates-header text-center pb-lg-3">
                {headerText}
            </p>

            <div className="position-relative">
                <input
                    type="email"
                    placeholder="Enter your Email Address"
                    value={email.value}
                    onChange={(e) => onChangeEmail(e.target.value)}
                    className="subscribe-input d-block mx-auto"
                />
                <div className="subscribe-error m-auto">{email.error}</div>
                <button
                    className="primary-btn"
                    disabled={!email.value || !!email.error}
                    onClick={executeReCaptcha}
                >
                    <div className="d-flex justify-content-center">
                        Subscribe
                        <img
                            src={Subscribe}
                            alt=""
                            className="ml-2 d-none d-xl-block"
                        />
                    </div>
                </button>
            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={config.invisibleCaptchaSiteKey}
                onChange={(key: string) => subscribe(key)}
            />
        </div>
    )
}
export default DebitCard
