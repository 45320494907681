import React, { useState, useEffect } from 'react'
import './PrivacyWithdrawModal.scss'
import back from '../../../../assets/images/project/back.png'
import forward from '../../../../assets/images/project/forward.svg'
import NOTE from '../../../../assets/images/project/NOTE.png'
import RECIPIENT_ADDRESS from '../../../../assets/images/project/RECIPIENT_ADDRESS.png'
import { Button } from 'react-bootstrap'
import Config from './../../../../config/config'
import {
    parseNote,
    withDrawal,
    waitForTxReceipt,
    getRelayerServiceFee,
} from '../../../../utils/tornado/tornado'
import LoaderModal from '../../LoaderModal/LoaderModal'
import { authContext } from '../../../../utils/context/AuthContext'
import { useHistory } from 'react-router-dom'
import { HiOutlineInformationCircle } from 'react-icons/hi'
const WAValidator = require('wallet-address-validator')

interface IProps {
    visible: boolean
    setVisible: Function
    isStatistics: boolean
    setIsStatistics: Function
}

const WithdrawModal: React.FC<IProps> = (props) => {
    const [note, setNote] = useState('')
    const [noteError, setNoteError] = useState('')
    const [parsedNote, setParsedNote] = useState({})
    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState('')
    const [txHash, setTxHash] = useState('')
    const [currency, setCurrency] = useState('')
    const [amount, setAmount] = useState('')
    const [fee, setFee] = useState('')
    const [withdrawStyle, setWithdrawStyle] = useState(false)
    const [isWithdrawLoading, setWithdrawLoading] = useState(false)
    const [isWithdrawSuccessful, setWithdrawSuccessful] = useState(false)
    const [withdrawError, setWithdrawError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isValidAddress, setValidAddress] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState(
        'Your transaction is sending...'
    )
    console.log(fee)
    const { auth: user } = React.useContext(authContext)
    const history = useHistory()

    useEffect(() => {
        if (address !== '' && note !== '') {
            setWithdrawStyle(true)
        } else {
            setWithdrawStyle(false)
        }
    }, [note, address])

    useEffect(() => {
        return () => {
            setNote('')
            setAddress('')
            setTxHash('')
            setAmount('')
            setFee('')
            setValidAddress(false)
            setParsedNote({})
            setWithdrawSuccessful(false)
        }
    }, [])

    function isEmpty(obj: Object) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false
        }
        return true
    }

    const validateNote = async (note: string) => {
        setLoading(true)
        try {
            const parseItems = await parseNote(note)
            setParsedNote(parseItems)
            setNoteError('')
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setNoteError(err.toString())
            // TODO handle exceptions
        }
    }

    const validateAddress = (address: string) => {
        let check = WAValidator.validate(address, 'ethereum')
        if (!check) {
            setAddressError('Error: This is not a valid address')
        } else {
            setValidAddress(true)
            setAddressError('')
        }
    }

    const startWithdrawal = async () => {
        try {
            if (!isValidAddress) {
                setAddressError('Address is required')
            } else {
                setAddressError('')
            }
            if (!isEmpty(parsedNote)) {
                if (isValidAddress) {
                    setWithdrawLoading(true)
                    setLoading(true)
                    const unConfirmedHash = await withDrawal(note, address)
                    if (unConfirmedHash) {
                        // TODO update Your trasnaction is broadcasted
                        setLoaderMessage('Your transaction is broadcasted')
                        const confimedTxDetails: any = await waitForTxReceipt(
                            unConfirmedHash
                        )

                        if (confimedTxDetails?.status === true) {
                            const fee = await getRelayerServiceFee()
                            fee && setFee(fee)

                            setTxHash(unConfirmedHash)
                            setAmount(note.split('-')[2])
                            setCurrency(note.split('-')[1])
                            // TODO update Your trasnaction is Mined and sent Successfully
                            setLoaderMessage(
                                'Your trasnaction is Mined and sent Successfully'
                            )
                            setTimeout(() => {
                                setLoading(false)
                            }, 3500)
                            setWithdrawSuccessful(true)
                            setParsedNote({})
                            setValidAddress(false)
                            updateTransaction()
                        } else {
                            // TODO Trasnaction Failed, Please try again later
                            setLoading(false)
                            setWithdrawError(
                                'Trasnaction Failed, Please try again later'
                            )
                            console.log('Transaction Failed ')
                        }
                    } else {
                        throw new Error('Failed to sendTransaction')
                    }
                }
            } else {
                setNoteError('Note is required')
            }
        } catch (err) {
            setLoading(false)
            setWithdrawError(err.toString())
        }
    }

    const updateTransaction = () => {
        const currency = note.split('-')[1]

        const transactions = JSON.parse(
            localStorage.getItem(`${user.walletAddress}${currency}Transactions`)
        )

        const index = transactions.findIndex((t: any) => t.note === note)

        if (index >= 0) {
            transactions[index].status = 'Spent'

            localStorage.setItem(
                `${user.walletAddress}${currency}Transactions`,
                JSON.stringify(transactions)
            )
        }
    }

    return (
        <div>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
                    <div className="pointer d-flex align-items-center">
                        {!isWithdrawSuccessful && (
                            <>
                                <img src={back} alt="<" className="pr-3" />
                                <span
                                    className="back-style"
                                    onClick={() =>
                                        props.setVisible(!props.visible)
                                    }
                                >
                                    Back Home
                                </span>
                            </>
                        )}
                    </div>
                    <div className="pointer d-flex align-items-center">
                        {isWithdrawSuccessful && (
                            <>
                                <span
                                    className="back-style"
                                    onClick={() =>
                                        props.setVisible(!props.visible)
                                    }
                                >
                                    Quit
                                </span>
                                <img src={forward} alt=">" className="pl-3" />
                            </>
                        )}
                    </div>
                </div>

                <div>
                    <div className="row justify-content-center withdraw-text-sm">
                        <text
                            className="big-title-text mt-2 mb-5"
                            style={{ fontSize: '48', lineHeight: '58px' }}
                        >
                            Withdraw
                        </text>
                    </div>

                    {!isWithdrawSuccessful && (
                        <div className="row justify-content-center mt-5">
                            <div className="col-lg-5 col-md-8 col-sm-10 justify-content-start p-5 options-main-div">
                                <div className="row justify-content-center">
                                    <div className="mb-4">
                                        <text className="withdraw-text">
                                            <span>
                                                Enter your unique note to gain
                                                access to your <br />
                                                <span className="text-gradient">
                                                    BL3NDER{' '}
                                                </span>
                                                funds. Choose a new, fresh
                                                ethereum address to send your
                                                new ETH's to.
                                            </span>
                                        </text>
                                    </div>
                                </div>
                                <div className="row justify-content-between align-items-center">
                                    <div>
                                        <img src={NOTE} alt="Select A Coin" />
                                        <HiOutlineInformationCircle className="ml-2" />
                                    </div>
                                </div>
                                <div className="pt-2 mb-5">
                                    <div className="enter-amount-input">
                                        <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Insert Note"
                                            value={note}
                                            style={{
                                                background: 'transparent',
                                                border: 0,
                                                color: 'white',
                                            }}
                                            onChange={(e) => {
                                                setNote(e.target.value)
                                            }}
                                            onBlur={(e) => {
                                                validateNote(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end pt-2">
                                        <text
                                            className=""
                                            style={{
                                                color: 'red',
                                                fontSize: 12,
                                            }}
                                        >
                                            {noteError}
                                        </text>
                                    </div>
                                </div>
                                <div className="row justify-content-between align-items-center">
                                    <div>
                                        <img
                                            src={RECIPIENT_ADDRESS}
                                            alt="Select A Coin"
                                        />
                                        <HiOutlineInformationCircle className="ml-2" />
                                    </div>
                                    {/* <span style={{fontSize: 9 }}>Gas Fee :{fee} KETH</span> */}
                                </div>
                                <div className="pt-2 pb-5">
                                    <div className="form-group enter-amount-input">
                                        <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Paste any address"
                                            value={address}
                                            style={{
                                                background: 'transparent',
                                                border: 0,
                                                color: 'white',
                                            }}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                            onBlur={(e) =>
                                                validateAddress(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <text
                                            className=""
                                            style={{
                                                color: 'red',
                                                fontSize: 12,
                                            }}
                                        >
                                            {addressError}
                                        </text>
                                    </div>
                                </div>
                                <div className="row justify-content-center ">
                                    <span className="amount-error">
                                        {withdrawError}
                                    </span>
                                </div>
                                <div className="row justify-content-center">
                                    <Button
                                        className={
                                            withdrawStyle
                                                ? 'continue-btn-ok'
                                                : 'continue-btn-style'
                                        }
                                        onClick={() => {
                                            startWithdrawal()
                                        }}
                                    >
                                        <span className="p-2">Withdraw</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    {isWithdrawSuccessful && (
                        <div className="transaction-details">
                            <div className="transaction-details-title text-center">
                                <text>Transaction Details</text>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-9">
                                    <div className="row transaction-details-card p-4 overflow-hidden">
                                        <div className="col-md-6 p-0">
                                            <div className="withdraw-details-title px-3">
                                                <text>
                                                    TX#2 WITHDRAW DETAILS
                                                </text>
                                            </div>

                                            <div className="px-3">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <text className="withdraw-details-text withdraw-details-description pr-3">
                                                        AMOUNT
                                                    </text>
                                                    <text className="withdraw-details-text">
                                                        {`${amount} ${currency}`}
                                                    </text>
                                                </div>

                                                {/* <div className="d-flex justify-content-between mb-3">
                          <text className="withdraw-details-text withdraw-details-description pr-3">
                            FEE
                          </text>
                          <text className="withdraw-details-text">
                            {fee && `${fee} ${currency}`}
                          </text>
                        </div> */}

                                                <div className="d-flex justify-content-between mb-3">
                                                    <text className="withdraw-details-text withdraw-details-description pr-3">
                                                        SERVICE
                                                    </text>
                                                    <text className="withdraw-details-text">
                                                        BLENDER
                                                    </text>
                                                </div>

                                                <div className="d-flex justify-content-between mb-3">
                                                    <text className="withdraw-details-text withdraw-details-description pr-3">
                                                        TX HASH
                                                    </text>
                                                    <text
                                                        className="withdraw-details-text hplink"
                                                        onClick={() => {
                                                            if (
                                                                user.chainId ===
                                                                42
                                                            ) {
                                                                window.open(
                                                                    `${Config.etherscanKovanUrl}/${txHash}`,
                                                                    '_blank'
                                                                )
                                                            } else {
                                                                window.open(
                                                                    `${Config.etherscanMainUrl}/${txHash}`,
                                                                    '_blank'
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        {txHash.slice(0, 10)}
                                                        ....
                                                    </text>
                                                </div>

                                                <div className="d-flex justify-content-between mb-3">
                                                    <text className="withdraw-details-text withdraw-details-description pr-3">
                                                        RECIPIENT
                                                    </text>
                                                    <text className="withdraw-details-text">
                                                        {address.substr(0, 3) +
                                                            '...' +
                                                            address.substr(
                                                                address.length -
                                                                    7
                                                            )}
                                                    </text>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 p-0 overflow-hidden">
                                            <div className="transaction-options-title px-3">
                                                <text>OPTIONS</text>
                                            </div>

                                            <div className="transaction-details-divider h-100 px-3">
                                                {/* <div className="transaction-options-card p-4 mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <text className="transaction-options-text">
                              See liquidity Statistics
                            </text>
                            <Button className="btn-gradient-round transaction-options-btn m-0">
                              <span>Statistics</span>
                            </Button>
                          </div>
                        </div> */}

                                                <div className="transaction-options-card p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <text className="transaction-options-text">
                                                            New Transaction
                                                        </text>
                                                        <Button
                                                            className="btn-gradient-round transaction-options-btn m-0"
                                                            onClick={() =>
                                                                history.replace(
                                                                    '/home'
                                                                )
                                                            }
                                                        >
                                                            <span>Home</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <LoaderModal
                show={isLoading}
                isOptions={isWithdrawLoading}
                title="Processing Withdraw"
                message={loaderMessage}
            />
        </div>
    )
}

export default WithdrawModal
