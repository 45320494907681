export class JsStorage {
    db: any = {}
    constructor() {
        this.db = {}
    }

    get(key: any) {
        return this.db[key]
    }

    get_or_element(key: string | number, defaultElement: any) {
        const element = this.db[key]
        if (element === undefined) {
            return defaultElement
        } else {
            return element
        }
    }

    put(key: string | number | undefined, value: undefined) {
        if (key === undefined || value === undefined) {
            throw Error('key or value is undefined')
        }
        this.db[key] = value
    }

    del(key: string | number) {
        delete this.db[key]
    }

    put_batch(key_values: any[]) {
        key_values.forEach((element: { key: string | number; value: any }) => {
            this.db[element.key] = element.value
        })
    }
}
