import React, { useState } from 'react'
import Header from './../../shared/components/Header/Header'
import Footer from './../../shared/components/Footer/Footer'
import Footer2 from './../../pages/home/sections/footer/HomeFooter'
import './MainLayout.css'
import { authContext } from './../../utils/context/AuthContext'
import {
    checkLocalStorage,
    checkLogin,
} from './../../utils/Utilities/contextUtils'
import { useHistory, useLocation } from 'react-router-dom'
import { showNotification } from './../../utils/notifications/notificationService'
import { useWeb3React } from '@web3-react/core'
import Sidebar from 'app/shared/components/Sidebar'
import DashboardHeader from './../../shared/components/Header/DashboardHeader'

interface IProps {
    footer: boolean
    menuHeader: boolean
}
const MainLayout: React.FunctionComponent = (
    props: React.PropsWithChildren<any>
) => {
    // Context store
    // history obj to redirection
    const history = useHistory()
    const context = useWeb3React()
    const location = useLocation()
    const [path, setPath] = React.useState(history.location.pathname)

    React.useEffect(() => {
        setPath(history.location.pathname)
    }, [location.pathname])

    let currentAccount = localStorage.getItem('Walletaddress')
    const { account, active, error }: any = context
    const { auth: user, setUnauthStatus } = React.useContext(authContext)
    const { deactivate } = context
    React.useEffect(() => {
        if (error) {
            if (error.code === -32002) {
                localStorage.clear()
                window.location.reload()
            }
        }
        
        if (account) {
            if (currentAccount) {
                if (account.toLowerCase() !== currentAccount) {
                    deactivate()
                    setUnauthStatus()
                    localStorage.clear()
                    window.location.href = '/home'
                }
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, active, error])
    React.useEffect(() => {
        if (props.props && props.props.routeShouldAuthenticate) {
            if (!checkLogin(user)) {
                if (!checkLocalStorage()) {
                    history.push('/home')
                    showNotification(
                        `[Authentication error]: User Not Authenticated`,
                        'error'
                    )
                }
            }
        }
    }, [props.props, props.props.routeShouldAuthenticate, history, user]);

    
    const sidebarTogglePaths = [
        '/earn',
        '/blender',
        '/staking',
        '/proposals',
        '/history',
        '/overview',
    ]

    const [sidenav, setSidenav] = useState(document.getElementById('sidebar'))
    let sidebar: any
    const toggleSidebar = () => {
        sidebar = document.getElementById('sidebar')
        sidebar.classList.toggle('transform')
    }

    return (
        <div className="main_div">
            <div className="d-flex bd-highlight">
            {sidebarTogglePaths.includes(path) && (
                        <>
                            <div className="dashboardFixedHeader">
                                <DashboardHeader />
                            </div>
                        </>
                    )}
                {sidebarTogglePaths.includes(path) && (
                    <>
                        <div
                            className="sidebar bd-highlight text-center sidebar-main-container"
                            onClick={() => toggleSidebar()}
                        >
                            <Sidebar />
                        </div>
                    </>
                )}
                <div className = "">
                    {sidebarTogglePaths.includes(path) && (
                        <p
                            onClick={() => toggleSidebar()}
                            className="pl-3 pt-3 toggler"
                        >
                            <i className="fas fa-bars"></i>
                        </p>
                    )}
                </div>

                <div className="w-100 bd-highlight ">
                    {props.props.menuHeader &&
                        !sidebarTogglePaths.includes(path) && <Header />}
                    

                    {props.children}
                    {props.props.footer &&
                        !sidebarTogglePaths.includes(path) && <Footer2 />}
                </div>
            </div>
        </div>
    )
}
MainLayout.defaultProps = {}

export default MainLayout
