import Anaylitcs from '../pages/anaylitcs/analytics'
import Dashboard from '../pages/dashboard/Dashboard'
import Home from '../pages/home/home'
import Blender from '../pages/privacy/privacy'
import Staking from '../pages/staking'
import RoadmapAndPressKits from '../pages/roadmap&presskits'
// import MobileMoney from '../pages/mobile-money';
// import Governance from "../pages/governance";
import Proposals from '../pages/proposals'
import ProposalsDetails from '../pages/proposal-details'
import PrivacyPolicy from '../pages/privacy-policy'
import TermsOfUse from '../pages/terms-of-use'
import LendingHome from '../pages/lending-home'
import StakingLanding from '../pages/staking-landing'

import {ROUTES} from './../utils/enums/routes'
import EarnLanding from 'app/pages/Earn-landing'
import BlenderLanding from '../pages/blendr-landing'
import Governance from 'app/pages/governance'
import History from 'app/pages/history/history'
import Overview from 'app/pages/overview'

export const routes = [
    {
        path: ROUTES.DEFAULT,
        component: Home,
        exact: true,
        props: {
            footer: false,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.BLENDER,
        component: Blender,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.DASHBOARD,
        component: Dashboard,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.HOME,
        component: Home,
        exact: true,
        props: {
            footer: false,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.ANALYTICS,
        component: Anaylitcs,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.STAKING,
        component: Staking,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    // {
    //   path: ROUTES.STAKING_TEST,
    //   component: Staking,
    //   exact: true,
    //   props: {
    //     footer: true,
    //     menuHeader: true,
    //     routeShouldAuthenticate: false
    //   }
    // },
    {
        path: ROUTES.ROADMAP_AND_PRESSKITS,
        component: RoadmapAndPressKits,
        exact: true,
        props: {
            footer: false,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    // {
    //   path: ROUTES.MOBILE_MONEY,
    //   component: MobileMoney,
    //   exact: true,
    //   props: {
    //     footer: false,
    //     menuHeader: true,
    //     routeShouldAuthenticate: false
    //   }
    // },
    // {
    //   path: ROUTES.GOVERNANCE,
    //   component: Governance,
    //   exact: true,
    //   props: {
    //     footer: false,
    //     menuHeader: true,
    //     routeShouldAuthenticate: false,
    //   },
    // },

    {
        path: ROUTES.OVERVIEW,
        component: Overview,
        exact: true,
        props: {
            footer: false,
            menuHeader: false,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.HISTORY,
        component: History,
        exact: true,
        props: {
            footer: false,
            menuHeader: false,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.PRIVACY_POLICY,
        component: PrivacyPolicy,
        exact: true,
        props: {
            footer: false,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.TERMS_OF_USE,
        component: TermsOfUse,
        exact: true,
        props: {
            footer: false,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.LENDING_HOME,
        component: LendingHome,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.EARN_LENDING,
        component: EarnLanding,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.BLENDER_LANDING,
        component: BlenderLanding,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.GOVERNANCE_LANDING,
        component: Governance,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.STAKING_LANDING,
        component: StakingLanding,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.GOVERNANCE,
        component: Governance,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.PROPOSALS,
        component: Proposals,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
    {
        path: ROUTES.PROPOSALS_DETAILS,
        component: ProposalsDetails,
        exact: true,
        props: {
            footer: true,
            menuHeader: true,
            routeShouldAuthenticate: false,
        },
    },
]
