import React from 'react'
import './App.css'
import Routing from './routes/routing'
import { ApolloProvider } from '@apollo/client'
import GraphqlClient from './utils/connections/graphQLClient'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthContextProvider from './utils/context/AuthContext'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import PairDataContextProvider, {
    Updater as PairDataContextUpdater,
} from 'app/utils/context/PairContext/PairData'
import ApplicationContextProvider from 'app/utils/context/PairContext/Application'
import GlobalDataContextProvider from 'app/utils/context/PairContext/GlobalData'

const App = () => {
    React.useEffect(() => {
        if (window.location.pathname === '/blog') {
            window.location.replace('https://medium.com/addxyz')
        }
    }, [])

    const getLibrary = (provider: any): Web3Provider => {
        const library = new Web3Provider(provider)

        library.pollingInterval = 8000
        return library
    }

    function ContextProviders({ children }) {
        return (
            <ApplicationContextProvider>
                <GlobalDataContextProvider>
                    <PairDataContextProvider>
                        <AuthContextProvider>{children}</AuthContextProvider>
                    </PairDataContextProvider>
                </GlobalDataContextProvider>
            </ApplicationContextProvider>
        )
    }

    function Updaters() {
        return (
            <>
                <PairDataContextUpdater />
            </>
        )
    }

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <ContextProviders>
                <Updaters />
                <ApolloProvider client={GraphqlClient}>
                    {/* NOTIFICATIONS CONTAINER  */}
                    <ToastContainer />
                    <Routing />
                </ApolloProvider>
            </ContextProviders>
        </Web3ReactProvider>
    )
}

export default App
