import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import Image from '../Image/Image'
import styled from 'styled-components'
// import { DropdownBoxProps } from './type'
import { useState } from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { CURRENCIES } from 'app/utils/enums/currencies'

const StyleDropDown = styled(Dropdown)`
  margin-top: 20px;
  border-radius : 10px;
 
  #dropdown-basic::after {
    display: none !important;
  }


  .dropdown {
    background: #333449;
    border-radius : 4px;
    font-weight: 700;
    font-family: 'Gilroy';
    font-size:24px;
    .icon{
        color : #787889;
        font-size : 24px; 
    }

  }

  .openedDropdown {
    background: #343346;
    border-top-left-radius : 20px;
    border-top-right-radius : 20px;
    .icon{
        color : #787889;
        font-size : 24px; 
    }
  }
  .dropdown-menu {
    background: #343346;
    border : none;
    padding: 0px;
    color:'red'
    
    transform: translate3d(0px, 33px, 0px) !important;
    min-width: -webkit-fill-available;
    /* border-bottom-left-radius : 20px;
    border-bottom-right-radius : 20px; */
    a {
      padding: 0.25rem 0.7rem;
    }
  }
  .dropdown-item:hover {
    background: #1e1e30;
  }
  .dropdown-item {
    color:white;
  }
  .dropdown-text {
    width: 100px;
    text-align: left;

  }

`

interface DropdownBoxProps {
    handleChange?: Function
    classes?: string
    options?: any
    size?: number
    weight?: number
    color?: string
    onClick?: any
}
const Select: React.FC<DropdownBoxProps> = (props) => {
    const [token, selectToken] = useState<any>(
        props.options && props.options[0]
    )
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    useEffect(() => {
        props.handleChange('ETH')
    }, [])

    return (
        <StyleDropDown>
            <Dropdown
            //className={isDropdownOpen ? 'openedDropdown' : 'dropdown'}
            >
                <Dropdown.Toggle
                    onClickCapture={() => {
                        setDropdownOpen(!isDropdownOpen)
                    }}
                    onBlur={() => {
                        setDropdownOpen(false)
                    }}
                    variant=""
                    className={
                        'd-flex align-items-center border-0 shadow-none ' +
                        (isDropdownOpen ? 'openedDropdown' : 'dropdown')
                    }
                    id="dropdown-basic"
                >
                    {token && (
                        <>
                            <Image
                                src={token?.icon}
                                classes="mr-2"
                                width="22"
                                height="22"
                            />
                            <span className="text-white"> {token.title} </span>
                        </>
                    )}
                    <RiArrowDropDownLine className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.options &&
                        props.options.map((option: any, index) => (
                            <>
                                {option.id !== token.id && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            selectToken(option)
                                            if (option.title === 'ETH') {
                                                props.handleChange(
                                                    CURRENCIES.ETH
                                                )
                                            } else {
                                                props.handleChange(
                                                    CURRENCIES.DAI
                                                )
                                            }
                                        }}
                                        key={index}
                                    >
                                        <div className="d-flex align-items-center">
                                            <Image
                                                src={option?.icon}
                                                classes="mr-2"
                                                width="22"
                                                height="22"
                                            />
                                            {option.title}
                                        </div>
                                    </Dropdown.Item>
                                )}
                            </>
                        ))}
                </Dropdown.Menu>
            </Dropdown>
        </StyleDropDown>
    )
}

export default Select
