/* eslint-disable */
import React from 'react'
import { Button } from 'react-bootstrap'

// assets
import ethSmall from '../../../../assets/images/project/eth_small_blue.svg'
import arrowDown from '../../../../assets/images/project/arrow_down.svg'

const Swap = () => {
    return (
        <>
            <div className="staking-modal-input">
                <div className="staking-modal-input-header d-flex justify-content-between">
                    <div>To</div>
                    <div>Balance: 0.479735</div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50"></div>
                    <div className="d-flex justify-content-around w-50">
                        <Button className="staking-modal-input-max-action">
                            Max
                        </Button>
                        <img src={ethSmall} alt="logo" />
                    </div>
                </div>
            </div>

            <div className="my-4 text-center">
                <img src={arrowDown} alt="Arrow Down" />
            </div>

            <div className="staking-modal-input mb-4">
                <div className="staking-modal-input-header d-flex justify-content-between">
                    <div>From</div>
                    <div>Balance: 0.479735</div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50"></div>
                    <div className="d-flex justify-content-around w-50">
                        <Button className="staking-modal-input-max-action">
                            Max
                        </Button>
                        <img src={ethSmall} alt="logo" />
                    </div>
                </div>
            </div>

            <div className="staking-modal-slippage-tolerance d-flex justify-content-between px-2 pb-1 mb-3">
                <div>Slippage tolerance</div>
                <div>3%</div>
            </div>

            <Button className="staking-modal-btn mb-3">Swap</Button>
        </>
    )
}

export default Swap
