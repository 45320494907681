// import { useWeb3React } from "@web3-react/core";
import { authContext } from 'app/utils/context/AuthContext'
import { showNotification } from 'app/utils/notifications/notificationService'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout/MainLayout'

import { routes } from './routes'
import { useWeb3React } from '@web3-react/core'
import Sidebar from 'app/shared/components/Sidebar'

const Routing = () => {
    const { auth, setAuthStatus, setUnauthStatus } = React.useContext(authContext)
    const context = useWeb3React()

    const { ethereum } = window as any
    const { deactivate } = context;

    const handleConnect = () => {
        // activate(injected)
    }
    const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
            if (auth.isAuthenticated) {
                auth['walletAddress'] = accounts[0]
                setAuthStatus(auth)
            }
        }
        else {
            deactivate()
            setUnauthStatus()
            localStorage.clear()
            window.location.href = '/home'
        }
    }
    const handleNetworkChanged = (networkId: number) => {
        auth['chainId'] = parseInt(networkId.toString())
        setAuthStatus(auth)
        // activate(injected)
    }
    try {
        ethereum.on('connect', handleConnect)
        ethereum.on('accountsChanged', handleAccountsChanged)
        ethereum.on('networkChanged', handleNetworkChanged)
    } catch (e) {
        showNotification(
            `[Browser error]: Please install metamask extension to access
        ADD.XYZ assets`,
            'error'
        )
    }

    const onToggleSidebar = () => {
        document.body.classList.remove('nav-open')
    }

    return (
        <BrowserRouter>
            <Switch>
                {routes.map((route: any, index: number) => (
                    <MainLayout key={index} {...route}>
                        <Route
                            path={route.path}
                            component={route.component}
                            key={route.path}
                            exact={route.exact}
                        />
                    </MainLayout>
                ))}
            </Switch>

            <div className="ui-mask-div ui-mask" onClick={onToggleSidebar} />
        </BrowserRouter>
    )
}

export default Routing
