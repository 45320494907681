/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './PrivacyDepositModal.scss'
import back from '../../../../assets/images/project/back.png'
import tick from '../../../../assets/images/project/tick.png'
import Eth1 from '../../../../assets/images/project/eth_1.svg'
import ethTitle from '../../../../assets/images/project/eth-title.png'
import Dai from '../../../../assets/images/project/Dai_1.svg'
import daiTitle from '../../../../assets/images/project/dai-title.png'
import amountTitle from '../../../../assets/images/project/EnteranAmount.png'
import blender from '../../../../assets/images/project/blender.png'
import tornadoCash from '../../../../assets/images/project/tornadoCash.png'
import blenderTitle from '../../../../assets/images/project/blenderTitle.png'
import blen_tornado from '../../../../assets/images/project/blen_tornado.png'
import TORNADO from '../../../../assets/images/project/TORNADO.png'
import cross_arrows from '../../../../assets/images/project/cross_arrows.png'
import ChooseMixingService from '../../../../assets/images/project/ChooseMixingService.png'
import BackupNoteAndDeposit from '../../../../assets/images/project/BackupNoteAndDeposit.png'
import backup_your_note from '../../../../assets/images/project/backup_your_note.png'
import YOUR_NOTE from '../../../../assets/images/project/YOUR_NOTE.png'
import copy_icon from '../../../../assets/images/project/copy_icon.png'
import Confirm_Details from '../../../../assets/images/project/Confirm_Details.png'
import Transaction from '../../../../assets/images/project/Transaction.png'
import TXDepositDETAILS from '../../../../assets/images/project/TXDepositDETAILS.png'
import eye_icon from '../../../../assets/images/project/eye_icon.png'
import rigthtArrow from '../../../../assets/images/project/rigthtArrow.png'
import divider from '../../../../assets/images/project/divider.png'
import WHAT_next from '../../../../assets/images/project/WHAT_next.png'
import whats_next_data from '../../../../assets/images/project/whats_next_data.png'
import Minuteshavepassed from '../../../../assets/images/project/Minuteshavepassed.png'
import warning from '../../../../assets/images/project/warning.png'
import blendAndTornado from '../../../../assets/images/project/blendAndTornado.png'
import info from '../../../../assets/images/project/info.png'
import Note from '../../../../assets/images/project/note.svg'
import Trash from '../../../../assets/images/project/trash.svg'

import { authContext } from '../../../../utils/context/AuthContext'
import { Button } from 'react-bootstrap'
import { Slide, toast } from 'react-toastify'
import copy from 'copy-to-clipboard'
import Timer from 'react-compound-timer'
import Config from './../../../../config/config'

import TopTab from './TopTabs/TopTab'
import LoaderModal from '../../LoaderModal/LoaderModal'
import { getTimeElapsed } from '../../../../utils/dates'
import moment from 'moment'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { Table } from 'react-bootstrap'

import { config } from '../../../../utils/tornado/config'

// ENUMS

import { CURRENCIES } from '../../../../utils/enums/currencies'

import {
    processConfirmDeposit,
    generateSecret,
    getEthBalance,
    getERC20Balance,
} from '../../../../utils/tornado/tornado'
import AmountSelector from './AmountSeletor/AmountSelector'
import { count } from 'console'
interface IProps {
    visible: boolean
    setVisible: Function
    isStatistics: boolean
    setIsStatistics: Function
    setWithdrawModal: Function
}

const DEAFULT_LOAD_MSG = 'Please confirm transaction in MetaMask...'

const DepositModal: React.FC<IProps> = (props) => {
    const [tabNo, setTabNo] = useState(0)
    const [amount, setAmount] = useState(0)
    const Eth_AmountList = Object.keys(
        config.netId1.TORNADO.ETH.instanceAddress
    )
    const Dai_AmountList = Object.keys(
        config.netId1.TORNADO.DAI.instanceAddress
    )
    const [amountList, setAmountList] = useState(Eth_AmountList.sort())
    const [note, setNote] = useState('')
    const [amountError, setAmountError] = useState('')
    const [depositObj, setDepositObj] = useState('')
    const [selectedCoin, setSelectedCoin] = useState(CURRENCIES.ETH)
    const [selectedMixture, setSelectedMixture] = useState('')
    const [selectedObj, setSelectedObj] = useState({})
    const [isBackedUpCheck, setBackedUpCheck] = useState(false)
    const [continueStyle, setContinueStyle] = useState(false)
    const [noteShow, setNoteShow] = useState(false)
    const [isTransactionModal, setTransactionModal] = useState(false)
    const [txHash, setTxHash] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState(DEAFULT_LOAD_MSG)
    const [transactionsHistory, setTransactionsHistory] = useState<any>(false)

    const { auth: user, setAuthStatus } = React.useContext(authContext)

    const notify = (msg: String) =>
        toast(msg, {
            position: 'top-right',
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
        })

    useEffect(() => {
        if (selectedCoin == CURRENCIES.ETH) {
            setAmountList(Eth_AmountList.sort())
        } else {
            setAmountList(Dai_AmountList.sort())
        }
    }, [selectedCoin])

    useEffect(() => {
        if (selectedMixture === '') {
            return
        }
        // let obj = {}
        // if (selectedMixture === 'BLENDER') {
        //   if(selectedCoin == CURRENCIES.ETH)
        //   obj = config.netId1.BLENDER_ETH
        //   else
        //   obj = config.netId1.BLENDER_DAI
        // } else if(selectedMixture == 'TORNADO'){
        //   if(selectedCoin == CURRENCIES.ETH)
        //   obj = config.netId1.TORNADO_ETH
        //   else
        //   obj = config.netId1.TORNADO_DAI
        // }
        // setSelectedObj(obj)
        preDeposit()
    }, [selectedMixture])

    function copyToClipboard(text: any) {
        copy(text)
        notify('Copied to Clipboard!')
    }

    function selectCoinHandler(type: CURRENCIES) {
        if (type === CURRENCIES.ETH) {
            setSelectedCoin(CURRENCIES.ETH)
        } else if (type === CURRENCIES.DAI) {
            setSelectedCoin(CURRENCIES.DAI)
        }
        setTabNo(1)
    }

    async function verifyBalance() {
        const mixture = selectedMixture ? selectedMixture : 'TORNADO'

        let balance: number = 0
        try {
            if (selectedCoin === CURRENCIES.ETH) {
                balance = await getEthBalance(
                    user.walletAddress,
                    CURRENCIES.ETH
                )
            } else if (selectedCoin === CURRENCIES.DAI) {
                const contractAddress =
                    config[`netId${user.chainId}`][`${mixture}`][
                        `${selectedCoin}`
                    ].tokenAddress
                balance = await getERC20Balance(
                    user.walletAddress,
                    CURRENCIES.DAI,
                    contractAddress
                )
            }
        } catch (err) {
            console.log('error while verifying user balance ', err)
        }
        return balance
    }
    async function preDeposit() {
        setLoading(true)
        setTransactionModal(true)
        setLoaderMessage('Please wait...')
        const intialDepositState: any = await generateSecret(
            selectedCoin,
            amount,
            selectedMixture
        )

        if (intialDepositState) {
            setNote(intialDepositState.note)
            setDepositObj(intialDepositState.deposit)
            setLoading(false)
            setTransactionModal(false)
            setLoaderMessage(DEAFULT_LOAD_MSG)

            setTabNo(3)
        }
    }
    async function processDeposit() {}

    async function enterAmountHandler() {
        if (amount === 0) {
            setAmountError('Select an amount to continue')
            setContinueStyle(false)
            return
        }

        setLoading(true)
        setTransactionModal(true)
        setLoaderMessage('Please wait...')

        const balance = await verifyBalance()

        if (balance < amount) {
            setAmountError('Insufficient Balance')
            setContinueStyle(false)
            setLoading(false)
            setTransactionModal(false)
            setLoaderMessage(DEAFULT_LOAD_MSG)
            return
        }
        setAmountError('')
        setLoading(false)
        setTransactionModal(false)
        setLoaderMessage(DEAFULT_LOAD_MSG)
        setTabNo(2)
    }

    async function sendDepositHandler() {
        if (isBackedUpCheck) {
            setLoading(true)
            setTransactionModal(true)
            try {
                let tx = await processConfirmDeposit(note, selectedMixture)
                if (tx?.status === true) {
                    setLoaderMessage('Trasaction Sent Successfuly')
                    setTxHash(tx.transactionHash)

                    setTimeout(() => {
                        setLoading(false)
                    }, 2500)
                    storeTransaction(tx.transactionHash)
                    setTabNo(4)
                }
            } catch (error) {
                if (error.code === 4001) {
                    toast.error('Transaction Rejected From Metamask')
                }
                setLoading(false)
                setTabNo(3)
            }
        } else {
            notify('Please Check the Backed Up Note !')
        }
    }

    const swapFilter = (coin: string) => {
        const transactions = JSON.parse(
            localStorage.getItem(`${user.walletAddress}${coin}Transactions`)
        )
        if (transactions) {
            setTransactionsHistory(transactions)
        } else {
            let emptyObj = []
            setTransactionsHistory(emptyObj)
        }
    }

    const storeTransaction = (txHash: string) => {
        const transactions = JSON.parse(
            localStorage.getItem(
                `${user.walletAddress}${selectedCoin}Transactions`
            )
        )

        const newTransaction = {
            amount,
            txHash,
            note,
            coin: selectedCoin,
            status: 'Unspent',
            timePassed: moment.now(),
        }

        let currentTransactions: any

        if (transactions) {
            currentTransactions = [...transactions]
            currentTransactions.unshift(newTransaction)
        } else {
            currentTransactions = [newTransaction]
        }

        setTransactionsHistory(currentTransactions)
        localStorage.setItem(
            `${user.walletAddress}${selectedCoin}Transactions`,
            JSON.stringify(currentTransactions)
        )
    }

    const deleteTransaction = (txHash: string) => {
        const transactions = JSON.parse(
            localStorage.getItem(
                `${user.walletAddress}${selectedCoin}Transactions`
            )
        )

        const newTransactions = transactions.filter(
            (t: any) => t.txHash !== txHash
        )

        setTransactionsHistory(newTransactions)
        localStorage.setItem(
            `${user.walletAddress}${selectedCoin}Transactions`,
            JSON.stringify(newTransactions)
        )
    }

    const checkTransactionCount = () => {
        let count = 0
        transactionsHistory.map((transaction) => {
            if (transaction.coin === selectedCoin) {
                count += 1
            }
        })
        if (count > 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="deposit-modal">
            <div className="container">
                <div className="d-flex mt-5 mb-5 w-100">
                    <div style={{ width: '15%', paddingTop: '9px' }}>
                        <img
                            src={back}
                            alt="<"
                            className="pr-3"
                            onClick={() => {
                                if (tabNo > 0) {
                                    if (tabNo == 4) {
                                        setTabNo(0)
                                    } else setTabNo(tabNo - 1)
                                } else {
                                    props.setVisible(!props.visible)
                                }
                            }}
                        />
                        <span
                            className="back-style mobile-back-hide"
                            onClick={() => {
                                if (tabNo > 0) {
                                    if (tabNo == 4) {
                                        setTabNo(0)
                                    } else setTabNo(tabNo - 1)
                                } else {
                                    props.setVisible(!props.visible)
                                }
                            }}
                        >
                            Back
                        </span>
                    </div>
                    <div className="" style={{ width: '70%' }}>
                        <TopTab step={tabNo} />
                    </div>
                </div>
                {/* Select A Coin  */}
                {tabNo == 0 && (
                    <div>
                        <div className="row justify-content-center">
                            <p className="select-coin-title mb-5">
                                Select a coin
                            </p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-8 col-sm-10 justify-content-start p-5 options-main-div options-main-shadow ">
                                <div className="row justify-content-center">
                                    <div className="mb-4">
                                        <img
                                            src={ethTitle}
                                            alt="Select A Coin"
                                            className="col-lg-12 col-sm-10"
                                        />
                                    </div>
                                </div>
                                <div className="pl-5">
                                    <img
                                        src={Eth1}
                                        alt="Select A Coin"
                                        className="col-lg-12 col-sm-10 ml-2"
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn-gradient-round-sm"
                                        onClick={() => {
                                            // deposit('dai', 100)
                                            //startWithdrawal('tornado-eth-0.1-42-0xd32280976bc3881019b5a2be9c8bb2541110528379fca9b6072dc73e24480303b1123908375ae0fc36d77d94e2b74156a18f01ff77d66686d90d114a5d55')
                                            selectCoinHandler(CURRENCIES.ETH)
                                        }}
                                    >
                                        <span className="p-3 text-capitalize">
                                            Select
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 row align-items-center justify-content-center mt-5 mb-5">
                                <h3 className="lightText">OR</h3>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-10 justify-content-start p-5 options-main-div options-main-shadow ">
                                <div className="row justify-content-center">
                                    <div className="mb-4">
                                        {' '}
                                        <img
                                            src={daiTitle}
                                            alt="Select A Coin"
                                            className="col-lg-12 col-sm-10"
                                        />
                                    </div>
                                </div>
                                <div className="pl-5">
                                    <img
                                        src={Dai}
                                        alt="Select A Coin"
                                        className="col-lg-12 col-sm-10 ml-2"
                                    />
                                    {/* <img
                    src={Dai1}
                    alt="Select A Coin"
                    className="col-lg-12 col-sm-10 ml-2"
                  /> */}
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn-gradient-round-sm"
                                        onClick={() =>
                                            selectCoinHandler(CURRENCIES.DAI)
                                        }
                                    >
                                        <span className="p-3 text-capitalize">
                                            Select
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Amount Tab  */}
                {tabNo == 1 && (
                    <div>
                        <div className="row justify-content-center">
                            <img
                                src={amountTitle}
                                alt="Select A Coin"
                                className="col-lg-3 col-md-4 col-sm-5 col-xs-5 mt-2 mb-5"
                            />
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-8 col-sm-10 justify-content-start p-5 options-main-div">
                                <div className="row justify-content-center align-items-center">
                                    <div
                                        className="mb-4"
                                        style={{ backgroundColor: '' }}
                                    >
                                        <img
                                            src={
                                                selectedCoin == 'ETH'
                                                    ? ethTitle
                                                    : daiTitle
                                            }
                                            alt="Select A Coin"
                                            className=""
                                        />
                                        <img
                                            src={
                                                selectedCoin == 'ETH'
                                                    ? Eth1
                                                    : Dai
                                            }
                                            alt="Select A Coin"
                                            className="pl-2"
                                            style={{ width: 40, height: 43 }}
                                        />
                                        <HiOutlineInformationCircle className="ml-1 info-icon-sm" />
                                    </div>
                                </div>

                                <div className="row justify-content-center align-items-center">
                                    <text>
                                        Select an amount to start mixing.
                                    </text>
                                </div>
                                <div className="row justify-content-center align-items-center"></div>
                                <div
                                    className=""
                                    style={{
                                        height: 230,
                                        paddingTop: '20%',
                                    }}
                                >
                                    <AmountSelector
                                        data={amountList}
                                        setAmount={setAmount}
                                        coin={selectedCoin}
                                    />
                                    <div
                                        className="form-group eenter-amount-input"
                                        style={{ position: 'relative' }}
                                    >
                                        <div className="row justify-content-center pt-5">
                                            <span className="amount-error">
                                                {amountError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3">
                                    <Button
                                        className={
                                            continueStyle
                                                ? 'continue-btn-ok'
                                                : 'continue-btn-style'
                                        }
                                        onClick={enterAmountHandler}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span className="p-2">Continue</span>
                                        <img src={rigthtArrow} alt="" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Choose A Mixer  */}
                {tabNo == 2 && (
                    <div className="">
                        <div className="">
                            <div className="row justify-content-center">
                                <img
                                    src={ChooseMixingService}
                                    alt="Select A Coin"
                                    className="col-lg-4 col-sm-7 col-xs-5 mt-2 mb-5"
                                />
                            </div>
                            <div className="row justify-content-around align-items-center w-100">
                                <div className="col-lg-3 col-md-8 col-sm-10 col-xs-12 justify-content-center p-5 options-main-div options-main-shadow service-div">
                                    <div className="row justify-content-center">
                                        <div className="mb-4">
                                            {' '}
                                            <img
                                                src={blenderTitle}
                                                alt="Select A Coin"
                                                className="col-lg-12 col-sm-10"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-5">
                                        <img
                                            src={blender}
                                            alt="Select A Coin"
                                            className=""
                                        />
                                    </div>
                                    {/* <div className="row justify-content-center">
                    <span className="p-2 text-capitalize">Coming Soon</span>
                  </div> */}
                                    <div className="row justify-content-center">
                                        <Button
                                            className="btn-gradient-round"
                                            onClick={() => {
                                                // deposit('dai', 100)
                                                // startWithdrawal('tornado-eth-0.1-42-0xd32280976bc3881019b5a2be9c8bb2541110528379fca9b6072dc73e24480303b1123908375ae0fc36d77d94e2b74156a18f01ff77d66686d90d114a5d55')
                                                setSelectedMixture('BLENDER')
                                                // preDeposit();
                                            }}
                                        >
                                            <span className="p-2 text-capitalize">
                                                Select
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-8 col-sm-10 justify-content-start p-5 options-main-div options-main-shadow service-div">
                                    <div className="row justify-content-center">
                                        <div className="mb-4">
                                            {' '}
                                            <img
                                                src={blen_tornado}
                                                alt="Select A Coin"
                                                className="col-lg-12 col-sm-10"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center p-5">
                                        <img
                                            src={blendAndTornado}
                                            alt="Select A Coin"
                                            className=""
                                        />
                                    </div>
                                    <div className="row justify-content-center">
                                        <span className="p-2 text-capitalize">
                                            Coming Soon
                                        </span>
                                    </div>
                                    {/* <div className="row justify-content-center">
                      <Button
                        className="btn-gradient-round"
                        onClick={() => {
                          setSelectedMixture("BLENDER + TORNADO.CASH");
                          setTabNo(3);
                        }}
                      >
                        <span className="p-3 text-capitalize">Select</span>
                      </Button>
                    </div> */}
                                </div>
                                <div className="col-lg-3 col-md-8 col-sm-10 justify-content-start p-5 options-main-div options-main-shadow service-div">
                                    <div className="row justify-content-center">
                                        <div className="mb-4">
                                            {' '}
                                            <img
                                                src={TORNADO}
                                                alt="Select A Coin"
                                                className="col-lg-12 col-sm-10"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center p-5">
                                        <img
                                            src={tornadoCash}
                                            alt="Select A Coin"
                                            className="p-3"
                                        />
                                    </div>
                                    <div className="row justify-content-center">
                                        <Button
                                            className="btn-gradient-round"
                                            onClick={() => {
                                                // deposit('dai', 100)
                                                // startWithdrawal('tornado-eth-0.1-42-0xd32280976bc3881019b5a2be9c8bb2541110528379fca9b6072dc73e24480303b1123908375ae0fc36d77d94e2b74156a18f01ff77d66686d90d114a5d55')
                                                setSelectedMixture('TORNADO')
                                                // preDeposit();
                                            }}
                                        >
                                            <span className="p-2 text-capitalize">
                                                Select
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Backup Note & Deposit Tab  */}
            {tabNo == 3 && (
                <div>
                    <div className="row justify-content-center">
                        <img
                            src={BackupNoteAndDeposit}
                            alt="Select A Coin"
                            className="col-lg-3 col-md-4 col-sm-5 col-xs-5 mt-2 mb-5"
                        />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-8 col-sm-10 justify-content-start p-5 options-main-div">
                            <div className="row justify-content-center align-items-center">
                                <div
                                    className="mb-4"
                                    style={{ backgroundColor: '' }}
                                >
                                    <img
                                        src={Confirm_Details}
                                        alt="Select A Coin"
                                        className=""
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-between pl-4 pr-4 detail-div">
                                <span
                                    style={{ color: 'rgba(255,255,255,0.5)' }}
                                >
                                    AMOUNT
                                </span>
                                <span>
                                    {amount} {selectedCoin}
                                </span>
                            </div>
                            <div className="row justify-content-between pl-4 pr-4 pt-2 detail-div">
                                <span
                                    style={{ color: 'rgba(255,255,255,0.5)' }}
                                >
                                    SERVICE
                                </span>
                                <span>{selectedMixture}</span>
                            </div>
                            <div
                                className="row justify-content-center mt-5 mb-2"
                                style={{ backgroundColor: '' }}
                            >
                                <img
                                    src={YOUR_NOTE}
                                    alt="Select A Coin"
                                    className=""
                                />
                            </div>
                            <div
                                className="row justify-content-center mt-4"
                                style={{ backgroundColor: '' }}
                            >
                                <img
                                    src={backup_your_note}
                                    alt="Select A Coin"
                                    className=""
                                />
                            </div>
                            <div className="pt-5 d-flex justify-content-center align-items-center">
                                <div>
                                    <textarea
                                        value={note}
                                        cols={30}
                                        disabled
                                        className="copy-text"
                                        style={{ height: '140px' }}
                                    />
                                </div>
                                <div
                                    className="d-flex pl-2 cursor-pointer"
                                    onClick={() => copyToClipboard(note)}
                                >
                                    <img
                                        src={copy_icon}
                                        alt="Select A Coin"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center col-lg-12 pl-5 pt-3 pb-3">
                                {isBackedUpCheck ? (
                                    <div
                                        className="check-box-style"
                                        onClick={() =>
                                            setBackedUpCheck(!isBackedUpCheck)
                                        }
                                    >
                                        <img
                                            src={tick}
                                            alt=""
                                            style={{ height: 10, width: 10 }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="uncheck-box-style"
                                        onClick={() =>
                                            setBackedUpCheck(!isBackedUpCheck)
                                        }
                                    ></div>
                                )}

                                <text className="pl-2">
                                    I backed up the note
                                </text>
                            </div>
                            <div className="row justify-content-center">
                                <Button
                                    className={
                                        isBackedUpCheck
                                            ? 'continue-btn-ok'
                                            : 'continue-btn-style'
                                    }
                                    onClick={sendDepositHandler}
                                >
                                    <span className="p-2">Send Deposit</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Confirm Transaction Tab  */}
            {tabNo == 4 && (
                <div>
                    <div className="d-flex justify-content-center">
                        <img
                            src={Transaction}
                            alt="Select A Coin"
                            className="mt-2 mb-4"
                        />
                    </div>
                    <div className="d-flex justify-content-center mb-4 mx-1">
                        Once you leave this page, you can't come back
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 justify-content-center py-5">
                            <div className="divider-border">
                                <div className="content-section">
                                    <div className="row justify-content-center align-items-center">
                                        <div
                                            className="mb-4"
                                            style={{ backgroundColor: '' }}
                                        >
                                            <img
                                                src={TXDepositDETAILS}
                                                alt="Select A Coin"
                                                className=""
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <text
                                            style={{
                                                color: 'rgba(255,255,255,0.5)',
                                            }}
                                        >
                                            AMOUNT
                                        </text>
                                        <text>
                                            {amount} {selectedCoin}
                                        </text>
                                    </div>
                                    <div className="d-flex justify-content-between pt-2">
                                        <span
                                            style={{
                                                color: 'rgba(255,255,255,0.5)',
                                            }}
                                        >
                                            SERVICE
                                        </span>
                                        <span>{selectedMixture}</span>
                                    </div>
                                    <div className="d-flex justify-content-between pt-2">
                                        <span
                                            style={{
                                                color: 'rgba(255,255,255,0.5)',
                                            }}
                                        >
                                            TX HASH
                                        </span>
                                        <div>
                                            <span className="pr-2">
                                                {txHash.slice(0, 10)}....
                                            </span>
                                            <img
                                                src={copy_icon}
                                                alt="copy txhash"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    copyToClipboard(txHash)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between pt-2">
                                        <span
                                            style={{
                                                color: 'rgba(255,255,255,0.5)',
                                            }}
                                        >
                                            NOTE
                                        </span>
                                        <div className="row justify-content-end align-items-center">
                                            <input
                                                type={
                                                    noteShow
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={note}
                                                style={{
                                                    background: 'transparent',
                                                    border: 'none',
                                                    color: 'white',
                                                    width: '40%',
                                                }}
                                            />
                                            <div
                                                className="d-flex pl-2 "
                                                onClick={() =>
                                                    copyToClipboard(note)
                                                }
                                            >
                                                <img
                                                    src={copy_icon}
                                                    alt="Select A Coin"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                            <img
                                                src={eye_icon}
                                                alt="Select A Coin"
                                                className="pl-2"
                                                onClick={() => {
                                                    setNoteShow(!noteShow)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5 mb-2 card pt-4 pb-4">
                                        <div className="d-flex justify-content-center">
                                            <span
                                                style={{
                                                    color: 'rgb(240,144,0)',
                                                    fontSize: '7px',
                                                }}
                                            >
                                                <Timer>
                                                    Warning! it’s only been{' '}
                                                    <Timer.Minutes
                                                        formatValue={(m) =>
                                                            `${m} minute${
                                                                m ? 's' : ''
                                                            } `
                                                        }
                                                    />
                                                    . Wait for more users to
                                                    join the pot
                                                </Timer>
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between pt-3 pb-3 px-3">
                                            <h3 className="title">
                                                Withdraw Funds
                                                <br />
                                                from the pot
                                            </h3>
                                            <div>
                                                <Button
                                                    className="btn-gradient-round withdraw-btn"
                                                    style={{ width: '100%' }}
                                                    onClick={() => {
                                                        props.setVisible(false)
                                                        props.setWithdrawModal(
                                                            true
                                                        )
                                                    }}
                                                >
                                                    <span
                                                        className="btn-text"
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        Withdraw
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 p-5">
                            <div className="mb-4">
                                <img
                                    src={WHAT_next}
                                    alt="Select A Coin"
                                    className=""
                                />
                            </div>
                            <div className="pt-3">
                                <img
                                    src={whats_next_data}
                                    alt=""
                                    className="transaction-img"
                                />
                            </div>
                            <div className="time-wrapper pt-5">
                                <Timer>
                                    <span className="time">
                                        <Timer.Minutes
                                            formatValue={(m) =>
                                                `${m} MINUTE${m ? 'S' : ''} `
                                            }
                                        />
                                    </span>{' '}
                                    HAVE PASSED
                                    <div className="time-warning time">
                                        Warning! it's been{' '}
                                        <Timer.Minutes
                                            formatValue={(m) =>
                                                `${m} minute${m ? 's' : ''} `
                                            }
                                        />
                                        . A 30 minute wait is recommended
                                    </div>
                                </Timer>
                            </div>
                        </div>
                    </div>

                    <div className="deposit-activity-section">
                        <div className="row justify-content-center my-5">
                            <text className="deposit-title">Activity</text>
                        </div>
                        <div className="row col-lg-12 justify-content-center">
                            <div className="row col-lg-11 col-md-12 justify-content-center">
                                <div className="filters row justify-content-start align-items-center w-100">
                                    <text className="mr-4">Filter by</text>
                                    <div
                                        className={
                                            'action px-3 py-2 mr-2 ' +
                                            (selectedCoin === CURRENCIES.ETH
                                                ? 'active'
                                                : '')
                                        }
                                        onClick={() => {
                                            setSelectedCoin(CURRENCIES.ETH)
                                            swapFilter(CURRENCIES.ETH)
                                        }}
                                    >
                                        <text>ETH</text>
                                    </div>
                                    <div
                                        className={
                                            'action px-3 py-2 ' +
                                            (selectedCoin === CURRENCIES.DAI
                                                ? 'active'
                                                : '')
                                        }
                                        onClick={() => {
                                            setSelectedCoin(CURRENCIES.DAI)
                                            swapFilter(CURRENCIES.DAI)
                                        }}
                                    >
                                        <text>DAI</text>
                                    </div>
                                    <div className="filters-divider" />

                                    <div className="action active px-3 py-2 mr-2">
                                        <text>DEPOSIT</text>
                                    </div>
                                    {/* <div className="action px-3 py-2">
                    <text>WITHDRAW</text>
                  </div> */}
                                </div>
                                {transactionsHistory.length > 0 ? (
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>TIME PASSED</th>
                                                <th>AMOUNT</th>
                                                <th>TX HASH</th>
                                                <th>STATUS</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionsHistory &&
                                                transactionsHistory.map(
                                                    (transaction: any) => (
                                                        <tr>
                                                            <td>
                                                                {getTimeElapsed(
                                                                    transaction.passedTime
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    transaction.amount
                                                                }
                                                            </td>
                                                            <td
                                                                className="tx-hash"
                                                                onClick={() => {
                                                                    if (
                                                                        user.chainId ===
                                                                        42
                                                                    ) {
                                                                        window.open(
                                                                            `${Config.etherscanKovanUrl}/${transaction.txHash}`,
                                                                            '_blank'
                                                                        )
                                                                    } else {
                                                                        window.open(
                                                                            `${Config.etherscanMainUrl}/${transaction.txHash}`,
                                                                            '_blank'
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {transaction.txHash.slice(
                                                                    0,
                                                                    10
                                                                )}
                                                                ....
                                                            </td>
                                                            <td>
                                                                {
                                                                    transaction.status
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-center">
                                                                    <div
                                                                        className="activity-action d-flex align-items-center p-2 mr-3"
                                                                        onClick={() =>
                                                                            copyToClipboard(
                                                                                transaction.note
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                Note
                                                                            }
                                                                            width="14"
                                                                            height="14"
                                                                            className="mr-2"
                                                                        />
                                                                        <text>
                                                                            Note
                                                                        </text>
                                                                    </div>

                                                                    <div
                                                                        className="activity-action d-flex align-items-center p-2"
                                                                        onClick={() =>
                                                                            deleteTransaction(
                                                                                transaction.txHash
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                Trash
                                                                            }
                                                                            width="14"
                                                                            height="16"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="w-100 empty-table-txn">
                                        No Transaction Data Found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoaderModal
                show={isLoading}
                isOptions={isTransactionModal}
                title="Confirming"
                message={loaderMessage}
            />
        </div>
    )
}

export default DepositModal
