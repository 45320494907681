/* eslint-disable */
import React from 'react'
import './RatesTable.scss'
import { Button } from 'react-bootstrap'
import { Table } from 'react-bootstrap'

import { gql, useApolloClient } from '@apollo/client'
import { spawn } from 'child_process'
import { cutNumber } from 'app/utils/Utilities/transactionsUtils'

const GET_ALL_PROTOCOL_RATES = gql`
    query getAllProtocolRates {
        getAllProtocolRates {
            data {
                tokenRates {
                    asset
                    lending
                    icon
                    tokenId
                }
                icon
                protocol
                protocolId
            }
            status
            message
        }
    }
`
const GET_PROTOCOLS = gql`
    query getProtocols($chainId: Int!) {
        getProtocols(chainId: $chainId) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            tokens {
                tokenId
                tokenName
                tokenIcon
                tokenType
                tokenSymbol
                tokenAddress
                tokenDecimals
            }
        }
    }
`

interface IProps {
    onPressToken?: Function
    setSelectedProtocol?: Function
    protocolsList: any
    SelectedToken?: Function
    connectionBalances?: any
    user?: any
    userTokenBalance?: Function
}

const RatesTable: React.FC<IProps> = (props) => {
    const [protocols, setProtocols] = React.useState<any>(false)
    const [tokens, setTokens] = React.useState<any>(false)
    const [activeTab, setActiveTab] = React.useState<any>(0)
    const [amountLocked, setAmountLocked] = React.useState<any>(0)
    const [protocolAddress, setProtocolAddress] = React.useState<any>(false)
    const [filteredDeposits, setFilteredDeposits] = React.useState<any>([])
    const [showBtn, setShowBtn] = React.useState<any>(null)
    const [isAnyTokenInvested, setAnyTokenInvested] = React.useState<boolean>(false)

    const client = useApolloClient()
    const boxRef = React.useRef(null)

    React.useEffect(() => {
        if (props.connectionBalances) {
            let tokensList = []

            props.connectionBalances.map((rate) => tokensList.push(rate.token))
            setTokens(tokensList)
        }
    }, [props.connectionBalances])

    React.useEffect(() => {
        client
            .query({
                query: GET_ALL_PROTOCOL_RATES,
            })
            .then(({ data }: any) => {
                const ratesObj = data.getAllProtocolRates.data
                setProtocols(ratesObj)
            })
    }, [client])
    React.useEffect(() => {
        client
            .query({
                query: GET_PROTOCOLS,
                variables: { chainId: props.user.chainId },
            })
            .then(({ data }: any) => {
                setProtocolAddress(data.getProtocols.map((proto) => proto))
            })
        // .finally(() => setGettingProtocols(false))
    }, [client])

    React.useEffect(() => {
        let tokenAddress = {
            tokenAddress: '',
        }
        let tokenDecimals = {
            tokenDecimals: 0,
        }
        if (props.connectionBalances && protocolAddress) {
            props.connectionBalances.map((connection) =>
                connection.token.map((connTok) =>
                    protocolAddress.map((proto) =>
                        proto.tokens.map((protoTok) => {
                            if (
                                parseInt(protoTok.tokenId) === connTok.tokenId
                            ) {
                                tokenAddress.tokenAddress =
                                    protoTok.tokenAddress
                                tokenDecimals.tokenDecimals =
                                    protoTok.tokenDecimals
                                Object.assign(connTok, tokenAddress)
                                Object.assign(connTok, tokenDecimals)
                            }
                        })
                    )
                )
            )
        }
    }, [props.connectionBalances])

    React.useEffect(() => {
        if (props.connectionBalances) {
            let newArr

            newArr = props.connectionBalances.map((element) => {
                return {
                    ...element,
                    token: element.token.filter(
                        (tok) => tok.balance.investment > 0
                    ),
                }
            })
            newArr.map((res)=>{
                if (res.token.length>0) {
                    setAnyTokenInvested(true)
                }
            })
            setFilteredDeposits(newArr)
        }
    }, [props.connectionBalances])
    
    return (
        <div className="rates-section">
            {' '}
            {/* ================================= Deposits Table ===================================================== */}
            {
            filteredDeposits &&  isAnyTokenInvested && (
                <Table className="table-rate-style mt-2" responsive>
                    <thead>
                        <tr>
                            <th>Assets</th>
                            <th className="text-center">Deposited</th>
                            <th className="text-center">APY</th>
                            <th className="text-center">Interest Earned</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDeposits &&
                            filteredDeposits.map((activity, index) =>
                                activity.token.map((deposit, index) => (
                                    <tr key={index}>
                                        <td>
                                            {' '}
                                            <div className="d-flex align-items-center justify-content-start">
                                                <img
                                                    src={require(`./../../../assets/images/${activity.protocolIcon}`)}
                                                    className="position-relative"
                                                    width="30"
                                                    height="30"
                                                    style={{
                                                        left: '8px',
                                                    }}
                                                    alt={activity.protocolName}
                                                />
                                                <img
                                                    src={require(`./../../../assets/images/${deposit.tokenIcon}`)}
                                                    className="position-relative"
                                                    width="30"
                                                    height="30"
                                                    style={{
                                                        right: '4px',
                                                    }}
                                                    alt={deposit.tokenSymbol}
                                                />
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {
                                                parseFloat(
                                                    deposit.balance.investment
                                                ).toFixed(2)

                                                // parseInt(
                                                //     deposit
                                                //         .balance
                                                //         .investment
                                                // ).toFixed(
                                                //     2
                                                // )
                                            }
                                        </td>
                                        <td className="text-center">
                                            {protocols &&
                                                protocols.map(
                                                    (protocol, index) =>
                                                        protocol.tokenRates.map(
                                                            (tok, index) =>
                                                                tok.tokenId ===
                                                                deposit.tokenId ? (
                                                                    <span>
                                                                        {tok.lending.toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )
                                                        )
                                                )}
                                        </td>
                                        <td className="text-center">
                                            {parseFloat(
                                                deposit.balance.interest
                                            ).toFixed(2)}
                                        </td>
                                        <td className="text-center">
                                            <button
                                                className="widthraw-btn"
                                                onClick={() => {
                                                    //   handleClick(element)
                                                    props.SelectedToken(deposit)
                                                    props.userTokenBalance(
                                                        deposit.balance
                                                            .investment
                                                    )
                                                    props.setSelectedProtocol(
                                                        activity
                                                    )
                                                    props.onPressToken &&
                                                        props.onPressToken(
                                                            'withdraw'
                                                        )
                                                }}
                                            >
                                                Withdraw
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                    </tbody>
                </Table>
            )}
            {/*  ============================================= Rates Table =================================================================================== */}
            <div className="mt-5 row protocols justify-content-between">
                {props.connectionBalances &&
                    props.connectionBalances.map(
                        (element: any, index: number) => (
                            <div
                                key={index}
                                className="col-xs-12 col-sm-4 col-md-2 col-lg-2 wrapper pointer"
                                onClick={() => {
                                    setActiveTab(index)
                                    if (props.connectionBalances.length > 0) {
                                        props.connectionBalances.map(
                                            (item, index) => {
                                                if (
                                                    item.protocolId ===
                                                    element.protocolId
                                                ) {
                                                    props.setSelectedProtocol(
                                                        item
                                                    )
                                                    console.log(
                                                        'protocol from table',
                                                        item
                                                    )
                                                }
                                            }
                                        )
                                    }
                                }}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={require(`./../../../assets/images/${element.protocolIcon}`)}
                                        alt=""
                                        className={`${
                                            element.protocolKey === 'Dydx'
                                                ? 'dydx-image'
                                                : element.protocolKey ===
                                                  'yEarn'
                                                ? 'yEarn-image'
                                                : 'image'
                                        }`}
                                    />
                                    {element.protocolKey !== 'Dydx' && (
                                        <text>{element.protocolKey}</text>
                                    )}
                                </div>
                                <div
                                    className={`mx-auto mt-2 ${
                                        activeTab === index ? 'active' : ''
                                    }`}
                                />
                            </div>
                        )
                    )}
            </div>
            <div className="token-section">
                <div className="token-wrapper col-12">
                    <Table className="table-rate-style" responsive>
                        <thead>
                            <tr>
                                <th>Assets</th>
                                <th>APY</th>
                                <th>Amount Locked</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tokens &&
                            tokens[activeTab] &&
                            tokens[activeTab].length > 0
                                ? tokens[activeTab].map(
                                      (element: any, index) => (
                                          <tr
                                              className="mb-3 mt-3"
                                              onClick={() => {
                                                  //   handleClick(element)
                                                  props.SelectedToken(element)
                                                  props.onPressToken &&
                                                      props.onPressToken(
                                                          'deposit'
                                                      )
                                              }}
                                              id={element.tokenId}
                                              key={element.id}
                                              onMouseLeave={() => {
                                                  setShowBtn(false)
                                              }}
                                          >
                                              <td>
                                                  <img
                                                      src={require(`./../../../assets/images/${element.tokenIcon}`)}
                                                      alt="token-icon"
                                                      className="token-logo"
                                                  />
                                                  <text className="token-name">
                                                      {element.tokenSymbol}
                                                  </text>
                                              </td>
                                              <td>
                                                  <text className="apy-value">
                                                      {protocols &&
                                                          protocols.map(
                                                              (
                                                                  protocol,
                                                                  index
                                                              ) =>
                                                                  protocol.tokenRates.map(
                                                                      (
                                                                          tok,
                                                                          index
                                                                      ) =>
                                                                          tok.tokenId ===
                                                                          element.tokenId ? (
                                                                              <span>
                                                                                  {tok.lending.toFixed(
                                                                                      2
                                                                                  )}

                                                                                  %
                                                                              </span>
                                                                          ) : (
                                                                              ''
                                                                          )
                                                                  )
                                                          )}
                                                      {/* 
                                                  {`${
                                                      element.lending
                                                          ? `${element.lending.toFixed(
                                                                2
                                                            )}%`
                                                          : 'NA'
                                                  }`} */}
                                                  </text>
                                              </td>
                                              <td className="amount-value">
                                                  ${' '}
                                                  {`${Math.round(
                                                      parseFloat(
                                                          element.balance
                                                              .investment
                                                      )
                                                  ).toFixed(2)}`}
                                              </td>
                                              <td
                                                //   id={element.tokenId}
                                                //   onMouseEnter={(e) => {
                                                //       let x =
                                                //           e.target as Element

                                                //       if (
                                                //           x.id ==
                                                //           element.tokenId
                                                //       ) {
                                                //           setShowBtn(x.id)
                                                //       }
                                                //   }}
                                              >
                                                  {/* {showBtn ==
                                                  element.tokenId ? ( */}
                                                      <button className="lend-btn-primary lendActionBtn">
                                                          Lend
                                                      </button>
                                                  {/* ) : (
                                                      ''
                                                  )} */}
                                              </td>
                                          </tr>
                                      )
                                  )
                                : ''}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default RatesTable
// <Button
//   className="deposit-btn"
//   onClick={() => {
//     props.onPressToken && props.onPressToken("deposit");
//     if (props.protocolsList.length > 0) {
//       props.protocolsList.map((item, index) => {
//         if (item.protocolId === protocols[activeTab].protocolId) {
//           props.setSelectedProtocol(item);
//         }
//       });
//     }
//   }}
// >
//   <span className="text-btn">Deposit</span>
// </Button>
