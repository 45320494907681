import React from 'react'
import './ResultSection.scss'
import {Doughnut} from 'react-chartjs-2'

interface IProps {
    proposalDetails: any
    governanceDetail: any
}
const ResultSection: React.FC<IProps> = (prop) => {
    const data = {
        type: '',
        labels: ['Against Votes', 'For Votes'],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    prop.proposalDetails.againstVotes,
                    prop.proposalDetails.forVotes,
                ],
                backgroundColor: ['#767676', '#2F80ED'],
                borderColor: ['#1D1E23', '#1D1E23'],
                borderWidth: 6,
            },
        ],
    }

    const options = {
        responsive: false,
        cutout: '75%',
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            arc: {
                borderRadius: 30,
            },
        },
    }

    function percentage(num) {
        let {againstVotes, forVotes} = prop.proposalDetails
        againstVotes = againstVotes === 0 ? 1 : againstVotes
        forVotes = forVotes === 0 ? 1 : forVotes
        const total = parseFloat(againstVotes || 0) + parseFloat(forVotes || 0)
        return ((parseFloat(num) / total) * 100).toFixed(2)
    }

    function totalVotes() {
        const {againstVotes, forVotes} = prop.proposalDetails
        const total = parseFloat(againstVotes || 0) + parseFloat(forVotes || 0)
        console.log('total: ', total)
        console.log('total.toFixed(2): ', total.toFixed(2))
        return Number(total).toFixed(2)
    }

    return (
        <>
            <div className="result-section">
                <div className="header-section">
                    <h2 className="title m-l-26">Results</h2>
                </div>
                <div className="content-section">
                    {/*  <div className="doughnut-chart-container">
            <Doughnut
              height={190}
              width={190}
              type="doughnut"
              data={data}
              options={options}
            />
            <div className="total-votes">
              <h2 className="total-votes-number">{totalVotes()}</h2>
              <h3 className="total-votes-title">total votes</h3>
            </div>
          </div> */}
                    <div className="progress-bar-content">
                        <div className="custom-progress-bar-container">
                            <div className="custom-progress-bar-stats">
                                <h2>
                                    For {prop.proposalDetails.forVotes}{' '}
                                    {prop.governanceDetail.nickName}
                                </h2>
                                <h2>
                                    {prop.proposalDetails.forVotes > 0
                                        ? percentage(
                                              prop.proposalDetails.forVotes
                                          )
                                        : 0}
                                    %
                                </h2>
                            </div>
                            <div className="custom-progress-bar">
                                <div className="custom-all-progress">
                                    <div
                                        style={{
                                            width:
                                                percentage(
                                                    prop.proposalDetails
                                                        .forVotes
                                                ) + '%',
                                        }}
                                        className="active-progress blue"
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div className="custom-progress-bar-container">
                            <div className="custom-progress-bar-stats">
                                <h2>
                                    Against {prop.proposalDetails.againstVotes}{' '}
                                    {prop.governanceDetail.nickName}
                                </h2>
                                <h2>
                                    {prop.proposalDetails.againstVotes > 0
                                        ? percentage(
                                              prop.proposalDetails.againstVotes
                                          )
                                        : 0}
                                    %
                                </h2>
                            </div>
                            <div className="custom-progress-bar">
                                <div className="custom-all-progress">
                                    <div
                                        style={{
                                            width:
                                                percentage(
                                                    prop.proposalDetails
                                                        .againstVotes
                                                ) + '%',
                                        }}
                                        className="active-progress orange"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResultSection
