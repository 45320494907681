/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './overview.scss'
import vector1 from '../../assets/icons/vector1.svg'
import vector2 from '../../assets/icons/vector2.svg'
import AnalyticsChart from 'app/shared/components/chart/AnalyticsChart'
import PairChart from 'app/shared/components/chart/PairChart'
import { authContext } from './../../utils/context/AuthContext'
import { useWeb3React } from '@web3-react/core'
import connectors from './../../utils/connections/walletConnectors'
import LendingChart from 'app/shared/components/chart/LendingChart'

import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
const GET_TRANSACTIONS = gql`
    query getTransactionsV2($chainId: Int, $walletAddress: String) {
        getTransactionsV2(chainId: $chainId, walletAddress: $walletAddress) {
            status
            message
            count
            transactions {
                amount
                transactionId
                targetData
                transactionId
                transactionHash
                transactionType
                transactionStatus
                from
                targetAddress
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`

const GET_PORTFOLTIO_INFO = gql`
    query getV2PortfolioInfo($chainId: Int!, $tokenId: Int!) {
        getV2PortfolioInfo(chainId: $chainId, tokenId: $tokenId) {
            tokenStats {
                amountDeposited
                interestEarned
            }
            totalAndFee {
                amountDeposited
                totalInterest
            }
            deposits {
                amountUsd
            }
            withdrawals {
                amountUsd
            }
        }
    }
`
const GET_GRAPH_TRANSACTIONS = gql`
    query getGraphTransactionsV2(
        $walletAddress: String!
        $filter: String!
        $chainId: Int!
    ) {
        getGraphTransactionsV2(
            walletAddress: $walletAddress
            filter: $filter
            chainId: $chainId
        ) {
            response {
                status
                message
            }
            data {
                protocol {
                    protocolId
                    protocolName
                    protocolIcon
                }
                graphData {
                    x
                    y
                }
            }
        }
    }
`
const GET_CONNECTION_BALANCES = gql`
    query getConnectionBalances($walletAddress: String!, $chainId: Int!) {
        getConnectionBalances(
            walletAddress: $walletAddress
            chainId: $chainId
        ) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            token {
                tokenId
                tokenName
                tokenIcon
                tokenSymbol
                balance {
                    investment
                    interest
                }
            }
        }
    }
`

interface IProps {}
const Overview: React.FC<IProps> = () => {
    const client = useApolloClient()
    const { auth: user, setAuthStatus } = React.useContext(authContext)
    const [activities, setActivities] = useState<any>([])
    const [confirmedDeposits, setConfirmedDeposits] = useState<any>([])
    const [graphTransactions, setGraphTransactions] = useState<any>([])
    const [selectedProtocolBalance, setSelectedProtocolBalance] =
        useState<any>(false)
    const context = useWeb3React()
    const { connector, account, activate, active, library } = context
    const walletsImportObj = {
        MetaMask: connectors.Injected,
        Fortmatic: connectors.Fortmatic,
        Ledger: connectors.Ledger,
        Trezor: connectors.Trezor,
        Torus: connectors.Torus,
        Authereum: connectors.Authereum,
        SquareLink: connectors.SquareLink,
        WalletConnect: connectors.WalletConnect,
    }

    React.useEffect(() => {
        if (user.walletAddress) {
            getAllTransactions()
            // getPortfolioInfo()
            getConnectionBalances()
            getGraphTransaction()
            if (user.accountType && !connector && !account) {
                if (user.accountType === 'METAMASK') {
                    activate(walletsImportObj['MetaMask'])
                }
            }
        }
    }, [user.walletAddress])

    const getAllTransactions = () => {
        client
            .query({
                query: GET_TRANSACTIONS,
                variables: {
                    chainId: user.chainId,
                    walletAddress: user.walletAddress,
                },
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                const reverse: any[] = [
                    ...data.getTransactionsV2.transactions,
                ].reverse()
                setActivities(reverse)
                setConfirmedDeposits(
                    reverse.filter(
                        (b: any) => b.transactionStatus === 'confirmed'
                        // &&
                        // b.transactionType === 'deposit'
                    )
                )
                data.getTransactionsV2.transactions.map(
                    (activity: any, i: number) => {
                        if (
                            activity.transactionType === 'walletCreation' &&
                            activity.transactionStatus === 'confirmed'
                        ) {
                            localStorage.setItem('walletCreation', 'confirmed')
                        }
                    }
                )
            })
    }

    // const getPortfolioInfo = () => {
    //     client
    //         .query({
    //             query: GET_PORTFOLTIO_INFO,
    //             variables: {
    //                 chainId: user.chainId,
    //                 tokenId: 1,
    //                 walletAddress: user.walletAddress,
    //             },
    //             fetchPolicy: 'no-cache',
    //         })
    //         .then(({ data }: any) => {
    //             const result: any[] = [data]
    //             console.log('data', result)
    //         })
    // }

    const getGraphTransaction = () => {
        client
            .query({
                query: GET_GRAPH_TRANSACTIONS,
                variables: {
                    walletAddress: user.walletAddress,
                    filter: '',
                    chainId: user.chainId,
                },
            })
            .then(({ data }: any) => {
                if (data.getGraphTransactionsV2) {
                    setGraphTransactions(data.getGraphTransactionsV2.data)
                    console.log('graph data', data.getGraphTransactionsV2.data)
                }
                // setTokens(data.getTokens);
            })
        // .finally(() => setGettingTokens(false));
    }

    const getConnectionBalances = () => {
        // setGettingProtocols(true);
        client
            .query({
                query: GET_CONNECTION_BALANCES,
                variables: {
                    walletAddress: user.walletAddress,
                    chainId: user.chainId,
                },
            })
            .then(({ data }: any) => {
                if (
                    data &&
                    data.getConnectionBalances &&
                    data.getConnectionBalances.length
                ) {
                    setSelectedProtocolBalance(data.getConnectionBalances[0])
                }
            })
        // .finally(() => setGettingProtocols(false))
    }
    return (
        <>
            <div className="d-flex justify-content-center align-items-center mb-5 overview">
                <div className="row w-100 mainContainerMarginTop">
                    <div className="col-md-6 ">
                        <h1 className="overview-title">$40,789.24</h1>
                        <h1 className="overview-subtitle">+12.32% ($2.89)</h1>
                        <div className="card">
                            <div className="card-title">
                                Portfolio Performance
                            </div>
                            <div className="card-body">
                                <LendingChart
                                    graphData={graphTransactions}
                                    selectedProtocol={selectedProtocolBalance}
                                />
                                {/* <PairChart
                                    name={'ADD-ETH UNI-V2 LP'}
                                    address={user.walletAddress}
                                    color={'rgba(39, 111, 255, 0.85)'}
                                />
                                <AnalyticsChart
                                    graphData={
                                        GraphTransactions.map((transaction) =>
                                            transaction.graphData.map((x) => {
                                                return {
                                                    data: x.y,
                                                    DAI: transaction.protocol
                                                        .protocolId,
                                                }
                                            })
                                        )
                                        //     [
                                        //     {
                                        //         data: '"17-11-2020 23:39:29"',
                                        //         DAI: '1',
                                        //     },
                                        //     {
                                        //         data: '"17-11-2020 23:39:29"',
                                        //         DAI: '2',
                                        //     },
                                        //     {
                                        //         data: '"17-11-2020 23:39:29"',
                                        //         DAI: '3',
                                        //     },
                                        // ]
                                    }
                                    graphMetaData={[
                                        {
                                            dataKey: 'DAI',
                                            strokeName: 'DAI',
                                            strokeOpacity: 1,
                                            strokeWidth: 5,
                                        },
                                        {
                                            dataKey: 'USDC',
                                            strokeName: 'USDC',
                                            strokeOpacity: 1,
                                            strokeWidth: 5,
                                        },
                                        {
                                            dataKey: 'DAI',
                                            strokeName: 'DAI',
                                            strokeOpacity: 1,
                                            strokeWidth: 5,
                                        },
                                    ]}
                                    selectedToken={0}
                                /> */}
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="card">
                                <div className="card-title">All Assets</div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody>
                                            {confirmedDeposits &&
                                                confirmedDeposits.map(
                                                    (transaction, index) => (
                                                        <tr>
                                                            <td className="border-top-0">
                                                                <div className="d-flex align-items-center justify-content-start">
                                                                    <img
                                                                        src={require(`../../assets/images/${transaction.protocolId.protocolIcon}`)}
                                                                        alt=""
                                                                        className="align-top"
                                                                        width="30"
                                                                        height="30"
                                                                    />
                                                                    <span className="ml-2 collapse-title">
                                                                        {
                                                                            transaction
                                                                                .protocolId
                                                                                .protocolName
                                                                        }{' '}
                                                                        <span className="percentage">
                                                                            69.98%
                                                                        </span>{' '}
                                                                        <br />
                                                                        <span className="collapse-subtitle">
                                                                            {/* 157.036
                                                                            ADD
                                                                            $0.27 */}{' '}
                                                                            $
                                                                            {
                                                                                transaction.amount
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="border-top-0 text-right ">
                                                                <span className="ml-2 collapse-title">
                                                                    ${' '}
                                                                    {
                                                                        transaction.amount
                                                                    }
                                                                    {/* <br />
                                                                    <span className="collapse-subtitle text-green">
                                                                        +4.65%
                                                                        $0.58
                                                                    </span> */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            {/* <tr>
                                                <td className="border-top-0">
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <img
                                                            src={vector1}
                                                            alt=""
                                                            className="align-top"
                                                        />
                                                        <span className="ml-2 collapse-title">
                                                            ADD{' '}
                                                            <span className="percentage">
                                                                69.98%
                                                            </span>{' '}
                                                            <br />
                                                            <span className="collapse-subtitle">
                                                                157.036 ADD
                                                                $0.27
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-right ">
                                                    <span className="ml-2 collapse-title">
                                                        $30.10 <br />
                                                        <span className="collapse-subtitle text-green">
                                                            +4.65% $0.58
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-top-0">
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <img
                                                            src={vector1}
                                                            alt=""
                                                            className="align-top"
                                                        />
                                                        <span className="ml-2 collapse-title">
                                                            ADD{' '}
                                                            <span className="percentage">
                                                                69.98%
                                                            </span>{' '}
                                                            <br />
                                                            <span className="collapse-subtitle">
                                                                157.036 ADD
                                                                $0.27
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-right ">
                                                    <span className="ml-2 collapse-title">
                                                        $30.10 <br />
                                                        <span className="collapse-subtitle text-green">
                                                            +4.65% $0.58
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Overview
