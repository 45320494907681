/* eslint-disable */
import React from 'react'
import { Button } from 'react-bootstrap'

// constants
import { supportedPools } from '../../../../config/constants'

// assets
import LiquidityAnalytics from '../LiquidityAnalytics'
import Eth from '../../../../assets/images/project/eth_add.svg'
import Dai from '../../../../assets/images/project/dai_add.svg'
import Usdt from '../../../../assets/images/project/usdt_add.svg'
import help from '../../../../assets/images/project/help.svg'
import PoolCard from '../PoolCard'
import Config from '../../../../config/config'

interface IProps {
    startBlock: any
    currentBlockNumber: any
    yourLiquidity?: any
    rewardsPerBlock?: any
    blockNumber?: any
    blockTime?: any
    totalRewardEarned: string
    getRewardandBalanceAmount: any
    endBlock: number
    blockDiff: number
    onBack: Function
}

const AddStaking: React.FC<IProps> = (props) => {
    const {
        startBlock,
        currentBlockNumber,
        yourLiquidity,
        rewardsPerBlock,
        blockNumber,
        blockTime,
        totalRewardEarned,
        getRewardandBalanceAmount,
        endBlock,
        blockDiff,
        onBack,
    } = props
    return (
        <div className="d-flex justify-content-center">
            <div className="add-xyz staking-ui ">
                {/* <div className=''>
        <LiquidityAnalytics
        {...props}
        // startBlock={startBlock}
        // currentBlockNumber = {currentBlockNumber}
        //   yourLiquidity={yourLiquidity}
        //   rewardsPerBlock={rewardsPerBlock}
        //   blockNumber={blockNumber}
        //   blockTime={blockTime}
        //   totalRewardEarned={totalRewardEarned}
        //   endBlock={endBlock}
        //   blockDiff={blockDiff}
        />
      </div> */}
                <div className="d-flex justify-content-between mb-5 flex-wrap wrap-items">
                    {supportedPools.map((pool, ind) => {
                        return (
                            <div key={ind} className="staking-card-round mb-4">
                                <PoolCard
                                    title={pool.name}
                                    multiplier={pool.multiplier}
                                    imageUrl={pool.icon}
                                    apr={pool.apr}
                                    earn={pool.earn}
                                    tokenAddress={pool.tokenAddress}
                                    pid={pool.pid}
                                    address={pool.address}
                                    getRewardandBalanceAmount={
                                        getRewardandBalanceAmount
                                    }
                                    currentBlockNumber={currentBlockNumber}
                                    endBlock={endBlock}
                                    yourliquidity={yourLiquidity}
                                    onBack={onBack}
                                />
                            </div>
                        )
                    })}
                </div>
                {/* <Button
        className='faq-button mt-5 mb-5 mx-auto'
        onClick={() => window.open('https://about.add.xyz/staking-liquidity-dashboard')}
      >
        <div className='d-flex justify-content-center align-items-center'>
          <img src={help} alt="" className="mr-1" />
          <span className='text-decoration-none faq-text ml-1'>
            FAQ?
          </span>
        </div>
      </Button> */}
            </div>
        </div>
    )
}

export default AddStaking
