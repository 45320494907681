import React from "react";
import "./InfoSection.scss";
import rectangle1 from "./../../../../assets/images/governance/rectangle-1@2x.png";

interface IProps {
  governanceDetail: any;
  proposalDetails: any;
}
const InfoSection: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="info-section">
        <div className="header-section">
          <h2 className="title m-l-26">Info</h2>
        </div>
        <div className="content-section">
          <div className="column">
            <h3>Token(s)</h3>
            <h2>
              {/* <img src={rectangle1} alt="" /> */}{" "}
              {props.governanceDetail.nickName}
            </h2>
          </div>
          <div className="column">
            <h3>Author</h3>
            <h2 style={{ wordBreak: "break-all" }}>
              {props.proposalDetails.author &&
                props.proposalDetails.author.substr(0, 6) +
                  "..." +
                  props.proposalDetails.author.substr(
                    props.proposalDetails.author.length - 7
                  )}
            </h2>
          </div>
          {/* <div className="column">
              <h3>IPFS</h3>
              <h2>#QmQxMTQ</h2>
          </div> */}
          <div className="column">
            <h3>Start date</h3>
            <h2>
              {props.proposalDetails.startDateTime
                ? new Date(props.proposalDetails.startDateTime).toDateString()
                : "-"}
            </h2>
          </div>
          <div className="column">
            <h3>End date</h3>
            <h2>
              {props.proposalDetails.endDateTime
                ? new Date(props.proposalDetails.endDateTime).toDateString()
                : "-"}
            </h2>
          </div>
          {/* <div className="column">
              <h3>Snapshot</h3>
              <h2>11,048,540</h2>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default InfoSection;
