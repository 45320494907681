import React, { useState } from 'react'
import USD_ICON from '../../../assets/images/usd.svg'
import BTC_ICON from '../../../assets/images/btc.svg'
import ETH_ICON from '../../../assets/images/eth.svg'

import { Dropdown } from 'react-bootstrap'
import rocket from '../../../assets/images/rocket.svg'
import SearchInput from '../SearchInput/SearchInput'
import axios from 'axios'
import Config from 'app/config/config'
import { authContext } from 'app/utils/context/AuthContext'
import Avatar from '../../../assets/images/project/avatar.svg'
const Web3 = require('web3')

const currency = [{
    image : USD_ICON,
    title : "USD US Dollar",
    symbol : "USD"
},
{
    image : BTC_ICON,
    title : "BTC Bitcoin",
    symbol : "BTC"
},
{
    image : ETH_ICON,
    title : "ETH Ether",
    symbol : "ETH"
}
]
const gasFee = [{
    image : rocket,
    title : "Fast",
    amount : "36 GWEI",
    symbol : "Fast"
},
{
    image : rocket,
    title : "Average",  
    amount : "30 GWEI",
    symbol : "Average"
},
{
    image : rocket,
    title : "Slow",
    amount : "24 GWEI",
    symbol : "Slow"
}
]
const DashboardHeader = () => {
    const { auth: user, setUnauthStatus } = React.useContext(authContext)
    const [selectedCurrency , setSelectedCurrency] = useState(currency[0])
    const [gasfee , setGasfee] = useState(gasFee[0])

    //this is use to get user balanace
    const web3 = new Web3(Web3.givenProvider)
    const [balance, setBalance] = useState<any>()
    web3.eth.getBalance(user.walletAddress, function (err, result) {
        if (err) {
            console.log(err)
        } else {
            getEthRate(web3.utils.fromWei(result, 'ether'))
            console.log(web3.utils.fromWei(result, 'ether') + ' ETH')
        }
    })

    const getEthRate = async (value) => {
        const result: any = await axios.get(Config.ethRateApiUrl)
        setBalance(result.data.USD * value)
    }
    
    return (
        <div className="dashboard-header">
            <div className="d-flex flex-wrap dashboardAvatarMain">
                    <img src={Avatar} alt="Avatar" />
                    <div className="ml-3">
                        <p className="wallet-address mb-1">
                            {user.walletAddress &&
                                user.walletAddress.substr(0, 3) +
                                    '...' +
                                    user.walletAddress.substr(
                                        user.walletAddress.length - 7
                                    )}
                        </p>
                        <p className="user-balance mb-0">
                            $ {parseFloat(balance).toFixed(4)}
                        </p>
                    </div>
            </div>
            <SearchInput placeholder="Search for tokens , pools..." classes = {'px-3'} />
            {/* <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                        <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.673 14.862L11.873 10.312C12.8028 9.22334 13.3626 7.84 13.3626 6.33334C13.3626 2.84134 10.3652 0 6.6813 0C2.99743 0 0 2.84131 0 6.33331C0 9.82531 2.99746 12.6667 6.68133 12.6667C8.27077 12.6667 9.73012 12.136 10.8786 11.2547L15.6786 15.8047C15.8157 15.9347 15.9958 16 16.1758 16C16.3559 16 16.5359 15.9347 16.6731 15.8047C16.9481 15.544 16.9481 15.1227 16.673 14.862ZM6.68133 11.3333C3.77248 11.3333 1.4066 9.09066 1.4066 6.33331C1.4066 3.57597 3.77248 1.33331 6.68133 1.33331C9.59018 1.33331 11.9561 3.57597 11.9561 6.33331C11.9561 9.09066 9.59014 11.3333 6.68133 11.3333Z"
                                fill="#B3B3B3"
                            />
                        </svg>
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for tokens, pools..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </div> */}
            <div className="d-flex">
                <Dropdown className="w-50 ">
                    <Dropdown.Toggle
                        variant=""
                        className="dropdown-tokens d-flex align-items-center ml-auto border-0 shadow-none"
                        id="dropdown-basic"
                    >
                        <img
                            src={selectedCurrency.image}
                            className="ml-1 mr-1"
                            alt=""
                            width="21"
                            height="21"
                        />
                        {selectedCurrency.symbol}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className= "image-bg">
                        {currency.map((coin, index)=>(
                            <Dropdown.Item className =  "dropdownItem" onClick  = {()=>{setSelectedCurrency(coin)}} key = {index}>
                                <div className="d-flex  align-items-center">
                                    <img
                                        src={coin.image}
                                        className="mr-2"
                                        alt=""
                                        width="21"
                                        height="21"
                                    />
                                    {coin.title}
                                </div>
                            </Dropdown.Item>
                        ))}
                       
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="w-50 ">
                    <Dropdown.Toggle
                        variant=""
                        className="dropdown-tokens d-flex align-items-center ml-auto border-0 shadow-none"
                        id="dropdown-basic"
                    >
                        <img
                            src={gasfee.image}
                            className="ml-1 mr-1"
                            alt=""
                            width="21"
                            height="21"
                        />
                        {gasfee.symbol}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className= "image-bg">
                    {gasFee.map((fee, index)=>(
                            <Dropdown.Item className =  "dropdownItem" onClick  = {()=>{setGasfee(fee)}} key = {index}>
                                <div className="d-flex align-items-center">
                                    <img
                                        src={fee.image}
                                        className="mr-2"
                                        alt=""
                                        width="21"
                                        height="21"
                                    />
                                    <span className = "width100px">{fee.title}</span>
                                    <span className = "feeAmountLabel">{fee.amount}</span>

                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default DashboardHeader
