import React from "react";
import "./ProposalDetail.scss";

interface IProps {
  proposalDetails: any;
}
const ProposalDetail: React.FC<IProps> = (props) => {
  const proposalInfo = props.proposalDetails;

  return (
    <>
      <div className="proposal-details">
        <div className="header-section">
          <h2 className="title">Proposal Details</h2>
          <div
            className={`status ${
              proposalInfo.votingStatus ? "active" : "closed"
            }`}
          >
            <h2>{proposalInfo.votingStatus ? "Active" : "Closed"}</h2>
          </div>
        </div>
        <div className="content-section">
          {/*  <p className="small-text">You can view the discussion here:</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://forum.yam.finance/t/pause-liquidity-incentives-until-yam-eth-update/821"
          >
            https://forum.yam.finance/t/pause-liquidity-incentives-until-yam-eth-update/821
          </a> */}

          {/* Basic Summary section */}
          <h2 className="p-t-29">Basic Summary</h2>
          <p>
            {proposalInfo.basicSummary
              ? proposalInfo.basicSummary
              : "Basic summary not found."}
          </p>

          {/* Abstract section */}
          <h2>Abstract</h2>
          <p className="m-b-11">
            {proposalInfo.abstract
              ? proposalInfo.abstract
              : "Abstract not found."}
          </p>

          {/* Motivation section */}
          <h2>Motivation</h2>
          <p className="m-b-5">
            {proposalInfo.motivation
              ? proposalInfo.motivation
              : "Motivation not found."}
          </p>

          {/* Specifications section */}
          <h2>Specifications</h2>
          <p className="m-b-12">{proposalInfo.specifications
              ? proposalInfo.specifications
              : "Specifications not found."}</p>

          {/*  <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://snapshot.page/#/yam/proposal/QmdBiJuXFtYephsmz9mWF9WoDDrnYtAVaGAPkoB8QrF76h"
          >
            https://snapshot.page/#/yam/proposal/QmdBiJuXFtYephsmz9mWF9WoDDrnYtAVaGAPkoB8QrF76h
          </a> */}
        </div>
      </div>
    </>
  );
};
export default ProposalDetail;
