import React from "react";
import "./CastYourVoteSection.scss";
import Modal from "react-modal";
import Spin from "../../../../shared/components/Spin/Spin";
import Web3 from "web3";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { showNotification } from "app/utils/notifications/notificationService";
// const { ethers } = require("ethers");
declare const window: any;

interface IProps {
  isModalOpen: (e: boolean) => void;
  proposalDetails: any;
  governanceDetail: any;
}
const InfoSection: React.FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [voteType, setVoteType] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVoteModalOpen, setIsVoteModalOpen] = React.useState(false);

  React.useEffect(() => {
    getWeb3();
  });

  function getABIOfContact(contractAddress) {
    try {
      const governanceDetails = props.governanceDetail;
      const url = `https://api${
        governanceDetails && governanceDetails.networkType === "kovan"
          ? "-kovan"
          : ""
      }.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=${"AGXPGZWEAEJAHASKNJYA81PAFXIVWRC4XG"}`;
      return fetch(url)
        .then((response) => response.json())
        .then(async (data) => {
          if (data && data.status === "1") {
            return data.result || null;
          }
          return null;
        })
        .catch((error) => {
          console.error("error: ", error);
          return null;
        });
    } catch (error) {
      console.error("error: ", error);
    }
  }

  async function castVote() {
    const governanceDetails = props.governanceDetail;
    const contractAddress = props.proposalDetails
      ? props.proposalDetails.contractAddress
      : "";
    if (!contractAddress) {
      return false;
    }
    const contractABI = await getABIOfContact(contractAddress);
    const signer = new ethers.providers.Web3Provider(
      window.ethereum
    ).getSigner();

    console.log("governanceDetails", governanceDetails);
    console.log("contractAddress", contractAddress);
    console.log("signer", signer);

    try {
      let tokenContract = await new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );
      if (governanceDetails && governanceDetails.votingMethod) {
        console.log(
          "governanceDetails.votingMethod",
          governanceDetails.votingMethod
        );
        console.log(
          "props.proposalDetails.proposalId",
          props.proposalDetails.proposalId
        );
        console.log("voteType === 1", voteType === 1);
        const votingDetails = await tokenContract[
          governanceDetails.votingMethod
        ](props.proposalDetails.proposalId, voteType === 1);
        if (votingDetails) {
          setIsLoading(false);
          showNotification("You have successfully voted.", "success");
        } else {
          setIsLoading(false);
          showNotification("Something went wrong! can you try again!", "error");
        }
        // await tokenContract.castVote(
        //   props.proposalDetails.proposalId,
        //   voteType === 1
        // );
      } else {
        setIsLoading(false);
        showNotification("Something went wrong! can you try again!", "error");
      }
    } catch (e) {
      setIsLoading(false);
      const errorMessage =
        e.message || "Something went wrong. Please try again later.";
      toast.error(errorMessage);
      console.log("e: ", e);
    }
  }

  const getWeb3 = () => {
    const FALLBACK_WEB3_PROVIDER =
      process.env.REACT_APP_NETWORK || "https://dev.add.xyz/";
    return new Promise((resolve, reject) => {
      // Wait for loading completion to avoid race conditions with web3 injection timing.
      window.addEventListener("load", async () => {
        // Modern dapp browsers...
        if (window.ethereum) {
          const web3 = new Web3(window.ethereum);
          try {
            // Request account access if needed
            await window.ethereum.enable();
            // Acccounts now exposed
            resolve(web3);
          } catch (error) {
            reject(error);
          }
        }
        // Legacy dapp browsers...
        else if (window.web3) {
          // Use Mist/MetaMask's provider.
          const web3 = window.web3;

          resolve(web3);
        }
        // Fallback to localhost; use dev console port by default...
        else {
          const provider = new Web3.providers.HttpProvider(
            FALLBACK_WEB3_PROVIDER
          );
          const web3 = new Web3(provider);

          resolve(web3);
        }
      });
    });
  };

  const openMetamask = () => {
    const FALLBACK_WEB3_PROVIDER =
      process.env.REACT_APP_NETWORK || "https://dev.add.xyz/";
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.enable();
          // Acccounts now exposed
          resolve(web3);
        } catch (error) {
          console.log("err", error);
          reject(error);
        }
      } else if (window.web3) {
        const web3 = window.web3;
        resolve(web3);
      }
      // Fallback to localhost; use dev console port by default...
      else {
        const provider = new Web3.providers.HttpProvider(
          FALLBACK_WEB3_PROVIDER
        );
        const web3 = new Web3(provider);
        alert("Please check metamask");
        resolve(web3);
      }
    });
  };

  const getAccounts = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      return accounts;
    } catch (e) {
      return [];
    }
  };

  const submitVote = async () => {
    const account: any = await getAccounts();
    if (account && account.length > 0) {
      castVote();
      setIsLoading(true);
    } else {
      openMetamask();
    }
    // setTimeout(() => {
    //   props.isModalOpen(false);
    //   setVoteType(0);
    //   setIsLoading(false);
    //   setIsVoteModalOpen(false);
    //   showNotification('Your vote is in!', 'success');
    // }, 2000);
  };

  const openModal = () => {
    setIsVoteModalOpen(true);
    setIsLoading(false);
    props.isModalOpen(true);
  };

  const closeModal = () => {
    setIsVoteModalOpen(false);
    setIsLoading(false);
    props.isModalOpen(false);
  };
  return (
    <>
      <div className="cast-vote-section">
        <div className="header-section">
          <h2 className="title m-l-26">Cast your vote</h2>
        </div>
        <div className="content-section">
          <div className="voting-action-buttons">
            <button
              className={`${voteType === 1 ? "active" : ""}`}
              onClick={() => setVoteType(voteType === 1 ? 0 : 1)}
            >
              Vote For
            </button>
            <button
              className={`${voteType === 2 ? "active" : ""}`}
              onClick={() => setVoteType(voteType === 2 ? 0 : 2)}
            >
              Vote Against
            </button>
          </div>
        </div>
        <div className="footer-section">
          <button
            onClick={openModal}
            className={`${voteType !== 0 ? "active" : ""}`}
          >
            VOTE
          </button>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={isVoteModalOpen}
          overlayClassName={"transparent"}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
            },
          }}
          shouldCloseOnOverlayClick={false}
          className="confirm-vote-model"
        >
          <h2 className="modal-title">Confirm vote</h2>
          <p className="modal-text">
            Are you sure you want to vote on “
            {props.proposalDetails.proposalTitle}?
          </p>
          <div className="voting-details">
            <div className="row">
              <div className="col-xl-4 col-md-4 col-sm-4 col-xsm-4 d-flex flex-column">
                <span className="item-title">Proposal</span>
              </div>
              <div className="col-xl-8 col-md-8 col-sm-8 col-xsm-8 d-flex flex-column text-left">
                <span className="item-text">
                  {props.proposalDetails.proposalTitle}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-md-8 col-sm-8 col-xsm-8 d-flex flex-column">
                <span className="item-title">Voting Choice</span>
              </div>
              <div className="col-xl-4 col-md-4 col-sm-4 col-xsm-4 d-flex flex-column text-left">
                <span className="item-text">
                  Voting {voteType === 1 ? "For" : "Against"}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-md-8 col-sm-8 col-xsm-8 d-flex flex-column">
                <span className="item-title">Your voting power</span>
              </div>
              <div className="col-xl-4 col-md-4 col-sm-4 col-xsm-4 d-flex flex-column text-left">
                <span className="item-text">
                  - {props.governanceDetail.nickName}
                </span>
              </div>
            </div>
          </div>
          <button className="voting-button" onClick={submitVote}>
            {!isLoading ? `Vote ${voteType === 1 ? "For" : "Against"}` : ``}
            {isLoading ? <Spin></Spin> : ""}
          </button>
          <button onClick={closeModal} className="cancel-button">
            Cancel
          </button>
        </Modal>
      </div>
    </>
  );
};
export default InfoSection;
