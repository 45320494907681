/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { LineChart, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import './AnalyticsChart.scss'
// import chart-tooltip-bg from './../../../assets/images/project/graph-tooltip-bg.png';
interface IProps {
    graphData?: any[]
    graphMetaData?: any[]
    selectedToken?: number
}
const AnalyticsChart: React.FC<IProps> = (props) => {
    const [opacity, setOpacity] = React.useState({
        uv: 1,
        pv: 1,
        amt: 1,
    })
    const [position, setPosition] = useState({ x: 0, y: 0 })

    const [graphMetaData, setGraphMetaData] = useState<any>([])

    const [hoveredLineName, setHoveredLineName] = useState<string>('')

    useEffect(() => {
        if (props.graphMetaData) {
            setGraphMetaData(props.graphMetaData && [...props.graphMetaData])
        }
    }, [props.graphMetaData])

    const handleMouseEnter = (e: any, lineName: string) => {
        // if (props.selectedToken && props.selectedToken === -1) {
        let tempMData: any = []
        setHoveredLineName(lineName)
        graphMetaData.map((data: any) => {
            if (data.dataKey === lineName) {
                data.strokeOpacity = 1
                tempMData.push(data)
            } else {
                data.strokeOpacity = 0.2
                tempMData.push(data)
            }
        })
        setGraphMetaData([...tempMData])
        // } else {
        //     graphMetaData.map((data: any) => {
        //         if (data.strokeOpacity === 1) {
        //             setHoveredLineName(data.dataKey)
        //         }
        //     })
        // }
    }

    const handleMouseLeave = (e: any, lineName: string) => {
        // if (props.selectedToken && props.selectedToken === -1) {
        let tempMData: any = []
        graphMetaData.map((data: any) => {
            data.strokeOpacity = 1
            tempMData.push(data)
        })
        setGraphMetaData([...tempMData])
        // }
        // setOpacity({ uv: 0.3, pv: 1, amt: 0.3 })
        // const { dataKey } = o;
        // setOpacity({ ...opacity, [dataKey]: 1 });
    }

    function tooltipContent(tooltipProps: any) {
        try {
            // console.log('tooltipProps', tooltipProps, position)
            // console.log('tooltipProps.position', tooltipProps.position)
            // setPosition(tooltipProps.position)
            return tooltipProps && tooltipProps.payload ? (
                <div className="tooltip-main-div">
                    <ul>
                        {tooltipProps.payload.length > 0 &&
                            tooltipProps.payload.map(
                                (data: any, index: any) => {
                                    return (
                                        hoveredLineName &&
                                        hoveredLineName === data.dataKey && (
                                            <>
                                                <li>{data.dataKey}:</li>
                                                <li>{data.value}</li>
                                                <li>Date:</li>
                                                {data.payload &&
                                                    data.payload.date && (
                                                        <li>
                                                            {data.payload.date.slice(
                                                                0,
                                                                9
                                                            )}
                                                        </li>
                                                    )}
                                            </>
                                        )
                                    )
                                }
                            )}
                    </ul>
                </div>
            ) : (
                ''
            )
        } catch (e) {
            console.log('error', e)
        }
    }

    return (
        <ResponsiveContainer width="95%" height={300}>
            <LineChart
                data={props.graphData && props.graphData}
                onMouseMove={(e) => {
                    // console.log('e', e);
                    if (e.isTooltipActive === true) {
                        setPosition({ x: e.chartX - 100, y: -90 })
                    }
                }}
            >
                <defs>
                    <linearGradient
                        id="colorDAI"
                        x1="0%"
                        y1="100%"
                        x2="50%"
                        y2="100%"
                    >
                        <stop
                            offset="0%"
                            stopColor="#46EFFA"
                            stopOpacity={opacity.pv}
                        />
                        <stop
                            offset="100%"
                            stopColor="#2000E3"
                            stopOpacity={opacity.pv}
                        />
                    </linearGradient>
                    <linearGradient
                        id="colorUSDC"
                        x1="0%"
                        y1="100%"
                        x2="50%"
                        y2="100%"
                    >
                        <stop
                            offset="10%"
                            stopColor="#00FFB2"
                            stopOpacity={opacity.uv}
                        />
                        <stop
                            offset="89%"
                            stopColor="#FAFF00"
                            stopOpacity={opacity.uv}
                        />
                    </linearGradient>
                    <linearGradient
                        id="colorFulcrum"
                        x1="0%"
                        y1="100%"
                        x2="50%"
                        y2="100%"
                    >
                        <stop
                            offset="10%"
                            stopColor="#EB0000"
                            stopOpacity={0.3}
                        />
                        <stop
                            offset="89%"
                            stopColor="#95008A"
                            stopOpacity={0.3}
                        />
                    </linearGradient>
                    <linearGradient
                        id="colorDydx"
                        x1="0%"
                        y1="100%"
                        x2="50%"
                        y2="50%"
                    >
                        <stop
                            offset="10%"
                            stopColor="#EBA000"
                            stopOpacity={0.3}
                        />
                        <stop
                            offset="89%"
                            stopColor="#95008A"
                            stopOpacity={0.3}
                        />
                    </linearGradient>
                </defs>
                {/*
                    <Legend
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    /> 
                */}
                {graphMetaData &&
                    graphMetaData.map((data: any) => {
                        return (
                            <Line
                                type="linear"
                                dataKey={data.dataKey}
                                stroke={`url(#color${data.dataKey})`}
                                strokeWidth={data.strokeWidth}
                                dot={false}
                                activeDot={false}
                                strokeOpacity={data.strokeOpacity}
                                onMouseEnter={(e: any) =>
                                    handleMouseEnter(e, data.dataKey)
                                }
                                onMouseLeave={(e: any) =>
                                    handleMouseLeave(e, data.dataKey)
                                }
                            />
                        )
                    })}
                {/*    <Line
                    type="linear"
                    dataKey="pv"
                    stroke="url(#colorPv)"
                    strokeWidth={5}
                    dot={false}
                    activeDot={{ r: 5 }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                // strokeOpacity={opacity.pv}
                />*/}
                <Tooltip position={position} content={tooltipContent} />
                {/*   */}
            </LineChart>
        </ResponsiveContainer>
    )
}
export default AnalyticsChart
