import React, { useState, useEffect, useContext } from 'react'

// components
import HowTo from '../../shared/components/Landing/HowTo'
import ModalComponent from './../../shared/components/Modal/ModalComponent'
import { Dropdown } from 'react-bootstrap'

// contexts
import { authContext } from './../../utils/context/AuthContext'

// assets
import play from '../../assets/images/staking-landing/play.svg'
import addImage from '../../assets/images/crypto/add-xyz.svg'
import ethImage from '../../assets/images/crypto/eth-z.svg'
import checkmark from '../../assets/images/lending-home/checkmark.svg'
import mobile from '../../assets/images/staking-landing/mobile.png'

import { Helmet } from 'react-helmet'

// styles
import './style.scss'

interface IProps {
    history: any
}
const LendingProduct: React.FC<IProps> = ({ history }) => {
    const [openModal, setOpenModal] = useState(-1)
    const { auth: user } = React.useContext(authContext)
    const staticApy = 7.56 //static value
    const [stakeValue, setStakeValue] = useState(1000)
    const [reward, setReward] = useState<number>()
    useEffect(() => {
        setReward((stakeValue * staticApy) / 100 / 12)
    }, [stakeValue])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const earnV3Features = [
        'No Platform Fee',
        '90% Reduction on Gas Fee',
        'Highest Interest Rates',
        'No Custodial and Locking',
        'Personal DeFi Dashboard',
        'Privacy on DeFi',
    ]
    const earnInstructions = [
        {
            title: 'Connect your Wallet',
            desc: 'Connect your Metamask or other supported wallets to our platform',
        },
        {
            title: 'Add Liquidity to ADD-ETH Pair',
            desc: 'Deposit supported cryptocurrencies and approve the transaction',
        },
        {
            title: 'Start Earning ADD Tokens in rewards',
            desc: 'That’s it! You will start earning interest on your deposits',
        },
    ]
    const renderConnectWalletButton = () => (
        <>
            {!user.walletAddress && (
                <button onClick={() => setOpenModal(0)} className="primary-btn">
                    Connect your Wallet
                </button>
            )}
        </>
    )

    const howToHeader = () => (
        <>
            How To
            <br />
            <span>Stake ADD?</span>
        </>
    )
    // let value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
    // setStakeValue(+value)
    return (
        <div className="">
            <div className="lending-page-ui container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        ADD Staking - Start Earning ADD Just By Depositing
                    </title>
                    <meta
                        name="description"
                        content="Lorem ipsum dolor sit amet"
                    />
                </Helmet>
                <h1 className="header">ADD Staking</h1>
                <p className="sub-header">
                    SADD Liquidity Staking is hosted on Uniswap with the ADD-ETH
                    Pair. All you need to do is deposit your ADD, pay the fee in
                    ETH, and the staking will automatically start.
                </p>

                <div className="d-flex flex-column justify-content-center align-items-center">
                    {renderConnectWalletButton()}
                    {user.walletAddress && (
                        <button
                            onClick={() => history.push('/staking')}
                            className="primary-btn"
                        >
                            Stake Now
                        </button>
                    )}
                    {/* <div className="seeWhatEarningWrapper d-flex d-flex flex-column container d-none">
          <p className="styleH5">See What You Could Be Earning </p>
          <div className="d-flex flex-column flex-md-row boxesWrapper">
            <div className="boxesTransparent  d-flex flex-column ">
              <p className="labelBoxes">How much do you want to Stake?</p>
              <div className="inputSeeWhatEarning"> 
              <span>$</span>
              <input type="text"  placeholder="$0" value={stakeValue} onChange={(e)=>{
 
              }} />
              </div>

              <p className="labelBoxes">Which Token?</p>
              <Dropdown className="dropDownStyle">
                <Dropdown.Toggle
                  variant=""
                  className="dropdown-tokens dropDownStyle pl-0 d-flex align-items-center ml-auto border-0 shadow-none"
                  id="dropdown-basic"
                >
                  <div className={"position-relative mr-2"}>
                    <img src={ethImage} alt="ethimg" />
                    <img
                      src={addImage}
                      className={"add-img-pos"}
                      alt="addimage"
                    />
                  </div>
                  <span>ADD-ETH</span>
                </Dropdown.Toggle>
              </Dropdown>
              <p className="labelBoxes">How Long?</p>
              <div className={"d-flex"}>
                <button className="day-btn day-btn-selected mr-2">
                  30 Days
                </button>
                <button className="day-btn mr-2">60 Days</button>
                <button className="day-btn ">90 Days</button>
              </div>
            </div>
            <div className="boxes d-flex d-flex flex-column">
              <p className="labelBoxes">Earning With ADD</p>
              <p className="amountBox2">${reward? reward.toFixed(2) : 0.00}</p>
              <div className="apyWrapper">
                <span className="spanHeading">APY</span>
                <p className="amountBox2">{staticApy}%</p>
              </div>
            </div>
          </div>
        </div> */}
                    {/* <button className="staking-tutorial-btn mt-5 d-flex justify-content-center">
          <img src={play} alt="Play" className="mr-2" />
          ADD Staking Tutorial
        </button> */}
                </div>

                <div className="why-stake-add-wrapper mt-5 d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div>
                        <h1>Why Stake ADD?</h1>
                        <div className="d-flex flex-wrap justify-content-between">
                            {earnV3Features.map((f) => (
                                <div className="staking-feature">
                                    <div
                                        key={f}
                                        className="d-flex align-items-center mx-auto"
                                    >
                                        <img
                                            src={checkmark}
                                            alt="Checkmark"
                                            className="mr-2"
                                        />
                                        <p className="mb-0">{f}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <img src={mobile} alt="" />
                    </div>
                </div>

                <HowTo
                    headerText={howToHeader()}
                    instructions={earnInstructions}
                />

                <h1 className="about-header pb-1 pb-md-0 mb-3 mb-md-4">
                    About <span>ADD.xyz</span>
                </h1>
                <p className="about-desc mx-auto">
                    ADD.xyz is a full-stack DeFi aggregator, plugging in
                    multiple products and DeFi applications into one single
                    platform, focusing on User Experience, Design, Privacy and
                    Anonymity. ADD has now teamed up with Compound, Aave, and
                    DYDX, with more future partnerships in the pipeline. By
                    expanding the network of cooperation, we are taking DeFi to
                    a new and more advanced level, while increasing the
                    liquidity, supply, demand, and ultimately profits. We have
                    recently launched +Earn v3, a DeFi Lending aggregator where
                    all the above-mentioned crypto lending protocols can be
                    accessed in one place.
                </p>

                {openModal === 0 && (
                    <ModalComponent
                        visible
                        isWalletModal={openModal === 0 ? true : false}
                        onClose={() => setOpenModal(-1)}
                    />
                )}
            </div>
        </div>
    )
}

export default LendingProduct
