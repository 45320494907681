import React, { useEffect, useContext, useState } from 'react'

import lockIcon from '../../../../assets/images/project/lockIcon.svg'
import online from '../../../../assets/images/project/online.svg'
import footer_logo from '../../../../assets/images/project/new_footer_logo.svg'
// Social Icons
import Medium_Logo from '../../../../assets/images/social/Medium.svg'
import Telegram from '../../../../assets/images/social/telegram.svg'
import Twitter from '../../../../assets/images/social/twitter.svg'
import LinkedIn from '../../../../assets/images/social/linkedin.svg'
// import Medium from '../../../../assets/images/social/medium.svg';
// import github_logo from '../../../../assets/images/social/github_logo.svg';
import Instagram from '../../../../assets/images/social/instagram.svg'
import MarketCap from '../../../../assets/images/social/marketcap.svg'
import CoinGecko from '../../../../assets/images/social/coin-gecko.svg'
import Facebook from '../../../../assets/images/social/facebook.svg'
import { Row, Col } from 'react-bootstrap'
import './style.scss'
import { NavLink } from 'react-router-dom'
import Web3 from 'web3'
import { authContext } from './../../../../utils/context/AuthContext'

interface IProps {}
const HomeFooter: React.FC<IProps> = () => {
    let [latestBlock, setLatestBlock] = useState<number>(0)
    const { auth: user } = useContext(authContext)
    const subscribeBlockNo = () => {
        var web3 = new Web3(Web3.givenProvider)
        //var filter = web3.eth.subscribe
        web3.eth.subscribe('newBlockHeaders', async (error, event) => {
            if (!error) {
                if (latestBlock < event.number) {
                    setLatestBlock(event.number)
                    return
                }
            }
            return
        })
    }

    useEffect(subscribeBlockNo, [])

    return (
        <Row className="add-footer m-0">
            <Col xs={12} lg={3} className="d-flex flex-column">
                <img
                    src={footer_logo}
                    alt="ADD"
                    className="footer-logo pb-1 mb-2 pb-md-0 mb-md-4"
                />
                <p className="summary pb-1 mb-4 pb-md-0 mb-md-0">
                    Add is an all-in-one DeFi aggregator platform where you can
                    easily track all your DeFi products.
                </p>
                <div className="social-links d-flex flex-wrap justify-content-start align-items-center pb-5 mb-5 pb-md-0 mb-md-0">
                    <a
                        href="https://twitter.com/adddotxyz"
                        target="_blank"
                        className="pr-2 mr-3"
                    >
                        <img src={Twitter} alt="Twitter" />
                    </a>
                    <a
                        href="https://www.instagram.com/adddotxyz"
                        target="_blank"
                        className="pr-2 mr-3"
                    >
                        <img src={Instagram} alt="Instagram" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/addxyz"
                        target="_blank"
                        className="pr-2 mr-3"
                    >
                        <img src={LinkedIn} alt="LinkedIn" />
                    </a>
                    <a target="_blank" className="pr-2 mr-3">
                        <img src={MarketCap} alt="MarketCap" />
                    </a>
                    <a
                        href="https://medium.com/addxyz"
                        target="_blank"
                        className="pr-2 mr-3"
                    >
                        <img src={Medium_Logo} alt="Medium" />
                    </a>
                    <a target="_blank" className="pr-2 mr-3">
                        <img src={CoinGecko} alt="CoinGecko" />
                    </a>
                    <a
                        href="https://t.me/ADDXyzpublic"
                        target="_blank"
                        className="pr-2 mr-3"
                    >
                        <img src={Telegram} alt="Telegram" />
                    </a>
                    <a target="_blank" className="pr-2 mr-3">
                        <img src={Facebook} alt="Facebook" />
                    </a>
                </div>
            </Col>
            <Col xs={12} lg={9}>
                <div className="d-flex justify-content-around flex-column flex-lg-row">
                    <div>
                        <p className="nav-header">Products</p>
                        <NavLink className="nav-item-link" to="/add-staking">
                            +Staking
                        </NavLink>
                        <NavLink
                            className="nav-item-link"
                            to="/earn-crypto-lending"
                        >
                            +Earn
                        </NavLink>
                        <NavLink
                            className="nav-item-link"
                            to="/blender-crypto-privacy-mixer"
                        >
                            +Bl3nd3r
                        </NavLink>
                    </div>
                    <div>
                        <p className="nav-header">Company</p>
                        <a
                            className="nav-item-link"
                            href="https://medium.com/addxyz"
                            target="_blank"
                        >
                            Blog
                        </a>
                        <a
                            className="nav-item-link"
                            href="https://about.add.xyz"
                            target="_blank"
                        >
                            About Us
                        </a>
                        <p className="nav-item-link mb-0">Contact Us:</p>
                        <a
                            className="nav-item-link"
                            href="mailto:hello@add.xyz"
                            style={{ color: '#30b6f3' }}
                        >
                            hello@add.xyz
                        </a>
                    </div>
                    <div>
                        <p className="nav-header">Support</p>
                        <NavLink className="nav-item-link" to="/privacy-policy">
                            Privacy Policy
                        </NavLink>
                        <NavLink className="nav-item-link" to="/terms-of-use">
                            Terms of Use
                        </NavLink>
                        <a
                            className="nav-item-link"
                            href="https://about.add.xyz"
                            target="_blank"
                        >
                            Gitbook
                        </a>
                        <a
                            className="nav-item-link"
                            href="https://t.me/ADDXyzpublic"
                            target="_blank"
                        >
                            Customer Support
                        </a>
                    </div>

                    {user.walletAddress && (
                        <div>
                            <div className="info-card">
                                <div className="d-flex justify-content-start align-items-center mb-1">
                                    <div className="status active mr-1" />
                                    <p className="info-title mb-0">Wallet</p>
                                </div>

                                <div className="value-wrapper">
                                    <p className="value mb-0">
                                        {user.walletAddress.substr(0, 3) +
                                            '...' +
                                            user.walletAddress.substr(
                                                user.walletAddress.length - 20
                                            )}
                                    </p>
                                </div>
                            </div>

                            <div className="info-card">
                                <div className="d-flex justify-content-start align-items-center mb-1">
                                    <div
                                        className={`status mr-1 ${
                                            latestBlock !== 0
                                                ? 'active'
                                                : 'unactive'
                                        }`}
                                    />
                                    <p className="info-title mb-0">
                                        Latest Block
                                    </p>
                                </div>

                                <div className="value-wrapper">
                                    <p className="value mb-0">{latestBlock}</p>
                                </div>
                            </div>

                            <div className="info-card">
                                <div className="d-flex justify-content-start align-items-center mb-1">
                                    <div className="status unactive mr-1" />
                                    <p className="info-title mb-0">
                                        Tor Routing
                                    </p>
                                </div>

                                <div className="value-wrapper">
                                    <p className="value mb-0">Disabled</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
        // <div className="d-flex justify-content-center align-items-center">
        //   <div className="col-lg-10 col-md-10 col-sm-11 d-flex justify-content-between pb-3">
        //     <img src={footer_logo} alt="ADD" className="pb-1 mb-2 pb-md-0 mb-md-4" />
        //     <p className="summary">
        //       Add is an all-in-one DeFi aggregator platform where you can easily track all your DeFi products.
        //     </p>
        //     {/* <div>
        //       <text
        //         className="title-text pb-3 grey-gradient"
        //         style={{ fontSize: '28px' }}
        //       >
        //         ADD.<text className="text-gradient">XYZ</text>
        //       </text>
        //     </div> */}
        //     {/* <div className="mobile-view">
        //       <img src={footer_logo} alt="" className="home-footer-logo" />
        //     </div> */}
        //     <div className="d-none d-md-block">
        //       {props.user.walletAddress ? (
        //         <div style={{ justifyContent: 'center', alignItems: 'center' }}>
        //           <div className="">
        //             <Button className="btn-gradient pointer mr-3">
        //               <span className="d-flex align-items-center">
        //                 <text className="">
        //                   {props.user.walletAddress &&
        //                     props.user.walletAddress.substr(0, 3) +
        //                       '...' +
        //                       props.user.walletAddress.substr(
        //                         props.user.walletAddress.length - 7
        //                       )}
        //                 </text>
        //                 <img src={online} alt="" className="p-2" />
        //               </span>
        //             </Button>
        //           </div>
        //         </div>
        //       ) : (
        //         <div
        //           style={{
        //             display: 'flex',
        //             flexDirection: 'row',
        //             justifyContent: 'space-between',
        //             alignItems: 'center'
        //           }}
        //         >
        //           <div className="pr-3">
        //             <text className="title-text" style={{ fontSize: '18px' }}>
        //               ABOUT US
        //             </text>
        //           </div>
        //           <div className="pr-3">
        //             <text className="title-text" style={{ fontSize: '18px' }}>
        //               FEEDBACK
        //             </text>
        //           </div>
        //           <div className="">
        //             <text
        //               className="title-text"
        //               style={{ fontSize: '18px', marginRight: '8px' }}
        //             >
        //               CONNECT
        //             </text>
        //             <img src={lockIcon} alt="" className="mb-1" />
        //           </div>
        //         </div>
        //       )}
        //     </div>
        //     <div className="row align-items-center social-setion">
        //       <a
        //         href="https://medium.com/@Add.xyz"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         <div className="social-div medium-logo mr-lg-3 mr-md-2 mr-sm-2">
        //           <img src={Medium_Logo} alt="medium" className="w-100" />
        //         </div>
        //       </a>
        //       <a
        //         href="https://t.me/ADDXyzpublic"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         <div className="social-div mr-lg-3 mr-md-2 mr-sm-2">
        //           <img src={Telegram} alt="" className="social-icon" />
        //         </div>
        //       </a>
        //       <a
        //         href="https://twitter.com/adddotxyz"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         <div className="social-div mr-lg-3 mr-md-2 mr-sm-2">
        //           <img src={Twitter} alt="" className="social-icon" />
        //         </div>
        //       </a>

        //       <a
        //         href="https://www.linkedin.com/company/plutus-defi"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         <div className="social-div mr-lg-3 mr-md-2 mr-sm-2">
        //           <img src={LinkedIn} alt="" className="social-icon" />
        //         </div>
        //       </a>
        //       <a
        //         href="https://coinmarketcap.com/currencies/add-xyz/"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         <div className="social-div mr-lg-3 mr-md-2 mr-sm-2">
        //           <img src={Medium} alt="" className="social-icon" />
        //         </div>
        //       </a>
        //       {/* <a
        //       href=""
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     > */}
        //       <div className="">
        //         <img src={github_logo} alt="" className="github-icon" />
        //       </div>
        //       {/* </a> */}
        //     </div>
        //   </div>
        // </div>
    )
}
export default HomeFooter
