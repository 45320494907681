import React from 'react'

interface IProps {
    startColor: string
    midColor: string
    endColor: string
    idCSS: string
    rotation: string
}
const GradientSVG: React.FC<IProps> = ({
    startColor,
    midColor,
    endColor,
    idCSS,
    rotation,
}) => {
    let gradientTransform = `rotate(${rotation})`
    return (
        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient
                    id={idCSS}
                    gradientTransform={gradientTransform}
                >
                    <stop offset="10.42%" stopColor={startColor} />
                    <stop offset="50.52%" stopColor={midColor} />
                    <stop offset="100%" stopColor={endColor} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GradientSVG
