/* eslint-disable */
import React from 'react'
import './WalletConnector.scss'
// import vector from './../../../assets/images/project/vector.png';
// Wallets img import
// import formatic from './../../../assets/images/crypto/formatic.png';
// import portis from './../../../assets/images/crypto/portis.png'
// import torus from './../../../assets/images/crypto/torus-logo.png';
// import trezor from './../../../assets/images/crypto/trezor.png'
// import authereum from './../../../assets/images/crypto/authereum-logo.png';
// import walletconnect from './../../../assets/images/crypto/walletconnect.png';
// import Ledger from './../../../assets/images/crypto/Ledger-logo.png';
// import matamask from './../../../assets/images/crypto/metamask.svg';
// import cross from './../../../assets/images/project/cross-light.png';

import MetaMask from './../../../assets/images/crypto/metamask_connect_wallet.svg'
import Ledger from './../../../assets/images/crypto/ledger_connect_wallet.svg'

import { AccountType } from './../../../utils/enums/walletConnectionsEnum'
import { getAccountType } from './../../../utils/connections/walletConnectionUtils'
import connectors from './../../../utils/connections/walletConnectors'

import { useWeb3React } from '@web3-react/core'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { authContext } from './../../../utils/context/AuthContext'
// import userEvent from '@testing-library/user-event';
import { toast } from 'react-toastify'
import Config from './../../../config/config'
import ChevronRight from './../../../assets/images/project/chevron_right.svg'
import Cross from './../../../assets/images/project/cross.svg'
import { Spinner } from 'react-bootstrap'

enum AccountTypeServer {
    METAMASK = 'METAMASK',
    FORTMATIC = 'FORTMATIC',
}

const ADD_USER_MUTATION = gql`
    mutation addConnection(
        $walletAddress: String!
        $accountType: AccountType!
        $chainId: Int
    ) {
        addConnection(
            walletAddress: $walletAddress
            accountType: $accountType
            chainId: $chainId
        ) {
            response {
                status
                message
            }
            data {
                connectionId
                walletAddress
                accountType
            }
        }
    }
`

interface Wallet {
    accountTypeLabel: string
    label: string
    image: any
}
interface IProps {
    closeModal: Function
}

const WalletConnector: React.FC<IProps> = (props) => {
    const context = useWeb3React()
    const { connector, chainId, account, activate, error } = context
    const [activatingConnector, setActivatingConnector] =
        React.useState<any>(null)

    const [loading, setLoading] = React.useState<string>('')
    const [walletType, setWalletType] = React.useState<string>('')
    const { auth: user, setAuthStatus } = React.useContext(authContext)

    const [addUser, { data: addUserData }] = useMutation(ADD_USER_MUTATION)

    React.useEffect(() => {
        if (addUserData) {
            localStorage.setItem(
                'Walletaddress',
                addUserData.addConnection.data.walletAddress
            )
            // localStorage.setItem(
            //   'walletCreation',
            //   addUserData.data.walletCreation
            // );
            const {
                walletAddress,
                // contractAddress,
                accountType,
                connectionId,
                // walletCreation,
                // userId
            } = addUserData.addConnection.data
            setAuthStatus({
                walletAddress,
                contractAddress: null,
                accountType,
                walletCreation: null,
                userId: 0,
                protocol: '0',
                currency: '',
                chainId: chainId || Config.chainId,
                isAuthenticated: true,
                bestProtocol: {},
                connectionId,
            })
            setLoading('')
            props.closeModal()
        }
    }, [addUserData])

    React.useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined)
            if (walletType && account) {
                addUser({
                    variables: {
                        walletAddress: account,
                        accountType: AccountTypeServer.METAMASK,
                        chainId: Config.chainId,
                    },
                })
            }

            //   loginSuccess(AccountType.MetaMask, account);
        }
        if (error) {
            setLoading('')
            if (error.name === 'NoEthereumProviderError') {
                toast.error(
                    'Please install metamask extension to access ADD.XYZ assets and Enable itx extenstion on Your Browser'
                )
            }
        }
    }, [activatingConnector, connector, error])

    /**
     * Handle connectors login
     */
    const handleConnectorsLogin = async (walletType: any) => {
        const accountType: string = getAccountType(connector)
        setLoading(walletType)
        setWalletType(walletType)
        // if (accountType === null) {
        if (walletType === AccountType.MetaMask) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.Injected)
            }
            await setActivatingConnector(connectors.Injected)
        }
        // else if (walletType === AccountType.Portis) {
        //     if ((activatingConnector === null) || (activatingConnector === undefined)) {
        //         activate(connectors.Portis);
        //     }
        //     await setActivatingConnector(connectors.Portis);

        // }
        else if (walletType === AccountType.Authereum) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.Authereum)
            }
            await setActivatingConnector(connectors.Authereum)
        } else if (walletType === AccountType.WalletConnect) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.WalletConnect)
            }
            await setActivatingConnector(connectors.WalletConnect)
        } else if (walletType === AccountType.Fortmatic) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.Fortmatic)
            }
            await setActivatingConnector(connectors.Fortmatic)
        } else if (walletType === AccountType.Torus) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.Torus)
            }
            await setActivatingConnector(connectors.Torus)
        } else if (walletType === AccountType.Ledger) {
            if (
                activatingConnector === null ||
                activatingConnector === undefined
            ) {
                activate(connectors.Ledger)
            }
            await setActivatingConnector(connectors.Ledger)
        } else {
            console.log('compiler in else wallet connection')
        }
    }

    return (
        <div className="col-12 d-flex justify-content-center position-relative">
            <img
                src={Cross}
                width="20"
                height="20"
                className="wc-close-icon pointer"
                onClick={() => props.closeModal()}
            />
            <div className="row justify-content-center w-100">
                <div className="col-12">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-12 d-flex justify-content-center text-center pb-3">
                            <h2 className="connect-wallet-header mt-4">
                                Connect your wallet
                            </h2>
                        </div>
                        {/*   {walletsList && walletsList.map((wallet: Wallet) => {
                            return (
                                <div className="col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div"
                                    onClick={() => handleConnectorsLogin( AccountType[wallet.accountTypeLabel])} >
                                    <img src={wallet.image} alt="" style={{ width: "37px" }} />
                                    <p className="m-0">{wallet.label}</p>
                                    {loading && loading === Object(AccountType)[wallet.accountTypeLabel] ? (
                                        <div className="circle" ></div>
                                    ) : (
                                            <img src={vector} alt="vector" style={{ width: "10px", height: "10px" }} />
                                        )}
                                </div>
                            )
                        })} */}

                        <div className="d-flex flex-column flex-md-row justify-content-around w-100">
                            <div className="wallet-card d-flex flex-row flex-md-column justify-content-between align-items-center align-items-md-start mb-3 mb-md-0">
                                <img
                                    src={MetaMask}
                                    alt="MetaMask"
                                    className="mb-md-4"
                                />
                                <p className="wallet-name mb-0 mb-md-4">
                                    MetaMask
                                </p>
                                <button
                                    className="connect-wallet-btn d-none d-md-block"
                                    onClick={() =>
                                        handleConnectorsLogin(
                                            AccountType.MetaMask
                                        )
                                    }
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p className="m-0 mr-1">Connect</p>
                                        {loading &&
                                            loading ===
                                                AccountType.MetaMask && (
                                                <Spinner
                                                    animation="border"
                                                    className="wallet-loader"
                                                />
                                            )}
                                    </div>
                                </button>
                                {loading && loading === AccountType.MetaMask ? (
                                    <Spinner
                                        animation="border"
                                        className="wallet-loader d-md-none"
                                    />
                                ) : (
                                    <img
                                        src={ChevronRight}
                                        alt=">"
                                        className="chevron d-md-none"
                                        onClick={() =>
                                            handleConnectorsLogin(
                                                AccountType.MetaMask
                                            )
                                        }
                                    />
                                )}
                            </div>

                            <div className="wallet-card d-flex flex-row flex-md-column justify-content-between align-items-center align-items-md-start">
                                <img
                                    src={Ledger}
                                    alt="Ledger"
                                    className="mb-md-4"
                                />
                                <p className="wallet-name mb-0 mb-md-4">
                                    Ledger
                                </p>
                                <button
                                    className="connect-wallet-btn d-none d-md-block"
                                    onClick={() =>
                                        handleConnectorsLogin(
                                            AccountType.Ledger
                                        )
                                    }
                                >
                                    <p className=" m-0">Connect</p>
                                </button>
                                {loading && loading === AccountType.Ledger ? (
                                    <Spinner
                                        animation="border"
                                        className="wallet-loader d-md-none"
                                    />
                                ) : (
                                    <img
                                        src={ChevronRight}
                                        alt=">"
                                        className="chevron d-md-none"
                                        onClick={() =>
                                            handleConnectorsLogin(
                                                AccountType.Ledger
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        {/* <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.MetaMask)}
            >
              <img src={matamask} alt='' className='icon-size' />
              <p className='m-0'>MetaMask</p>
              {loading && loading === AccountType.MetaMask ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div> */}
                        {/*    <div className="col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3"
                            onClick={() => handleConnectorsLogin(AccountType.Portis)} >
                            <img src={portis} alt="" style={{ width: "37px" }} />
                            <p className="m-0">Portis</p>
                            {loading && loading === AccountType.Portis ? (
                                <div className="circle" ></div>
                            ) : (
                                    <img src={vector} alt="vector" style={{ width: "10px", height: "10px" }} />
                                )}

                        </div>*/}
                        {/* <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.Authereum)}
            >
              <img src={authereum} alt='' className='icon-size' />
              <p className='m-0'>Authereum</p>
              {loading && loading === AccountType.Authereum ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div>
            <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.WalletConnect)}
            >
              <img src={walletconnect} alt='' className='icon-size' />
              <p className='m-0'>WalletConnect</p>
              {loading && loading === AccountType.WalletConnect ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div> */}
                        {/*    <div className="col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3"
                            onClick={() => handleConnectorsLogin(AccountType.Portis)} >
                            <img src={portis} alt="" style={{ width: "37px" }} />
                            <p className="m-0">Portis</p>
                            {loading && loading === AccountType.Portis ? (
                                <div className="circle" ></div>
                            ) : (
                                    <img src={vector} alt="vector" style={{ width: "10px", height: "10px" }} />
                                )}

                        </div>*/}
                        {/* <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.Fortmatic)}
            >
              <img src={formatic} alt='' className='icon-size' />
              <p className='m-0'>Fortmatic</p>
              {loading && loading === AccountType.Fortmatic ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div>
            <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.Torus)}
            >
              <img src={torus} alt='' className='icon-size' />
              <p className='m-0'>Torus</p>
              {loading && loading === AccountType.Torus ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div>
            <div
              className='col-11 col-lg-7 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2  wallet-main-div-selected mt-3'
              onClick={() => handleConnectorsLogin(AccountType.Ledger)}
            >
              <img src={Ledger} alt='' className='icon-size' />
              <p className='m-0'>Ledger</p>
              {loading && loading === AccountType.Ledger ? (
                <div className='circle connector-loader'></div>
              ) : (
                <img
                  src={vector}
                  alt='vector'
                  style={{width: '10px', height: '10px'}}
                />
              )}
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletConnector
