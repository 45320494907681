import moment from 'moment'

export const getTimeElapsed = (date: any) => {
    const now = moment()
    const diff: any = now.diff(date, 'days')

    switch (true) {
        case diff > 365:
            return 'a year ago'

        case diff > 30:
            return 'a month ago'

        case diff > 7:
            return 'a week ago'

        case diff > 1:
            return 'a day ago'

        case diff < 1:
            return 'less than a day ago'

        default:
            break
    }
}
