import React from 'react'

// components
import { Button } from 'react-bootstrap'

// config
import config from '../../../../config/config'

// assets
import screen from '../../../../assets/images/project/screen.svg'
import logo from '../../../../assets/images/project/logo_transparent.svg'
import investorKit from '../../../../assets/images/project/investor_kit.svg'

interface IProps {}
const PressKits: React.FC<IProps> = () => {
    return (
        <div className="press-kit-section pb-5">
            <div className="row justify-content-center">
                <div className="d-flex col-12 justify-content-center mb-4">
                    {/* <Button className="btn-port center pointer">
            <span className="d-flex px-2 py-1 align-items-center">
              <text className="text-font">ROADMAP & PRESS KIT</text>
            </span>
          </Button> */}
                </div>
                <div className="col-9 mt-5">
                    <p className="header mb-5">PRESS KITS</p>
                    <div className="row justify-content-center">
                        <div className="kit-card col-lg-3 col-md-8 col-sm-10 col-xs-12 justify-content-center p-5">
                            <div className="d-flex flex-column h-100">
                                <div className="kit-image row justify-content-center mb-5">
                                    <img
                                        src={investorKit}
                                        alt="Investor Kit"
                                        // className="col-lg-12 col-sm-10"
                                    />
                                </div>
                                <div className="row justify-content-center flex-column mb-5">
                                    <div className="header mb-3">
                                        Digital Assets
                                    </div>
                                    <div className="text">
                                        Logos, Colour pallete, fonts
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-auto">
                                    <Button
                                        className="btn-gradient-round connect-wallet-btn w-auto"
                                        onClick={() =>
                                            window.open(
                                                `${config.googleDriveFoldersUrl}${config.pressKitDigitalAssetsId}`
                                            )
                                        }
                                    >
                                        <span className="action">View</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="kit-card col-lg-3 col-md-8 col-sm-10 col-xs-12 justify-content-center p-5">
                            <div className="d-flex flex-column h-100">
                                <div className="kit-image row justify-content-center mb-5">
                                    {' '}
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        // className="col-lg-12 col-sm-10"
                                    />
                                </div>
                                <div className="row justify-content-center flex-column mb-5">
                                    <div className="header mb-3">
                                        Company Pitch
                                    </div>
                                    <div className="text">
                                        About: ADD.XYZ Presentation
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-auto">
                                    <Button
                                        className="btn-gradient-round connect-wallet-btn w-auto"
                                        onClick={() =>
                                            window.open(
                                                `${config.googleDriveFoldersUrl}${config.pressKitCompanyPitchId}`
                                            )
                                        }
                                    >
                                        <span className="action">View</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="kit-card col-lg-3 col-md-8 col-sm-10 col-xs-12 justify-content-center p-5">
                            <div className="d-flex flex-column h-100">
                                <div className="kit-image row justify-content-center mb-5">
                                    {' '}
                                    <img
                                        src={screen}
                                        alt="Product Screens"
                                        // className="col-lg-12 col-sm-10"
                                    />
                                </div>
                                <div className="row justify-content-center flex-column mb-5">
                                    <div className="header mb-3">
                                        Product Screens
                                    </div>
                                    <div className="text">
                                        Sneak peak through product screenshots
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-auto">
                                    <Button
                                        className="btn-gradient-round connect-wallet-btn w-auto"
                                        onClick={() =>
                                            window.open(
                                                `${config.googleDriveFoldersUrl}${config.pressKitProductScreensId}`
                                            )
                                        }
                                    >
                                        <span className="action">View</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PressKits
