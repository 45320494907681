/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { authContext } from './../../../utils/context/AuthContext'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { getTransactionData } from './../../../utils/Utilities/transactionsUtils'
import { getProtocol } from './../../../utils/Utilities/transactionsUtils'
import { showNotification } from './../../../utils/notifications/notificationService'

import { Button } from 'react-bootstrap'
import loaderImg from './../../../assets/images/project/loader.png'

interface IProps {
    title: string
    loadingTitle?: string
    signTransactionBefore: Function
    signTransactionSuccess: Function
    signTransactionError: Function
    signTransactionAfter: Function
    transaction?: any
    getTransaction?: null | Function
    loading?: boolean
    className?: string
    setLoader?: Function
    shouldSignTransactionFlag: boolean
}

const GET_TRANSACTION_SIGNING = gql`
    query getSignableTransaction($protocol: String, $objectId: String) {
        getSignableTransaction(protocol: $protocol, objectId: $objectId) {
            status {
                code
                message
            }
            transaction {
                _id
                to
                transactionStatus
                targetAddress
                targetData
                transactionType
            }
        }
    }
`

const SignTransactionButton: React.FunctionComponent<IProps> = ({
    title,
    loadingTitle,
    signTransactionBefore,
    signTransactionAfter,
    signTransactionSuccess,
    signTransactionError,
    transaction,
    getTransaction,
    loading,
    className,
    setLoader,
    shouldSignTransactionFlag,
}) => {
    // if (!transaction && !getTransaction) {
    //   return null;
    // }
    const { auth: user } = React.useContext(authContext)
    const context = useWeb3React<Web3Provider>()
    const { library, account } = context
    const [buttonLoading, setButtonLoading] = useState<boolean>(
        loading || false
    )
    const [signTransaction, setSignTransaction] = useState<any>(null)
    const [signTransactionProtocol, setSignTransactionProtocol] =
        useState<any>(null)
    const [isOpenSigninModal, openSigninModal] = useState<boolean>(false)
    const client = useApolloClient()

    useEffect(() => {
        if (signTransaction && isOpenSigninModal) {
            signProvider()
        }
    }, [signTransaction, isOpenSigninModal])

    useEffect(() => {
        if (setLoader) {
            setLoader(buttonLoading)
        }
    }, [buttonLoading])

    useEffect(() => {
        const protocol = getProtocol(user.protocol)

        if (protocol !== signTransactionProtocol) {
            setSignTransaction(null)
            setSignTransactionProtocol(null)
        }
    }, [user])

    const handleError = (message: string, transaction: any) => {
        setButtonLoading(false)
        showNotification(message, 'error')
        signTransactionError(message, transaction)
        signTransactionAfter()
        openSigninModal(false)
    }

    const handleSuccess = (
        signature: any,
        transaction: any,
        signTransactionProtocol: any
    ) => {
        setButtonLoading(false)
        signTransactionSuccess(signature, transaction, signTransactionProtocol)
        signTransactionAfter()
        openSigninModal(false)
    }

    const signProvider = async () => {
        if (signTransaction) {
            const { targetAddress, targetData } = signTransaction
            if (targetData && targetAddress) {
                if (!!(library && account)) {
                    // if (account !== user.walletAddress) {
                    //   const message = 'Wallet address mismatch.';
                    //   handleError(message, signTransaction);
                    // } else {
                    if (targetData && targetAddress) {
                        const transactionSend = await getTransactionData(
                            signTransaction,
                            user,
                            library,
                            false,
                            () => {}
                        )
                        await library
                            .getSigner(account.toLowerCase())
                            .sendTransaction(transactionSend)
                            .then((signature: any) => {
                                handleSuccess(
                                    signature,
                                    signTransaction,
                                    signTransactionProtocol
                                )
                            })
                            .catch((error: any) => {
                                const message = `Failure! ${
                                    error && error.message
                                        ? `\n\n${error.message}`
                                        : ''
                                }`
                                handleError(message, signTransaction)
                            })
                    }
                    // }
                } else {
                    setButtonLoading(false)
                    const message = `Failure!`
                    handleError(message, signTransaction)
                }
            } else {
                const message = 'Something went wrong.'
                handleError(message, signTransaction)
            }
        } else {
            const message = 'Something went wrong.'
            handleError(message, signTransaction)
        }
    }

    const signTransactionFromUser = async () => {
        setButtonLoading(true)
        const protocol = getProtocol(user.protocol)
        let noTransactionError = null
        let signTransactionObject = transaction
        signTransactionBefore(signTransactionObject)
        if (shouldSignTransactionFlag === true) {
            if (signTransaction) {
                openSigninModal(true)
                return
            }
            /* In withdraw, we have to call api to get transaction */
            if (!signTransaction && getTransaction) {
                const result = await getTransaction()
                if (result) {
                    if (result.data) {
                        signTransactionObject = result.data
                    } else if (result.message) {
                        signTransactionObject = null
                        noTransactionError = result.message
                    }
                }
            }
            if (!signTransactionObject) {
                const message = noTransactionError
                    ? noTransactionError
                    : 'No transaction found.'
                setButtonLoading(false)
                showNotification(message, 'error')
                signTransactionAfter()
                return
            }
            const {
                _id: txnId,
                targetData = null,
                targetAddress = null,
            } = signTransactionObject
            let signTransactionData = null
            if (targetData && targetAddress) {
                signTransactionData = signTransactionObject
                setSignTransactionProtocol(protocol)
                setSignTransaction(signTransactionObject)
                openSigninModal(true)
                return
            }
            if (!signTransactionData) {
                const {
                    data: getSignableTransactionData,
                    loading: getSignableTransactionLoading,
                } = await client.query({
                    query: GET_TRANSACTION_SIGNING,
                    variables: {
                        objectId: txnId,
                        protocol: protocol.toLowerCase(),
                    },
                })

                if (
                    !getSignableTransactionLoading &&
                    getSignableTransactionData.getSignableTransaction
                ) {
                    const {
                        status: { code, message },
                        transaction = null,
                    } = getSignableTransactionData.getSignableTransaction

                    if (code === 200) {
                        setSignTransaction({
                            ...signTransactionObject,
                            ...transaction,
                        })
                        setSignTransactionProtocol(protocol)
                        openSigninModal(true)
                        return
                    }
                    setButtonLoading(false)
                    showNotification(message, 'error')
                    signTransactionAfter()
                    return
                }
            }
        } else {
            setButtonLoading(false)
        }
    }

    return (
        <>
            {buttonLoading ? (
                <Button
                    className={'btn-gradient-round-half ' + className}
                    onClick={signTransactionFromUser}
                >
                    <span className="pt-3 pb-3 d-flex justify-content-end">
                        {loadingTitle}
                        <div
                            className="loader"
                            style={{ float: 'right', marginLeft: '87px' }}
                        >
                            <img
                                src={loaderImg}
                                alt="loader"
                                style={{ width: '22px', float: 'right' }}
                            />
                        </div>
                    </span>
                </Button>
            ) : (
                <Button
                    className={'btn-gradient-round-half ' + className}
                    onClick={signTransactionFromUser}
                >
                    <span className="pt-3 pb-3">{title}</span>
                </Button>
            )}
        </>
    )
}

SignTransactionButton.defaultProps = {
    title: 'Sign Transaction',
    loadingTitle: 'Signing ...',
    signTransactionBefore: () => {},
    signTransactionAfter: () => {},
    signTransactionSuccess: () => {},
    signTransactionError: () => {},
    transaction: null,
    getTransaction: null,
    loading: false,
    className: 'w-100 ',
}

export default SignTransactionButton
