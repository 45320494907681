/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './history.scss'
import SearchInput from '../../shared/components/SearchInput/SearchInput'
import QuestionsAccordion from '../../shared/components/QuestionsAccordion/QuestionsAccordion'
import Accordion from 'react-bootstrap/Accordion'
import receive_icon from '../../assets/icons/receive_icon.svg'
import vector1 from '../../assets/icons/vector1.svg'
import vector2 from '../../assets/icons/vector2.svg'
import vector3 from '../../assets/icons/vector3.png'
import vector4 from '../../assets/icons/vector4.png'
import user_icon from '../../assets/icons/user_icon.png'
import share_icon from '../../assets/icons/share_icon.svg'
import approval_icon from '../../assets/icons/approval_icon.svg'
import send_icon from '../../assets/icons/send_icon.svg'
import swap_icon from '../../assets/icons/swap_icon.svg'
import chevron_right from '../../assets/icons/chevron_right.svg'
import { authContext } from './../../utils/context/AuthContext'
import { useWeb3React } from '@web3-react/core'
import connectors from './../../utils/connections/walletConnectors'
import moment from 'moment'

import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
var _ = require('lodash')
const GET_TRANSACTIONS = gql`
    query getTransactionsV2($chainId: Int, $walletAddress: String) {
        getTransactionsV2(chainId: $chainId, walletAddress: $walletAddress) {
            status
            message
            count
            transactions {
                amount
                transactionId
                targetData
                transactionId
                transactionHash
                transactionType
                transactionStatus
                from
                targetAddress
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`

interface IProps {}

const History: React.FC<IProps> = () => {
    const client = useApolloClient()
    const { auth: user, setAuthStatus } = React.useContext(authContext)
    const [activities, setActivities] = useState<any>([])
    const [confirmedDeposits, setConfirmedDeposits] = useState<any>([])
    const context = useWeb3React()
    const { connector, account, activate, active, library } = context
    const walletsImportObj = {
        MetaMask: connectors.Injected,
        Fortmatic: connectors.Fortmatic,
        Ledger: connectors.Ledger,
        Trezor: connectors.Trezor,
        Torus: connectors.Torus,
        Authereum: connectors.Authereum,
        SquareLink: connectors.SquareLink,
        WalletConnect: connectors.WalletConnect,
    }

    React.useEffect(() => {
        if (user.walletAddress) {
            getAllTransactions()
            if (user.accountType && !connector && !account) {
                if (user.accountType === 'METAMASK') {
                    activate(walletsImportObj['MetaMask'])
                }
            }
        }
    }, [user.walletAddress])

    const getAllTransactions = () => {
        client
            .query({
                query: GET_TRANSACTIONS,
                variables: {
                    chainId: user.chainId,
                    walletAddress: user.walletAddress,
                },
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                const reverse: any[] = [
                    ...data?.getTransactionsV2?.transactions,
                ].reverse()
                _.map(
                    reverse,
                    moment
                        .unix(parseInt('createdAt'))
                        .format('DD MMM YYYY hh:mm a')
                )
                const reverse1 = _.orderBy(
                    reverse,
                    [new Date('createdAt')],

                    ['asc']
                )

                const reverse2 = _.groupBy(reverse1, 'createdAt')
                setActivities(reverse2)

                setConfirmedDeposits(
                    reverse.filter(
                        (b: any) => b.transactionStatus === 'confirmed'
                        // &&
                        // b.transactionType === 'deposit'
                    )
                )
                data.getTransactionsV2.transactions.map(
                    (activity: any, i: number) => {
                        if (
                            activity.transactionType === 'walletCreation' &&
                            activity.transactionStatus === 'confirmed'
                        ) {
                            localStorage.setItem('walletCreation', 'confirmed')
                        }
                    }
                )
            })
    }
    // useEffect(() => {
    //     if (confirmedDeposits) {
    //         _.sortBy(confirmedDeposits, [
    //             function (o) {
    //                 return o.amount
    //             },
    //         ])
    //     }
    // }, [confirmedDeposits])
    return (
        <>
            <div className="mainContainerMarginTop mb-5 history">
                {console.log('activities', activities)}
                {/* {_.mapValues(activities, function (o) {
                    return (
                        <div>
                            <p>{o}</p>
                        </div>
                    )
                })} */}

                <div className="row w-100 mx-0">
                    <div className="col-md-8 ">
                        <h1 className="history-title">History</h1>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <SearchInput placeholder="Search by Address, Protocol, Asset or Type" />
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className="date">July 23, 2021</p>

                            <div id="accordion" className="w-100">
                                {confirmedDeposits &&
                                    confirmedDeposits.map(
                                        (transaction, index) => (
                                            <>
                                                <div className="card">
                                                    <div
                                                        key={index}
                                                        className="card-header w-100"
                                                        id={`heading${transaction.transactionId}`}
                                                        data-toggle="collapse"
                                                        data-target={`#collapse${transaction.transactionId}`}
                                                        aria-expanded="true"
                                                        aria-controls={`collapseOne${transaction.transactionId}`}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center justify-content-between ">
                                                                <img
                                                                    src={
                                                                        transaction.transactionType ===
                                                                        'withdraw'
                                                                            ? send_icon
                                                                            : transaction.transactionType ===
                                                                              'authorize'
                                                                            ? approval_icon
                                                                            : transaction.transactionType ===
                                                                              'deposit'
                                                                            ? receive_icon
                                                                            : receive_icon
                                                                    }
                                                                    alt=""
                                                                    className="align-top"
                                                                />
                                                                <span className="ml-2 collapse-title text-capitalize">
                                                                    {
                                                                        transaction.transactionType
                                                                    }
                                                                    <br />
                                                                    <span className="collapse-subtitle">
                                                                        {moment
                                                                            .unix(
                                                                                transaction.createdAt /
                                                                                    1000
                                                                            )
                                                                            .format(
                                                                                'DD MMM YYYY hh:mm a'
                                                                            )
                                                                            .toString()}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center justify-content-start">
                                                                    <img
                                                                        src={require(`../../assets/images/${transaction.protocolId.protocolIcon}`)}
                                                                        alt=""
                                                                        className="position-relative"
                                                                        width="30"
                                                                        height="30"
                                                                        style={{
                                                                            left: '8px',
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={require(`../../assets/images/${transaction.tokenId.tokenIcon}`)}
                                                                        className="position-relative"
                                                                        width="30"
                                                                        height="30"
                                                                        style={{
                                                                            right: '4px',
                                                                        }}
                                                                    />
                                                                </div>

                                                                <span className="ml-2 collapse-title">
                                                                    ${' '}
                                                                    {
                                                                         transaction?.amount?.toFixed(1)
                                                                    }
                                                                    {/* <br />
                                                                    <span className="collapse-subtitle">
                                                                        ${' '}
                                                                        {
                                                                            transaction.amount
                                                                        }
                                                                    </span> */}
                                                                </span>
                                                            </div>
                                                            <div className="">
                                                                <p className="collapse-subtitle">
                                                                    From
                                                                </p>

                                                                <span className="d-flex align-items-center collapse-subtitle">
                                                                    <img
                                                                        src={
                                                                            user_icon
                                                                        }
                                                                        alt=""
                                                                        className="mr-2"
                                                                    />
                                                                    {transaction.from.slice(
                                                                        0,
                                                                        4
                                                                    )}
                                                                    ...{' '}
                                                                    {transaction.from.slice(
                                                                        -4
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        id={`collapse${transaction.transactionId}`}
                                                        className="collapse "
                                                        aria-labelledby={`heading${transaction.transactionId}`}
                                                        data-parent="#accordion"
                                                    >
                                                        <div className="card-body ">
                                                            <div className="row px-5 py-3">
                                                                <div className="col-md-5">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        {/* <div>
                                                                            <p className="history-details-title mb-0">
                                                                                Fee
                                                                            </p>
                                                                            <p className="history-details-subtitle">
                                                                                23
                                                                                ADD
                                                                                ($8.90)
                                                                            </p>
                                                                        </div> */}
                                                                        <div>
                                                                            <p className="history-details-title mb-0">
                                                                                Transaction
                                                                                Harsh
                                                                            </p>
                                                                            <p className="history-details-subtitle">
                                                                                {transaction.transactionHash.slice(
                                                                                    0,
                                                                                    4
                                                                                )}
                                                                                ...
                                                                                {transaction.transactionHash.slice(
                                                                                    -6
                                                                                )}
                                                                                <img
                                                                                    src={
                                                                                        share_icon
                                                                                    }
                                                                                    alt=""
                                                                                    className="ml-2"
                                                                                />
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )}
                                {/* <div className="card">
                                    <div
                                        className="card-header w-100"
                                        id="headingOne"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <img
                                                    src={receive_icon}
                                                    alt=""
                                                    className="align-top"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    Receive <br />
                                                    <span className="collapse-subtitle">
                                                        08:44 AM
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <img
                                                    src={vector1}
                                                    alt=""
                                                    className="align-top"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    +3,600 ADD <br />
                                                    <span className="collapse-subtitle">
                                                        $1,310.40
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="">
                                                <p className="collapse-subtitle">
                                                    From
                                                </p>

                                                <span className="d-flex align-items-center collapse-subtitle">
                                                    <img
                                                        src={user_icon}
                                                        alt=""
                                                        className="mr-2"
                                                    />
                                                    0x87eb...784b
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        id="collapseOne"
                                        className="collapse "
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body ">
                                            <div className="row px-5 py-3">
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Fee
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                23 ADD ($8.90)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Transaction
                                                                Harsh
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                0x7fh5k....9082
                                                                <img
                                                                    src={
                                                                        share_icon
                                                                    }
                                                                    alt=""
                                                                    className="ml-2"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div
                                        className="card-header"
                                        id="headingTwo"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <img
                                                    src={approval_icon}
                                                    alt=""
                                                    className="align-top"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    Approval <br />
                                                    <span className="collapse-subtitle">
                                                        08:44 AM
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <img
                                                    src={vector3}
                                                    alt=""
                                                    className="align-top flex-1"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    Uniswap ADD <br />
                                                </span>
                                            </div>
                                            <div className="">
                                                <p className="collapse-subtitle">
                                                    Application
                                                </p>

                                                <span className="d-flex align-items-center collapse-subtitle">
                                                    <img
                                                        src={user_icon}
                                                        alt=""
                                                        className="mr-2"
                                                    />
                                                    0x87eb...784b
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseTwo"
                                        className="collapse"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row px-5 py-3">
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Fee
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                23 ADD ($8.90)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Transaction
                                                                Harsh
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                0x7fh5k....9082
                                                                <img
                                                                    src={
                                                                        share_icon
                                                                    }
                                                                    alt=""
                                                                    className="ml-2"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div
                                        className="card-header"
                                        id="headingThree"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <img
                                                    src={send_icon}
                                                    alt=""
                                                    className="align-top"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    Send <br />
                                                    <span className="collapse-subtitle">
                                                        08:44 AM
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <img
                                                    src={vector4}
                                                    alt=""
                                                    className="align-top"
                                                />
                                                <span className="ml-2 collapse-title">
                                                    7,200 USDT <br />
                                                    <span className="collapse-subtitle">
                                                        $1,310.40
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="">
                                                <p className="collapse-subtitle">
                                                    From
                                                </p>

                                                <span className="d-flex align-items-center collapse-subtitle">
                                                    <img
                                                        src={user_icon}
                                                        alt=""
                                                        className="mr-2"
                                                    />
                                                    0x87eb...784b
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseThree"
                                        className="collapse"
                                        aria-labelledby="headingThree"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row px-5 py-3">
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Fee
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                23 ADD ($8.90)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Transaction
                                                                Harsh
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                0x7fh5k....9082
                                                                <img
                                                                    src={
                                                                        share_icon
                                                                    }
                                                                    alt=""
                                                                    className="ml-2"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div
                                        className="card-header"
                                        id="heading4"
                                        data-toggle="collapse"
                                        data-target="#collapse4"
                                        aria-expanded="false"
                                        aria-controls="collapse4"
                                    >
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <div className="col-md-5 col-sm-3 col-xs-3 text-left pl-0 ml-0 col-4">
                                                <div className="d-flex align-items-center justify-content-start ">
                                                    <img
                                                        src={swap_icon}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        Trade <br />
                                                        <span className="collapse-subtitle">
                                                            08:44 AM
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-sm-9 col-8 col-xs-9 d-flex justify-content-between text-left">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <img
                                                        src={vector4}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        7,200 USDT <br />
                                                        <span className="collapse-subtitle">
                                                            $1,310.40
                                                        </span>
                                                    </span>
                                                </div>
                                                <img
                                                    src={chevron_right}
                                                    alt=""
                                                />
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <img
                                                        src={vector3}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        2,446.90 USDT
                                                        <br />
                                                        <span className="collapse-subtitle">
                                                            $1,310.40
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapse4"
                                        className="collapse"
                                        aria-labelledby="heading4"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row px-5 py-3">
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Sent
                                                            </p>
                                                            <div className="d-flex align-items-center justify-content-start">
                                                                <img
                                                                    src={
                                                                        vector3
                                                                    }
                                                                    alt=""
                                                                    className="align-top"
                                                                />
                                                                <span className="ml-2 collapse-title">
                                                                    2,446.90
                                                                    USDT
                                                                    <br />
                                                                    <span className="collapse-subtitle">
                                                                        $1,310.40
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={
                                                                    chevron_right
                                                                }
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Received
                                                            </p>
                                                            <div className="d-flex align-items-center justify-content-start">
                                                                <img
                                                                    src={
                                                                        vector4
                                                                    }
                                                                    alt=""
                                                                    className="align-top"
                                                                />
                                                                <span className="ml-2 collapse-title">
                                                                    2,446.90
                                                                    USDT
                                                                    <br />
                                                                    <span className="collapse-subtitle">
                                                                        $1,310.40
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="mt-3">
                                                            <p className="history-details-title mb-0">
                                                                Fee
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                23 ADD ($8.90)
                                                            </p>
                                                        </div>
                                                        <div className="mt-3 text-left">
                                                            <p className="history-details-title mb-0">
                                                                Transaction
                                                                Harsh
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                0x7fh5k....9082
                                                                <img
                                                                    src={
                                                                        share_icon
                                                                    }
                                                                    alt=""
                                                                    className="ml-2"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div
                                        className="card-header"
                                        id="heading5"
                                        data-toggle="collapse"
                                        data-target="#collapse5"
                                        aria-expanded="false"
                                        aria-controls="collapse5"
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="col-md-5 col-lg-5 col-xs-5 col-4 col-sm-5 text-left pl-0 ml-0">
                                                <div className="d-flex align-items-center justify-content-start ">
                                                    <img
                                                        src={swap_icon}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        Swap <br />
                                                        <span className="collapse-subtitle">
                                                            08:44 AM
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-8 d-flex justify-content-between">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <img
                                                        src={vector4}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        7,200 USDT <br />
                                                        <span className="collapse-subtitle">
                                                            $1,310.40
                                                        </span>
                                                    </span>
                                                </div>
                                                <img
                                                    src={chevron_right}
                                                    alt=""
                                                />
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <img
                                                        src={vector3}
                                                        alt=""
                                                        className="align-top"
                                                    />
                                                    <span className="ml-2 collapse-title">
                                                        2,446.90 USDT
                                                        <br />
                                                        <span className="collapse-subtitle">
                                                            $1,310.40
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapse5"
                                        className="collapse"
                                        aria-labelledby="heading5"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row px-5 py-3">
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Sent
                                                            </p>
                                                            <div className="d-flex align-items-center justify-content-start">
                                                                <img
                                                                    src={
                                                                        vector3
                                                                    }
                                                                    alt=""
                                                                    className="align-top"
                                                                />
                                                                <span className="ml-2 collapse-title">
                                                                    2,446.90
                                                                    USDT
                                                                    <br />
                                                                    <span className="collapse-subtitle">
                                                                        $1,310.40
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={
                                                                    chevron_right
                                                                }
                                                                alt=""
                                                            />
                                                        </div>

                                                        <div>
                                                            <p className="history-details-title mb-0">
                                                                Received
                                                            </p>
                                                            <div className="d-flex align-items-center justify-content-start">
                                                                <img
                                                                    src={
                                                                        vector4
                                                                    }
                                                                    alt=""
                                                                    className="align-top"
                                                                />
                                                                <span className="ml-2 collapse-title">
                                                                    2,446.90
                                                                    USDT
                                                                    <br />
                                                                    <span className="collapse-subtitle">
                                                                        $1,310.40
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="mt-3">
                                                            <p className="history-details-title mb-0">
                                                                Fee
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                23 ADD ($8.90)
                                                            </p>
                                                        </div>
                                                        <div className="mt-3 text-left">
                                                            <p className="history-details-title mb-0">
                                                                Transaction
                                                                Harsh
                                                            </p>
                                                            <p className="history-details-subtitle">
                                                                0x7fh5k....9082
                                                                <img
                                                                    src={
                                                                        share_icon
                                                                    }
                                                                    alt=""
                                                                    className="ml-2"
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default History
