import React, { useState, useEffect } from 'react'
import axios from 'axios'

// components
import Modal from 'react-modal'
import DepositModal from './Deposit'

// config
import Config from './../../../config/config'

// assets
import cross_light from '../../../assets/images/project/cross-light.png'

import { gql, useApolloClient } from '@apollo/client'

// styles
import './style.scss'

const GET_GAS_PRICE = gql`
    query getGasPrices {
        getGasPrices {
            response {
                status
                message
            }
            data {
                standard
                fast
                instant
            }
        }
    }
`

const customStyles = () => ({
    content: {
        top: '50%',
        left: '50%',
        zIndex: 999,
        padding: '0px',
        transform: 'translate(-50%, -50%)',
        height: '80%',
    },
})

interface IProps {
    showLendingModal: false | 'deposit' | 'withdraw'
    setShowLendingModal: any
    protocols: any
    gettingProtocols: boolean
    tokens: any
    gettingTokens: boolean
    balances: any
    onGotoDashboard: Function
    selectedId: any
    selectedProtocol: any
    selectedToken: any
    userTokenBalance: any
}
const LendingModals: React.FC<IProps> = ({
    showLendingModal,
    setShowLendingModal,
    protocols,
    gettingProtocols,
    tokens,
    gettingTokens,
    balances,
    onGotoDashboard,
    selectedId,
    selectedProtocol,
    selectedToken,
    userTokenBalance,
}) => {
    const [depositStep, setDepositStep] = useState(0)
    const client = useApolloClient()

    const [gasPrices, setGasPrices] = useState('')
    // const [withdrawStep, setWithdrawSte] = useState(0);
    // const [withdrawLoading, setWithdrawLoading] = useState(false);

    const [gettinGasPrices, setGettingGasPrices] = useState(false)

    useEffect(() => {
        getGasFeeCall()
        if (showLendingModal) {
            setDepositStep(1)
        }
    }, [showLendingModal])

    const getGasFeeCall = async () => {
        await client
            .query({
                query: GET_GAS_PRICE,
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                console.log('r.data = ', data.getGasPrices.data)
                setGasPrices(data.getGasPrices.data)
            })
    }

    const renderTitles = () => {
        let title: string

        if (showLendingModal) {
            if (depositStep === 1) {
                if (showLendingModal === 'deposit') {
                    title = 'Deposit Liquidity'
                } else {
                    title = 'Withdraw Liquidity'
                }
            } else if (depositStep === 3) {
                title = 'Approving'
            } else if (depositStep === 5) {
                title = 'Confirming'
            } else if (depositStep === 6) {
                title = 'Success'
            }
        }

        return title
    }

    // const getGasPrices = () => {
    //   setGettingGasPrices(true);
    //   axios
    //     .get(Config.etherchainGasPriceUrl)
    //     .then((r) => {
    //     console.log('r.data = ',r.data);
    //       setGasPrices(r.data)
    //     })
    //     .finally(() => setGettingGasPrices(false));
    // };

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen
                // onRequestClose={closeModal}
                contentLabel="Wallet Modal"
                style={customStyles()}
                shouldCloseOnOverlayClick={false}
                className="lending-modals-ui lending-modal-card modal-style-lending  p-4"
            >
                <>
                    <div
                        className={`d-flex align-items-center justify-content-${
                            depositStep === 1 ? 'between' : 'center'
                        } position-relative`}
                    >
                        <div className="lending-modal-header mb-3">
                            {renderTitles()}
                        </div>
                        <img
                            onClick={() => {
                                setShowLendingModal(false)
                                onGotoDashboard()
                            }}
                            src={cross_light}
                            alt="Light cross"
                            className="pointer position-absolute mb-3"
                            style={{
                                width: '15px',
                                height: '15px',
                                right: 0,
                                top: 0,
                            }}
                        />
                    </div>
                    {/* {depositStep === 1 && (
            <div className="lending-modal-subheader mb-3">
              {showLendingModal === "deposit"
                ? "Safely lend your assets to earn passive income, in any protocol of your choice."
                : "Withdraw your earnings anytime, from any protocol."}
            </div>
          )} */}
                </>
                <DepositModal
                    selectedId={selectedId}
                    setDepositStep={setDepositStep}
                    showLendingModal={showLendingModal}
                    setShowLendingModal={setShowLendingModal}
                    step={depositStep}
                    protocols={protocols}
                    tokens={tokens}
                    gasPrices={gasPrices}
                    balances={balances}
                    selectedToken={selectedToken}
                    loading={
                        gettingProtocols || gettingTokens || gettinGasPrices
                    }
                    onGotoDashboard={onGotoDashboard}
                    selectedProtocol={selectedProtocol}
                    userTokenBalance={userTokenBalance}
                />
            </Modal>
            {console.log('lending modal', selectedProtocol)}
        </>
    )
}

export default LendingModals
