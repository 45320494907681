import React, { useState, useEffect } from 'react'
import './style.scss'
import Modal from 'react-modal'
import newTabIcon from '../../../../assets/images/project/newTabIcon.svg'
import cross from '../../../../assets/images/project/closeIcon.svg'
import { Button } from 'react-bootstrap'
import { provider } from 'web3-core'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

// utils
import { getContract, getWeb3 } from '../../../../utils/Utilities/erc20'
import { stakeOrUnstake } from '../../../../utils/Utilities/transactionsUtils'
import { showNotification } from '../../../../utils/notifications/notificationService'
import unstakeIcon from '../../../../assets/icons/unstakeicon.svg'
import ConfirmModal from '../ConfirmModal'
import { Spinner } from 'react-bootstrap'
// constants
import Config from '../../../../config/config'

interface IProps {
    isVisible: Boolean
    setIsVisible: Function
    header: string
    title: string
    balance: string
    pid: number
    transactionType: 'deposit' | 'withdraw'
    checkAllowance: () => {}
    getRewardandBalanceAmount: any
    onBack?: Function
}

const AmountModal: React.FC<IProps> = ({
    isVisible,
    setIsVisible,
    header,
    title,
    balance,
    pid,
    transactionType,
    checkAllowance,
    getRewardandBalanceAmount,
    onBack,
}) => {
    const context = useWeb3React<Web3Provider>()
    const { account, library } = context

    const [amount, setAmount] = useState('')
    const [isTransacting, setIsTransacting] = useState(false)
    const [transactionHash, setTransactionHash] = useState(false)
    const [showConfirmationModal, setConfirmationModal] = useState(false)
    useEffect(() => {
        setIsVisible(true)
        // onBack(false)
    })

    //   const [isConfirm, setConfirm] = useState(false);
    const webInstance = getWeb3(library.provider as provider)

    const handleStakeOrUnstake = async () => {
        setIsTransacting(true)
        try {
            const masterchefContract = getContract(
                library.provider as provider,
                Config.masterchefAddress,
                true
            )
            const amountDeposited = webInstance.utils.toWei(
                amount.toString(),
                'ether'
            )
            const tx = await stakeOrUnstake(
                masterchefContract,
                pid,
                amountDeposited,
                account,
                transactionType
            )

            if (tx && tx.status) {
                getRewardandBalanceAmount()
                checkAllowance()
                setTransactionHash(tx.transactionHash)
                showNotification(`${transactionType} successful!`, 'success')
                setIsTransacting(false)
            }
        } catch (error) {
            setIsTransacting(false)
        }
    }

    const closeModal = () => {
        setIsVisible(false)
    }

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={isVisible ? true : false}
                onRequestClose={closeModal}
                contentLabel="Wallet Modal"
                shouldCloseOnOverlayClick
                className="amount-modal-view"
            >
                <div className="px-5 py-4">
                    <div className="d-flex justify-content-center">
                        <span className="amount-modal-title">
                            {header} LP Tokens
                        </span>
                        <img src={unstakeIcon} alt="ADD.xyz Logo" />
                        {/* <img src={cross} alt='ADD.xyz' onClick={closeModal} /> */}
                    </div>
                    <div className="text-center mt-5 mb-3">
                        <span className="amount-modal-header">
                            {title} UNI-V2 LP
                        </span>
                    </div>

                    <div className="d-flex justify-content-end mt-3 mb-3">
                        <span className="amount-modal-balance-text">
                            Balance: {balance}
                        </span>
                    </div>
                    <div className="input-field-box mb-3">
                        <input
                            className="amount-modal-input"
                            placeholder="0"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <div className="d-flex align-items-center">
                            <Button
                                className="pool-max-btn ml-2"
                                onClick={() => setAmount(balance)}
                            >
                                <span>Max</span>
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <Button
                            className="cancel-amount-btn"
                            onClick={closeModal}
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button
                            className="confirm-amount-btn"
                            onClick={handleStakeOrUnstake}
                        >
                            <span>
                                {isTransacting ? 'Confirming' : 'Confirm'}
                            </span>
                        </Button>
                        {/* <Button
              disabled={Number(balance) <= 0 || Number(amount) > Number(balance) ? true : false}
              className={`confirm-amount-btn ${
                isTransacting ? 'disabled' : ''
              }`}
              onClick={handleStakeOrUnstake}
         
         
            >
              <span>{isTransacting ? 'Confirming' : 'Confirm'}</span>
            </Button> */}
                    </div>
                    {transactionHash && (
                        <div className="d-flex justify-content-center pointer">
                            <span className="footer-text mr-2">
                                {title} UNI-V2 LP
                            </span>
                            <img
                                src={newTabIcon}
                                alt="ADD.xyz"
                                onClick={() =>
                                    window.open(
                                        `${Config.etherscanKovanUrl}/${transactionHash}`
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            </Modal>
            {showConfirmationModal && (
                <ConfirmModal
                    header={'Unstake'}
                    isVisible={showConfirmationModal}
                    setIsVisible={setConfirmationModal}
                />
            )}
        </div>
    )
}

export default AmountModal
