/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react'
import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
import { authContext } from './../../../../utils/context/AuthContext'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { getTransactionData } from './../../../../utils/Utilities/transactionsUtils'
import { getERC20Balance } from './../../../../utils/tornado/tornado'
import { showNotification } from './../../../../utils/notifications/notificationService'

// components
import { Button, Spinner, Dropdown, Form } from 'react-bootstrap'
// import Slider from "@bit/mui-org.material-ui.slider";
import { Slider } from 'material-ui-slider'

// assets
import AaveCombined from './../../../../assets/images/crypto/aave_combined.svg'
import USDC from './../../../../assets/images/crypto/USDC_lending.png'
import ArrowForward from './../../../../assets/images/project/filled_arrow_right.svg'

// config
import Config from './../../../../config/config'
import { numberWithCommas } from './../../../../utils/number'

const marks = [
    {
        value: 0,
        label: 'Slow',
    },
    {
        value: 1,
        label: 'Standard',
    },
    {
        value: 2,
        label: 'Fast',
    },
    {
        value: 3,
        label: 'Custom',
    },
]

// SUBSCRIPTION
const V2TransactionHash = gql`
    subscription v2TransactionHash($hash: String!) {
        v2TransactionHash(hash: $hash) {
            response {
                status
                message
            }
            data {
                transactionId
                transactionHash
                transactionType
                transactionStatus
                tokenId {
                    tokenId
                    tokenName
                    tokenIcon
                }
                protocolId {
                    protocolId
                    protocolName
                    protocolIcon
                }
            }
        }
    }
`

//END SUbscription

const GET_APPROVE_TRANSACTION = gql`
    query getApproveTransactionV2(
        $amount: Float!
        $tokenId: Int!
        $protocolId: Int!
        $chainId: Int!
        $isDeposit: Boolean!
    ) {
        getApproveTransactionV2(
            amount: $amount
            tokenId: $tokenId
            protocolId: $protocolId
            chainId: $chainId
            isDeposit: $isDeposit
        ) {
            response {
                status
                message
            }
            data {
                targetData
                targetAddress
            }
        }
    }
`

const ADD_TRANSACTION = gql`
    mutation addTransactionV2(
        $connectionId: Int!
        $protocolId: Int!
        $tokenId: Int!
        $transactionType: String!
        $transactionHash: String!
        $chainId: Int!
        $amount: Float!
    ) {
        addTransactionV2(
            connectionId: $connectionId
            protocolId: $protocolId
            tokenId: $tokenId
            transactionType: $transactionType
            transactionHash: $transactionHash
            chainId: $chainId
            amount: $amount
        ) {
            response {
                status
                message
            }
            data {
                transactionId
                transactionStatus
                transactionType
                transactionHash
            }
        }
    }
`

const CONFIRM_TRANSACTION = gql`
    mutation confirmTransactionV2($transactionId: Int!, $status: Boolean!) {
        confirmTransactionV2(transactionId: $transactionId, status: $status) {
            response {
                status
                message
            }
            data {
                transactionId
                amount
                amountUsd
                transactionHash
                transactionType
                transactionStatus
                targetAddress
                targetData
                tokenId {
                    tokenId
                }
                protocolId {
                    protocolId
                }
            }
        }
    }
`

const GET_DEPOSIT_TRANSACTION = gql`
    query getDepositTransactionV2(
        $amount: Float!
        $tokenId: Int!
        $protocolId: Int!
        $chainId: Int!
        $isDeposit: Boolean!
        $address: String!
    ) {
        getDepositTransactionV2(
            amount: $amount
            tokenId: $tokenId
            protocolId: $protocolId
            chainId: $chainId
            isDeposit: $isDeposit
            address: $address
        ) {
            response {
                status
                message
            }
            data {
                targetData
                targetAddress
            }
        }
    }
`

const CHECK_ALLOWANCE = gql`
    query checkAllowance(
        $walletAddress: String!
        $tokenId: Int!
        $protocolId: Int!
        $chainId: Int!
    ) {
        checkAllowance(
            walletAddress: $walletAddress
            tokenId: $tokenId
            protocolId: $protocolId
            chainId: $chainId
        ) {
            value
            response {
                status
                message
            }
        }
    }
`
interface IProps {
    showLendingModal: false | 'deposit' | 'withdraw'
    setShowLendingModal: Function
    step: Number
    setDepositStep: any
    protocols: any
    tokens: any
    selectedToken: any
    balances: any
    gasPrices: any
    loading: boolean
    onGotoDashboard: Function
    selectedId: any
    selectedProtocol: any
    userTokenBalance: any
}
const Deposit: React.FC<IProps> = ({
    showLendingModal,
    setShowLendingModal,
    step,
    setDepositStep,
    protocols,
    tokens,
    gasPrices,
    loading,
    balances,
    onGotoDashboard,
    selectedId,
    selectedProtocol,
    selectedToken,
    userTokenBalance,
}) => {
    const [token, setToken] = useState<any>(false)
    const [amount, setAmount] = useState('')
    const [protocol, setProtocol] = useState<any>(false)
    const [balance, setBalance] = useState<any>('')
    const [transactionHash, setTransactionHash] = useState<string>('')
    const [customGasPrice, setCustomGasPrice] = useState('')
    const [selectedGasPrice, setSelectedGasPrice] = useState<any>(1)
    const [gettingBalance, setGettingBalance] = useState(false)
    const [tokenList, setTokenList] = useState<any>(false)

    const { auth: user } = useContext(authContext)
    const context = useWeb3React<Web3Provider>()
    const { library, account } = context

    const client = useApolloClient()
    const [addTransaction] = useMutation(ADD_TRANSACTION)
    const [confirmTransaction] = useMutation(CONFIRM_TRANSACTION)

    // SUBSCRIPTION FOR V2 TRANSACTION HASH to backend
    const {
        data: v2TransactionHashData,
        loading: v2TransactionHashLoading,
        error: v2TransactionHashError,
    } = useSubscription(V2TransactionHash, {
        variables: { hash: transactionHash },
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        console.log('deposit modal', selectedProtocol)
        if (
            !v2TransactionHashLoading &&
            v2TransactionHashData &&
            v2TransactionHashData.v2TransactionHash &&
            v2TransactionHashData.v2TransactionHash.data &&
            v2TransactionHashData.v2TransactionHash.data.transactionHash ===
                transactionHash
        ) {
            setTransactionHash('')
            if (
                v2TransactionHashData.v2TransactionHash.data.transactionType ===
                'authorize'
            ) {
                setDepositStep(4) // for approving transaction refer user to lending page , and for dpeosit then refer to any other
            } else {
                setDepositStep(6)
            }
        }
    })
    // END OF SUBSCRIPTION FOR V2 TRANSACTION HASH to backend

    useEffect(() => {
        if (selectedId) {
            console.log(protocols)
            const obj = protocols.find((obj) => obj.protocolId === selectedId)
            obj && setProtocol(obj)

            setTokenList(obj.token)
            if (obj.token.length > 0) {
                // setToken(obj.tokens[0])
                let incomingToken = obj.token.find(
                    (b: any) => b.tokenId === selectedToken.tokenId
                )
                setToken(incomingToken)
            } else {
                setToken(false)
            }
        }
    }, [])

    useEffect(() => {
        if (selectedProtocol) {
            setProtocol(selectedProtocol)
            setTokenList(selectedProtocol.token)
        }
    }, [selectedProtocol])

    useEffect(() => {
        if (tokenList && tokenList.length) {
            // setToken(tokenList[0])
            console.log('list', tokenList)
            let incomingToken = tokenList.find(
                (b: any) => b.tokenSymbol === selectedToken.tokenSymbol
            )
            console.log('incoming token', incomingToken)

            setToken(incomingToken)

            showLendingModal === 'deposit' && getBalance(incomingToken)
            // showLendingModal === 'deposit' && getBalance(selectedToken)
            showLendingModal === 'withdraw' &&
                BalanceCalculation(tokenList[0], protocols[0])
        }
    }, [tokenList])

    // useEffect(() => {
    //   showLendingModal === "withdraw" &&
    //     setBalance(findBalance(protocols[0].protocolKey, false));
    // }, [tokenList]);

    useEffect(() => {
        if (protocols && showLendingModal === 'withdraw') {
            // setProtocol(protocols[0])
            setProtocol(selectedProtocol)
            // setBalance(findBalance(protocols[0].protocolKey, false))
            setBalance(findBalance(selectedProtocol.protocolKey, false))
        }
    }, [protocols])

    const truncateDecimalPoints = (num: number): string =>
        num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]

    const getApproveCall = async () => {
        await client
            .query({
                query: CHECK_ALLOWANCE,
                variables: {
                    walletAddress: user.walletAddress,
                    tokenId: Number(token.tokenId),
                    protocolId: protocol.protocolId,
                    chainId: user.chainId,
                },
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                console.log('CHECK_ALLOWANCE', data.checkAllowance.value)
                console.log('approved', data)

                if (
                    data.checkAllowance.value === 0 ||
                    data.checkAllowance.value < +amount
                ) {
                    setDepositStep(2)
                } else {
                    setDepositStep(4)
                }
            })
            .catch(() => {
                setDepositStep(1)
            })
    }

    const onSubmitFirstStep = () => {
        getApproveCall()

        // if (
        //     showLendingModal === 'withdraw' &&
        //     protocol.protocolKey === 'Dydx'
        // ) {
        //     setDepositStep(4)
        // } else {
        //     //here code of approve
        //     getApproveCall()
        // }
    }

    const getApproveTransaction = () => {
        setDepositStep(3)
        console.log(
            amount,
            token.tokenId,
            protocol.protocolId,
            user.chainId,
            user.walletAddress
        )
        client
            .query({
                query: GET_APPROVE_TRANSACTION,
                variables: {
                    amount: parseFloat(amount),
                    tokenId: Number(token.tokenId),
                    protocolId: protocol.protocolId,
                    chainId: user.chainId,
                    isDeposit: showLendingModal === 'deposit' ? true : false,
                },
            })
            .then(({ data }: any) => {
                if (data.getApproveTransactionV2) {
                    signProvider(data.getApproveTransactionV2.data, true)
                    console.log('transaction approved ', data)
                }
            })
            .catch(() => setDepositStep(2))
    }

    const getDepositTransaction = () => {
        setDepositStep(5)
        console.log(
            amount,
            token.tokenId,
            protocol.protocolId,
            user.chainId,
            user.walletAddress
        )

        client
            .query({
                query: GET_DEPOSIT_TRANSACTION,
                variables: {
                    amount: parseFloat(amount),
                    tokenId: Number(token.tokenId),
                    protocolId: protocol.protocolId,
                    chainId: user.chainId,
                    isDeposit: showLendingModal === 'deposit' ? true : false,
                    address: user.walletAddress,
                },
            })
            .then(({ data }: any) => {
                console.log('transaction response', data)
                if (data.getDepositTransactionV2) {
                    console.log(
                        'transaction response',
                        data.getDepositTransactionV2
                    )
                    signProvider(data.getDepositTransactionV2.data, false)
                }
            })
            .catch(() => setDepositStep(4))
    }

    const handleError = (message: string, isApproving: boolean) => {
        if (isApproving) {
            setDepositStep(2)
        } else {
            setDepositStep(4)
        }
        showNotification(message, 'error')
    }

    const signProvider = async (tranObj: any, isApproving: boolean) => {
        if (tranObj) {
            const { targetAddress, targetData } = tranObj
            if (targetData && targetAddress) {
                if (!!(library && account)) {
                    // if (account !== user.walletAddress) {
                    //   const message = 'Wallet address mismatch.';
                    //   handleError(message, signTransaction);
                    // } else {
                    if (targetData && targetAddress) {
                        const transactionSend = await getTransactionData(
                            tranObj,
                            user,
                            library,
                            selectedGasPrice !== 3
                                ? gasPrices[
                                      Object.keys(gasPrices)[selectedGasPrice]
                                  ]
                                : customGasPrice,
                            () => {
                                if (isApproving) {
                                    setDepositStep(2)
                                } else {
                                    setDepositStep(4)
                                }
                                showNotification(
                                    'Transaction will fail!',
                                    'error'
                                )
                            }
                        )

                        if (transactionSend) {
                            await library
                                .getSigner(account.toLowerCase())
                                .sendTransaction(transactionSend)
                                .then((data: any) => {
                                    setTransactionHash(data.hash)
                                    addTransaction({
                                        variables: {
                                            connectionId: user.connectionId,
                                            amount: parseFloat(amount),
                                            tokenId: Number(token.tokenId),
                                            protocolId: protocol.protocolId,
                                            chainId: user.chainId,
                                            transactionType: isApproving
                                                ? 'authorize'
                                                : showLendingModal === 'deposit'
                                                ? 'deposit'
                                                : 'withdraw',
                                            transactionHash: data.hash,
                                        },
                                    }).then(({ data }: any) => {
                                        console.log(
                                            'AFTER ADD TRANSACTION V2 => ',
                                            transactionHash
                                        )
                                        console.log(
                                            'after adding transaction',
                                            data
                                        )
                                    })
                                })
                                .catch((error: any) => {
                                    const message = `Failure! ${
                                        error && error.message
                                            ? `\n\n${error.message}`
                                            : ''
                                    }`
                                    handleError(message, isApproving)
                                })
                        }
                    }
                    // }
                } else {
                    const message = `Failure!`
                    handleError(message, isApproving)
                }
            } else {
                const message = 'Something went wrong.'
                handleError(message, isApproving)
            }
        } else {
            const message = 'Something went wrong.'
            handleError(message, isApproving)
        }
    }

    const isDataValid = () => {
        if (
            protocol !== false &&
            amount &&
            parseFloat(amount) > 0 &&
            /^\d*\.?\d+$/.test(amount) &&
            token !== false
        ) {
            // if (
            //     selectedGasPrice === 3 &&
            //     (!customGasPrice ||
            //         !(parseFloat(customGasPrice) > 0) ||
            //         !/^\d*\.?\d+$/.test(customGasPrice))
            // ) {
            //     return true
            // }

            if (
                showLendingModal === 'withdraw' &&
                parseFloat(amount) >
                    parseFloat(findBalance(protocol.protocolKey, false))
            ) {
                return true
            }
            return false
        } else {
            return true
        }
    }

    const getBalance = (selectedToken: any) => {
        console.log('selected protocol', selectedProtocol)

        // debugger
        setGettingBalance(true)
        getERC20Balance(user.walletAddress, '', selectedToken.tokenAddress)
            .then((r) => {
                if (selectedToken.tokenDecimals != 18) {
                    //fron wei function in getERC20 balance only handles 18 USDC and USDCT are 6
                    r = r * Math.pow(10, 18 - selectedToken.tokenDecimals)
                    //above line re-calibrates decimals for token other then 18 decunaks
                }
                console.log('r', r)
                setBalance(r)
            })
            .finally(() => setGettingBalance(false))
    }

    const selectToken = (selectedToken: any) => {
        showLendingModal === 'deposit' && getBalance(selectedToken)
        setToken(selectedToken)
        setAmount('')
        protocols &&
            showLendingModal === 'withdraw' &&
            BalanceCalculation(selectedToken, protocol)
    }

    const goToDashboard = () => {
        onGotoDashboard()
        setShowLendingModal(false)
        setTransactionHash('')
        setDepositStep(1)
        setAmount('')
        setProtocol(false)
        setBalance('')
    }

    const openEtherscanLink = () => {
        if (user.chainId === 42) {
            window.open(
                `${Config.etherscanKovanUrl}/${transactionHash}`,
                '_blank'
            )
        } else {
            window.open(
                `${Config.etherscanMainUrl}/${transactionHash}`,
                '_blank'
            )
        }
    }

    const findBalance = (protocolKey: any, doTruncation: boolean) => {
        const protocolFind = balances.find(
            (b: any) => b.protocolKey === protocolKey
        )

        const obj = protocols.find((obj) => obj.protocolId === selectedId)
        if (obj.token.length > 0) {
            if (!setToken) {
                setToken(obj.token[0])
            }
        } else {
            setToken(false)
        }

        const tokenFind = protocolFind.token.find(
            (t: any) => t.tokenSymbol === token.tokenSymbol
        )
        return doTruncation && tokenFind !== undefined
            ? truncateDecimalPoints(parseFloat(tokenFind.balance.investment))
            : tokenFind !== undefined
            ? parseFloat(tokenFind.balance.investment).toString()
            : '0'
    }

    const BalanceCalculation = (selectedToken: any, selectedProto: any) => {
        let bal = 0
        balances.map((item, index) => {
            if (item.protocolKey === selectedProto.protocolKey) {
                const tokenFind = item.token.find(
                    (t: any) => t.tokenSymbol === selectedToken.tokenSymbol
                )
                if (tokenFind !== undefined) {
                    bal = parseFloat(tokenFind.balance.investment)
                }
            }
        })
        setBalance(bal)
        // return bal;
    }

    const totalTokenBalance = () => {
        const total = balances.reduce((a: number, c: any) => {
            const tokenFind = c.token.find(
                (t: any) => t.tokenSymbol === token.tokenSymbol
            )
            if (tokenFind !== undefined) {
                return tokenFind !== undefined
                    ? a + parseFloat(tokenFind.balance.investment)
                    : 0
            }
        }, 0)
        return total !== undefined ? total : 0
    }

    const setMaxAmount = () => {
        if (showLendingModal === 'deposit') {
            setAmount(balance)
        } else {
            setAmount(findBalance(protocol.protocolKey, false))
        }
    }

    const setWithdrawProtocol = (selectedProtocol: any) => {
        if (amount === findBalance(protocol.protocolKey, false)) {
            setAmount(findBalance(selectedProtocol.protocolKey, false))
        } else {
            setAmount('')
        }
        // setBalance(findBalance(selectedProtocol.protocolKey, false));
        setProtocol(selectedProtocol)
    }

    // Percentage Calculator

    const calPer = (value) => {
        if (showLendingModal === 'deposit') {
            let perAmount = (balance * value) / 100
            setAmount(JSON.stringify(perAmount))
        }

        if (showLendingModal === 'withdraw') {
            let perAmount = (userTokenBalance * value) / 100
            setAmount(JSON.stringify(perAmount))
        }
    }

    return (
        <>
            {step === 1 && (
                <>
                    {loading ? (
                        <>
                            <div className="py-5 my-5 mx-auto">
                                {' '}
                                <Spinner
                                    animation="border"
                                    className="lending-modal-loader"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {showLendingModal === 'deposit' && (
                                <table className="table privacy-table">
                                    <tbody>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Asset
                                            </td>
                                            <td
                                                className=" align-middle"
                                                style={{ width: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {protocol.protocolName}
                                            </td>
                                        </tr>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Interest APY
                                            </td>
                                            <td className=" align-middle">
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {/* {selectedToken.lending ? (
                                                    <p>
                                                        {selectedToken.lending.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </p>
                                                ) : (
                                                    'NA'
                                                )} */}
                                                {token &&
                                                    parseInt(
                                                        token.balance.interest
                                                    ).toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Balance
                                            </td>
                                            <td className=" align-middle">
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {gettingBalance ? (
                                                    <Spinner
                                                        animation="border"
                                                        className="lending-modal-balance-loader ml-2"
                                                    />
                                                ) : (
                                                    numberWithCommas(balance)
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}

                            {/* {showLendingModal === "deposit" && (
                <div className="lending-modal-section mb-3">
                  <div className="header mb-3">
                    <div>Select a Protocol:</div>
                  </div>

                  <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-center protocol-step-deposit">
                    {protocols &&
                      protocols.map((p: any) => (
                        <Button
                          onClick={() => {
                            setProtocol(p);
                            setTokenList(p.tokens);
                          }}
                          className={`protocol-btn ${
                            protocol.protocolId === p.protocolId ? "active" : ""
                          } d-flex align-items-center mb-3 shadow-none`}
                        >
                          <img
                            src={require(`./../../../../assets/images/${p.protocolIcon}`)}
                          />
                          {p.protocolName !== "Dydx" && (
                            <div className="ml-2">{p.protocolName}</div>
                          )}
                        </Button>
                      ))}
                  </div>
                </div>
              )} */}
                            {/* <div className="lending-modal-section mb-3">
                                {/* <div className="header d-flex flex-column flex-column-reverse flex-sm-row justify-content-between mb-4">
                  <div>{`Enter a amount to ${
                    showLendingModal === "deposit" ? "lend" : "withdraw"
                  }:`}</div>
                  <div className="d-flex align-items-center">
                    Balance:{" "}
                    {gettingBalance ? (
                      <Spinner
                        animation="border"
                        className="lending-modal-balance-loader ml-2"
                      />
                    ) : (
                      numberWithCommas(balance)
                    )}
                  </div>
                </div> *
                            </div> */}

                            {showLendingModal === 'withdraw' && (
                                <table className="table privacy-table">
                                    <tbody>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Protocol
                                            </td>
                                            <td
                                                className=" align-middle"
                                                style={{ width: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {console.log('hello', protocol)}
                                                {protocol.protocolName}
                                            </td>
                                        </tr>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Asset
                                            </td>
                                            <td
                                                className=" align-middle"
                                                style={{ width: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {token && token.tokenSymbol}
                                            </td>
                                        </tr>

                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Interest APY
                                            </td>
                                            <td className=" align-middle">
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {token &&
                                                    parseInt(
                                                        token.balance.interest
                                                    ).toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr className="align-items-center">
                                            <td className="table-label">
                                                Balance
                                            </td>
                                            <td className=" align-middle">
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            '1px dashed #9FA0BA ',
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="table-value">
                                                {gettingBalance ? (
                                                    <Spinner
                                                        animation="border"
                                                        className="lending-modal-balance-loader ml-2"
                                                    />
                                                ) : (
                                                    parseInt(
                                                        userTokenBalance
                                                    ).toFixed(2)
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                // <div className="lending-modal-section mb-3">
                                //     <div className="d-flex justify-content-between">
                                //         <div className="header mb-3">
                                //             <div>Select a Protocol:</div>
                                //         </div>
                                //         <div className="value">{`Total ${
                                //             token.tokenSymbol
                                //         } balance: ${numberWithCommas(
                                //             totalTokenBalance()
                                //         )}`}</div>
                                //     </div>

                                //     <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-center protocol-step-1">
                                //         {protocols &&
                                //             protocols.map((p: any) => (
                                //                 <div
                                //                     className={`pointer protocol-section ${
                                //                         protocol.protocolId ===
                                //                         p.protocolId
                                //                             ? 'protocol-section-active'
                                //                             : ''
                                //                     } mb-1`}
                                //                     onClick={() => {
                                //                         setWithdrawProtocol(p)
                                //                         BalanceCalculation(
                                //                             token,
                                //                             p
                                //                         )
                                //                         setTokenList(p.tokens)
                                //                     }}
                                //                 >
                                //                     <img
                                //                         src={require(`./../../../../assets/images/${p.protocolIcon}`)}
                                //                         className="section-img"
                                //                     />
                                //                     <div>
                                //                         <span className="value">
                                //                             {findBalance(
                                //                                 p.protocolKey,
                                //                                 true
                                //                             )}
                                //                         </span>
                                //                     </div>
                                //                     <div>
                                //                         <span className="coin-info">
                                //                             {token.tokenSymbol}{' '}
                                //                             Balance
                                //                         </span>
                                //                     </div>
                                //                 </div>
                                //             ))}
                                //     </div>
                                // </div>
                            )}

                            <div className="d-flex justify-content-between mb-3 lending-modal-amount">
                                <Form.Control
                                    type="text"
                                    placeholder="$ 0.00"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="lending-modal-amount-input border-0"
                                />
                                <div className="d-flex justify-content-center align-items-center w-50">
                                    {/* <Button
                      className="lending-modal-input-max-action"
                      onClick={setMaxAmount}
                    >
                      Max
                    </Button> */}

                                    <Dropdown className="w-50 ">
                                        <Dropdown.Toggle
                                            variant=""
                                            className="dropdown-tokens d-flex align-items-center ml-auto border-0 shadow-none"
                                            id="dropdown-basic"
                                        >
                                            {token && token.tokenIcon && (
                                                <img
                                                    src={require(`./../../../../assets/images/${token.tokenIcon}`)}
                                                    className="ml-1 mr-1"
                                                    alt=""
                                                    width="21"
                                                    height="21"
                                                />
                                            )}
                                            {token &&
                                                token.tokenSymbol &&
                                                token.tokenSymbol}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {tokenList &&
                                                tokenList.map((t: any) => (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            selectToken(t)
                                                        }
                                                    >
                                                        <div className="d-flex">
                                                            <img
                                                                src={require(`./../../../../assets/images/${t.tokenIcon}`)}
                                                                className="mr-2"
                                                                alt=""
                                                                width="21"
                                                                height="21"
                                                            />
                                                            {t.tokenSymbol}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            {/* 
              {gasPrices && (
                <div className="lending-modal-section price-slider-section pt-3 pb-2 mb-3">
                  <div className="d-flex justify-content-between py-2">
                    <span className="slider-breakpoint">
                      {gasPrices.standard} GWEI
                    </span>
                    <span className="slider-breakpoint">
                      {gasPrices.fast} GWEI
                    </span>
                    <span className="slider-breakpoint">
                      {gasPrices.instant} GWEI
                    </span>
                    <Form.Control
                      type="text"
                      placeholder="0 GWEI"
                      value={customGasPrice}
                      onChange={(e) => {
                        setCustomGasPrice(e.target.value);
                      }}
                      onFocus={() => setSelectedGasPrice(3)}
                      className="slider-breakpoint-custom shadow-none"
                    />
                  </div>
                  <Slider
                    value={selectedGasPrice}
                    step={1}
                    min={0}
                    max={3}
                    marks={marks}
                    onChange={(e, v) => {
                      setSelectedGasPrice(v);
                    }}
                  />
                </div>
              )} */}

                            <div className="lending-modal-section mb-3 bg-transparent">
                                <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-center protocol-step-deposit">
                                    <Button
                                        className="percentage-btn "
                                        onClick={(event) => {
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[0]
                                                .classList.toggle('active')
                                            calPer(25)
                                        }}
                                        onBlur={() =>
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[0]
                                                .classList.remove('active')
                                        }
                                    >
                                        {' '}
                                        25%
                                    </Button>
                                    <Button
                                        className="percentage-btn "
                                        onClick={() => {
                                            calPer(50)
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[1]
                                                .classList.toggle('active')
                                        }}
                                        onBlur={() =>
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[1]
                                                .classList.remove('active')
                                        }
                                    >
                                        {' '}
                                        50%
                                    </Button>
                                    <Button
                                        className="percentage-btn "
                                        onClick={() => {
                                            calPer(75)
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[2]
                                                .classList.toggle('active')
                                        }}
                                        onBlur={() =>
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[2]
                                                .classList.remove('active')
                                        }
                                    >
                                        {' '}
                                        75%
                                    </Button>
                                    <Button
                                        className="percentage-btn "
                                        onClick={() => {
                                            calPer(100)
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[3]
                                                .classList.toggle('active')
                                        }}
                                        onBlur={() =>
                                            document
                                                .getElementsByClassName(
                                                    'percentage-btn'
                                                )[3]
                                                .classList.remove('active')
                                        }
                                    >
                                        {' '}
                                        100%
                                    </Button>
                                </div>
                            </div>

                            <Button
                                className="lending-modal-btn w-100 mb-3"
                                disabled={isDataValid()}
                                onClick={onSubmitFirstStep}
                            >
                                {showLendingModal === 'deposit'
                                    ? 'Continue'
                                    : 'Collect'}
                            </Button>
                        </>
                    )}
                </>
            )}
            {step === 2 && (
                <>
                    <div className="d-flex justify-content-center mt-5 mb-5 pb-5">
                        <img
                            src={require(`./../../../../assets/images/crypto/${protocol.protocolKey}_lending.png`)}
                            alt={protocol.protocolKey}
                            className="position-relative"
                            width="75"
                            height="75"
                            style={{ left: '8px' }}
                        />
                        <img
                            src={require(`./../../../../assets/images/${
                                token.tokenIcon ? token.tokenIcon : null
                            }`)}
                            alt={token.tokenSymbol}
                            className="position-relative"
                            width="75"
                            height="75"
                            style={{ right: '8px' }}
                        />
                    </div>
                    <div className="lending-modal-transaction-status text-center pb-5">
                        {protocol.protocolName} needs permission to use your{' '}
                        {token.tokenSymbol}
                        {token.asset && token.asset}
                    </div>

                    <Button
                        className="lending-modal-btn w-100 mb-3"
                        onClick={getApproveTransaction}
                    >
                        Approve my {token.tokenSymbol && token.tokenSymbol}
                        {token.asset && token.asset}
                    </Button>
                </>
            )}
            {step === 3 && (
                <>
                    <div className="py-5 my-5 mx-auto">
                        {' '}
                        <Spinner
                            animation="border"
                            className="lending-modal-loader"
                        />
                    </div>
                    <div className="lending-modal-transaction-status secondary text-center pb-5">
                        Your tokens are being approved
                    </div>

                    {transactionHash && (
                        <div
                            className="pointer lending-modal-link text-center mt-auto"
                            onClick={openEtherscanLink}
                        >
                            View etherscan
                        </div>
                    )}
                </>
            )}
            {step === 4 && (
                <>
                    <>
                        <div className="d-flex justify-content-center mt-5 mb-5 pb-5">
                            <img
                                src={require(`./../../../../assets/images/${protocol.protocolIcon}`)}
                                alt={protocol.protocolKey}
                                className="position-relative"
                                width="75"
                                height="75"
                                style={{ left: '8px' }}
                            />
                            <img
                                src={require(`./../../../../assets/images/${token.tokenIcon}`)}
                                alt={token.tokenSymbol}
                                className="position-relative"
                                width="75"
                                height="75"
                                style={{ right: '8px' }}
                            />
                        </div>
                        <div className="lending-modal-transaction-status text-center pb-4">
                            {amount} {token.tokenSymbol} approved
                        </div>

                        <div className="lendiing-modal-action-text text-center mb-3">
                            {`Click on "${
                                showLendingModal === 'deposit'
                                    ? 'Lend'
                                    : 'Withdraw'
                            }" to deposit your tokens`}
                        </div>

                        <Button
                            className="lending-modal-btn w-100 mb-3"
                            onClick={getDepositTransaction}
                        >
                            {showLendingModal === 'deposit'
                                ? 'Lend'
                                : 'Withdraw'}
                        </Button>
                    </>
                </>
            )}
            {step === 5 && (
                <>
                    <div className="py-5 my-5 mx-auto">
                        {' '}
                        <Spinner
                            animation="border"
                            className="lending-modal-loader"
                        />
                    </div>
                    <div className="lending-modal-transaction-status secondary text-center pb-5">
                        Your transaction is being processed
                    </div>
                    {transactionHash && (
                        <div
                            className="lending-modal-link text-center mt-auto"
                            onClick={openEtherscanLink}
                        >
                            View etherscan
                        </div>
                    )}
                </>
            )}
            {step === 6 && (
                <>
                    <div className="d-flex flex-wrap justify-content-around mt-5 mb-3 pt-4">
                        <img
                            src={require(`./../../../../assets/images/${
                                showLendingModal === 'withdraw'
                                    ? protocol.protocolIcon
                                    : token.tokenIcon
                            }`)}
                            alt={protocol.protocolKey}
                            width="75"
                            height="75"
                        />
                        <img src={ArrowForward} alt="->" />
                        <img
                            src={require(`./../../../../assets/images/${
                                showLendingModal === 'deposit'
                                    ? protocol.protocolIcon
                                    : token.tokenIcon
                            }`)}
                            alt={protocol.protocolKey}
                            width="75"
                            height="75"
                        />
                    </div>
                    <div className="d-flex flex-wrap justify-content-around mb-5 pb-1">
                        <p className="transaction-protocol-token mb-0">
                            {showLendingModal === 'deposit'
                                ? token.tokenSymbol
                                : protocol.protocolName}
                        </p>
                        <div></div>
                        <p className="transaction-protocol-token mb-0">
                            {showLendingModal === 'deposit'
                                ? protocol.protocolName
                                : token.tokenSymbol}
                        </p>
                    </div>
                    <div className="lending-modal-transaction-status text-center pb-4">
                        <span className="green">{amount}</span>{' '}
                        {token.tokenSymbol} has been transferred{' '}
                        {showLendingModal === 'deposit' ? 'into' : 'out of'}{' '}
                        {protocol.protocolName}
                    </div>

                    <Button
                        className="lending-modal-btn w-100 mb-3"
                        onClick={goToDashboard}
                    >
                        Go to Dashboard
                    </Button>
                </>
            )}
        </>
    )
}

export default Deposit
