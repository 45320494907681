/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import './analytics.scss'
import { Button } from 'react-bootstrap'
import AnalyticsChart from 'app/shared/components/chart/AnalyticsChart'
import { gql, useApolloClient } from '@apollo/client'
import { authContext } from './../../utils/context/AuthContext'
import Table from 'app/shared/components/table/table'
import loader from './../../assets/images/project/loader.png'
import loaderImg from './../../assets/images/project/loader.png'
import { render } from '@testing-library/react'

const GET_TOKENS = gql`
    query getTokens {
        getTokens {
            tokenSymbol
            tokenId
            tokenDecimals
            tokenName
            tokenIcon
            tokenType
            tokenAddress
        }
    }
`

const GET_PROTOCOLS = gql`
    query getProtocols($chainId: Int) {
        getProtocols(chainId: $chainId) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            protocolRatesKey
            protocolInvestContract
            protocolRegistryContract
        }
    }
`

const GET_ALL_PROTOCOL_RATES = gql`
    query getAllProtocolRates {
        getAllProtocolRates {
            data {
                tokenRates {
                    asset
                    borrowing
                    lending
                    icon
                    tokenId
                }
                icon
                protocol
                protocolId
            }
            status
            message
        }
    }
`

const GET_TRANSACTIONS_BY_TYPE = gql`
    query getTransactionByType(
        $transactionType: String!
        $chainId: Int!
        $pageNo: Int
        $pageSize: Int
    ) {
        getTransactionByType(
            transactionType: $transactionType
            chainId: $chainId
            pageNo: $pageNo
            pageSize: $pageSize
        ) {
            transactions {
                amount
                transactionId
                createdAt
                transactionId
                targetAddress
                transactionHash
                transactionType
                transactionStatus
                protocolId {
                    protocolId
                    protocolKey
                }
                tokenId {
                    tokenId
                    tokenSymbol
                }
            }
            count
        }
    }
`

const GET_TRANSACTIONS_BY_PROTOCOL = gql`
    query getTransactionByProtocol(
        $protocolId: Int!
        $chainId: Int!
        $pageNo: Int
        $pageSize: Int
    ) {
        getTransactionByProtocol(
            protocolId: $protocolId
            chainId: $chainId
            pageNo: $pageNo
            pageSize: $pageSize
        ) {
            transactions {
                amount
                transactionId
                createdAt
                transactionId
                targetAddress
                transactionHash
                transactionType
                transactionStatus
                protocolId {
                    protocolId
                    protocolKey
                }
                tokenId {
                    tokenId
                    tokenSymbol
                }
            }
            count
        }
    }
`

const GET_PORTFOLIO_INFO = gql`
    query getPortfolioInfo($tokenId: Int!, $chainId: Int!) {
        getPortfolioInfo(tokenId: $tokenId, chainId: $chainId) {
            tokenStats {
                interestEarned
                amountDeposited
            }
            totalAndFee {
                totalInterest
                totalWithdraw
                avgInterest
                amountDeposited
                feeBurned
                feeRevenue
                feeCalculated
            }
            deposits {
                amount
                transactionId
                createdAt
                transactionId
                targetAddress
                transactionHash
                transactionType
                transactionStatus
                protocolId {
                    protocolId
                    protocolKey
                }
                tokenId {
                    tokenId
                    tokenSymbol
                }
            }
            withdrawals {
                amount
                transactionId
                createdAt
                transactionId
                targetAddress
                transactionHash
                transactionType
                transactionStatus
                protocolId {
                    protocolId
                    protocolKey
                }
                tokenId {
                    tokenId
                    tokenSymbol
                }
            }
            lending {
                amount
                protocol
                percentage
            }
        }
    }
`

interface IProps {}
const Analytics: React.FC<IProps> = () => {
    const client = useApolloClient()
    const [chartSelectedFilter, setChartSelectedFilter] = useState('1d')
    const [tokenArray, setTokenArray] = useState<any>([])
    const [selectedToken, setSelectedToken] = useState<any>({})
    const [tokenRatesArray, setTokenRatesArray] = useState<any>([])
    const [portfolioInfo, setPortfolioInfo] = useState<any>({})

    // depsoit table state
    const [depositData, setDepositData] = useState<any>([])
    const [depositCount, setDepositCount] = useState<number>(0)
    const [depositLoading, setDepositLoading] = useState(true)
    const [withdrawLoading, setWithdrawLoading] = useState(true)
    // withdraw table state
    const [withdrawData, setWithdrawData] = useState<any>([])
    const [withdrawCount, setWithdrawCount] = useState<number>(0)
    let pageNo = useRef(0)
    // protocols
    const [protocols, setProtocols] = React.useState<any>([])
    const [selectedProtocol, setSelectedProtocol] = React.useState<any>({})
    // tokens graph data
    const [activitiesList, setActivitiesList] = React.useState<any>([])
    const [tokensGraphActivities, setTokensGraphActivities] =
        React.useState<any>([])
    const [tokensGraphMetaData, setTokensGraphMetaData] = React.useState<any>(
        []
    )
    const [landingData, setLandingData] = useState<any>([])

    // Context store
    const { auth: user, setAuthStatus } = React.useContext(authContext)

    // fetch all tokens
    useEffect(() => {
        getTokens()
    }, [client])

    /**
     * getTokens api
     */
    const getTokens = () => {
        client
            .query({
                query: GET_TOKENS,
            })
            .then(({ data }: any) => {
                if (data) {
                    setTokenArray(data.getTokens)
                    setSelectedToken(data.getTokens[0])
                }
            })
    }

    /**
     * Get list of all protocols
     */
    const getProtocolsList = () => {
        client
            .query({
                query: GET_PROTOCOLS,
                variables: { chainId: user.chainId },
            })
            .then(({ data }: any) => {
                if (data.getProtocols.length > 0) {
                    setSelectedProtocol(data.getProtocols[0])
                    if (user.protocol && user.protocol.protocolId) {
                        setProtocols([...data.getProtocols])
                    } else {
                        user['protocol'] = data.getProtocols[0]
                        setAuthStatus(user)
                        setProtocols([...data.getProtocols])
                    }
                }
            })
    }

    //Comparing based on the property qty
    function compare_percentage(a, b) {
        // a should come before b in the sorted order
        if (a.percentage > b.percentage) {
            return -1
            // a should come after b in the sorted order
        } else if (a.percentage < b.percentage) {
            return 1
            // a and b are the same
        } else {
            return 0
        }
    }
    /**
     * getPortFolioInfo api
     */
    const getPortfolioInfo = (tokenId: number) => {
        client
            .query({
                query: GET_PORTFOLIO_INFO,
                variables: { chainId: user.chainId, tokenId },
            })
            .then(({ data }: any) => {
                setPortfolioInfo(data.getPortfolioInfo)
                if (data.getPortfolioInfo.lending) {
                    let array =
                        data.getPortfolioInfo.lending.sort(compare_percentage)
                    if (array) {
                        var newArray = []
                        for (var i = array.length - 1; i >= 0; i--) {
                            newArray.push(array[i])
                        }
                        var newArray2 = []
                        for (var i = array.length - 1; i >= 0; i--) {
                            newArray2.push(newArray[i])
                        }
                        if (newArray2) {
                            setLandingData(newArray2)
                        }
                    }
                }
            })
    }
    const [selectedTokenName, setSelectedTokenName] = useState('DAI')
    /**
     * getTransactionByType Call
     */
    const getTransactionsByType = (
        transactionType: string,
        pageNo: number,
        pageSize: number
    ) => {
        setDepositLoading(false)
        setWithdrawLoading(false)
        let array = []
        depositData.length = 0
        withdrawData.length = 0
        setDepositData(depositData)
        setWithdrawData(withdrawData)
        client
            .query({
                query: GET_TRANSACTIONS_BY_TYPE,
                variables: {
                    transactionType,
                    chainId: user.chainId,
                    pageNo,
                    pageSize,
                },
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                const tempArray: any = []
                data.getTransactionByType.transactions.map(
                    (transaction: any) => {
                        transaction['createdAt'] = timeConverter(
                            transaction.createdAt,
                            0
                        )
                        tempArray.push({ show: transaction })
                    }
                )
                var count = 0
                var withdrawCount = 0
                if (transactionType === 'Deposit') {
                    data.getTransactionByType.transactions.map(
                        (transaction: any) => {
                            if (
                                transaction.transactionStatus === 'confirmed' &&
                                transaction.protocolId !== null &&
                                transaction.tokenId !== null &&
                                transaction.tokenId.tokenSymbol ===
                                    selectedTokenName
                            ) {
                                count++
                                depositData.push(transaction)
                                setDepositData(depositData)
                            }
                        }
                    )
                    setDepositCount(count)
                    // setDepositData([...tempArray])
                    setDepositLoading(true)
                    setWithdrawLoading(true)
                } else {
                    data.getTransactionByType.transactions.map(
                        (transaction: any) => {
                            if (
                                transaction.transactionStatus === 'confirmed' &&
                                transaction.protocolId !== null &&
                                transaction.tokenId !== null &&
                                transaction.tokenId.tokenSymbol ===
                                    selectedTokenName
                            ) {
                                withdrawCount++
                                withdrawData.push(transaction)
                                setWithdrawData(withdrawData)
                            }
                        }
                    )
                    setWithdrawCount(withdrawCount)
                    setDepositLoading(true)
                    setWithdrawLoading(true)
                }
            })
            .catch(({ error }) => {
                setDepositLoading(true)
                setWithdrawLoading(true)
            })
    }

    /**
     * getTransactionsByProtocol Call
     */
    const getTransactionsByProtocol = (
        protocolId: number,
        pageNo?: number,
        pageSize?: number
    ) => {
        client
            .query({
                query: GET_TRANSACTIONS_BY_PROTOCOL,
                variables: {
                    protocolId,
                    chainId: user.chainId,
                    pageNo: pageNo && pageNo,
                    pageSize: pageSize && pageSize,
                },
            })
            .then(({ data }: any) => {
                setActivitiesList(data.getTransactionByProtocol.transactions)
            })
    }

    useEffect(() => {
        if (selectedProtocol && selectedProtocol.protocolId) {
            getTransactionsByProtocol(selectedProtocol.protocolId)
        }
    }, [selectedProtocol])

    // change data on selected token change
    useEffect(() => {
        if (selectedToken && selectedToken.tokenId) {
            client
                .query({
                    query: GET_ALL_PROTOCOL_RATES,
                })
                .then(({ data }: any) => {
                    const ratesObj = data.getAllProtocolRates.data
                    const protocolsLength: number = ratesObj
                        ? parseInt(ratesObj.length)
                        : 0
                    const tokensLength: number =
                        ratesObj && ratesObj[0]
                            ? ratesObj[0].tokenRates.length
                            : 0
                    const protocolsList: any = []
                    // debugger;
                    let tokensRatesList: any = []
                    for (let i = 0; i < protocolsLength; i++) {
                        protocolsList.push({
                            protocol: ratesObj[i].protocol,
                            icon: ratesObj[i].icon,
                            protocolId: ratesObj[i].protocolId,
                        })
                        const rateObj = []
                        if (i < tokensLength) {
                            for (let j = 0; j < protocolsLength; j++) {
                                if (j < protocolsLength) {
                                    rateObj.push(ratesObj[j].tokenRates[i])
                                    rateObj[j]['protocolId'] =
                                        ratesObj[j].protocolId
                                    rateObj[j]['protocolName'] =
                                        ratesObj[j].protocol
                                } else {
                                    rateObj.push(ratesObj[i].tokenRates[j])
                                }
                            }
                            tokensRatesList.push(rateObj)
                        }
                    }
                    tokensRatesList.map((tokenRate: any) => {
                        if (
                            tokenRate[0].tokenId ===
                            parseInt(selectedToken.tokenId)
                        ) {
                            setTokenRatesArray(tokenRate)
                        }
                    })
                })
            // call other onMount api's here
            getPortfolioInfo(parseInt(selectedToken.tokenId))
            getTransactionsByType('Deposit', 0, 500)
            getTransactionsByType('Withdraw', 0, 500)
            getProtocolsList()
        }
    }, [selectedToken])

    /**
     * Time converter timestamp => date time
     */
    const timeConverter = (UNIX_timestamp: any, flag: number) => {
        let a = new Date(UNIX_timestamp * 1)
        let months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ]
        let days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ]
        let year = a.getFullYear()
        let month = months[a.getMonth()]
        let monthNumber = a.getMonth()
        let date = a.getDate()
        let day = days[a.getDay()]
        let hour = a.getHours()
        let min = a.getMinutes()
        let sec = a.getSeconds()
        let gmt = a.getTimezoneOffset()
        let time = ''
        if (flag === 0) {
            time = date + '/' + monthNumber + ' ' + hour + ':' + min
        } else {
            time =
                date +
                '-' +
                monthNumber +
                '-' +
                year +
                ' ' +
                hour +
                ':' +
                min +
                ':' +
                sec
        }

        return time
    }

    /**
     * getActivities for graph
     */
    useEffect(() => {
        if (activitiesList) {
            const balanceList: any = {}
            const graphData: any = []
            let graphTempData: any = {}
            tokenArray.map((token: any) => {
                if (selectedToken.tokenId === token.tokenId) {
                    balanceList[token.tokenSymbol] = 0
                }
            })
            const reverse: any[] = [...activitiesList].reverse()
            reverse.map((activity: any) => {
                if (activity.tokenId && activity.tokenId.tokenId) {
                    if (activity.transactionType === 'deposit') {
                        balanceList[activity.tokenId.tokenSymbol] =
                            balanceList[activity.tokenId.tokenSymbol] +
                            parseFloat(activity.amount)
                    } else if (activity.transactionType === 'withdraw') {
                        balanceList[activity.tokenId.tokenSymbol] =
                            balanceList[activity.tokenId.tokenSymbol] -
                            parseFloat(activity.amount)
                    }
                    graphTempData = {
                        date: timeConverter(activity.createdAt, 1),
                        ...balanceList,
                    }
                    graphData.push(graphTempData)
                }
            })
            /**
             * Once graph data is done create meta data for graph
             */
            const graphMetaData: any = []
            const tokensKeyList = Object.keys(balanceList)
            // if (chartSelectedProtocol === -1) {

            tokensKeyList.map((key: any) => {
                graphMetaData.push({
                    dataKey: key,
                    strokeName: key,
                    strokeWidth: 5,
                    strokeOpacity: 1,
                })
            })
            // }
            setTokensGraphActivities(graphData)
            setTokensGraphMetaData(graphMetaData)
        }
    }, [activitiesList, selectedToken])

    // graph filter list
    const graphFilterList = [
        {
            name: '1D',
            key: '1d',
        },
        {
            name: '5D',
            key: '5d',
        },
        {
            name: '1M',
            key: '1m',
        },
        {
            name: '6M',
            key: '6m',
        },
        {
            name: 'YTD',
            key: 'ytd',
        },
        {
            name: 'MAX',
            key: 'max',
        },
    ]

    /**
     * deposit withdraw table meta-data
     */
    const columns = React.useMemo(
        () => [
            {
                Header: 'h',
                columns: [
                    {
                        Header: 'Transaction Type',
                        accessor: 'show.transactionType',
                        width: 100,
                    },
                    {
                        Header: 'Status',
                        accessor: 'show.transactionStatus',
                        width: 100,
                    },
                    {
                        Header: 'Date/Time',
                        accessor: 'show.createdAt',
                        width: 100,
                    },
                    {
                        Header: 'Amount',
                        accessor: 'show.amount',
                        width: 100,
                    },
                ],
            },
        ],
        []
    )
    const renderDepositData = () => {
        return depositData.map((element: any, index: number) => {
            return (
                <div className="d-flex bd-highlight">
                    <div className="py-2 pr-2 width-sec flex-fill bd-highlight">
                        {element.createdAt
                            ? element.createdAt.slice(0, 11)
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        {element.protocolId
                            ? element.protocolId.protocolKey
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        {element.tokenId.tokenSymbol
                            ? element.tokenId.tokenSymbol
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        ${element.amount.toFixed(4)}
                    </div>
                </div>
            )
        })
    }
    const renderWithdrawData = () => {
        return withdrawData.map((element: any, index: number) => {
            return (
                <div className="d-flex bd-highlight">
                    <div className="py-2 pr-2 width-sec flex-fill bd-highlight">
                        {element.createdAt
                            ? element.createdAt.slice(0, 11)
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        {element.protocolId
                            ? element.protocolId.protocolKey
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        {element.tokenId.tokenSymbol
                            ? element.tokenId.tokenSymbol
                            : 'Null'}
                    </div>
                    <div className="p-2 width-sec flex-fill bd-highlight">
                        ${element.amount.toFixed(4)}
                    </div>
                </div>
            )
        })
    }
    const [tokenIndex, setTokenIndex] = useState(0)
    return (
        <div>
            <div className="row d-flex justify-content-center w-100 mb-1">
                <div className="col-12 d-flex justify-content-center">
                    <Button className="btn-port center pointer">
                        <span className="d-flex px-2 py-1 align-items-center">
                            <text className="text-font">PORTFOLIO</text>
                        </span>
                    </Button>
                </div>
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
                <div className="d-flex justify-content-end col-lg-8 col-md-10 col-sm-12 p-3">
                    {tokenArray &&
                        tokenArray.map((token: any, i: number) => {
                            return (
                                <div
                                    className={
                                        i === 0
                                            ? 'selector-div-left'
                                            : 'selector-div'
                                    }
                                    onClick={() => {
                                        setSelectedToken(token)
                                        setSelectedTokenName(token.tokenSymbol)
                                        if (token.tokenSymbol === 'DAI') {
                                            setTokenIndex(0)
                                        } else {
                                            setTokenIndex(1)
                                        }
                                    }}
                                >
                                    <text
                                        className={
                                            selectedToken.tokenId ===
                                            token.tokenId
                                                ? 'selector-text-active'
                                                : 'selector-text'
                                        }
                                    >
                                        {token.tokenSymbol}
                                    </text>
                                </div>
                            )
                        })}
                </div>
                <div className="col-lg-8 col-md-10 col-sm-12 row top-div">
                    <div className="col-lg-5 col-md-12">
                        <text className="div-title-text">
                            ADD Token Statistics
                        </text>
                        {portfolioInfo &&
                            portfolioInfo.tokenStats &&
                            portfolioInfo.tokenStats && (
                                <div className="d-flex justify-content-between pb-4">
                                    <text className="key-style">
                                        Amount deposited
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.tokenStats.amountDeposited.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.tokenStats &&
                            portfolioInfo.tokenStats && (
                                <div className="d-flex justify-content-between pb-4">
                                    <text className="key-style">
                                        Interest earned
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.tokenStats.interestEarned.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                    </div>
                    <div className="col-lg-1 divider-div">
                        <div className="divider"></div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <text className="div-title-text">Oracle/APIs</text>
                        {tokenRatesArray &&
                            tokenRatesArray.map((tokenRate: any) => {
                                return (
                                    <div className="d-flex justify-content-between pb-4">
                                        <text className="key-style">
                                            $/{tokenRate.protocolName}
                                        </text>
                                        <text className="value-style">
                                            {tokenRate.lending &&
                                                tokenRate.lending.toFixed(
                                                    8
                                                )}{' '}
                                            {tokenRate.asset}
                                        </text>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div className="col-12 row justify-content-center align-items-center mt-5 pt-4 mb-5">
                    <Button className="btn-analytics">
                        <span className="analytics-btn-text">Lending</span>
                    </Button>
                    {/* <Button className="btn-analytics">
          <span className="analytics-btn-text">Insurance</span>
        </Button> */}
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 row justify-content-center mb-5">
                    <div className="col-lg-5 col-md-12 col-sm-12 top-div flex-column justify-content-start align-items-start mr-lg-2">
                        <text className="div-title-text">Lending</text>
                        <div className="d-flex flex-column align-items-center justify-content-center w-100">
                            {
                                landingData.length !== 0 ? (
                                    <div
                                        className="d-flex justify-content-center analytics-circles-div"
                                        style={{}}
                                    >
                                        <div className="big-blue-div">
                                            <text className="big-blue-title">
                                                {landingData[0].protocol}
                                            </text>
                                            <text className="big-blue-subtitle mt-1">
                                                $
                                                {landingData[0].amount.toFixed(
                                                    4
                                                )}
                                            </text>
                                            <text className="big-blue-subtitle mt-1">
                                                {landingData[0].percentage.toFixed(
                                                    2
                                                )}
                                                %
                                            </text>
                                        </div>
                                        <div
                                            className={
                                                '' +
                                                (landingData[1].amount === 0
                                                    ? 'medium-x-blue-div'
                                                    : 'medium-blue-div')
                                            }
                                        >
                                            <text
                                                className={
                                                    '' +
                                                    (landingData[1].amount === 0
                                                        ? 'medium-x-blue-title'
                                                        : 'medium-blue-title')
                                                }
                                            >
                                                {landingData[1].protocol}
                                            </text>
                                            <text
                                                className={
                                                    'mt-1 ' +
                                                    (landingData[1].amount === 0
                                                        ? 'medium-x-blue-subtitle'
                                                        : 'medium-blue-subtitle')
                                                }
                                            >
                                                $
                                                {landingData[1].amount.toFixed(
                                                    4
                                                )}
                                            </text>
                                            <text
                                                className={
                                                    'mt-1 ' +
                                                    (landingData[1].amount === 0
                                                        ? 'medium-x-blue-subtitle'
                                                        : 'medium-blue-subtitle')
                                                }
                                            >
                                                {landingData[1].percentage.toFixed(
                                                    2
                                                )}
                                                %
                                            </text>
                                        </div>
                                        <div className="small-blue-div">
                                            <text className="small-blue-title">
                                                {landingData[2].protocol}
                                            </text>
                                            <text className="small-blue-subtitle mt-1">
                                                $
                                                {landingData[2].amount.toFixed(
                                                    4
                                                )}
                                            </text>
                                            <text className="small-blue-subtitle mt-1">
                                                {landingData[2].percentage.toFixed(
                                                    2
                                                )}
                                                %
                                            </text>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )
                                // <div className="loader" style={{ float: "right", marginLeft: "87px" }}>
                                //   <img src={loaderImg} alt="loader" style={{ width: "22px", float: "right" }} />
                                // </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 top-div flex-column align-items-start ml-lg-2">
                        <text className="div-title-text">Balance</text>
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">
                                        Amount deposited
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.amountDeposited.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">
                                        Total interests
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.totalInterest.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">
                                        Amount Withdrawals
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.totalWithdraw.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">
                                        Average int. %
                                    </text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.avgInterest.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}

                        <text className="div-title-text mt-5">Fees</text>
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">Collected</text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.feeCalculated.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">Revenue</text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.feeRevenue.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                        {portfolioInfo &&
                            portfolioInfo.totalAndFee &&
                            portfolioInfo.totalAndFee && (
                                <div className="d-flex justify-content-between pb-4 w-100">
                                    <text className="key-style">Burned</text>
                                    <text className="value-style">
                                        {portfolioInfo.totalAndFee.feeBurned.toFixed(
                                            8
                                        )}{' '}
                                        {selectedToken.tokenSymbol}
                                    </text>
                                </div>
                            )}
                    </div>
                </div>
                {/**
                 *Deposit Withdraw tables
                 */}
                <div className="col-lg-10 col-md-10 col-sm-12  row justify-content-center mb-5">
                    <div className="col-lg-5 col-md-12 col-sm-12 top-div-table flex-column align-items-start mr-lg-2 pb-5">
                        <div className="row d-flex justify-content-center ml-2 w-100">
                            <div className="col-12">
                                <text className="div-title-text">Deposits</text>
                            </div>
                            <div className="pb-2 col-12">
                                <p className="div-p-txt">
                                    Total Records:{' '}
                                    <span>
                                        {depositCount ? depositCount : '0'}
                                    </span>
                                </p>
                            </div>
                            <div className="col-12 pr-0">
                                {depositLoading ? (
                                    depositData.length === 0 ? (
                                        'No Data'
                                    ) : (
                                        <div className="tableBodyScroll ">
                                            {renderDepositData()}
                                        </div>
                                    )
                                ) : (
                                    <div className="m-5">
                                        <div className="col-2 d-flex justify-content-center align-items-center mt-3">
                                            <div className="loader">
                                                <img
                                                    src={loader}
                                                    alt="loader"
                                                    style={{ width: '50px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 top-div-table flex-column align-items-start ml-lg-2 pb-5">
                        <div className="row d-flex justify-content-center w-100">
                            <div className="col-12">
                                <text className="div-title-text">
                                    Withdrawals
                                </text>
                            </div>
                            <div className="pb-2 col-12">
                                <p className="div-p-txt">
                                    Total Records:{' '}
                                    <span>
                                        {withdrawCount ? withdrawCount : '0'}
                                    </span>
                                </p>
                            </div>
                            <div className="col-12 pr-0 div-p-txt">
                                {withdrawLoading ? (
                                    withdrawData.length === 0 ? (
                                        'No Data'
                                    ) : (
                                        <div className="tableBodyScroll">
                                            {renderWithdrawData()}
                                        </div>
                                    )
                                ) : (
                                    <div className="m-5">
                                        <div className="col-2 d-flex justify-content-center align-items-center mt-3">
                                            <div className="loader">
                                                <img
                                                    src={loader}
                                                    alt="loader"
                                                    style={{ width: '50px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 row justify-content-center align-items-center mt-5 pt-4 mb-5">
                    {protocols &&
                        protocols.map((protocol: any) => {
                            return (
                                <Button
                                    className={
                                        'btn-analytics protocols-btn-width ' +
                                        (selectedProtocol === protocol
                                            ? 'select-border'
                                            : '')
                                    }
                                    onClick={() => {
                                        setSelectedProtocol(protocol)
                                    }}
                                >
                                    {protocol.protocolName && (
                                        <span className="analytics-btn-text">
                                            {protocol.protocolName}
                                        </span>
                                    )}
                                </Button>
                            )
                        })}
                </div>
                {/* 
        <div className="col-12 row justify-content-center align-items-center pt-4 mb-5">
          <div className="d-flex">
            <Button
              className="continue-btn-style"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "157.07px",
                height: "59.74px",
              }}
            >
              <span className="deposit-btn-text">Deposits</span>
            </Button>
            <Button
              className="continue-btn-style"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "157.07px",
                height: "59.74px",
              }}
            >
              <span className="deposit-btn-text">Withdrawals</span>
            </Button>
          </div>
        </div> */}

                <div className="col-12 col-lg-8 justify-content-center mb-5">
                    {/* Graph div */}
                    <div className="row graph-div">
                        <div className="col-12 d-flex justify-content-between graph-row-div mb-3">
                            <div className="d-flex">
                                {tokenArray &&
                                    tokenArray.map((token: any) => {
                                        return (
                                            <div className="d-flex align-items-center ml-3 justify-content-center">
                                                <div
                                                    style={{
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '20px',
                                                        background:
                                                            token &&
                                                            token.tokenSymbol ===
                                                                'DAI'
                                                                ? '#2000E3'
                                                                : '#FAFF00',
                                                    }}
                                                ></div>
                                                <p
                                                    className="deposit-btn-text pl-3"
                                                    style={{
                                                        fontSize: '16px',
                                                        marginBottom: 0,
                                                        color: 'rgba(255, 255, 255, 0.5)',
                                                    }}
                                                >
                                                    {token.tokenSymbol &&
                                                        token.tokenSymbol}
                                                </p>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className="d-flex">
                                {graphFilterList &&
                                    graphFilterList.map((filter) => {
                                        return (
                                            <div
                                                className={`${
                                                    filter.key ===
                                                    chartSelectedFilter
                                                        ? 'analytics-graph-filter-active'
                                                        : 'analytics-graph-filter '
                                                }`}
                                                onClick={() => {
                                                    setChartSelectedFilter(
                                                        filter.key
                                                    )
                                                }}
                                            >
                                                {filter.name}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center justify-content-lg-start mt-4">
                            <AnalyticsChart
                                graphData={tokensGraphActivities}
                                graphMetaData={tokensGraphMetaData}
                                selectedToken={tokenIndex}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-10 col-md-10 col-sm-12 row justify-content-center mb-5 pb-5">
          <div className="col-lg-5 col-md-12 col-sm-12 top-div flex-column justify-content-start align-items-start mr-lg-2">
            <div className="d-flex justify-content-center w-100 mt-3 mb-5">
              <text className="big-blue-subtitle">DAI</text>
            </div>
            <text className="div-title-text">Fundamentals</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Liquidity</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total deposit</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total Interest</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total withdrawals</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Average int. %</text>
              <text className="value-style">220.00000 USDC</text>
            </div>

            <text className="div-title-text mt-5">Fees</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Collected</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <text className="div-title-text mt-5" style={{ paddingBottom: 10 }}>
              Deposit Rate
          </text>
            <text className="div-title-text analytics-small-subtitle">
              Variable
          </text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Now</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">7D</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">30D</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <text className="div-title-text mt-5">Stable</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Now</text>
              <text className="value-style">DISABLED</text>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 top-div flex-column align-items-start ml-lg-2">
            <div className="d-flex justify-content-center w-100 mt-3 mb-5">
              <text className="big-blue-subtitle">USDC</text>
            </div>
            <text className="div-title-text">Fundamentals</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Liquidity</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total deposit</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total Interest</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Total withdrawals</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Average int. %</text>
              <text className="value-style">220.00000 USDC</text>
            </div>

            <text className="div-title-text mt-5">Fees</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Collected</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <text className="div-title-text mt-5" style={{ paddingBottom: 10 }}>
              Deposit Rate
          </text>
            <text className="analytics-small-subtitle">Variable</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Now</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">7D</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">30D</text>
              <text className="value-style">220.00000 USDC</text>
            </div>
            <text className="div-title-text mt-5">Stable</text>
            <div className="d-flex justify-content-between pb-4 w-100">
              <text className="key-style">Now</text>
              <text className="value-style">DISABLED</text>
            </div>
          </div>
        </div> */}
            </div>
        </div>
    )
}
export default Analytics
