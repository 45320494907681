import React from 'react'

interface IProps {
    isActive: string
}
const LendIcon: React.FC<IProps> = ({ isActive }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        stroke={isActive ? '#0094FF' : '#A1A3A5'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 13.2218H2.33333V5.44401H0V13.2218ZM3.88889 13.2218H6.22222V3.11068H3.88889V13.2218ZM7.77778 13.2218H10.1111V0.777344H7.77778V13.2218ZM11.6667 13.2218H14V1.16585H12.8333H11.6667V13.2218Z"
            stroke={isActive ? '#0094FF' : '#A1A3A5'}
        />
    </svg>
)

export default LendIcon
