import React from 'react'
import './CustomInput.scss'
import Form from 'react-bootstrap/Form'

interface IProps {
    label?: string
    secondaryLabel?: string
    type?: string
    placeholder?: string
    value?: any
    handleChange?: (value) => void
    validateNote?: (value) => void
}
const CustomInput: React.FC<IProps> = ({
    label,
    placeholder,
    type,
    value,
    secondaryLabel,
    handleChange,
    validateNote,
}) => {
    return (
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="d-flex justify-content-between">
                <Form.Label>{label}</Form.Label>
                <Form.Label className="text-blue">{secondaryLabel}</Form.Label>
            </div>

            <Form.Control
                type={type}
                placeholder={placeholder}
                onChange={(e) => {
                    handleChange(e.target.value)
                }}
                onBlur={(e) => {
                    validateNote(e.target.value)
                }}
            />
        </Form.Group>
    )
}

export default CustomInput
