import dotenv from 'dotenv'
dotenv.config()

export const config: any = {
    netId1: {
        TORNADO: {
            ETH: {
                instanceAddress: {
                    '0.1': '0x12D66f87A04A9E220743712cE6d9bB1B5616B8Fc',
                    '1': '0x47CE0C6eD5B0Ce3d3A51fdb1C52DC66a7c3c2936',
                    '10': '0x910Cbd523D972eb0a6f4cAe4618aD62622b39DbF',
                    '100': '0xA160cdAB225685dA1d56aa342Ad8841c3b53f291',
                },
                symbol: 'ETH',
                decimals: 18,
            },
            DAI: {
                instanceAddress: {
                    '100': '0xD4B88Df4D29F5CedD6857912842cff3b20C8Cfa3',
                    '1000': '0xFD8610d20aA15b7B2E3Be39B396a1bC3516c7144',
                    '10000': '0xF60dD140cFf0706bAE9Cd734Ac3ae76AD9eBC32A',
                    '100000': undefined,
                },
                tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
                symbol: 'DAI',
                decimals: 18,
            },
        },
        BLENDER: {
            ETH: {
                instanceAddress: {
                    '0.1': '0xd52DAd9812791C58ca8F854030787F0083272567',
                    '1': '0x6b4e172F9aDC01E81FAc31bcEbBD35C37c809123',
                    '10': '0x74E29BB4Eb8B393F0E804B926Cf12d8763e7b905',
                    '100': '0xC94b6041db701b419Dfd1a4Ea38b970Fc0b794E2',
                },
                symbol: 'ETH',
                decimals: 18,
            },
            DAI: {
                instanceAddress: {
                    '100': '0x29E749e563973D6EF8273772a8e8cE36e31d8999',
                    '1000': '0x8Dc0A561efa08383e83107D51b09fcd79FE2F611',
                    '10000': '0x9B605096aCaB27181F92124D0DB71cC1f8352F98',
                    '100000': undefined,
                },
                tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
                symbol: 'DAI',
                decimals: 18,
            },
        },
    },
    netId42: {
        TORNADO: {
            ETH: {
                instanceAddress: {
                    '0.1': '0x8b3f5393bA08c24cc7ff5A66a832562aAB7bC95f',
                    '1': '0xD6a6AC46d02253c938B96D12BE439F570227aE8E',
                    '10': '0xe1BE96331391E519471100c3c1528B66B8F4e5a7',
                    '100': '0xd037E0Ac98Dab2fCb7E296c69C6e52767Ae5414D',
                },
                symbol: 'ETH',
                decimals: 18,
            },
            DAI: {
                instanceAddress: {
                    '100': '0xdf2d3cC5F361CF95b3f62c4bB66deFe3FDE47e3D',
                    '1000': '0xD96291dFa35d180a71964D0894a1Ae54247C4ccD',
                    '10000': '0xb192794f72EA45e33C3DF6fe212B9c18f6F45AE3',
                    '100000': undefined,
                },
                tokenAddress: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
                symbol: 'DAI',
                decimals: 18,
            },
        },
        BLENDER: {
            ETH: {
                instanceAddress: {
                    '0.1': '0xA446c7edED22611AF4b87441b3401872e15BD62B',
                    '1': '0xbb65ce467b3F3D4695B6bF39207ECDf7AA4a17Ac',
                    '10': '0xe1BE96331391E519471100c3c1528B66B8F4e5a7',
                    '100': '0xd037E0Ac98Dab2fCb7E296c69C6e52767Ae5414D',
                },
                symbol: 'ETH',
                decimals: 18,
            },
            DAI: {
                instanceAddress: {
                    '100': '0x66ae0B80cb7c5b68E314AAA5dae7ca192B07ECBe',
                    '1000': '0x3af14B591213fb7EDE8C1251248b1CF96930970c',
                    '10000': '0xb192794f72EA45e33C3DF6fe212B9c18f6F45AE3',
                    '100000': undefined,
                },
                tokenAddress: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
                symbol: 'DAI',
                decimals: 18,
            },
        },
    },
}
