import React, { useRef, useState } from 'react'

import ProductImage from './../../assets/images/project/home_product_img.png'
import Security from './../../assets/images/project/security.svg'
import Privacy from './../../assets/images/project/privacy.svg'
import SmartContractAudits from './../../assets/images/project/smart_contract_audits.svg'
import Automation from './../../assets/images/project/automation.svg'
import PrivateDashboard from './../../assets/images/project/private_dashboard.svg'
import UserFriendly from './../../assets/images/project/user_friendly.svg'

// Partners
import Aave from './../../assets/images/project/Aave.svg'
import PartnersDydx from './../../assets/images/project/partners_dydx.svg'
import PeckShield from './../../assets/images/project/partners_peckshield.svg'
import Ankr from './../../assets/images/project/Ankr.svg'
import Fulcrum from './../../assets/images/home/fulcrum.svg'

import Metamask from './../../assets/images/home/metamask.svg'
import YearnFinance from './../../assets/images/home/yearnfinance.svg'
import Sentinel from './../../assets/images/home/sentinel.svg'
import MTN from './../../assets/images/home/mtn.svg'
import CompoundIcon from './../../assets/images/home/compound.svg'
import FormaticIcon from './../../assets/images/home/formatic.svg'
import CurveIcon from './../../assets/images/home/Curve.svg'
import NIB from './../../assets/images/home/nib.svg'
import DIA from './../../assets/images/home/dia.svg'
import Fintech from '../../assets/images/home/fintech.svg'
import Forbes from '../../assets/images/home/forbes.svg'
import Publish from '../../assets/images/home/publish.svg'
import BusinessInsider from '../../assets/images/home/businessInsider.svg'
import Cityam from '../../assets/images/home/cityam.svg'
import HackerNoon from '../../assets/images/home/hackernoon.svg'
import Nabais from '../../assets/images/home/nabaiscapital.svg'
import TouchLight from '../../assets/images/home/touchlight.svg'
import SmcCapital from '../../assets/images/home/smc_capital.svg'
import Sammantis from '../../assets/images/home/sammantis.svg'
import GoldBaeum from '../../assets/images/home/goldbaeum.svg'
import Bitcoin from '../../assets/images/home/bitcoin.svg'
import NGC from '../../assets/images/home/ngc.svg'
import ChangellyIcon from '../../assets/images/home/changelly.svg'
import Earn from './../../assets/images/home/earn.svg'
import OneStep from './../../assets/images/home/oneStep.png'
import VectorSvg from './../../assets/images/home/Vector.svg'
import AddToken from '../../assets/images/home/add_token.svg'
import Staking from '../../assets/images/home/staking.svg'
import Medium from '../../assets/images/home/medium.svg'
import Twitter from '../../assets/images/home/twitter.svg'
import Uniswap from '../../assets/images/home/uniswap.svg'
import Poloniex from '../../assets/images/home/poloniex.svg'
import YahooFinance from '../../assets/images/home/yahoo_finance.svg'

// import formatic_logo from './../../assets/images/project/formatic_logo.svg';
import Hoo from './../../assets/images/project/Hoo.svg'
// import sentinel_logo from './../../assets/images/project/sentinel_logo.svg';
// import openGraph from './../../assets/images/project/openGraph.svg';
// import inch_exchange from './../../assets/images/project/inch_exchange.svg';
// import ankr from './../../assets/images/project/ankr.svg';
// import poloniex from './../../assets/images/project/poloniex.svg';
// import KuCoin from './../../assets/images/project/KuCoin.svg';
// import mxc from './../../assets/images/project/mxc.svg';
// import Dia from './../../assets/images/project/Dia.svg';

import Polonix from './../../assets/images/project/Polonix.svg'
// import PeckShield from './../../assets/images/project/peckShield.svg';
import OneInch from './../../assets/images/project/one-Inch.svg'
import Formatic from './../../assets/images/project/Formatic.svg'
import Bibox from './../../assets/images/project/Bibox.svg'
import coin from './../../assets/images/project/CoinW.svg'
import uniswap from './../../assets/images/project/Uniswap.svg'
import Bilaxy from './../../assets/images/project/Bilaxy.svg'
import Changelly from './../../assets/images/project/Changelly.svg'
import Compound from './../../assets/images/project/Compound.svg'
import Curve from './../../assets/images/project/Curve.svg'
// import Aave from './../../assets/images/project/Aave.svg';
import BZX from './../../assets/images/project/BZX.svg'
import EightY from './../../assets/images/project/8Y.svg'
import YFI from './../../assets/images/project/yfi.svg'
import DiaData from './../../assets/images/project/DiaData.svg'

// Integrations
// import dYdX from './../../assets/images/project/dYdX.svg';
// import compound_logo from './../../assets/images/project/compound_logo.svg';
// import ave_logo from './../../assets/images/project/ave_logo.svg';
// import USDC from './../../assets/images/project/USDC.svg';
// import random from './../../assets/images/project/random.svg';
// import bZx from './../../assets/images/project/bZx.svg';

import banner from './../../assets/images/project/landing_banner.png'
import triangle1 from './../../assets/images/project/triangle_1.svg'
import triangle2 from './../../assets/images/project/triangle_2.svg'
import circle from './../../assets/images/project/circle.svg'
import square from './../../assets/images/project/square.svg'
import square2 from './../../assets/images/project/square_2.svg'

import './home.scss'
import ModalComponent from './../../shared/components/Modal/ModalComponent'
import OurProducts from './sections/OurProducts/OurProducts'
import HomeFooter from './sections/footer/HomeFooter'
import WhoWeAre from './sections/WhoWeAre/WhoWeAre'
import SubscribeEmail from './sections/SubscribeEmail'
import PltUtilities from './sections/PltUtilities/PltUtilities'

import { authContext } from './../../utils/context/AuthContext'
import { useHistory } from 'react-router-dom'
import { Button, Carousel, CarouselItem } from 'react-bootstrap'

interface IProps {}
const Home: React.FC<IProps> = () => {
    /**
     * openModal cases
     * === -1 // don't open
     * === 0 // open wallets modal
     */
    const [openModal, setOpenModal] = useState(-1)
    // history obj to redirection
    const history = useHistory()
    // Context store
    const { auth: user } = React.useContext(authContext)
    let lendClicked = useRef(false)

    const renderConnectWalletButton = () => (
        <>
            {user.walletAddress ? (
                <button className="primary-btn">Wallet Connected</button>
            ) : (
                <button onClick={() => setOpenModal(0)} className="primary-btn">
                    Connect Wallet
                </button>
            )}
        </>
    )

    return (
        <div
            className="add-home-ui col-12 p-0 m-0"
            style={{ position: 'relative' }}
        >
            <div className="bg-image overflow-hidden position-relative">
                {/* <img src={triangle1} alt='' className='tri-1' />
        <img src={circle} alt='' className='circle-1' />
        <img src={square} alt='' className='square-1' />
        <img src={square2} alt='' className='square-2' />
        <img src={triangle2} alt='' className='tri-2' />
        <img src={circle} alt='' className='circle-2' />
        <img src={circle} alt='' className='circle-3' />
        <img src={square2} alt='' className='square-3' /> */}

                <p className="banner-main-text text-center pt-5 mt-5 mb-0">
                    Your Privacy Preserving DeFi Aggregator
                </p>

                <p className="banner-sub-text text-center pt-1 mt-2 pt-lg-1 mt-lg-4">
                    We are building a DeFi Aggregator with special attention to
                    your privacy, safety, and security. ADD.xyz will be your
                    one-stop platform to use all DeFi products with ease and
                    anonymity.
                </p>

                {renderConnectWalletButton()}

                {/* <button className="primary-btn coming-soon-btn">Coming Soon</button> */}

                <img
                    src={ProductImage}
                    alt="Product Image"
                    className="product-img img-fluid z-index-1 pt-2 mt-4 pt-lg-2 mt-lg-5"
                />
            </div>

            <div className="d-flex flex-column align-items-center why-add-section mx-auto">
                <p className="why-add-header mb-0">Why ADD?</p>
                <p className="why-add-sub-header mt-3 mt-lg-4 mb-0">
                    The Next Generation Aggregation with your Privacy Protect
                </p>

                <Carousel
                    className="d-md-none mt-5"
                    slide
                    controls={false}
                    interval={null}
                >
                    <Carousel.Item>
                        <div className="d-flex justify-content-center">
                            <div className="why-add-card">
                                <img
                                    src={Security}
                                    alt="Security"
                                    className="img-fluid d-block ml-auto"
                                />
                                <p className="title mb-1 mb-lg-4">Security</p>
                                <p className="description mb-0">
                                    Add’s ecosystem is built with security so
                                    that you can invest, lend, earn DeFi coins
                                    with peace of mind.
                                </p>
                            </div>

                            <div className="why-add-card">
                                <img
                                    src={Privacy}
                                    alt="Privacy"
                                    className="img-fluid d-block ml-auto"
                                />
                                <p className="title mb-1 mb-lg-4">Privacy</p>
                                <p className="description mb-0">
                                    Privacy on your DeFi activity is our top
                                    priority. Only you will know about your
                                    activity, and no one else. Not even us!
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="d-flex justify-content-center">
                            <div className="why-add-card">
                                <div className="d-flex">
                                    <img
                                        src={SmartContractAudits}
                                        alt="Smart Contract Audits"
                                        className="img-fluid d-block ml-auto my-auto"
                                    />
                                </div>
                                <p className="title mb-1 mb-lg-4">
                                    Smart Contract Audits
                                </p>
                                <p className="description mb-0">
                                    We conduct regular Smart Contract audits to
                                    ensure safety and efficient automation of
                                    protocols.
                                </p>
                            </div>

                            <div className="why-add-card">
                                <img
                                    src={Automation}
                                    alt="Automation"
                                    className="img-fluid d-block ml-auto"
                                />
                                <p className="title mb-1 mb-lg-4">Automation</p>
                                <p className="description mb-0">
                                    Blockchain’s best boon to the word -
                                    automation which makes fees on Add’s
                                    platform negligible.
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="d-flex justify-content-center">
                            <div className="why-add-card">
                                <img
                                    src={PrivateDashboard}
                                    alt="Private Dashboard"
                                    className="img-fluid d-block ml-auto"
                                />
                                <p className="title mb-1 mb-lg-4">
                                    Private Dashboard
                                </p>
                                <p className="description mb-0">
                                    Get your own private dashboard to track all
                                    your DeFi assets and maximize your
                                    potential!
                                </p>
                            </div>

                            <div className="why-add-card">
                                <div className="d-flex">
                                    <img
                                        src={UserFriendly}
                                        alt="User-friendly"
                                        className="img-fluid d-block ml-auto my-auto"
                                    />
                                </div>
                                <p className="title mb-1 mb-lg-4">
                                    User-friendly
                                </p>
                                <p className="description mb-0">
                                    Beginner or professional, Add’s platform is
                                    easy to learn and use with clear
                                    visualizations on your dashboard.
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>

                <div className="d-none d-md-flex flex-wrap justify-content-center pt-lg-5 mt-5">
                    <div className="why-add-col">
                        <div className="why-add-card">
                            <img
                                src={Security}
                                alt="Security"
                                className="img-fluid d-block ml-auto"
                            />
                            <p className="title mb-1 mb-lg-4">Security</p>
                            <p className="description mb-0">
                                Add’s ecosystem is built with security so that
                                you can invest, lend, earn DeFi coins with peace
                                of mind.
                            </p>
                        </div>
                    </div>

                    <div className="why-add-col">
                        <div className="why-add-card">
                            <img
                                src={Privacy}
                                alt="Privacy"
                                className="img-fluid d-block ml-auto"
                            />
                            <p className="title mb-1 mb-lg-4">Privacy</p>
                            <p className="description mb-0">
                                Privacy on your DeFi activity is our top
                                priority. Only you will know about your
                                activity, and no one else. Not even us!
                            </p>
                        </div>
                    </div>

                    <div className="why-add-col">
                        <div className="why-add-card">
                            <div className="d-flex">
                                <img
                                    src={SmartContractAudits}
                                    alt="Smart Contract Audits"
                                    className="img-fluid d-block ml-auto my-auto"
                                />
                            </div>
                            <p className="title mb-1 mb-lg-4">
                                Smart Contract Audits
                            </p>
                            <p className="description mb-0">
                                We conduct regular Smart Contract audits to
                                ensure safety and efficient automation of
                                protocols.
                            </p>
                        </div>
                    </div>

                    <div className="why-add-col">
                        <div className="why-add-card">
                            <img
                                src={Automation}
                                alt="Automation"
                                className="img-fluid d-block ml-auto"
                            />
                            <p className="title mb-1 mb-lg-4">Automation</p>
                            <p className="description mb-0">
                                Blockchain’s best boon to the word - automation
                                which makes fees on Add’s platform negligible.
                            </p>
                        </div>
                    </div>

                    <div className="why-add-col">
                        <div className="why-add-card">
                            <img
                                src={PrivateDashboard}
                                alt="Private Dashboard"
                                className="img-fluid d-block ml-auto"
                            />
                            <p className="title mb-1 mb-lg-4">
                                Private Dashboard
                            </p>
                            <p className="description mb-0">
                                Get your own private dashboard to track all your
                                DeFi assets and maximize your potential!
                            </p>
                        </div>
                    </div>

                    <div className="why-add-col">
                        <div className="why-add-card">
                            <div className="d-flex">
                                <img
                                    src={UserFriendly}
                                    alt="User-friendly"
                                    className="img-fluid d-block ml-auto my-auto"
                                />
                            </div>
                            <p className="title mb-1 mb-lg-4">User-friendly</p>
                            <p className="description mb-0">
                                Beginner or professional, Add’s platform is easy
                                to learn and use with clear visualizations on
                                your dashboard.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className='col-lg-9'>
            <div className='row justify-content-center'>
              <OurProducts />
            </div>
          </div> */}
            </div>

            <p className="logos-header text-center mt-5 mt-md-0">
                OUR PARTNERS & INTEGRETORS
            </p>

            <div className="d-flex justify-content-center flex-wrap mx-auto our-partners-logos">
                <a href="https://aave.com" target="_blank">
                    <img src={Aave} alt="AAVE" />
                </a>
                <a href="https://peckshield.com/en" target="_blank">
                    <img
                        src={PeckShield}
                        alt="PeckShield"
                        className="mr-3 mr-md-5"
                    />
                </a>
                <a href="https://dydx.exchange" target="_blank">
                    <img
                        src={PartnersDydx}
                        alt="Dydx"
                        className="mr-3 mr-md-5"
                    />
                </a>
                <a href="https://www.ankr.com" target="_blank">
                    <img src={Ankr} alt="Ankr" className="mr-3 mr-md-5" />
                </a>
                <a href="https://fulcrum.trade" target="_blank">
                    <img src={Fulcrum} alt="Fulcrum" className="mr-3 mr-md-5" />
                </a>
                <a href="https://metamask.io" target="_blank">
                    <img src={Metamask} alt="METAMASK" />
                </a>
            </div>

            <div className="d-flex justify-content-center align-items-center flex-wrap mx-auto our-partners-logos">
                <a href="https://yearn.finance" target="_blank">
                    <img
                        src={YearnFinance}
                        alt="YearnFinance"
                        className="mr-3 mr-md-5"
                    />
                </a>
                <a href="https://sentinel.co" target="_blank">
                    <img
                        src={Sentinel}
                        alt="Sentinel"
                        className="mr-3 mr-md-5"
                    />
                </a>
                <a href="https://www.mtnonline.com" target="_blank">
                    <img src={MTN} alt="MTN" className="mr-3 mr-md-5" />
                </a>
                <a href="https://compound.finance/" target="_blank">
                    <img src={CompoundIcon} alt="Compound" />
                </a>
            </div>

            <div className="d-flex justify-content-center align-items-center flex-wrap mx-auto our-partners-logos">
                <a href="https://fortmatic.com" target="_blank">
                    <img
                        src={FormaticIcon}
                        alt="Formatic"
                        className="mr-3 mr-md-5"
                    />
                </a>
                <img src={CurveIcon} alt="Curve" className="mr-3 mr-md-5" />
                <a href="https://nibss-plc.com.ng" target="_blank">
                    <img src={NIB} alt="NIB" className="mr-3 mr-md-5" />
                </a>
                <a href="https://diadata.org" target="_blank">
                    <img src={DIA} alt="DIA" />
                </a>
            </div>

            <div className="middle-section mx-auto">
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-50 mr-lg-3">
                        <p className="middle-title mb-4">
                            +Earn - Grow Your DeFi Earnings
                        </p>
                        <p className="middle-description mb-4">
                            EARN up to x% interest on your Crypto Deposits with
                            our DeFi Lending Aggregator. So what if you are
                            sleeping, your cryptos shouldn’t.
                        </p>
                        <div className="d-flex flex-column align-items-start align-items-sm-stretch flex-sm-row">
                            {renderConnectWalletButton()}
                            <button
                                className="primary-btn outline ml-2 mt-3 mt-sm-0"
                                onClick={() =>
                                    window.open(
                                        'https://medium.com/addxyz/an-ultimate-step-by-step-guide-to-lend-cryptocurrencies-on-defi-protocols-and-start-earning-a99e4df99f9c'
                                    )
                                }
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div className="flex-0-0-50 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 ml-lg-3">
                        <img
                            src={Earn}
                            alt="Earn"
                            className="d-block img-fluid mx-auto mx-lg-0 ml-lg-auto"
                        />
                    </div>
                </div>
            </div>

            <div className="middle-section mx-auto">
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-50 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 ml-lg-3">
                        <img
                            src={OneStep}
                            alt="Earn"
                            className="d-block img-fluid mx-auto mx-lg-0 mr-lg-auto"
                        />
                    </div>
                    <div className="flex-0-0-50 mr-lg-3">
                        <p className="middle-title mb-4">
                            Your One-Stop Plaform for DeFi
                        </p>
                        <p className="middle-description mb-4">
                            ADD.xyz is a full-stack DeFi aggregator, plugging in
                            multiple products and DeFi applications into one
                            single platform, focusing on User Experience,
                            Design, Privacy and Anonymity.
                        </p>
                        <div className="d-flex flex-column align-items-start align-items-sm-stretch flex-sm-row">
                            <button
                                className="primary-btn px-3 py-2 ml-2 mt-3 mt-sm-0"
                                onClick={() => {
                                    window.open(
                                        'https://docs.google.com/forms/d/e/1FAIpQLSdqqtkpw96IU9yl4Q6Df9hTFbgQENhcwoeX_lBtFM-GQU_LTA/viewform'
                                    )
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div>
                                        <img
                                            src={VectorSvg}
                                            alt="vector"
                                            className="d-block img-fluid mr-2"
                                        />
                                    </div>
                                    <div className="text-left">
                                        <div className="play-text">
                                            Get on the
                                        </div>
                                        <div className="sub-text">
                                            Google Play
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <p className="logos-header text-center">OUR INVESTORS</p>

            <div className="d-flex justify-content-center flex-wrap mx-auto featured-in-logos">
                <a href="https://nabais.capital" target="_blank">
                    <img src={Nabais} alt="Nabais" className="mr-5" />
                </a>
                <a href="http://torchlight.vc" target="_blank">
                    <img src={TouchLight} alt="TouchLight" className="mr-5" />
                </a>
                <a href="http://www.smc-capital.com" target="_blank">
                    <img src={SmcCapital} alt="SMCCapital" className="mr-5" />
                </a>
                <a href="http://sammantics.com" target="_blank">
                    <img src={Sammantis} alt="Sammantis" />
                </a>
            </div>

            <div className="d-flex justify-content-center flex-wrap mx-auto featured-in-logos">
                <a href="https://goldbaumandpartners.com" target="_blank">
                    <img src={GoldBaeum} alt="GoldBaeum" className="mr-5" />
                </a>
                <a href="https://www.bitcoin.com" target="_blank">
                    <img src={Bitcoin} alt="Bitcoin" className="mr-5" />
                </a>
                <a href="https://www.ngc.fund/home" target="_blank">
                    <img src={NGC} alt="NGC" className="mr-5" />
                </a>
                <a href="https://changelly.com" target="_blank">
                    <img src={ChangellyIcon} alt="Changelly" />
                </a>
            </div>

            <div className="middle-section mx-auto">
                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-50 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 mr-lg-3">
                        <img
                            src={AddToken}
                            alt="Add Token"
                            className="d-block mx-auto mx-lg-0 mr-lg-auto img-fluid"
                        />
                    </div>
                    <div className="flex-0-0-50 ml-3">
                        <p className="middle-title mb-4">
                            $ADD Token - Fueling the Ecosystem
                        </p>
                        <p className="middle-description mb-4">
                            $ADD is the native token of Add’s ecosystem which
                            powers the internal operations and protocols of the
                            network. Built with high utility and value
                            generation, you can now buy, hold, and stake $ADD to
                            earn higher rewards.
                        </p>
                        <div className="d-flex flex-column align-items-start align-items-sm-stretch flex-sm-row">
                            <button
                                className="primary-btn outline mr-2"
                                onClick={() =>
                                    window.open(
                                        'https://info.uniswap.org/#/pools/0x2ab653894ce12ae9b4538aefc90b2d6e96458e1d'
                                    )
                                }
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={Uniswap}
                                        alt=""
                                        className="mr-2"
                                    />
                                    Buy on Uniswap
                                </div>
                            </button>
                            <button
                                className="primary-btn outline ml-1 mt-3 mt-sm-0"
                                onClick={() =>
                                    window.open(
                                        'https://poloniex.com/exchange/usdt_add'
                                    )
                                }
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <img
                                        src={Poloniex}
                                        alt=""
                                        className="mr-2"
                                    />
                                    Buy on Poloniex
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <p className="logos-header text-center">FEATURED IN</p>

            <div className="d-flex justify-content-center flex-wrap mx-auto featured-in-logos">
                <a
                    href="https://finance.yahoo.com/news/add-xyz-partners-major-cross-083700903.html"
                    target="_blank"
                >
                    <img
                        src={YahooFinance}
                        alt="YahooFinance"
                        className="mr-5"
                    />
                </a>
                <a
                    href="https://www.forbes.com/sites/kenrapoza/2021/01/26/telegram-gains-on-whatsapp-blunder-disgust-with-legacy-social-media/?sh=663e12973468"
                    target="_blank"
                >
                    <img src={Forbes} alt="Forbes" className="mr-5" />
                </a>
                <a
                    href="https://www.publish0x.com/analysis-from-moon/addxyz-bridges-the-gap-between-defi-and-africa-s-mobile-mone-xjjkveq"
                    target="_blank"
                >
                    <img src={Publish} alt="Publish" className="mr-5" />
                </a>
                <a
                    href="https://markets.businessinsider.com/news/stocks/add-xyz-partners-with-major-cross-border-mobile-money-networks-and-banking-providers-in-africa-to-introduce-mobile-money-users-to-defi-1029819278"
                    target="_blank"
                >
                    <img
                        src={BusinessInsider}
                        alt="BusinessInsider"
                        className="mr-5"
                    />
                </a>
                <a
                    href="https://www.cityam.com/wp-content/uploads/2019/11/CITYAM-2019-11-26.pdf"
                    target="_blank"
                >
                    <img src={Cityam} alt="Cityam" className="img-fluid mr-5" />
                </a>
                <a
                    href="https://hackernoon.com/what-will-it-take-for-defi-protocols-to-seize-the-future-of-loan-payments-za1l3uuk"
                    target="_blank"
                >
                    <img src={HackerNoon} alt="HackerNoon" />
                </a>
            </div>

            <div className="middle-section mx-auto">
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-50 pb-3 mr-3 mb-4 pb-lg-0 mb-lg-0">
                        <p className="middle-title mb-4">Staking $ADD</p>
                        <p className="middle-description mb-4">
                            Stake and Earn $ADD rewards just by holding $ADD
                            tokens in your wallet. By holding $ADD tokens, you
                            are contributing to the security and governance of
                            the network.
                        </p>
                        <div className="d-flex flex-column align-items-start align-items-sm-stretch flex-sm-row">
                            <button
                                className="primary-btn mr-2"
                                onClick={() => {
                                    history.push('/add-staking')
                                }}
                            >
                                Start Staking
                            </button>
                            <button
                                className="primary-btn outline ml-1 mt-3 mt-sm-0"
                                onClick={() =>
                                    window.open('https://about.add.xyz')
                                }
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div className="flex-0-0-50 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 ml-lg-3">
                        <img
                            src={Staking}
                            alt="Staking"
                            className="d-block mx-auto img-fluid mx-auto mx-lg-0 ml-lg-auto"
                        />
                    </div>
                </div>
            </div>

            <p className="add-suite-header text-center pb-lg-4 mb-5">
                The Upcoming ADD Suite
            </p>
            <div className="add-suite-wrapper hide-scrollbar d-flex justify-content-between">
                <div className="add-suite-card d-flex flex-column position-relative">
                    <p className="add-suite-card-title">
                        DeFi Lending Aggregator
                    </p>
                    <p className="add-suite-card-description">
                        Take control of your future. Earn maximum APY on your
                        assets with full control via non - custodial ownership.
                        Connect to multiple protocols within a single platform,
                        switch between protocols, and even stealth your
                        transactions before lending!
                    </p>
                    <span>...</span>
                    {/* <div className="d-flex justify-content-start mt-auto">
              <img src={Medium} alt="Medium" className="pointer mr-4" />
              <img src={Twitter} alt="Twitter" className="pointer" />
            </div> */}
                </div>
                <div className="add-suite-card d-flex flex-column position-relative">
                    <p className="add-suite-card-title">ADD Mobile App</p>
                    <p className="add-suite-card-description">
                        The next generation of DeFi Mobile Apps, Monitor your
                        portfolio, lend your assets, check the news and
                        seamlessly access other Decentralised Finance Protocols
                        on Mobile.
                    </p>
                    <span>...</span>
                    {/* <div className="d-flex justify-content-start mt-auto">
              <img src={Medium} alt="Medium" className="pointer mr-4" />
              <img src={Twitter} alt="Twitter" className="pointer" />
            </div> */}
                </div>
                <div className="add-suite-card d-flex flex-column position-relative">
                    <p className="add-suite-card-title">
                        Staking Liquidity Program
                    </p>
                    <p className="add-suite-card-description">
                        Stake your $ADD into Uniswap and join the growing
                        community of Liquidity Providers earning APY on ADD
                        every single month.
                    </p>
                    <span style={{ right: '52px' }}>...</span>
                    {/* <div className="d-flex justify-content-start mt-auto">
              <img src={Medium} alt="Medium" className="pointer mr-4" />
              <img src={Twitter} alt="Twitter" className="pointer" />
            </div> */}
                </div>
                <div className="add-suite-card d-flex flex-column position-relative">
                    <p className="add-suite-card-title">Your DeFi Dashboard</p>
                    <p className="add-suite-card-description">
                        Participant in the Decentralisation of Protocols through
                        our Aggregated Governance Dashboard, View and Vote in
                        upcoming proposals across all DeFi protocols.
                    </p>
                    <span>...</span>
                    {/* <div className="d-flex justify-content-start mt-auto">
              <img src={Medium} alt="Medium" className="pointer mr-4" />
              <img src={Twitter} alt="Twitter" className="pointer" />
            </div> */}
                </div>
            </div>

            <SubscribeEmail headerText="Stay updated with our updates before anyone else." />

            <div className="center-views">
                <HomeFooter />

                {openModal === 0 && (
                    <ModalComponent
                        visible={true}
                        isWalletModal={openModal === 0 ? true : false}
                        onClose={() => {
                            if (lendClicked.current) {
                                history.push('/earn')
                            }
                            setOpenModal(-1)
                            lendClicked.current = false
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default Home
