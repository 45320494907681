import React, { useEffect } from 'react'

// contexts
import { useWeb3React } from '@web3-react/core'
import { authContext } from './../../../utils/context/AuthContext'
import { useHistory, useLocation } from 'react-router-dom'

// components
import LendIcon from './components/LendIcon'
import PrivacyIcon from './components/PrivacyIcon'
import SwapIcon from './components/SwapIcon'
import StakingIcon from './components/StakingIcon'
import GovernanceIcon from './components/GovernanceIcon'
import SettingsIcon from './components/SettingsIcon'
import HistoryIcon from './components/HistoryIcon'
import OverviewIcon from './components/OverviewIcon'
import { MdSmartphone } from 'react-icons/md'
import { BiHome } from 'react-icons/bi'

// styles
import './style.scss'

// assets
import Avatar from '../../../assets/images/project/avatar.svg'
import { useState } from 'react'
import Config from 'app/config/config'
import axios from 'axios'
const Web3 = require('web3')

const Sidebar: React.FC = ({}) => {
    const { deactivate } = useWeb3React()
    const { auth: user, setUnauthStatus } = React.useContext(authContext)
    const history = useHistory()
    const [path, setPath] = React.useState(history.location.pathname)
    const location = useLocation()
    const web3 = new Web3(Web3.givenProvider)

    React.useEffect(() => {
        setPath(history.location.pathname)
    }, [location.pathname])

    const logout = () => {
        deactivate()
        setUnauthStatus()
        window.location.href = '/home'
    }

    const renderActiveClass = (route: string) => {
        if (path === route) {
            return 'active'
        }
        return ''
    }

    const navItems = [
        // {
        //   icon: <BiHome className={path === "/" ? "selected" : ""} />,
        //   pathName: "/",
        //   text: "Overview"
        // },
        {
            icon: (
                <OverviewIcon
                    isActive={
                        renderActiveClass('/overview') ||
                        renderActiveClass('/overview')
                    }
                />
            ),
            pathName: '/overview',
            text: 'Overview',
        },
        {
            icon: (
                <LendIcon
                    isActive={
                        renderActiveClass('/earn-crypto-lending') ||
                        renderActiveClass('/earn')
                    }
                />
            ),
            pathName: '/earn',
            text: 'Lend',
        },
        {
            icon: (
                <PrivacyIcon
                    isActive={
                        renderActiveClass('/earn-crypto-lending') ||
                        renderActiveClass('/blender')
                    }
                />
            ),
            pathName: '/blender',
            text: 'Privacy',
        },
        {
            icon: (
                <StakingIcon
                    isActive={
                        renderActiveClass('/earn-crypto-lending') ||
                        renderActiveClass('/staking')
                    }
                />
            ),
            pathName: '/staking',
            text: 'Staking',
        },
        // {
        //     icon: (
        //         <GovernanceIcon
        //             isActive={
        //                 renderActiveClass('/proposals') || renderActiveClass('')
        //             }
        //         />
        //     ),
        //     pathName: '/proposals',
        //     text: 'Governance',
        // },
        // {
        //     icon: (
        //         <SwapIcon
        //             isActive={
        //                 renderActiveClass('/swap') || renderActiveClass('')
        //             }
        //         />
        //     ),
        //     pathName: '/',
        //     text: 'Swap',
        // },
        {
            icon: (
                <HistoryIcon
                    isActive={
                        renderActiveClass('/history') || renderActiveClass('')
                    }
                />
            ),
            pathName: '/history',
            text: 'History',
        },
        // {
        //     icon: (
        //         <SettingsIcon
        //             isActive={
        //                 renderActiveClass('/settings') || renderActiveClass('')
        //             }
        //         />
        //     ),
        //     pathName: '/',
        //     text: 'Settings',
        // },

        // {
        //   icon: <StakingIcon isActive={renderActiveClass("/staking")} />,
        //   pathName: "/staking",
        //   text: "Staking"
        // },
        // {
        //   icon: <PrivacyIcon isActive={renderActiveClass("/blender")} />,
        //   pathName: "/blender",
        //   text: "Blender"
        // }
        // {
        //   icon: <SwapIcon isActive={renderActiveClass("/swap")} />,
        //   pathName: "",
        //   text: "Swap",
        //   commingSoon: true
        // },

        // {
        //   icon: <GovernanceIcon isActive={renderActiveClass("/governance")} />,
        //   pathName: "",
        //   text: "Governance",
        //   commingSoon: true
        // },
        // {
        //   icon: <HistoryIcon isActive={renderActiveClass("/history")} />,
        //   pathName: "",
        //   text: "History"
        // }
    ]

    const navItemsBottom = [
        {
            text: 'Logout',
            action: logout,
        },
        // {
        //   text: "Gitbook",
        //   action: () => window.open("https://about.add.xyz")
        // },
        {
            text: 'Feedback',
            action: () => window.open(Config.feedbackLink),
        },
        // {
        //   text: "Press Kit",
        //   action: () => history.push("/roadmap-presskits")
        // },
        {
            text: 'About Us',
            action: () => window.open(Config.aboutUrl),
        },
        {
            text: 'Settings',
            action: () => {},
        },
        {
            text: 'Etherscan',
            action: () => window.open(Config.etherScanAdd),
        },
    ]

    const [balance, setBalance] = useState<any>()
    web3.eth.getBalance(user.walletAddress, function (err, result) {
        if (err) {
            console.log(err)
        } else {
            getEthRate(web3.utils.fromWei(result, 'ether'))
            console.log(web3.utils.fromWei(result, 'ether') + ' ETH')
        }
    })

    const getEthRate = async (value) => {
        const result: any = await axios.get(Config.ethRateApiUrl)
        setBalance(result.data.USD * value)
    }

    return (
        <div
            className="sidebar side-nav d-flex flex-column transform"
            id="sidebar"
        >
            <div className="navigation ">
                {/* <div className="d-flex flex-wrap mb-5">
                    <img src={Avatar} alt="Avatar" />
                    <div className="ml-3">
                        <p className="wallet-address mb-1">
                            {user.walletAddress &&
                                user.walletAddress.substr(0, 3) +
                                    '...' +
                                    user.walletAddress.substr(
                                        user.walletAddress.length - 7
                                    )}
                        </p>
                        <p className="user-balance mb-0">
                            $ {parseFloat(balance).toFixed(4)}
                        </p>
                    </div>
                </div> */}
                {navItems.map((i) => (
                    <div
                        key={i.text}
                        className={`sidebar-item d-flex align-items-center`}
                        onClick={() => {
                            setPath(i.pathName)
                            i.pathName && history.replace(i.pathName)
                        }}
                    >
                        {' '}
                        {i.icon}
                        <p
                            className={`${renderActiveClass(
                                i.pathName
                            )}  ml-4 mb-0 position-relative`}
                        >
                            {i.text}
                        </p>
                    </div>
                ))}
            </div>
            {/* <div className="navigation-bottom mt-auto ">
        {navItemsBottom.map((i) => (
          <p className="sidebar-item text-left" onClick={i.action}>
            {i.text}
          </p>
        ))}
      </div> */}
        </div>
    )
}

export default Sidebar
