import React, { useEffect } from 'react'
import './style.scss'

interface IProps {}
const PrivacyPolicy: React.FC<IProps> = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div className="privacy-policy-terms-of-use-bg">
            <div className="privacy-policy-terms-of-use-ui mx-auto mb-5">
                <h1 className="text-center">
                    Privacy Policy <span>Notice</span>
                </h1>
                <p className="text-center">Effective Date: May 1, 2021</p>

                <h2>Introduction</h2>
                <p>
                    Welcome to the ADD.xyz Privacy and Cookie Policy ("Privacy
                    Policy").
                </p>
                <p>
                    ADD.xyz respects your privacy and is committed to protecting
                    your personal data. This Privacy Policy will inform you as
                    to how we look after your personal data when you visit our
                    website (regardless of where you visit it from) and tell you
                    about your privacy rights and how the law protects you.
                </p>
                <p className="mb-4">
                    This Privacy Policy is provided in a layered format so you
                    can click through to the specific areas set out below.
                    Alternatively you can download a pdf version of the policy
                    here. Please also use the Glossary to understand the meaning
                    of some of the terms used in this Privacy Policy.
                </p>

                <ol className="list-style-type-decimal">
                    <li>
                        <a href="#important-information-and-who-we-are">
                            IMPORTANT INFORMATION AND WHO WE ARE
                        </a>
                    </li>
                    <li>
                        <a href="#the-data-we-collect-about-you">
                            THE DATA WE COLLECT ABOUT YOU
                        </a>
                    </li>
                    <li>
                        <a href="#how-is-your-personal-data-collected">
                            HOW IS YOUR PERSONAL DATA COLLECTED
                        </a>
                    </li>
                    <li>
                        <a href="#how-we-use-your-personal-data">
                            HOW WE USE YOUR PERSONAL DATA
                        </a>
                    </li>
                    <li>
                        <a href="#disclosures-of-your-personal-data">
                            DISCLOSURES OF YOUR PERSONAL DATA
                        </a>
                    </li>
                    <li>
                        <a href="#international-transfers">
                            INTERNATIONAL TRANSFERS
                        </a>
                    </li>
                    <li>
                        <a href="#data-security">DATA SECURITY</a>
                    </li>
                    <li>
                        <a href="#data-retention">DATA RETENTION</a>
                    </li>
                    <li>
                        <a href="#your-legal-rights">YOUR LEGAL RIGHTS</a>
                    </li>
                    <li>
                        <a href="#glossary">GLOSSARY</a>
                    </li>
                </ol>

                <h2 id="important-information-and-who-we-are">
                    1. Important information and who we are
                </h2>
                <h3>Purpose of this Privacy Policy</h3>
                <p>
                    This Privacy Policy aims to give you information on how
                    ADD.xyz collects and processes your personal data through
                    your use of this website, including any data you may provide
                    through this website when you sign up to our newsletter or
                    alerts, or purchase any product or service that we offer
                    through the website including products or services we offer
                    in our capacity as advisors on behalf of our third party
                    token sale clients ("Clients") through our website and
                    associated widgets.
                </p>
                <p>
                    This website is not intended for children and we do not
                    knowingly collect data relating to children.
                </p>
                <p>
                    It is important that you read this Privacy Policy together
                    with any other Privacy Policy or fair processing notice we
                    may provide on specific occasions when we are collecting or
                    processing personal data about you so that you are fully
                    aware of how and why we are using your data. This Privacy
                    Policy supplements any other notices and is not intended to
                    override them.
                </p>

                <h2>Privacy</h2>
                <p>
                    ADD.xyz, which is a holding group registered as an offshore
                    entity (together with any other subsidiaries of ADD.xyz from
                    time to time, may be referred to as, the "ADD.xyz Group")
                </p>
                <p>
                    This Privacy Policy is issued on behalf of the ADD.xyz Group
                    so when we mention ADD.xyz "we", "us" or "our" in this
                    Privacy Policy, we are referring to the relevant company in
                    the ADD.xyz Group responsible for processing your data. Due
                    to our jurisdiction, we are not required to have a data
                    controller, however, we take precautions regarding the
                    handling and management of your data.
                </p>
                <p>
                    If you are dissatisfied and/or have queries about the use of
                    your data, you have the right to make a complaint to:
                </p>

                <h2>
                    Changes to the Privacy Policy and your duty to inform us of
                    changes
                </h2>
                <p>
                    This version was last updated on 03 March 2020. Please note
                    that changes may also be made to legislation governing data
                    in the jurisdictions relevant to other members of the
                    ADD.xyz Group as well as you yourself from time to time.
                </p>
                <p>
                    It is important that the personal data we hold about you is
                    accurate and current. Please keep us informed if your
                    personal data changes during your relationship with us.
                </p>

                <h2>Third-party links</h2>
                <p>
                    This website may include links to third-party websites,
                    plug-ins and applications. Clicking on those links or
                    enabling those connections may allow third parties to
                    collect or share data about you. We do not control these
                    third-party websites and are not responsible for their
                    privacy statements. When you leave our website, we encourage
                    you to read the Privacy Policy of every website you visit.
                </p>

                <h2 id="the-data-we-collect-about-you">
                    2. The data we collect about you
                </h2>
                <p>
                    Personal data, or personal information, means any
                    information about an individual from which that person can
                    be identified. It does not include data where the identity
                    has been removed (anonymous data).
                </p>
                <p className="mb-5">
                    We may collect, use, store and transfer different kinds of
                    personal data about you which we have grouped together
                    follows:
                </p>

                <ul>
                    <li>
                        <b>Identity Data</b> includes first name, maiden name,
                        last name, username or similar identifier, marital
                        status, title, date of birth and gender as well as,
                        where applicable, any additional personal data collected
                        as part of Anti Money Laundering and Know-Your-Customer
                        (together "AML") procedures carried out by certain 3rd
                        party AML service providers on our behalf, which may
                        include ID photos, selfie photos and the results of any
                        Watchlist (Politically Exposed Persons and Sanctions)
                        checks.
                    </li>
                    <li>
                        <b>Contact Data</b> includes email address, address,
                        telephone number.
                    </li>
                    <li>
                        <b>Financial Data</b> includes wallet address.
                    </li>
                    <li>
                        <b>Transaction Data</b> includes details about payments
                        to and from you and other details of products and
                        services you have purchased from us or our Clients.
                    </li>
                    <li>
                        <b>Technical Data</b> includes internet protocol (IP)
                        address, your login data, browser type and version, time
                        zone setting and location, browser plug-in types and
                        versions, operating system and platform and other
                        technology on the devices you use to access this
                        website.
                    </li>
                    <li>
                        <b>Profile Data</b> includes your username and password,
                        purchases or orders made by you, your interests,
                        preferences, feedback and survey responses.
                    </li>
                    <li>
                        <b>Usage Data</b> includes information about how you use
                        our website and or widgets, products and services.
                    </li>
                    <li>
                        <b>Marketing and Communications Data</b> includes your
                        preferences in receiving marketing from us and our third
                        parties and your communication preferences.
                    </li>
                </ul>

                <p>
                    We also collect, use and share Aggregated Data such as
                    statistical or demographic data for any purpose. Aggregated
                    Data may be derived from your personal data but is not
                    considered personal data in law as this data does not
                    directly or indirectly reveal your identity. For example, we
                    may aggregate your Usage Data to calculate the percentage of
                    users accessing a specific website feature. However, if we
                    combine or connect Aggregated Data with your personal data
                    so that it can directly or indirectly identify you, we treat
                    the combined data as personal data which will be used in
                    accordance with this Privacy Policy.
                </p>
                <p>
                    We do not collect any Special Categories of Personal Data
                    about you (this includes details about your race or
                    ethnicity, religious or philosophical beliefs, sex life,
                    sexual orientation, political opinions, trade union
                    membership, information about your health and genetic and
                    biometric data). If at any time you go through our AML
                    process, we may collect information about criminal
                    convictions and offences.
                </p>

                <h2>If you fail to provide personal data</h2>
                <p>
                    Where we need to collect personal data by law, or under the
                    terms of a contract we have with you and you fail to provide
                    that data when requested, we may not be able to perform the
                    contract we have or are trying to enter into with you (for
                    example, to provide you with goods or services). In this
                    case, we may have to cancel a product or service you have
                    with us but we will notify you if this is the case at the
                    time.
                </p>

                <h2 id="how-is-your-personal-data-collected">
                    3. How is your personal data collected?
                </h2>
                <p>
                    We use different methods to collect data from and about you
                    including through:
                </p>
                <ul>
                    <li>
                        <b>Direct interactions.</b> You may give us your
                        Identity, Contact and Financial Data by filling in forms
                        or by corresponding with us by email. This includes
                        personal data you provide when you.
                    </li>
                    <ul>
                        <li>create an account on our website.</li>
                        <li>subscribe to our newsletter.</li>
                        <li>subscribe to our alerts.</li>
                        <li>
                            purchase or make use of a service or product on our
                            website and/or widget.
                        </li>
                        <li>go through our AML process.</li>
                        <li>request marketing to be sent to you.</li>
                        <li>enter a competition, promotion or survey. or</li>
                        <li>give us some feedback.</li>
                    </ul>

                    <li>
                        <b>Automated technologies or interactions.</b> As you
                        interact with our website, we may automatically collect
                        Technical Data about your equipment, browsing actions
                        and patterns. We collect this personal data by using
                        cookies, server logs and other similar technologies. We
                        may also receive Technical Data about you if you visit
                        other websites employing our cookies. Please see further
                        details of our use of cookies below.
                    </li>
                    <li>
                        <b>Third parties or publicly available sources.</b> We
                        may receive personal data about you from various third
                        parties and public sources as set out below:
                    </li>
                    <ul>
                        <li>Technical Data from the following parties:</li>
                        <ol>
                            <li>
                                analytics providers such as Google anywhere in
                                the world.
                            </li>
                            <li>advertising networks. and</li>
                            <li>search information providers.</li>
                        </ol>
                        <li>
                            Contact, Identity, Financial and Transaction Data
                            from providers of AML, other technical, payment and
                            delivery services such as the AML service providers
                            anywhere in the world.
                        </li>
                        <li>
                            Identity and Contact Data from publicly available
                            sources such as Companies House and the Electoral
                            Register based anywhere in the world.
                        </li>
                        <li>
                            Identity, Contact, Financial and Transaction Data
                            from Clients.
                        </li>
                    </ul>
                </ul>

                <h2 id="how-we-use-your-personal-data">
                    4. How we use your personal data
                </h2>
                <p>
                    We will only use your personal data when the law allows us
                    to. Most commonly, we will use your personal data in the
                    following circumstances:
                </p>
                <ul>
                    <li>
                        Where we need to perform the contract we are about to
                        enter into or have entered into with you.
                    </li>
                    <li>
                        Where it is necessary for our legitimate interests (or
                        those of a third party) and your interests and
                        fundamental rights do not override those interests.
                    </li>
                    <li>
                        Where we need to comply with a legal or regulatory
                        obligation.
                    </li>
                </ul>
                <p className="mt-5">
                    Generally we do not rely on consent as a legal basis for
                    processing your personal data other than in relation to
                    sending third party direct marketing communications to you
                    via email. You have the right to withdraw consent to
                    marketing at any time by contacting us.
                </p>

                <h2>Purposes for which we will use your personal data</h2>
                <p>
                    We have set out below, in a table format, a description of
                    all the ways we plan to use your personal data, and which of
                    the legal bases we rely on to do so. We have also identified
                    what our legitimate interests are where appropriate.
                </p>
                <p>
                    Note that we may process your personal data for more than
                    one lawful ground depending on the specific purpose for
                    which we are using your data. Please contact us if you need
                    details about the specific legal ground we are relying on to
                    process your personal data where more than one ground has
                    been set out in the table below.
                </p>

                <h2>Purpose/Activity</h2>
                <h3>Type of data</h3>
                <h3>
                    Lawful basis for processing including basis of legitimate
                    interest
                </h3>
                <p>To register you as a new customer</p>
                <ol>
                    <li>Identity</li>
                    <li>Contact</li>
                </ol>
                <p>
                    To process and deliver your order of our products or
                    services, or products and services provided by us to you on
                    behalf of our token sale Clients, including:
                </p>

                <h2>Promotional offers from us</h2>
                <p>
                    We may use your Identity, Contact, Technical, Usage and
                    Profile Data to form a view on what we think you may want or
                    need, or what may be of interest to you. This is how we
                    decide which products, services and offers may be relevant
                    for you (we call this marketing).
                </p>
                <p>
                    You will receive marketing communications from us if you
                    have requested information from us or purchased goods or
                    services from us or from our Clients through our website or
                    if you provided us with your details when you entered a
                    competition or registered for a promotion and, in each case,
                    you have opted in to receiving that marketing.
                </p>

                <h2>Third-party marketing</h2>
                <p>
                    We will never share your personal data with any company
                    outside the ADD.xyz Group for marketing purposes.
                </p>

                <h2>Opting out</h2>
                <p>
                    You can ask us to stop sending you marketing messages at any
                    time by logging into the by following the opt-out links on
                    any marketing message sent to you or by contacting us at any
                    time.
                </p>

                <h2>Cookies</h2>
                <p>
                    Our website may place and access certain first party cookies
                    on your computer or device. First party cookies are those
                    placed directly by us and are used only by us. We use
                    cookies to facilitate and improve your experience of our
                    website and to provide and improve our services. We have
                    carefully chosen these cookies and have taken steps to
                    ensure that your privacy is protected and respected at all
                    times.
                </p>
                <p>
                    By using our website you may also receive certain third
                    party cookies on your computer or device. Third party
                    cookies are those placed by websites, services, and/or
                    parties other than us. We use third party cookies on our
                    website for advertising services. These cookies are not
                    integral to the functioning of our website.
                </p>
                <p>
                    All cookies used by and on our website vary in use in
                    accordance with multiple countries and jurisdictions’ cookie
                    law.
                </p>
                <p>
                    Before any cookies are placed on your computer or device
                    (unless strictly necessary or placed by analytics services)
                    you will be shown a pop-up message bar requesting your
                    consent to set those cookies. By giving your consent to the
                    placing of cookies you are enabling us to provide the best
                    possible experience and service to you. You may, if you
                    wish, deny consent to the placing of cookies; however
                    certain features of our website may not function fully or as
                    intended. You will be given the opportunity to allow only
                    first party cookies and block third party cookies.
                </p>
                <p>
                    Certain features of our website depend on cookies to
                    function. Under varied jurisdictions, cookie law deems these
                    cookies to be "strictly necessary". These cookies are shown
                    below. Your consent will not be sought to place these
                    cookies. You may still block these cookies by changing your
                    internet browser's settings as detailed, but please be aware
                    that our website may not work as intended if you do so. We
                    have taken great care to ensure that your privacy is not at
                    risk by allowing them.
                </p>
                <p>
                    The following cookies may be placed on your computer or
                    device:
                </p>

                <h2>Type of Cookie</h2>
                <h3>Purpose</h3>
                <p>
                    <b>Site Performance Cookies</b>
                </p>
                <p>
                    These cookies collect information about how visitors use a
                    website, for instance which pages visitors go to most often,
                    and if they get error messages from web pages. These cookies
                    don't collect information that identifies a visitor. All
                    information these cookies collect is aggregated and
                    therefore anonymous. It is only used to improve how a
                    website works.
                </p>
                <p>
                    <b>Anonymous Analytics Cookies</b>
                </p>
                <p>
                    Every time someone visits our website, software provided by
                    another organisation generates an \'anonymous analytics
                    cookie\'.These cookies can tell us whether or not you have
                    visited the site before. Your browser will tell us if you
                    have these cookies and, if you don\'t, we generate new ones.
                    This allows us to track how many individual users we have,
                    and how often they visit the site. Unless you are signed in
                    to ADD.xyz, we cannot use these cookies to identify
                    individuals. We use them to gather statistics, for example,
                    the number of visits to a page. If you are logged in, we
                    will also know the details you gave to us for this, such as
                    your username and email address.
                </p>
                <p>
                    <b>Geo-Targeting Cookies</b>
                </p>
                <p>
                    These cookies are used by software which tries to work out
                    what country you are in from the information supplied by
                    your browser when you click on a web page. This cookie is
                    completely anonymous and we use it in order to better
                    understand where our users are.
                </p>
                <p>
                    <b>Registration Cookies</b>
                </p>
                <p>
                    When you register with ADD.xyz, we generate cookies that let
                    us know whether you are signed in or not. Our servers use
                    these cookies to work out which account you are signed in
                    with. If you have not selected \'keep me signed in\', your
                    cookies get deleted when you either close your browser or
                    shut down your computer. While you are signed in, we combine
                    information from your registration cookies with analytics
                    cookies, which we could use to identify which pages you have
                    seen on the website.
                </p>
                <p>
                    <b>Advertising Cookies</b>
                </p>
                <p>
                    These cookies are used to deliver adverts more relevant to
                    you and your interests They are also used to limit the
                    number of times you see an advertisement as well as help
                    measure the effectiveness of the advertising campaigns. They
                    remember that you have visited a website and this
                    information is shared with other organisations such as
                    advertisers. We may use cookies set by another organisation,
                    so we can more accurately target advertising to you. These
                    cookies are anonymous and store information about what you
                    are looking at on our site, but not about who you are. We
                    also set anonymous cookies on certain other sites that we
                    advertise on. If you receive one of those cookies, we may
                    then use it to identify you as having visited that site if
                    you later visit ADD.xyz. We can then target our advertising
                    based on this information.
                </p>
                <p>
                    Website analytics refers to a set of tools used to collect
                    and analyse usage statistics, enabling us to better
                    understand how people use our website. This, in turn,
                    enables us to improve our website and the services offered
                    through it. You do not have to allow us to use these
                    cookies, however whilst our use of them does not pose any
                    risk to your privacy or your safe use of our website, it
                    does enable us to continually improve our website, making it
                    a better and more useful experience for you.
                </p>

                <h2>Change of purpose</h2>
                <p>
                    We will only use your personal data for the purposes for
                    which we collected it.
                </p>
                <p>
                    Please note that we may process your personal data without
                    your knowledge or consent, in compliance with the above
                    rules, where this is required or permitted by law.
                </p>

                <h2 id="disclosures-of-your-personal-data">
                    5. Disclosures of your personal data
                </h2>
                <p>
                    We may have to share your personal data with the parties set
                    out below for the purposes set out in the table in paragraph
                    4 above.
                </p>
                <ul>
                    <li>Internal Third Parties as set out in the Glossary.</li>
                    <li>External Third Parties as set out in the Glossary.</li>
                    <li>
                        Third parties to whom we may choose to sell, transfer,
                        or merge parts of our business or our assets.
                        Alternatively, we may seek to acquire other businesses
                        or merge with them. If a change happens to our business,
                        then the new owners may use your personal data in the
                        same way as set out in this Privacy Policy.
                    </li>
                </ul>
                <p>
                    We require all third parties to respect the security of your
                    personal data and to treat it in accordance with the law. We
                    do not allow our third-party service providers to use your
                    personal data for their own purposes and only permit them to
                    process your personal data for specified purposes and in
                    accordance with our instructions.
                </p>

                <h2 id="international-transfers">6. International transfers</h2>
                <p>
                    We share your personal data within the ADD.xyz Group. This
                    may involve transferring your data outside the European
                    Economic Area (EEA).
                </p>
                <p>
                    We ensure your personal data is protected by requiring all
                    our group companies to follow the same rules when processing
                    your personal data. These rules are called \"binding
                    corporate rules\". For further details, see European
                    Commission: Binding corporate rules.
                </p>
                <p>
                    Some of our external third parties are also based outside
                    the European Economic Area (EEA) so their processing of your
                    personal data will involve a transfer of data outside the
                    EEA.
                </p>
                <p>
                    Whenever we transfer your personal data out of the EEA, we
                    ensure a similar degree of protection is afforded to it by
                    ensuring at least one of the following safeguards is
                    implemented:
                </p>
                <ul>
                    <li>
                        We will only transfer your personal data to countries
                        that have been deemed to provide an adequate level of
                        protection for personal data by the European Commission.
                        For further details, see European Commission: Adequacy
                        of the protection of personal data in non-EU countries.
                    </li>
                    <li>
                        Where we use certain service providers, we may use
                        specific contracts approved by the European Commission
                        which give personal data the same protection it has in
                        Europe. For further details, see European Commission:
                        Model contracts for the transfer of personal data to
                        third countries.
                    </li>
                    <li>
                        Where we use providers based in the US, we may transfer
                        data to them if they are part of the Privacy Shield
                        which requires them to provide similar protection to
                        personal data shared between the Europe and the US. For
                        further details, see European Commission: EU-US Privacy
                        Shield. Please contact us if you want further
                        information on the specific mechanism used by us when
                        transferring your personal data out of the EEA.
                    </li>
                </ul>

                <h2 id="data-security">7. Data security</h2>
                <p>
                    We have put in place appropriate security measures to
                    prevent your personal data from being accidentally lost,
                    used or accessed in an unauthorised way, altered or
                    disclosed. In addition, we limit access to your personal
                    data to those employees, agents, contractors and other third
                    parties who have a business need to know. They will only
                    process your personal data on our instructions and they are
                    subject to a duty of confidentiality.
                </p>
                <p>
                    We have put in place procedures to deal with any suspected
                    personal data breach and will notify you and the supervisory
                    authority of a breach where we are legally required to do
                    so.
                </p>

                <h2 id="data-retention">8. Data retention</h2>
                <h3>How long will you use my personal data for?</h3>
                <p>
                    We will only retain your personal data for as long as
                    necessary to fulfil the purposes we collected it for,
                    including for the purposes of satisfying any legal,
                    accounting, or reporting requirements.
                </p>
                <p>
                    To determine the appropriate retention period for personal
                    data, we consider the amount, nature, and sensitivity of the
                    personal data, the potential risk of harm from unauthorised
                    use or disclosure of your personal data, the purposes for
                    which we process your personal data and whether we can
                    achieve those purposes through other means, and the
                    applicable legal requirements.
                </p>
                <p>
                    By law we have to keep basic information about our customers
                    (including Contact, Identity, Financial and Transaction
                    Data) for six years after they cease being customers for tax
                    purposes, and a minimum of 5 years from the time of the last
                    transaction for AML purposes.
                </p>
                <p>
                    In some circumstances you can ask us to delete your data:
                    see Request erasure below for further information.
                </p>
                <p>
                    In some circumstances we may anonymise your personal data
                    (so that it can no longer be associated with you) for
                    research or statistical purposes in which case we may use
                    this information indefinitely without further notice to you.
                </p>

                <h2 id="your-legal-rights">9. Your legal rights</h2>
                <p>
                    Under certain circumstances, you have rights under data
                    protection laws in relation to your personal data. Please
                    click on the links below to find out more about these
                    rights:
                </p>
                <ul>
                    <li>Request access to your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request erasure of your personal data.</li>
                    <li>Object to processing of your personal data.</li>
                    <li>
                        Request restriction of processing your personal data.
                    </li>
                    <li>Request transfer of your personal data.</li>
                    <li>Right to withdraw consent.</li>
                    <li>
                        Right not to be subject to a decision based solely on
                        automated processing
                    </li>
                </ul>
                <p>
                    If you wish to exercise any of the rights set out above,
                    please contact us.
                </p>

                <h2>No fee usually required</h2>
                <p>
                    You will not have to pay a fee to access your personal data
                    (or to exercise any of the other rights). However, we may
                    under the conditions referred to in the European Union
                    General Data Protection Regulation and Gibraltar law charge
                    a reasonable fee if your request is clearly unfounded,
                    repetitive or excessive. Alternatively, we may refuse to
                    comply with your request in these circumstances.
                </p>

                <h2>What we may need from you</h2>
                <p>
                    We may need to request specific information from you to help
                    us confirm your identity and ensure your right to access
                    your personal data (or to exercise any of your other
                    rights). This is a security measure to ensure that personal
                    data is not disclosed to any person who has no right to
                    receive it. We may also contact you to ask you for further
                    information in relation to your request to speed up our
                    response.
                </p>

                <h2>Time limit to respond</h2>
                <p>
                    We respond to all legitimate requests within one month. if
                    your request is particularly complex or you have made a
                    number of requests, we will notify you and keep you updated
                    on the estimated time for receiving our response in a longer
                    time than 30 days.
                </p>

                <h2 id="glossary">10. Glossary</h2>

                <h2>LAWFUL BASIS</h2>
                <p>
                    <b>Legitimate Interest</b> means the interest of our
                    business in conducting and managing our business to enable
                    us to give you the best service/product and the best and
                    most secure experience. We make sure we consider and balance
                    any potential impact on you (both positive and negative) and
                    your rights before we process your personal data for our
                    legitimate interests. We do not use your personal data for
                    activities where our interests are overridden by the impact
                    on you (unless we have your consent or are otherwise
                    required or permitted to by law). You can obtain further
                    information about how we assess our legitimate interests
                    against any potential impact on you in respect of specific
                    activities by contacting us
                </p>
                <p>
                    <b>Performance of Contract</b> means processing your data
                    where it is necessary for the performance of a contract to
                    which you are a party or to take steps at your request
                    before entering into such a contract.
                </p>
                <p>
                    <b>Comply with a legal or regulatory obligation</b> means
                    processing your personal data where it is necessary for
                    compliance with a legal or regulatory obligation that we are
                    subject to.
                </p>

                <h2>THIRD PARTIES</h2>
                <h3>Internal Third Parties</h3>
                <p>
                    Other companies in the ADD.xyz Group acting as joint
                    controllers or processors and who are based either in the
                    United Kingdom, Malta or Finland and provide IT and system
                    administration services and undertake leadership reporting.
                </p>
                <h3>External Third Parties</h3>

                <ul>
                    <li>
                        Service providers based in the United Kingdom, the
                        United States and/or elsewhere in the E.U. who provide
                        IT, AML and system administration services.
                    </li>
                    <li>
                        Professional advisers acting as processors or joint
                        controllers including lawyers, bankers, auditors and
                        insurers based in Gibraltar, Malta, Finland, the UK, the
                        United States or elsewhere in the E.U. who provide
                        consultancy, banking, legal, insurance and accounting
                        services.
                    </li>
                    <li>
                        Regulators and other authorities acting as processors or
                        joint controllers based in Gibraltar, Malta, the UK, the
                        United States or elsewhere in the E.U. who require
                        reporting of processing activities in certain
                        circumstances.
                    </li>
                    <li>
                        ADD.xyz Clients on whose behalf we are providing token
                        sale advisory services who are based in Gibraltar,
                        Malta, the UK, elsewhere in the E.U. and the United
                        States and in some cases outside the EEA/US.
                    </li>
                </ul>

                <h2>YOUR LEGAL RIGHTS</h2>
                <h3>You have the right to:</h3>
                <p>
                    <b>Request access</b> to your personal data (commonly known
                    as a \"data subject access request\"). This enables you to
                    receive a copy of the personal data we hold about you and to
                    check that we are lawfully processing it.
                </p>
                <p>
                    <b>Request correction</b> of the personal data that we hold
                    about you. This enables you to have any incomplete or
                    inaccurate data we hold about you corrected, though we may
                    need to verify the accuracy of the new data you provide to
                    us.
                </p>
                <p>
                    <b>Request erasure of your personal data.</b> This enables
                    you to ask us to delete or remove personal data where there
                    is no good reason for us continuing to process it. You also
                    have the right to ask us to delete or remove your personal
                    data where you have successfully exercised your right to
                    object to processing (see below), where we may have
                    processed your information unlawfully or where we are
                    required to erase your personal data to comply with local
                    law. Note, however, that we may not always be able to comply
                    with your request of erasure for specific legal reasons
                    which will be notified to you, if applicable, at the time of
                    your request.
                </p>
                <p>
                    <b>Object to processing</b> of your personal data where we
                    are relying on a legitimate interest (or those of a third
                    party) and there is something about your particular
                    situation which makes you want to object to processing on
                    this ground as you feel it impacts on your fundamental
                    rights and freedoms. You also have the right to object where
                    we are processing your personal data for direct marketing
                    purposes. In some cases, we may demonstrate that we have
                    compelling legitimate grounds to process your information
                    which override your rights and freedoms.
                </p>
                <p>
                    <b>Request restriction of processing</b> of your personal
                    data. This enables you to ask us to suspend the processing
                    of your personal data in the following scenarios: (a) if you
                    want us to establish the data\'s accuracy; (b) where our use
                    of the data is unlawful but you do not want us to erase it;
                    (c) where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise or defend
                    legal claims; or (d) you have objected to our use of your
                    data but we need to verify whether we have overriding
                    legitimate grounds to use it.
                </p>
                <p>
                    <b>Request the transfer</b> of your personal data to you or
                    to a third party. We will provide to you, or a third party
                    you have chosen, your personal data in a structured,
                    commonly used, machine-readable format. Note that this right
                    only applies to automated information which you initially
                    provided consent for us to use or where we used the
                    information to perform a contract with you.
                </p>
                <p>
                    <b>Withdraw consent at any time</b> where we are relying on
                    consent to process your personal data. However, this will
                    not affect the lawfulness of any processing carried out
                    before you withdraw your consent. If you withdraw your
                    consent, we may not be able to provide certain products or
                    services to you. We will advise you if this is the case at
                    the time you withdraw your consent.
                </p>
                <p>
                    <b>
                        Right not to be subject to a decision based solely on
                        automated processing
                    </b>{' '}
                    which means your right to object to a decision, which may
                    include a measure, evaluating personal aspects relating to
                    you which is based solely on automated processing and which
                    produces legal effects concerning you or similarly
                    significantly affects you, such as automatic refusal of an
                    online credit application or e-recruiting practices without
                    any human intervention. As of now, ADD.xyz does not apply
                    automated processing in its business procedures but if we
                    were to introduce them in the future you have the right to
                    object it.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
