import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import './Proposals.scss'
import ProposalSubHeader from '../../shared/components/ProposalSubHeader/ProposalSubHeader'
import {Spinner} from 'react-bootstrap'
import Config from 'app/config/config'
import axios from 'axios'
import sideArrow from '../../assets/images/governance/arrow_side.svg'

interface IProps {}
const Governance: React.FC<IProps> = () => {
    const history = useHistory()
    const [proposals, setProposals] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [governanceIsLoading, setGovernanceIsLoading] = useState(true)
    const [governanceDetail, setGovernanceDetail] = useState<any>({
        nickName: '',
    })

    const navigateTo = (proposalId) =>
        history.push(`/proposal-detail/${proposalId}`)

    React.useEffect(() => {
        console.log('jey')
        const governanceId = window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
        )
        axios
            .get(
                `${Config.governanceBaseUrl}/admin/proposal/list/${governanceId}`
            )
            .then((res) => {
                if (res && res.data) {
                    const {code, data} = res.data
                    if (code === 200) {
                        const result = data.filter((x) => {
                            return x.status
                        })
                        setProposals(result)
                        setIsLoading(false)
                        axios
                            .get(
                                `${Config.governanceBaseUrl}/admin/governance/${governanceId}`
                            )
                            .then((governanceRes) => {
                                if (governanceRes && governanceRes.data) {
                                    const {code, data} = governanceRes.data
                                    if (
                                        code === 200 &&
                                        data &&
                                        data.length !== 0
                                    ) {
                                        setGovernanceDetail(data[0])
                                    }
                                    setGovernanceIsLoading(false)
                                }
                            })
                    }
                }
            })
    }, [])

    function start_and_end(str) {
        if (str.length > 35) {
            return (
                str.substr(0, 6) +
                '...' +
                str.substr(str.length - 4, str.length)
            )
        }
        return str
    }

    return (
        <>
            <div className="container main-div proposal-container">
                <div className="proposal-list-container">
                    <ProposalSubHeader
                        loading={governanceIsLoading}
                        ticker={governanceDetail.nickName}
                        governanceDetail={governanceDetail}
                        title={`${governanceDetail.nickName} Proposals`}
                    ></ProposalSubHeader>
                    <div className="coin-title">
                        <h2 className="title">All Proposals</h2>
                    </div>
                    <div className="proposal-content">
                        {isLoading && (
                            <div className="spinner-container">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        )}
                        {!isLoading &&
                            proposals &&
                            proposals.map((proposal) => {
                                return (
                                    proposal.status === true && (
                                        <div
                                            onClick={() =>
                                                navigateTo(proposal._id)
                                            }
                                            className="proposal-column"
                                        >
                                            <div className="row">
                                                <div className="col-xl-1 d-flex flex-column">
                                                    <div
                                                        className={`status absolute-center ${
                                                            proposal.votingStatus
                                                                ? 'active'
                                                                : 'closed'
                                                        }`}
                                                    >
                                                        <h2 className="status-text">
                                                            {proposal.votingStatus
                                                                ? 'active'
                                                                : 'closed'}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="col-xl-11 d-flex flex-column">
                                                    <h2 className="proposal-title">
                                                        {proposal.proposalTitle}
                                                        <img
                                                            className="right-arrow"
                                                            alt="side arrow"
                                                            src={sideArrow}
                                                        />
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="row proposal-details">
                                                <div className="col-xs-12 col-sm-12 col-md d-flex flex-column">
                                                    <h4>
                                                        #
                                                        {`${proposal._id.substring(
                                                            0,
                                                            3
                                                        )}${proposal._id.substring(
                                                            proposal._id
                                                                .length - 3,
                                                            proposal._id.length
                                                        )}`}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md d-flex flex-column">
                                                    <h4>
                                                        By{' '}
                                                        {proposal.author
                                                            ? start_and_end(
                                                                  proposal.author
                                                              )
                                                            : '-'}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md d-flex flex-column">
                                                    <h4>
                                                        {'10k'}{' '}
                                                        {governanceDetail
                                                            ? governanceDetail.nickName
                                                            : ''}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md d-flex flex-column">
                                                    <h4>
                                                        <span>start</span>{' '}
                                                        {proposal.startDateTime
                                                            ? new Date(
                                                                  proposal.startDateTime
                                                              ).toDateString()
                                                            : '-'}
                                                    </h4>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md d-flex flex-column">
                                                    <h4>
                                                        <span>end</span>{' '}
                                                        {proposal.endDateTime
                                                            ? new Date(
                                                                  proposal.endDateTime
                                                              ).toDateString()
                                                            : '-'}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            })}

                        {!isLoading &&
                            (!proposals ||
                                (proposals && proposals.length === 0)) && (
                                <div>
                                    <h2 className="no-data-found">
                                        No record found
                                    </h2>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Governance
