import * as React from 'react'

/** Custom types */
import { UserAuth } from './../CustomTypes/UserType'

/** Utils */
import { DEFAULT_USER_AUTH } from './../CustomTypes/TypeDeclaration'

const useAuthHandler = (initialState: UserAuth) => {
    const [auth, setAuth] = React.useState(initialState)
    const [isRefetch, setIsRefetch] = React.useState<boolean>(false)
    const [protocolToChange, changeProtocolSet] = React.useState(null)
    const [currencyState, changeCurrencySet] = React.useState(
        auth.currency || 'DAI'
    )
    const [loading, setLoading] = React.useState(false)
    const [pair, setPair] = React.useState(0)

    const [changePercent, setChangePercentState] = React.useState(0)
    const setAuthStatus = (userAuth: UserAuth) => {
        try {
            window.localStorage.setItem('UserAuth', JSON.stringify(userAuth))
            setAuth(userAuth)
            setAuthLoading(false)
            setRefetch(false)
            changeProtocolSet(null)
        } catch (error) {
            console.log('error ===>>', error)
        }
    }

    const setSelectedPair = (pair: number) => {
        setPair(pair)
    }

    const setChangePercent = (percent: number) => {
        setChangePercentState(percent)
    }

    const setUnauthStatus = () => {
        window.localStorage.removeItem('walletCreation')
        window.localStorage.removeItem('Walletaddress')
        window.localStorage.removeItem('UserAuth')
        setAuth(DEFAULT_USER_AUTH)
        setAuthLoading(false)
        setRefetch(false)
        changeProtocolSet(null)
        console.log('set setUnauthStatus called')
    }

    const setAuthLoading = (flag: boolean) => {
        setLoading(flag)
        return true
    }

    const setRefetch = (flag: boolean) => {
        setIsRefetch(flag)
    }

    const changeProtocol = (protocol: any) => {
        changeProtocolSet(protocol)
    }
    const changeCurrency = (currency: any) => {
        changeCurrencySet(currency)
        window.localStorage.setItem(
            'UserAuth',
            JSON.stringify({ ...auth, currency })
        )
    }

    return {
        auth,
        changeCurrency,
        setAuthStatus,
        setUnauthStatus,
        setAuthLoading,
        setSelectedPair,
        setChangePercent,
        setRefetch,
        isRefetch,
        protocolToChange,
        currencyState,
        changeProtocol,
        selectedPair: pair,
        authLoading: loading,
        changePercent,
    }
}

export default useAuthHandler
