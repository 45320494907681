import * as React from 'react'

/** Custom types */
import { UserAuth } from './../CustomTypes/UserType'
/** Custom Hooks */
import useAuthHandler from './AuthHandler'
/** Utils */
import { DEFAULT_USER_AUTH } from './../CustomTypes/TypeDeclaration'
import { getStoredUserAuth } from './../Utilities/contextUtils'

interface IAuthContextInterface {
    authLoading: Boolean
    isRefetch: Boolean
    setRefetch: (flag: boolean) => void
    protocolToChange: any
    changeProtocol: (protocol: any) => void
    auth: UserAuth
    setAuthStatus: (userAuth: UserAuth) => void
    setUnauthStatus: () => void
    setAuthLoading: (flag: boolean) => void
    currencyState: any
    changeCurrency: (currency: any) => void
    selectedPair: number
    setSelectedPair: (pair: any) => void
    setChangePercent: (changePercent: number) => void
    changePercent: number
}

export const authContext = React.createContext<IAuthContextInterface>({
    authLoading: false,
    isRefetch: false,
    setRefetch: () => {},
    protocolToChange: null,
    changeProtocol: () => {},
    auth: DEFAULT_USER_AUTH,
    setAuthStatus: () => {},
    setUnauthStatus: () => {},
    setAuthLoading: () => {},
    currencyState: null,
    changeCurrency: () => {},
    selectedPair: 0,
    setSelectedPair: () => {},
    setChangePercent: () => {},
    changePercent: 0,
})
const { Provider } = authContext

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const {
        auth,
        setAuthStatus,
        setUnauthStatus,
        setRefetch,
        isRefetch,
        setAuthLoading,
        authLoading,
        changeProtocol,
        protocolToChange,
        currencyState,
        changeCurrency,
        selectedPair,
        setSelectedPair,
        setChangePercent,
        changePercent,
    } = useAuthHandler(getStoredUserAuth())

    return (
        <Provider
            value={{
                isRefetch,
                setRefetch,
                auth,
                setAuthStatus,
                setUnauthStatus,
                setAuthLoading,
                authLoading,
                selectedPair,
                setSelectedPair,
                changeProtocol,
                protocolToChange,
                currencyState,
                changeCurrency,
                setChangePercent,
                changePercent,
            }}
        >
            {children}
        </Provider>
    )
}

export default AuthProvider
