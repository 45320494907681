/* eslint-disable */
import React from 'react'
import { Button } from 'react-bootstrap'
// import Slider from "@bit/mui-org.material-ui.slider";
import { Slider } from 'material-ui-slider'

// assets
import ethSmall from '../../../../assets/images/project/eth_small_blue.svg'

const WithdrawLiquidity = () => {
    return (
        <>
            <div className="staking-modal-input pb-3 mb-4">
                <div className="staking-modal-input-header mb-0">Amount</div>

                <div className="staking-modal-withdraw-amount">100%</div>

                <Slider
                    min={0}
                    max={100}
                    value={50}
                    //   onChange={t => console.log(t)}
                    aria-labelledby="continuous-slider"
                />

                <div className="d-flex justify-content-between flex-wrap">
                    <Button className="staking-modal-slider-option mt-1">
                        25%
                    </Button>
                    <Button className="staking-modal-slider-option mt-1">
                        50%
                    </Button>
                    <Button className="staking-modal-slider-option mt-1">
                        75%
                    </Button>
                    <Button className="staking-modal-slider-option mt-1">
                        100%
                    </Button>
                </div>
            </div>

            <div className="staking-modal-border p-3 mb-3">
                <div className="staking-withdraw-modal-plt-value d-flex justify-content-between mb-2">
                    <div>0.8</div>
                    <div className="d-flex justify-content-around">
                        <img src={ethSmall} alt="logo" />
                        <div className="pl-2 ml-1">ETH</div>
                    </div>
                </div>

                <div className="staking-withdraw-modal-plt-value d-flex justify-content-between">
                    <div>200,313.09</div>
                    <div className="d-flex justify-content-around">
                        <img src={ethSmall} alt="logo" />
                        <div className="pl-2 ml-1">PLT</div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between px-2 mb-2 staking-withdraw-modal-prices">
                <div>Price:</div>
                <div>
                    <div>1 ETH = 234,092 PLT</div>
                    <div>1 PLT = 0.0000432 ETH</div>
                </div>
            </div>

            <div className="d-flex justify-content-center mb-3">
                <Button className="staking-modal-btn mr-3">Approve</Button>
                <Button className="staking-modal-btn ml-3">Withdraw</Button>
            </div>
        </>
    )
}

export default WithdrawLiquidity
