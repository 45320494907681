import React from 'react'
import './searchinput.scss'
import icon from '../../../assets/icons/chevron-down.svg'

interface IProps {
    title?: string
    placeholder?: string
    classes?:string
}
const SearchInput: React.FC<IProps> = ({ title, placeholder, classes }) => {
    return (
        <>
            <div className= {`input-group w-100  ${classes}` }>
                <div className="input-group-prepend">
                    <span className="input-group-text first-prepend">
                        <i className="fas fa-search"></i>
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control input-group-form-control"
                    placeholder={placeholder}
                />
                <div className="input-group-append second-prepend">
                    <span className="input-group-text">|</span>
                </div>
            </div>
        </>
    )
}

export default SearchInput
