import React, { useEffect } from 'react'
import './../privacy-policy/style.scss'

interface IProps {}
const TermsOfUse: React.FC<IProps> = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div className="privacy-policy-terms-of-use-bg">
            <div className="privacy-policy-terms-of-use-ui mx-auto mb-5">
                <h1 className="text-center">Terms of Use</h1>
                <p className="text-center">Effective Date: May 1, 2021</p>

                <p className="mb-5">
                    PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING
                    OR USING THE SERVICES, YOU AGREE TO BE LEGALLY BOUND BY
                    THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED BY
                    REFERENCE.
                </p>
                <p className="mb-5">
                    ADD.xyz is a private limited company incorporated in British
                    Virgin Islands with company number 2023035 and registered
                    address OMC Chambers, Wickhams Cay 1, Road Town, Tortola,
                    British Virgin Islands. (hereafter "ADD.xyz", "we", or
                    "us"). ADD.xyz operates under BVI law and EU law where
                    applicable.
                </p>
                <p>
                    These Terms of Service and any terms expressly incorporated
                    herein ("Terms") apply to your access to and use of the
                    websites, mobile applications and widgets provided by
                    ADD.xyz Limited (collectively, our "Services").
                </p>

                <h2>1. Acceptable Use</h2>

                <p>
                    1.1 When accessing or using the Services, you agree that you
                    will not violate any law, contract, intellectual property or
                    other third-party right or commit a tort, and that you are
                    solely responsible for your conduct while using our
                    Services. Without limiting the generality of the foregoing,
                    you agree that you will not:
                </p>
                <p>
                    {' '}
                    1.1.1 Use our Services in any manner that could interfere
                    with, disrupt, negatively affect or inhibit other users from
                    fully enjoying our Services, or that could damage, disable,
                    overburden or impair the functioning of our Services in any
                    manner;
                </p>
                <p>
                    1.1.2 Use our Services to pay for, support or otherwise
                    engage in any illegal gambling activities; fraud;
                    money-laundering; or terrorist activities; or other illegal
                    activities;
                </p>
                <p>
                    1.1.3 Use any robot, spider, crawler, scraper or other
                    automated means or interface not provided by us to access
                    our Services or to extract data;
                </p>
                <p>
                    1.1.4 Use or attempt to use another user's account without
                    authorization;
                </p>
                <p>
                    1.1.5 Attempt to circumvent any content filtering techniques
                    we employ, or attempt to access any service or area of our
                    Services that you are not authorized to access;
                </p>
                <p>
                    1.1.6 Develop any third-party applications that interact
                    with our Services without our prior written consent;
                </p>
                <p>
                    1.1.7 Provide false, inaccurate, or misleading information;
                </p>
                <p>
                    1.1.8 Encourage or induce any third party to engage in any
                    of the activities prohibited under this Section;
                </p>
                <p>
                    1.1.9 Use the Services if you are under the age of 18; and
                </p>
                <p>
                    1.1.10 Use our Services from any jurisdiction where such a
                    service is prohibited.
                </p>

                <h2>2.0 Identity Verification</h2>
                <p>
                    Some of our Services will require that you provide us with
                    certain data, details of which can be found in our Privacy
                    and Cookie Policy here. You will need to complete certain
                    verification procedures for the purpose of detecting
                    potential money laundering, terrorist financing, fraud and
                    other financial crime. You agree and acknowledge that we
                    shall be permitted to carry out such due diligence as we
                    deem necessary to verify your compliance with this clause
                    2.0 including but not limited to carrying out know your
                    customer, anti-money-laundering and source of funds checks,
                    either ourselves or through one of our service providers, as
                    required by law in British Virgin Islands where we are based
                    such as under the Proceeds of Crime Act, and/or under any
                    applicable law where you are based.
                </p>

                <h2>3.0 Electronic Notices</h2>
                <h3>3.1 Consent to Electronic Delivery.</h3>
                <p>
                    You agree and consent to receive electronically all
                    communications, agreements, documents, receipts, notices and
                    disclosures (collectively, "Communications") that ADD.xyz
                    provides in connection with your use of the Services. You
                    agree that ADD.xyz may provide these Communications to you
                    by posting them via the Services, by emailing them to you at
                    the email address you provide, and/or by sending an SMS or
                    text message to a mobile phone number that you provide. Your
                    carrier's normal, messaging, data and other rates and fees
                    may apply to any mobile Communications. You should maintain
                    copies of electronic Communications by printing a paper copy
                    or saving an electronic copy.
                </p>
                <h3>3.2 Hardware and Software Requirements. </h3>
                <p>
                    In order to access and retain electronic Communications, you
                    will need a computer with an Internet connection that has a
                    current web browser (preferably Google Chrome) with cookies
                    enabled and 128-bit encryption. You will also need to have a
                    valid email address on file with ADD.xyz and have sufficient
                    storage space to save past Communications or an installed
                    printer to print them.
                </p>
                <h3>3.3 Withdrawal of Consent.</h3>
                <p>
                    You may withdraw your consent to receive electronic
                    Communications by sending a withdrawal notice to support
                    email hello@ADD.xyz.com. If you decline or withdraw consent
                    to receive electronic Communications, ADD.xyz may suspend or
                    terminate your use of the Services.
                </p>
                <h3>3.4 Updating Contact Information.</h3>
                <p>
                    It is your responsibility to keep your email address and/or
                    mobile phone number on file with ADD.xyz up to date so that
                    ADD.xyz can communicate with you electronically. You
                    understand and agree that if ADD.xyz sends you an electronic
                    Communication but you do not receive it because your email
                    address or mobile phone number on file is incorrect, out of
                    date, blocked by your service provider, or you are otherwise
                    unable to receive electronic Communications, ADD.xyz will be
                    deemed to have provided the Communication to you. Please
                    note that if you use a spam filter that blocks or re-routes
                    emails from senders not listed in your email address book,
                    you must add ADD.xyz to your email address book so that you
                    will be able to receive the Communications we send to you.
                    You can update your email address, mobile phone number or
                    street address at any time by logging into your ADD.xyz
                    Account or by sending such information to support email
                    Hello@ADD.xyz. If your email address or mobile phone number
                    becomes invalid such that electronic Communications sent to
                    you by ADD.xyz are returned, ADD.xyz may deem your account
                    to be inactive, and you may not be able to complete any
                    transaction via our Services until we receive a valid,
                    working email address or mobile phone number from you.
                </p>

                <h2>4.0 Third-Party Content</h2>
                <p>
                    In using our Services, you will view content provided by
                    third parties, including articles, reviews, comments,
                    insights, analysis and links to web pages of such parties,
                    including but not limited to blogs, forums, communities and
                    social networks. We do not control or approve any
                    Third-Party Content and shall have no responsibility for
                    Third-Party Content, including without limitation material
                    that may be misleading, incomplete, erroneous, offensive,
                    indecent or otherwise objectionable. We are not responsible
                    or liable for any loss or damage of any sort incurred as the
                    result of any such dealings, and you understand that your
                    use of Third-Party Content, and your interactions with third
                    parties that are linked to our Service, is at your own risk.
                </p>

                <h2>5.0 Disclaimer & Risk Warning</h2>
                <p>
                    Any token, network or token sale information accessed by you
                    via the Services is provided for information purposes only
                    and is not intended to constitute the offering of securities
                    or regulated investments or advice, promotion, invitation or
                    solicitation for regulated investment or securities purposes
                    by ADD.xyz. You must obtain professional or specialist
                    advice or carry out your own due diligence before taking, or
                    refraining from taking, any action on the basis of the
                    content of our Services. In most cases the Services contain
                    information on token sales or exchange events that are
                    entirely unrelated to ADD.xyz and for which ADD.xyz has no
                    involvement in (including any technical structuring or
                    promotion). Although we make reasonable efforts to update
                    the information on our Services, we make no representations,
                    warranties or guarantees, whether express or implied, that
                    the content on our Services is accurate, complete or up to
                    date. For a detailed summary of the various risks involved
                    in token sales and the related technologies please see our
                    Sector Risk Warning page. In some cases a token offer may be
                    brought to you in association with ADD.xyz. If this is the
                    case, this will be flagged on our token sale webpage. Whilst
                    we take care to try to ensure that the persons involved in
                    token sales with which we are associated, and that the
                    proposed technology base for the token is credible, we do
                    not warrant that the information provided in the token sale
                    documentation is accurate nor that the proposed token will
                    function as intended or expected or that they will be fit
                    for any particular purpose. Prospective contributors are
                    advised that they must carry out their own research on the
                    people, technology, business plan and use cases of the
                    proposed network and token. Any terms and conditions entered
                    into by contributors in respect of the acquisition of tokens
                    are between them and the issuer of the token and ADD.xyz is
                    not the seller of such tokens. ADD.xyz has no legal
                    responsibility for any representations made by third parties
                    in respect of any token sale and any claim for breach of
                    contract must also be made directly against the token
                    issuing entity listed therein.
                </p>

                <h2>6.0 Private & Presale</h2>
                <p>
                    By registering to receive Private and Presale deal flow you
                    confirm that you understand that early stage contributions
                    to token sale projects is not regulated, that ADD.xyz is not
                    a regulated investment firm, advisor or broker and that
                    ADD.xyz makes no endorsement of or recommendation for
                    participation in any project. You are aware that
                    contributions to early stage projects will not be protected
                    by any compensation scheme. You also understand that
                    participation in early stage projects, although unregulated,
                    is often only available to those who would qualify as
                    Professional or Accredited participants within traditional
                    markets. You confirm that you qualify for such status and
                    understand that you may be required to evidence this in
                    addition to any other KYC requirements. You understand that
                    this service is not aimed at anyone in any jurisdiction
                    where such a service is prohibited and that if you are in
                    such a jurisdiction, you must not register to receive
                    Private and Presale deal flow.
                </p>

                <h2>7.0 DISCLAIMER OF WARRANTIES</h2>
                <p>
                    EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY
                    US, OUR SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
                    AVAILABLE" BASIS. WE EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                    INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                    NON-INFRINGEMENT AS TO OUR SERVICES, INCLUDING THE
                    INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN. YOU
                    ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH
                    OUR SERVICES MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR
                    TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES,
                    INCLUDING SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY
                    PROVIDERS, INTERNET OUTAGES, FORCE MAJEURE EVENT OR OTHER
                    DISASTERS INCLUDING THIRD PARTY DDOS ATTACKS, SCHEDULED OR
                    UNSCHEDULED MAINTENANCE, OR OTHER CAUSES EITHER WITHIN OR
                    OUTSIDE OUR CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING
                    UP AND MAINTAINING DUPLICATE COPIES OF ANY INFORMATION YOU
                    STORE OR TRANSFER THROUGH OUR SERVICES.
                </p>

                <h2>8.0 LIMITATION OF LIABILITY</h2>
                <p>
                    (a) EXCEPT AS OTHERWISE REQUIRED BY LAW, IN NO EVENT SHALL
                    OUR COMPANY, OUR DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE
                    LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES,
                    OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED
                    TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN
                    AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
                    NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
                    CONNECTED WITH THE USE OF OR INABILITY TO USE OUR SERVICES
                    OR THE COMPANY MATERIALS, INCLUDING WITHOUT LIMITATION ANY
                    DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY ANY USER ON
                    ANY INFORMATION OBTAINED FROM ADD.xyz, OR THAT RESULT FROM
                    MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR
                    EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR
                    TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT
                    RESULTING FROM A FORCE MAJEURE EVENT, COMMUNICATIONS
                    FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO
                    ADD.xyz’S RECORDS, PROGRAMS OR SERVICES.
                </p>
                <p>
                    (b) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT SHALL THE AGGREGATE LIABILITY OF ADD.xyz (INCLUDING
                    OUR DIRECTORS, MEMBERS, EMPLOYEES AND AGENTS), WHETHER IN
                    CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER
                    ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT
                    LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE
                    USE OF, OR INABILITY TO USE, ADD.xyz OR TO THESE TERMS
                    EXCEED THE FEES PAID BY YOU TO ADD.xyz DURING THE 12 MONTHS
                    IMMEDIATELY PRECEDING THE DATE OF ANY CLAIM GIVING RISE TO
                    SUCH LIABILITY.
                </p>

                <h2>9.0 Indemnity</h2>
                <p>
                    You agree to defend, indemnify and hold harmless ADD.xyz
                    (and each of our officers, directors, members, employees,
                    agents and affiliates) from any claim, demand, action,
                    damage, loss, cost or expense, including without limitation
                    reasonable attorneys’ fees, arising out or relating to (a)
                    your use of, or conduct in connection with, our Services;
                    (b) any Feedback you provide; (c) your violation of these
                    Terms; or (d) your violation of any rights of any other
                    person or entity. If you are obligated to indemnify us, we
                    will have the right, in our sole discretion, to control any
                    action or proceeding (at our expense) and determine whether
                    we wish to settle it.
                </p>

                <h2>10.0 Discontinuance of Services</h2>
                <p>
                    We may, in our sole discretion and without liability to you,
                    with or without prior notice and at any time, modify or
                    discontinue, temporarily or permanently, any portion of our
                    Services.
                </p>

                <h2>11.0 Feedback</h2>
                <p>
                    We will own non-exclusive rights, including all intellectual
                    property rights, to any feedback, suggestions, ideas or
                    other information or materials regarding ADD.xyz or our
                    Services that you provide, whether by email, posting through
                    our Services or otherwise ("Feedback"). Any Feedback you
                    submit is non-confidential and shall become the sole
                    property of ADD.xyz. We will be entitled to the unrestricted
                    use and dissemination of such Feedback for any purpose,
                    commercial or otherwise, without acknowledgment or
                    compensation to you. You waive any rights you may have to
                    the Feedback (including any copyrights or moral rights). Do
                    not send us Feedback if you expect to be paid or want to
                    continue to own or claim rights in them; your idea might be
                    great, but we may have already had the same or a similar
                    idea and we do not want disputes. We also have the right to
                    disclose your identity to any third party who is claiming
                    that any content posted by you constitutes a violation of
                    their intellectual property rights, or of their right to
                    privacy. We have the right to remove any posting you make on
                    our website if, in our opinion, your post does not comply
                    with the content standards set out in this section.
                </p>

                <h2>12.0 Copyright and Other Intellectual Property Rights</h2>
                <p>
                    Unless otherwise indicated by us, all copyright and other
                    intellectual property rights in all content and other
                    materials contained on our website or provided in connection
                    with the Services, including, without limitation, the
                    ADD.xyz logo and all designs, text, graphics, pictures,
                    information, data, software, sound files, other files and
                    the selection and arrangement thereof (collectively,
                    "ADD.xyz Materials") are the proprietary property of ADD.xyz
                    or our licensors or suppliers and are protected by English
                    and international copyright laws and other intellectual
                    property rights laws. We hereby grant you a limited,
                    non-exclusive and non-sublicensable license to access and
                    use the ADD.xyz Materials for your personal or internal
                    business use. Such license is subject to these Terms and
                    does not permit (a) any resale of the ADD.xyz Materials; (b)
                    the distribution, public performance or public display of
                    any ADD.xyz Materials; (c) modifying or otherwise making any
                    derivative uses of the ADD.xyz Materials, or any portion
                    thereof; or (d) any use of the ADD.xyz Materials other than
                    for their intended purposes. The license granted under this
                    Section will automatically terminate if we suspend or
                    terminate your access to the Services.
                </p>

                <h2>13.0 Trademarks</h2>
                <p>
                    "The ADD.xyz logo and any other ADD.xyz product or service
                    names, logos or slogans that may appear on our Services are
                    trademarks of ADD.xyz Limited, in European Union and in
                    other countries, and may not be copied, imitated or used, in
                    whole or in part, without our prior written permission. You
                    may not use any trademark, product or service name of
                    ADD.xyz without our prior written permission, including
                    without limitation any metatags or other "hidden text"
                    utilizing any trademark, product or service name of ADD.xyz.
                    In addition, the look and feel of our Services, including
                    all page headers, custom graphics, button icons and scripts,
                    is the service mark, trademark and/or trade dress of ADD.xyz
                    and may not be copied, imitated or used, in whole or in
                    part, without our prior written permission. All other
                    trademarks, registered trademarks, product names and company
                    names or logos mentioned through our Services are the
                    property of their respective owners. Reference to any
                    products, services, processes or other information, by name,
                    trademark, manufacturer, supplier or otherwise does not
                    constitute or imply endorsement, sponsorship or
                    recommendation by us.
                </p>

                <h2>14.0 Notice of Take Down</h2>
                <p>
                    In making material available on its website, ADD.xyz is
                    acting in good faith. However it is possible that material
                    published online may be in breach of copyright laws or
                    contain content that is otherwise unlawful. If you are
                    concerned that you have found material on our website that
                    is in breach of any law or regulation, please email
                    compliance@ADD.xyz, being sure to provide your contact
                    details and full details of the material and the reason for
                    your request.
                </p>

                <h2>15.0 Privacy & Cookie Policy</h2>
                <p>
                    Please refer to our Privacy & Cookie Policy for information
                    about how we collect, use and share your information.
                </p>

                <h2>16.0 SUSPENSION; TERMINATION</h2>
                <p>
                    In the event of any Force Majeure Event (as defined in
                    "Miscellaneous" Section), breach of this agreement, or any
                    other event that would make provision of the Services
                    commercially unreasonable for ADD.xyz, we may, in our
                    discretion and without liability to you, with or without
                    prior notice, suspend your access to all or a portion of our
                    Services. We may terminate your access to the Services in
                    our sole discretion, immediately and without prior notice,
                    and delete or deactivate your ADD.xyz Account and all
                    related information and files in such account without
                    liability to you, including, for instance, in the event that
                    you breach any term of these Terms.
                </p>

                <h2>17.0 APPLICABLE LAW; ARBITRATION</h2>
                <p>
                    PLEASE READ THE FOLLOWING PARAGRAPH CAREFULLY BECAUSE IT
                    REQUIRES YOU TO ARBITRATE DISPUTES WITH US AND IT LIMITS THE
                    MANNER IN WHICH YOU CAN SEEK RELIEF.
                </p>
                <p>
                    You and ADD.xyz agree to arbitrate any dispute arising from
                    these Terms or your use of the Services, except for disputes
                    in which either party seeks equitable and other relief for
                    the alleged unlawful use of copyrights, trademarks, trade
                    names, logos, trade secrets or patents. ARBITRATION PREVENTS
                    YOU FROM SUING IN COURT. You and ADD.xyz agree to notify
                    each other in writing of any dispute within thirty (30) days
                    of when it arises. Notice to ADD.xyz shall be sent to
                    Hello@ADD.xyz. You and ADD.xyz further agree: (a) to attempt
                    informal resolution prior to any demand for arbitration; (b)
                    that any arbitration will occur in BVI; (c) that arbitration
                    will be conducted confidentially by a single arbitrator in
                    accordance with the rules of arbitration in the British
                    Virgin Islands and (d) that the courts in BVI have exclusive
                    jurisdiction over any appeals of an arbitration award and
                    over any suit between the parties not subject to
                    arbitration. Other than class procedures and remedies
                    discussed below, the arbitrator has the authority to grant
                    any remedy that would otherwise be available in court. Any
                    dispute between the parties will be governed by these Terms
                    and the laws of BVI and applicable English common law,
                    without giving effect to any conflict of laws principles
                    that may provide for the application of the law of another
                    jurisdiction. Whether the dispute is heard in arbitration or
                    in court, you and ADD.xyz will not commence a class action,
                    class arbitration or representative action or proceeding.
                </p>

                <h2>18.0 Miscellaneous</h2>
                <p>
                    <b>Entire Agreement; Order of Precedence.</b> These Terms
                    contain the entire agreement, and supersede all prior and
                    contemporaneous understandings between the parties regarding
                    the Services. These Terms do not alter the terms or
                    conditions of any other electronic or written agreement you
                    may have with ADD.xyz for the Services or for any other
                    ADD.xyz product or service or otherwise (for instance the
                    content of the various Risk Warnings & Disclaimers from
                    ADD.xyz). In the event of any conflict between these Terms
                    and any other agreement you may have with ADD.xyz, the terms
                    of that other agreement will control only if these Terms are
                    specifically identified and declared to be overridden by
                    such other agreement.
                </p>
                <p>
                    <b>Amendment.</b> We reserve the right to make changes or
                    modifications to these Terms from time to time, in our sole
                    discretion. If we make changes to these Terms, we will
                    provide you with notice of such changes, such as by sending
                    an email, providing notice on the homepage of the Site
                    and/or by posting the amended Terms via the applicable
                    ADD.xyz websites and mobile applications and updating the
                    "Last Updated" date at the top of these Terms. The amended
                    Terms will be deemed effective immediately upon posting for
                    any new users of the Services. In all other cases, the
                    amended Terms will become effective for pre-existing users
                    upon the earlier of either: (i) the date users click or
                    press a button to accept such changes, or (ii) continued use
                    of our Services 30 days after ADD.xyz provides notice of
                    such changes. Any amended Terms will apply prospectively to
                    use of the Services after such changes become effective. If
                    you do not agree to any amended Terms, you must discontinue
                    using our Services and contact us to terminate your account.
                </p>
                <p>
                    <b>Waiver.</b> Our failure or delay in exercising any right,
                    power or privilege under these Terms shall not operate as a
                    waiver thereof.
                </p>
                <p>
                    <b>Severability.</b> The invalidity or unenforceability of
                    any of these Terms shall not affect the validity or
                    enforceability of any other of these Terms, all of which
                    shall remain in full force and effect.
                </p>
                <p>
                    <b>Force Majeure Events.</b> ADD.xyz shall not be liable for
                    (1) any inaccuracy, error, delay in, or omission of (i) any
                    information, or (ii) the transmission or delivery of
                    information; (2) any loss or damage arising from any event
                    beyond ADD.xyz's reasonable control, including but not
                    limited to flood, extraordinary weather conditions,
                    earthquake, or other act of God, fire, war, insurrection,
                    riot, labor dispute, accident, action of government,
                    communications, power failure, or equipment or software
                    malfunction or any other cause beyond ADD.xyz's reasonable
                    control (each, a "Force Majeure Event").
                </p>
                <p>
                    <b>Assignment.</b> You may not assign or transfer any of
                    your rights or obligations under these Terms without prior
                    written consent from ADD.xyz, including by operation of law
                    or in connection with any change of control. ADD.xyz may
                    assign or transfer any or all of its rights under these
                    Terms, in whole or in part, without obtaining your consent
                    or approval.
                </p>
                <p>
                    <b>Headings.</b> Headings of sections are for convenience
                    only and shall not be used to limit or construe such
                    sections.
                </p>
                <p>
                    Survival. Sections "Identity Verification", “ADD.xyz
                    Account”, “Risk Disclosure”, ”Fees”, “Unclaimed Property”,
                    “Feedback”, “Copyrights”, “Trademarks”, “Third-Party
                    Content”, “Disclaimer of Warranties”, “Limitation of
                    Liability”; “Indemnity”, “Applicable Law; Arbitration” and
                    this Section “Miscellaneous” shall survive any termination
                    or expiration of these Terms.
                </p>
            </div>
        </div>
    )
}

export default TermsOfUse
