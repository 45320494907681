import React, { useState } from 'react'
import './style.scss'
import Modal from 'react-modal'
import newTabIcon from '../../../../assets/images/project/newTabIcon.svg'
import cross from '../../../../assets/images/project/closeIcon.svg'
import { Button } from 'react-bootstrap'
import { provider } from 'web3-core'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

// utils
import { getContract, getWeb3 } from '../../../../utils/Utilities/erc20'
import { stakeOrUnstake } from '../../../../utils/Utilities/transactionsUtils'
import { showNotification } from '../../../../utils/notifications/notificationService'
import unstakeIcon from '../../../../assets/icons/unstakeicon.svg'
import successIcon from '../../../../assets/images/staking-landing/successicon.svg'
// constants
import Config from '../../../../config/config'

interface IProps {
    isVisible: Boolean
    setIsVisible: Function
    header?: string
    title?: string
    balance?: string
    pid?: number
    transactionType?: 'deposit' | 'withdraw'
    checkAllowance?: () => {}
    getRewardandBalanceAmount?: any
}

const ConfirmModal: React.FC<IProps> = ({
    isVisible,
    setIsVisible,
    header,
    title,
    balance,
    pid,
    transactionType,
    checkAllowance,
    getRewardandBalanceAmount,
}) => {
    const context = useWeb3React<Web3Provider>()
    const { account, library } = context

    const [amount, setAmount] = useState('')
    const [isTransacting, setIsTransacting] = useState(false)
    const [transactionHash, setTransactionHash] = useState(false)
    //   const [isConfirm, setConfirm] = useState(false);
    const webInstance = getWeb3(library.provider as provider)

    const handleStakeOrUnstake = async () => {
        setIsTransacting(true)
        try {
            const masterchefContract = getContract(
                library.provider as provider,
                Config.masterchefAddress,
                true
            )
            const amountDeposited = webInstance.utils.toWei(
                amount.toString(),
                'ether'
            )
            const tx = await stakeOrUnstake(
                masterchefContract,
                pid,
                amountDeposited,
                account,
                transactionType
            )

            if (tx && tx.status) {
                getRewardandBalanceAmount()
                checkAllowance()
                setTransactionHash(tx.transactionHash)
                showNotification(`${transactionType} successful!`, 'success')
                setIsTransacting(false)
            }
        } catch (error) {
            setIsTransacting(false)
        }
    }

    const closeModal = () => {
        setIsVisible(false)
    }

    return (
        <div>
            <Modal
                ariaHideApp={false}
                isOpen={isVisible ? true : false}
                onRequestClose={closeModal}
                contentLabel="Wallet Modal"
                shouldCloseOnOverlayClick
                className="amount-modal-view"
            >
                <div className="px-5 py-4">
                    <div className="d-flex justify-content-center">
                        <span className="amount-modal-title">
                            {header} LP Token
                        </span>
                        <img src={unstakeIcon} alt="ADD.xyz Logo" />
                        {/* <img src={cross} alt='ADD.xyz' onClick={closeModal} /> */}
                    </div>
                    <div className="text-center mt-3">
                        <svg
                            width="104"
                            height="104"
                            viewBox="0 0 104 104"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M52 104C80.7188 104 104 80.7188 104 52C104 23.2812 80.7188 0 52 0C23.2812 0 0 23.2812 0 52C0 80.7188 23.2812 104 52 104Z"
                                fill="#27AE60"
                            />
                            <g filter="url(#filter0_d_15102:31053)">
                                <path
                                    d="M79.039 31.2002L45.759 68.6402L24.959 52.0002"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    shape-rendering="crispEdges"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_15102:31053"
                                    x="19.959"
                                    y="30.2002"
                                    width="64.0801"
                                    height="47.4404"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_15102:31053"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_15102:31053"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>

                        {/* <img src={successIcon} alt="ADD.xyz Logo" width="100" /> */}
                    </div>
                    <text className="confirmation-text mt-3">
                        Unstakeing 3.4 tokens has been successfully completed
                    </text>

                    <div className="d-flex justify-content-between mb-4 mt-3">
                        <Button
                            className="cancel-amount-btn"
                            onClick={closeModal}
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button className="confirm-amount-btn">
                            <span>Confirm</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ConfirmModal
