export enum ROUTES {
    BLENDER = '/blender',
    DASHBOARD = '/earn',
    ANALYTICS = '/analytics',
    STAKING = '/staking',
    HOME = '/home',
    DEFAULT = '/',
    HOMEREDESIGN = '/home-redesign',
    ROADMAP_AND_PRESSKITS = '/roadmap-presskits',
    // MOBILE_MONEY = '/mobile-money',
    // GOVERNANCE = "/governance",
    GOVERNANCE = '/governance',
    PROPOSALS = '/proposals/:governanceId',
    PROPOSALS_DETAILS = '/proposal-detail/:governanceId',
    // STAKING_TEST = '/staking-test',
    PRIVACY_POLICY = '/privacy-policy',
    TERMS_OF_USE = '/terms-of-use',
    LENDING_HOME = '/earn-crypto-lending',
    STAKING_LANDING = '/add-staking',
    EARN_LENDING = '/lend_earn',
    BLENDER_LANDING = '/blender-crypto-privacy-mixer',
    GOVERNANCE_LANDING = '/governance',
    HISTORY = '/history',
    OVERVIEW = '/overview',
}
