/* eslint-disable */
import React, { useEffect, useState } from 'react'
import './Dashboard.scss'
import Accordion from './../../shared/components/Acccordion/Accordion'
import tether from './../../assets/images/project/tether-small.png'
import celebrate from './../../assets/images/project/celebrate.png'
import aave from './../../assets/images/crypto/aaave-small.png'
import { Button } from 'react-bootstrap'
import RatesTable from './../../shared/components/RatesTable/RatesTable'
import LendingChart from './../../shared/components/chart/LendingChart'

import ModalComponent from './../../shared/components/Modal/ModalComponent'
import LendingModal from './../../shared/components/LendingModals'
import QuestionsAccordion from './../../shared/components/QuestionsAccordion/QuestionsAccordion'
import Sidebar from './../../shared/components/Sidebar'
import Config from './../../config/config'
import { cutNumber } from './../../utils/Utilities/transactionsUtils'
import connect_header from './../../assets/images/project/connect_header.svg'
import lockIcon from './../../assets/images/project/lockIcon.svg'
import { MdMenu } from 'react-icons/md'
import { MdArrowDropDown } from 'react-icons/md'
import { MdArrowDropUp } from 'react-icons/md'
import { Table } from 'react-bootstrap'

import {
    checkLocalStorage,
    checkLogin,
    getStoredUserAuth,
} from './../../utils/Utilities/contextUtils'
import { authContext } from './../../utils/context/AuthContext'
import { numberWithCommas } from './../../utils/number'
import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
import loader from './../../assets/images/project/loader.png'
import { useWeb3React } from '@web3-react/core'
import connectors from './../../utils/connections/walletConnectors'
import ProtocolsButtons from './../../shared/components/ProtocolsButtons/protocolsButtons'
import SignTransactionButton from './../../shared/components/SignTransactionButton/signTransactionButton'
import failed_error from './../../assets/images/project/failed-error.png'
import TokensDropdown from './../../shared/components/TokensDropdown/tokensDropdown'
import StartLending from './../../assets/images/project/start_lending.svg'
import CollectEarnings from './../../assets/images/project/collect_earnings.svg'
import BlankChartIcon from './../../assets/images/project/blank_chart_icon.svg'

//protocol balance icons
import SmallAaveImg from '../../assets/images/crypto/aaave-small.png'
import SmallCompoundImg from '../../assets/images/crypto/compound-small.png'
import SmallFulcrumImg from '../../assets/images/crypto/fulcrum-small.png'
import SmallYfiImg from '../../assets/images/crypto/yfi-small.png'
import SmallCurveImg from '../../assets/images/crypto/curve-small.png'
import { toK } from 'app/utils/context/PairContext/functions'

const GET_GRAPH_TRANSACTIONS = gql`
    query getGraphTransactionsV2(
        $walletAddress: String!
        $filter: String!
        $chainId: Int!
    ) {
        getGraphTransactionsV2(
            walletAddress: $walletAddress
            filter: $filter
            chainId: $chainId
        ) {
            response {
                status
                message
            }
            data {
                protocol {
                    protocolId
                    protocolName
                    protocolIcon
                }
                graphData {
                    x
                    y
                }
            }
        }
    }
`

const GET_USER_BALANCES = gql`
    query getUserBalances(
        $userId: Int!
        $chainId: Int!
        $protocolId: Int
        $tokenId: Int
    ) {
        getUserBalances(
            userId: $userId
            chainId: $chainId
            protocolId: $protocolId
            tokenId: $tokenId
        ) {
            protocolId
            protocolName
            protocolKey
            protocolIcon
            token {
                tokenId
                tokenName
                tokenIcon
                tokenSymbol
                balance {
                    interest
                    investment
                }
            }
        }
    }
`
const GET_ALL_PROTOCOL_RATES = gql`
    query getAllProtocolRates {
        getAllProtocolRates {
            data {
                tokenRates {
                    asset
                    lending
                    icon
                    tokenId
                }
                icon
                protocol
                protocolId
            }
            status
            message
        }
    }
`

const GET_USD_BALANCES = gql`
    query getUsdBalances($chainId: Int!, $userId: Int!) {
        getUsdBalances(chainId: $chainId, userId: $userId) {
            usdBalance
            protocolName
            protocolIcon
        }
    }
`

const GET_TRANSACTIONS = gql`
    query getTransactionsV2($chainId: Int, $walletAddress: String) {
        getTransactionsV2(chainId: $chainId, walletAddress: $walletAddress) {
            status
            message
            count
            transactions {
                amount
                transactionId
                targetData
                transactionId
                transactionHash
                transactionType
                transactionStatus
                from
                targetAddress
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`
const SUBSCRIBE_PENDING_PAYMENTS = gql`
    subscription subscribePendingPayments($walletAddress: String) {
        subscribePendingPayments(walletAddress: $walletAddress) {
            transaction {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                transactionType
                transactionStatus
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
                amount
            }
            status {
                message
                status
            }
        }
    }
`

const UPDATE_TRANSACTION_SIGNING = gql`
    mutation updateTransaction(
        $transactionId: Int!
        $transactionHash: String!
    ) {
        updateTransaction(
            transactionId: $transactionId
            transactionHash: $transactionHash
        ) {
            transactions {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                amount
                transactionType
                transactionStatus
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`
const SUBSCRIBE_BALANCE_OF = gql`
    subscription v2BalanceOf($walletAddress: String) {
        v2BalanceOf(walletAddress: $walletAddress) {
            status {
                status
                message
            }
            balance {
                protocolId
                protocolIcon
                protocolName
                protocolKey
                token {
                    tokenId
                    tokenName
                    tokenSymbol
                    tokenIcon
                    balance {
                        interest
                        investment
                    }
                }
            }
        }
    }
`
const SUBSCRIBE_CONFIRMED_PAYMENTS = gql`
    subscription subscribeConfirmedPayments($walletAddress: String) {
        subscribeConfirmedPayments(walletAddress: $walletAddress) {
            transaction {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                transactionType
                transactionStatus
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
                amount
            }
            status {
                message
                status
            }
        }
    }
`

const GET_CONNECTION_BALANCES = gql`
    query getConnectionBalances($walletAddress: String!, $chainId: Int!) {
        getConnectionBalances(
            walletAddress: $walletAddress
            chainId: $chainId
        ) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            token {
                tokenId
                tokenName
                tokenIcon
                tokenSymbol
                balance {
                    investment
                    interest
                }
            }
        }
    }
`

const GET_TOKENS = gql`
    query {
        getTokens {
            tokenSymbol
            tokenAddress
            tokenType
            tokenDecimals
            tokenIcon
            tokenId
        }
    }
`

const GET_PROTOCOLS = gql`
    query getProtocols($chainId: Int!) {
        getProtocols(chainId: $chainId) {
            protocolId
            protocolKey
            protocolName
            protocolIcon
            tokens {
                tokenId
                tokenName
                tokenIcon
                tokenType
                tokenSymbol
                tokenAddress
                tokenDecimals
            }
        }
    }
`

export const positionSummery = [
    {
        protocolIcon: 'crypto/aaave-small.png',
        protocolId: '',
        protocolKey: '',
        protocolName: '',
        token: [
            {
                balance: {
                    interest: '0.00000000',
                    investment: '0.00000000',
                },
                lendingRate: '',
                tokenIcon: 'crypto/aaave-small.png',
                tokenId: '',
                tokenName: '',
                tokenSymbol: '',
            },
        ],
    },
]

interface IProps {
    history: object
}
const Dashboard: React.FC<IProps> = ({ history }) => {
    /**
     * openModal cases
     * === -1 // don't open
     * === 0 // open wallets modal
     * === 1 // open Deposit modal
     * === 2 // open withdraw modal
     */
    const [openModal, setOpenModal] = useState(-1)
    const [userTokenBalance, setUserTokenBalance] = useState(0)
    // Withdraw sign transaction loading
    const [withdrawLoading, setWithdrawLoading] = useState<boolean>(false)
    const [showLendingModal, setShowLendingModal] = useState<
        false | 'deposit' | 'withdraw'
    >(false)
    const [graphTransactions, setGraphTransactions] = useState(false)
    // refetchProtocolsTokens to refetch tokens and protocols
    const [refetchProtocolsTokens, setRefetchProtocolsTokens] =
        useState<boolean>(false)
    // Activities State
    const [activities, setActivities] = useState<any>([])
    const [confirmedDeposits, setConfirmedDeposits] = useState<any>([])
    const [isOpen, setOpen] = React.useState(false)
    // activity State which will be passed to deposit or withdraw modal respectively
    const [pendingActivity, setPendingActivity] = useState<any>({})
    // Get user balances list
    const [balancesList, setBalancesList] = useState<any>()
    // single balance for a protocol and token
    const [balance, setBalance] = useState<any>({})
    // protocolId and tokenId for withdraw modal
    // we get data from rates table
    const [withdrawData, setWithdrawData] = useState<any>({})
    // set list of protocols.
    const [protocolList, setProtocolList] = useState<any>([])
    // set selected protocol for chart.
    const [chartSelectedProtocol, setChartSelectedProtocol] =
        useState<number>(-1)
    // set graph data.
    const [activitiesGraphData, setActivitiesGraphData] = useState<any>([])
    // set graph data.
    const [activitiesGraphMetaData, setActivitiesGraphMetaData] = useState<any>(
        []
    )
    // update transaction signings on confirmed payments return call
    const [updateTransactionSignings, { data: updateTransaction }] =
        useMutation(UPDATE_TRANSACTION_SIGNING)

    // set tokens
    const [tokens, setTokens] = useState([])
    const [gettingTokens, setGettingTokens] = useState(false)

    // set protocols
    const [protocols, setProtocols] = useState([])
    const [protocolRates, setProtocolRates] = React.useState<any>(false)

    const [gettingProtocols, setGettingProtocols] = useState(false)

    const [balances, setBalances] = useState<any>(false)
    const [totalBalance, setTotalBalance] = useState<any>(false)
    const [selectedProtocolBalance, setSelectedProtocolBalance] =
        useState<any>(false)
    const [selectedProtocol, setSelectedProtocol] = useState<any>(false)
    const [selectedToken, setselectedToken] = useState<Number>()
    const { auth: user, setAuthStatus } = React.useContext(authContext)

    // Apollo client defination
    const client = useApolloClient()
    // Confirmed payment subscriptions integration
    const { data: confirmPaymentData } = useSubscription(
        SUBSCRIBE_CONFIRMED_PAYMENTS,
        {
            variables: { walletAddress: user.walletAddress },
        }
    )

    React.useEffect(() => {
        client
            .query({
                query: GET_ALL_PROTOCOL_RATES,
            })
            .then(({ data }: any) => {
                const ratesObj = data.getAllProtocolRates.data
                setProtocolRates(ratesObj)
            })
    }, [client])

    // update transaction signing return in use effect
    React.useEffect(() => {
        if (updateTransaction) {
            getAllTransactions()
            getUserBalances()
        }
    }, [updateTransaction])

    // Show subscribeConfirmedPayments
    const [showConfirmSubscription, setShowConfirmSubscription] =
        useState<boolean>(false)
    // Context store

    /**
     * Web3 react core  useWeb3React
     */
    const context = useWeb3React()
    const { connector, account, activate, active, library } = context

    React.useEffect(() => {
        if (library) {
            library.on('blockNumber', (blockNumber: any) => {})
        }
    }, [library])

    React.useEffect(() => {
        if (localStorage.getItem('Walletaddress')) {
            refetchAllApis()
            getUserBalancesByFilter(1, 1)
        }
    }, [account, localStorage.getItem('Walletaddress')])

    React.useEffect(() => {
        if (user.walletAddress) {
            getUsdBalances()
            getUserBalances()
        }
    }, [user.walletAddress])

    // pending payment subscriptions integration
    const { data: pendingPaymentData } = useSubscription(
        SUBSCRIBE_PENDING_PAYMENTS,
        {
            variables: { walletAddress: user.walletAddress },
        }
    )

    const { data: v2BalanceOf } = useSubscription(SUBSCRIBE_BALANCE_OF, {
        variables: { walletAddress: user.walletAddress },
    })
    // Listener for blance subscription
    React.useEffect(() => {
        if (
            v2BalanceOf &&
            v2BalanceOf.v2BalanceOf.balance &&
            v2BalanceOf.v2BalanceOf.balance.length
        ) {
            calculateBalances(v2BalanceOf.v2BalanceOf.balance)
        }
    }, [v2BalanceOf])

    // Listener for payment subscription
    React.useEffect(() => {
        if (
            confirmPaymentData &&
            confirmPaymentData.subscribeConfirmedPayments
        ) {
            setTimeout(function () {
                refetchAllApis()
                getUserBalancesByFilter(
                    user.protocol.protocolId,
                    user.currency.tokenId
                )
            }, 2000)
        }
    }, [user.walletAddress, confirmPaymentData])
    // Listener for pending payment subscription
    React.useEffect(() => {
        if (pendingPaymentData && pendingPaymentData.subscribePendingPayments) {
            getAllTransactions()
        }
    }, [user.walletAddress, pendingPaymentData])
    /**
     * Call effect on wallet address change
     */

    const walletsImportObj = {
        MetaMask: connectors.Injected,
        Fortmatic: connectors.Fortmatic,
        Ledger: connectors.Ledger,
        Trezor: connectors.Trezor,
        Torus: connectors.Torus,
        Authereum: connectors.Authereum,
        SquareLink: connectors.SquareLink,
        WalletConnect: connectors.WalletConnect,
    }
    const [blanceLoading, setBalanceLoading] = useState(false)
    React.useEffect(() => {
        if (user.walletAddress) {
            getAllTransactions()
            if (user.accountType && !connector && !account) {
                if (user.accountType === 'METAMASK') {
                    activate(walletsImportObj['MetaMask'])
                }
            }
        }
    }, [user.walletAddress])
    /**
     * Check if user is authenticated
     * if there is no authContext check local storage
     * if there is data in local storage then update context else open wallet modal;
     */
    React.useEffect(() => {
        setOpenModal(-1)
        if (!checkLogin(user)) {
            if (checkLocalStorage()) {
                setAuthStatus(getStoredUserAuth())
            } else {
                setOpenModal(0)
            }
        }
        refetchAllApis()
    }, [])

    /**
     * Refetch all data
     */
    const refetchAllApis = () => {
        if (user.walletAddress.length > 0) {
            setOpenModal(-1)
            setPendingActivity({})
            getGraphTransaction()
            getAllTransactions()
            getConnectionBalances()
            getTokens()
            getProtocols()
            getUsdBalances()
            getUserBalances()
        }
        // getUserBalancesByFilter(user.protocol.protocolId, user.currency.tokenId)
        // setRefetchProtocolsTokens(true)
    }

    useEffect(() => {
        if (
            user.protocol &&
            user.protocol.protocolId &&
            user.currency &&
            user.currency.tokenId
        ) {
            getUserBalancesByFilter(
                user.protocol.protocolId,
                user.currency.tokenId
            )
        }
    }, [user.protocol, user.currency])

    /**
     * getUserBalances // get user balances
     * This function is called on init
     */
    const getUserBalances = () => {
        // client
        //     .query({
        //         query: GET_USER_BALANCES,
        //         variables: { chainId: Config.chainId, userId: user.userId },
        //         fetchPolicy: 'no-cache',
        //     })
        //     .then(({ data: balancesData }: any) => {
        //         client
        //             .query({
        //                 query: GET_ALL_PROTOCOL_RATES,
        //             })
        //             .then(({ data: ratesData }: any) => {
        //                 if (
        //                     balancesData &&
        //                     balancesData.getUserBalances &&
        //                     balancesData.getUserBalances.length > 0
        //                 ) {
        //                     for (
        //                         let i = 0;
        //                         i < balancesData.getUserBalances.length;
        //                         i++
        //                     ) {
        //                         // get same protocol obj index from ratesData list
        //                         const protocolObject =
        //                             ratesData.getAllProtocolRates.data.find(
        //                                 function (o: any) {
        //                                     return (
        //                                         o.protocolId ===
        //                                         balancesData.getUserBalances[i]
        //                                             .protocolId
        //                                     )
        //                                 }
        //                             )
        //                         for (
        //                             let j = 0;
        //                             j <
        //                             balancesData.getUserBalances[i].token
        //                                 .length;
        //                             j++
        //                         ) {
        //                             const balanceTokens =
        //                                 protocolObject.tokenRates.find(
        //                                     function (o: any) {
        //                                         return (
        //                                             o.tokenId ===
        //                                             balancesData
        //                                                 .getUserBalances[i]
        //                                                 .token[j].tokenId
        //                                         )
        //                                     }
        //                                 )
        //                             // Add rates data in balances list accordingly
        //                             balancesData.getUserBalances[i].token[j][
        //                                 'lendingRate'
        //                             ] = balanceTokens.lending
        //                         }
        //                     }
        //                     const tempObj = balancesData.getUserBalances
        //                     setBalancesList(tempObj)
        //                 }
        //             })
        //     })
    }

    /**
     * getUserBalances According to protocol and token // get user balances
     * This function is called on init
     */
    const getUserBalancesByFilter = (protocolId: any, tokenId: any) => {
        // if (user.walletAddress && protocolId && tokenId) {
        //   client
        //     .query({
        //       query: GET_USER_BALANCES,
        //       variables: {
        //         chainId: Config.chainId,
        //         userId: user.userId,
        //         protocolId: parseInt(protocolId),
        //         tokenId: parseInt(tokenId)
        //       }
        //     })
        //     .then(({data}: any) => {
        //       if (data.getUserBalances) {
        //         setBalance(data.getUserBalances[0]);
        //       } else {
        //         setBalance([]);
        //       }
        //     });
        // }
    }

    /**
     * getAllActivities // get all transactions api integration
     * This function is called on init
     */
    const getAllTransactions = () => {
        client
            .query({
                query: GET_TRANSACTIONS,
                variables: {
                    chainId: user.chainId,
                    walletAddress: user.walletAddress,
                },
                fetchPolicy: 'no-cache',
            })
            .then(({ data }: any) => {
                const reverse: any[] = [
                    ...data.getTransactionsV2.transactions,
                ].reverse()
                setActivities(reverse)
                setConfirmedDeposits(
                    reverse.filter(
                        (b: any) =>
                            b.transactionStatus === 'confirmed' &&
                            b.transactionType === 'deposit'
                    )
                )
                data.getTransactionsV2.transactions.map(
                    (activity: any, i: number) => {
                        if (
                            activity.transactionType === 'walletCreation' &&
                            activity.transactionStatus === 'confirmed'
                        ) {
                            localStorage.setItem('walletCreation', 'confirmed')
                        }
                    }
                )
            })
    }
    /**
     * Activity btn click function
     * We assign specific activity to activity state and also open modal accordingly
     * we will set modal step 2 and then sign activity there
     *
     */
    const activityBtnClick = (activity: any, clickEvent: string) => {
        /**
         * if wallet creation open modal and directly take it to step 2
         * if deposit then take flow to step 3 // todo
         * if withdraw open withdraw modal and // todo
         */
        if (
            clickEvent === 'walletCreation' ||
            activity.transactionType === 'walletCreation'
        ) {
            setOpenModal(1)
            setPendingActivity(activity)
        } else if (clickEvent === 'deposit') {
            setOpenModal(1)
            setPendingActivity(activity)
        } else if (clickEvent === 'withdraw') {
            // setOpenModal(2)
            setPendingActivity(activity)
        }
    }

    /**
     * Time converter timestamp => date time
     * 0 flag for 'Wednesday, Oct21th 2020 14:23:14 GMT-300'
     * 1 flag for 'DD-MMM-YY HH:mm:ss'
     */
    const timeConverter = (UNIX_timestamp: any, flag: number) => {
        let a = new Date(UNIX_timestamp * 1)
        let months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ]
        let days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ]
        let year = a.getFullYear()
        let month = months[a.getMonth()]
        let monthNumber = a.getMonth()
        let date = a.getDate()
        let day = days[a.getDay()]
        let hour = a.getHours()
        let min = a.getMinutes()
        let sec = a.getSeconds()
        let gmt = a.getTimezoneOffset()
        let time = ''
        if (flag === 0) {
            time =
                day +
                ', ' +
                month +
                ' ' +
                date +
                'th' +
                ' ' +
                year +
                ' ' +
                hour +
                ':' +
                min +
                ':' +
                sec +
                ' ' +
                'GMT' +
                gmt
        } else {
            time =
                date +
                '/' +
                monthNumber +
                '/' +
                year +
                ' ' +
                hour +
                ':' +
                min +
                ':' +
                sec
        }
        return time
    }

    // Sign transaction Functions/Events
    const signTransactionBefore = (signableActivity: any) => {
        setPendingActivity(signableActivity)
        setWithdrawLoading(true)
    }

    const signTransactionAfter = () => {
        setWithdrawLoading(false)
    }

    const signTransactionSuccess = async (signature: any) => {
        if (pendingActivity) {
            setShowConfirmSubscription(true)
            const { transactionId: txnId } = pendingActivity
            if (signature && txnId) {
                updateTransactionSignings({
                    variables: {
                        transactionId: txnId,
                        transactionHash: signature.hash,
                    },
                })
            } else {
            }
        }
    }

    const signTransactionError = (message: string) => {
        setShowConfirmSubscription(false)
    }

    const ratesTableOpenModalClick = (
        modalType: string,
        protocolId: number,
        tokenId: number
    ) => {
        if (modalType === 'deposit') {
            setOpenModal(1)
        } else {
            setWithdrawData({ protocolId, tokenId })
            setOpenModal(2)
        }
    }

    /**
     * get protocol list data from protocol buttons component
     * and pass this data to charts component
     */
    const passProtocolList = (protocolList: any) => {
        setProtocolList(protocolList)
    }

    // graph filter list
    const graphFilterList = [
        // {
        //   name: '1H',
        //   key: '1h'
        // },
        // {
        //   name: '4H',
        //   key: '4h'
        // },
        {
            name: '1D',
            key: '1d',
        },
        {
            name: '1W',
            key: '1w',
        },
        {
            name: '1M',
            key: '1m',
        },
        {
            name: '1Y',
            key: '1y',
        },
    ]
    const [chartSelectedFilter, setChartSelectedFilter] = useState('1w')
    const [graphActivities, setGraphActivities] = useState([])
    // balance selected according to chart protocol
    const [selectedChartProtocolBalance, setSelectedChartProtocolBalance] =
        useState(0)
    // list of usd balances
    const [usdBalanceList, setUsdBalanceList] = useState([])

    /**
     * getActivities for graph
     */
    useEffect(() => {
        if (graphActivities) {
            const balanceList: any = {}
            const graphData: any = []
            let graphTempData: any = {}
            protocolList.map((protocol: any) => {
                balanceList[protocol.protocolName] = 0
            })

            const reverse: any[] = [...graphActivities].reverse()

            reverse.map((activity: any) => {
                graphTempData = {
                    date: timeConverter(activity.createdAt, 1),
                    ...balanceList,
                }
                graphData.push(graphTempData)
                console.log('activity.createdAt', activity.createdAt)
                if (activity.protocolId && activity.protocolId.protocolId) {
                    if (chartSelectedProtocol === -1) {
                        if (activity.transactionType === 'deposit') {
                            balanceList[activity.protocolId.protocolName] =
                                balanceList[activity.protocolId.protocolName] +
                                parseFloat(activity.amount)
                        } else if (activity.transactionType === 'withdraw') {
                            balanceList[activity.protocolId.protocolName] =
                                balanceList[activity.protocolId.protocolName] -
                                parseFloat(activity.amount)
                        }
                        graphTempData = {
                            date: timeConverter(activity.createdAt, 1),
                            ...balanceList,
                        }
                        graphData.push(graphTempData)
                    } else {
                    }
                }
            })
            /**
             * Once graph data is done create meta data for graph
             */
            const graphMetaData: any = []
            const protocolKeyList = Object.keys(balanceList)
            if (chartSelectedProtocol === -1) {
                protocolKeyList.map((key: any) => {
                    graphMetaData.push({
                        dataKey: key,
                        strokeName: key,
                        strokeWidth: 5,
                        strokeOpacity: 1,
                    })
                })
            }
            setActivitiesGraphData(graphData)
            setActivitiesGraphMetaData(graphMetaData)
        }
    }, [graphActivities])

    useEffect(() => {
        let tempMData: any = []
        if (chartSelectedProtocol !== -1) {
            protocolList.map((protocol: any) => {
                if (protocol.protocolId === chartSelectedProtocol) {
                    activitiesGraphMetaData.map(
                        (graphMData: any, index: number) => {
                            if (graphMData.dataKey === protocol.protocolName) {
                                graphMData.strokeOpacity = 1
                                tempMData.push(graphMData)
                            } else {
                                graphMData.strokeOpacity = 0.2
                                tempMData.push(graphMData)
                            }
                        }
                    )
                }
            })
            setActivitiesGraphMetaData([...tempMData])

            // set Selected balance of graph according to protocol
            protocolList.map((protocol: any) => {
                if (protocol.protocolId === chartSelectedProtocol) {
                    usdBalanceList &&
                        usdBalanceList.map((balance: any) => {
                            if (
                                balance.protocolName === protocol.protocolName
                            ) {
                                setSelectedChartProtocolBalance(
                                    balance.usdBalance
                                )
                            }
                        })
                }
            })
        } else {
            activitiesGraphMetaData.map((graphMData: any, index: number) => {
                graphMData.strokeOpacity = 1
                tempMData.push(graphMData)
            })
            setActivitiesGraphMetaData([...tempMData])

            // set Selected balance of graph according to protocol
            usdBalanceList &&
                usdBalanceList.map((balance: any) => {
                    if (balance.protocolName === 'All') {
                        setSelectedChartProtocolBalance(balance.usdBalance)
                    }
                })
        }
    }, [chartSelectedProtocol, usdBalanceList])

    const onClickBlance = () => {
        if (localStorage.getItem('Walletaddress')) {
            getUsdBalances()
            getUserBalances()
        }
    }

    /**
     * getUsdBalances // get balances in usd
     * This function is called on init
     */
    const getUsdBalances = () => {
        // setBalanceLoading(true);
        // client
        //   .query({
        //     query: GET_USD_BALANCES,
        //     variables: {chainId: user.chainId, userId: user.userId},
        //     fetchPolicy: 'no-cache'
        //   })
        //   .then(({data}: any) => {
        //     setUsdBalanceList(data.getUsdBalances);
        //   });
    }

    const calculateBalances = (data: any) => {
        let totalBalance = 0

        const accumulated = data.map((p: any) => {
            const total = p.token.reduce(
                (accumulator: number, currentValue: any) =>
                    accumulator + parseFloat(currentValue.balance.investment),
                0
            )
            p.total = total
            totalBalance += total
            return p
        })

        setBalances(accumulated)
        setTotalBalance(totalBalance)
    }

    const [filteredProtocols, setFilteredProtocols] = useState<any>([])
    const [filteredDeposits, setFilteredDeposits] = useState<any>([])
    const [connectionProtocols, setConnectionProtocols] = useState<any>([])
    const [totalAmountLocked, setTotalAmountLocked] = useState<any>(0)
    const [avgInterest, setAvgInterest] = useState<any>(0)

    const findNestedSum = (arr) => {
        let sum = 0
        for (let len = 0; len < arr.length; len++) {
            sum += Array.isArray(arr[len]) ? findNestedSum(arr[len]) : arr[len]
        }
        return sum
    }

    const getConnectionBalances = () => {
        // setGettingProtocols(true);
        client
            .query({
                query: GET_CONNECTION_BALANCES,
                variables: {
                    walletAddress: user.walletAddress,
                    chainId: user.chainId,
                },
            })
            .then(({ data }: any) => {
                if (
                    data &&
                    data.getConnectionBalances &&
                    data.getConnectionBalances.length
                ) {
                    calculateBalances(data.getConnectionBalances)
                    setConnectionProtocols(data.getConnectionBalances)

                    setSelectedProtocolBalance(data.getConnectionBalances[0])
                    let total = 0
                    let tInterest = 0

                    let sum = data.getConnectionBalances.map((protocol) =>
                        protocol.token
                            .map((tok) => tok)
                            .map((el) => parseInt(el.balance.investment))
                    )
                    let interest = data.getConnectionBalances.map((protocol) =>
                        protocol.token
                            .map((tok) => tok)
                            .map((el) => parseInt(el.balance.interest))
                    )

                    for (let len = 0; len < sum.length; len++) {
                        total += Array.isArray(sum[len])
                            ? findNestedSum(sum[len])
                            : sum[len]

                        tInterest += Array.isArray(interest[len])
                            ? findNestedSum(interest[len])
                            : interest[len]
                    }

                    setTotalAmountLocked(total)
                    setAvgInterest(tInterest)

                    let newArr

                    newArr = data.getConnectionBalances.map((element) => {
                        return {
                            ...element,
                            token: element.token.filter(
                                (tok) => tok.balance.investment > 0
                            ),
                        }
                    })
                    setFilteredDeposits(newArr)
                }
            })
            .finally(() => setGettingProtocols(false))
    }

    const getGraphTransaction = () => {
        client
            .query({
                query: GET_GRAPH_TRANSACTIONS,
                variables: {
                    walletAddress: user.walletAddress,
                    filter: '',
                    chainId: user.chainId,
                },
            })
            .then(({ data }: any) => {
                if (data.getGraphTransactionsV2) {
                    setGraphTransactions(data.getGraphTransactionsV2.data)
                }
                // setTokens(data.getTokens);
            })
        // .finally(() => setGettingTokens(false));
    }

    const getTokens = () => {
        setGettingTokens(true)
        client
            .query({
                query: GET_TOKENS,
            })
            .then(({ data }: any) => {
                setTokens(data.getTokens)
            })
            .finally(() => setGettingTokens(false))
    }

    const getProtocols = () => {
        setGettingProtocols(true)
        client
            .query({
                query: GET_PROTOCOLS,
                variables: { chainId: user.chainId },
            })
            .then(({ data }: any) => {
                setProtocols(data.getProtocols)
            })
            .finally(() => setGettingProtocols(false))
    }

    const getTokenForProtocol = (id: number) =>
        balances.filter((protocol) => protocol.protocolId === id)

    const renderEmptyScreen = (status: boolean) => (
        <div className="row no-data-section">
            <div className="col-xl-8 d-flex flex-column pl-3 pt-4">
                <p className="blank-graph-screen-heading">
                    Lend your tokens and watch them earn interest!
                </p>
                <span className="no-data-txt">No data? No chart.</span>
                <p className="blank-graph-screen-sub-heading">
                    Safely lend your tokens to earn interest by clicking the
                    button below. Deposit and withdraw anytime.
                </p>
                <Button
                    className="start-lending-btn border-0 mt-auto mb-5"
                    onClick={() =>
                        user.walletAddress && setShowLendingModal('deposit')
                    }
                >
                    {status
                        ? `Start Lending ${
                              selectedProtocolBalance
                                  ? selectedProtocolBalance.protocolName.toUpperCase()
                                  : ''
                          }`
                        : 'Start Lending'}
                </Button>
            </div>
            <div className="col-xl-4 image-wrapper">
                <img src={BlankChartIcon} className="img-fluid" />
            </div>
        </div>
    )

    return (
        <>
            {/* <div className='dashboard-wrapper'> */}
            <div className="mb-5 mx-4 dashboard-page-wrapper">
                {/* <MdMenu
            size={30}
            className='pointer sidebar-toggle mb-3'
            onClick={() => document.body.classList.add('nav-open')}
          /> */}

                {/* <div className="row d-flex justify-content-center w-100 mb-5">
        <div className="col-12 d-flex justify-content-center">
          <Button className="btn-port center pointer">
            <span className="d-flex px-2 py-1 align-items-center">
              <text className="text-font">PORTFOLIO</text>
            </span>
          </Button>
        </div>
      </div> */}

                {/* <div className="d-flex justify-content-center mt-5 pt-2 mb-5 main-div"> */}
                {/* <div className="col-12 col-lg-11 col-xl-10 justify-content-center"> */}
                {/* toggle Switch */}
                {/* <div className="row align-items-center mb-4 mt-5">
            <label htmlFor="switch">Wallet View</label>
            <label id="switch" className="switch ml-2">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div> */}

                <div className="mb-5 mainContainerMarginTop">
                    <div>
                        <p className="lending-heading">Lending</p>
                        <div className="d-flex mb-5 balance-pills">
                            <div className="p-3 balance-box d-flex flex-column mr-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Total Amount Locked</span>
                                </div>
                                <div className="balance-box-amount">
                                    {/* <span>{`$ ${numberWithCommas(totalBalance.toFixed(3))}`}</span> */}
                                    <span>{`$  ${
                                        totalAmountLocked && totalAmountLocked
                                    }`}</span>
                                    {/* <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        9.69%
                                    </span> */}
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Average Interest Earned</span>
                                </div>
                                <div className="balance-box-amount">
                                    <span>{`$ ${
                                        avgInterest && avgInterest
                                    }`}</span>
                                    {/* <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        +12.79%
                                    </span> */}
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3 invisible">
                                <div className="mb-2 balance-box-txt">
                                    <span>Total Amount Locked</span>
                                </div>
                                <div className="balance-box-amount">
                                    <span>{`$ 1,890,467.34`}</span>
                                    <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        +2.79%
                                    </span>
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3 invisible">
                                <div className="mb-2 balance-box-txt">
                                    <span>Average Interest Earned</span>
                                </div>
                                <div className="balance-box-amount">
                                    <span>{`$ 390.33`}</span>
                                    <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        9.69%
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            {filteredDeposits && filteredDeposits.length > 0 && (
                                <>
                                    <span className="choice-heading">
                                        My Deposits
                                    </span>
                                    <Table
                                        className="table-rate-style mt-2"
                                        responsive
                                    >
                                        <thead>
                                            <tr>
                                                <th>Assets</th>
                                                <th className="text-center">
                                                    Deposited
                                                </th>
                                                <th className="text-center">
                                                    APY
                                                </th>
                                                <th className="text-center">
                                                    Interest Earned
                                                </th>
                                                <th className="text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredDeposits.map(
                                                (activity, index) =>
                                                    activity.token.map(
                                                        (deposit, index) => (
                                                            <>
                                                                <tr
                                                                    className="mb-3 mt-3"
                                                                    key={index}
                                                                >
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-start">
                                                                            <img
                                                                                src={require(`./../../assets/images/${activity.protocolIcon}`)}
                                                                                className="position-relative"
                                                                                width="30"
                                                                                height="30"
                                                                                style={{
                                                                                    left: '8px',
                                                                                }}
                                                                                alt={
                                                                                    activity.protocolName
                                                                                }
                                                                            />
                                                                            <img
                                                                                src={require(`./../../assets/images/${deposit.tokenIcon}`)}
                                                                                className="position-relative"
                                                                                width="30"
                                                                                height="30"
                                                                                style={{
                                                                                    right: '4px',
                                                                                }}
                                                                                alt={
                                                                                    deposit.tokenSymbol
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {
                                                                            parseFloat(
                                                                                deposit
                                                                                    .balance
                                                                                    .investment
                                                                            ).toFixed(
                                                                                2
                                                                            )

                                                                            // parseInt(
                                                                            //     deposit
                                                                            //         .balance
                                                                            //         .investment
                                                                            // ).toFixed(
                                                                            //     2
                                                                            // )
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        Demo
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {parseFloat(
                                                                            deposit
                                                                                .balance
                                                                                .interest
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <a href="">
                                                                            Withdraw
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    )
                                            )}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </div> */}
                        <div className="mt-2 mainDashboardTableContainer">
                            <span className="choice-heading">
                                Lend on any protocol of your choice
                            </span>
                            <RatesTable
                                onPressToken={setShowLendingModal}
                                setSelectedProtocol={setSelectedProtocol}
                                protocolsList={protocols}
                                SelectedToken={setselectedToken}
                                connectionBalances={connectionProtocols}
                                user={user}
                                userTokenBalance={setUserTokenBalance}
                            />
                        </div>
                    </div>
                    {/* <div className="col-lg-7 col-md-12 col-sm-12 col-sm-12 text-left mb-5 p-0">
                        <QuestionsAccordion />
                    </div> */}
                    <div className="col-9 ">
                        {/* {balances && (
              <div
                className="d-flex flex-wrap protocol-balance"
                style={{ justifyContent: "space-evenly" }}
              >
                {totalBalance !== false && (
                  <div className="protocol-balance-box total-balance d-flex flex-column mx-1 mb-3">
                    <div className="p-3 protocol-balance-box-txt">
                      <span>Total Balance</span>
                    </div>
                    <div className="px-3 protocol-balance-box-amount">
                      <span>{`$ ${numberWithCommas(
                        totalBalance.toFixed(3)
                      )}`}</span>
                    </div>
                    <Accordion isOpen={isOpen}>
                      <div className="row justify-content-center pl-1 pr-5 pl-3 pr-lg-3">
                        <div className="col-12 d-block justify-content-start">
                          <div className="description">
                            You are viewing{" "}
                            <span>
                              all
                              <br /> balances.
                            </span>{" "}
                            To hide, click
                            <br />
                            on “Hide Balances.”
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                )}
                {balances.map((b: any) => (
                  <div
                    className={`pointer mx-1 mb-3 protocol-balance-box ${
                      b.protocolKey === selectedProtocolBalance.protocolKey &&
                      totalBalance > 0
                        ? "active"
                        : ""
                    }`}
                    onClick={() => setSelectedProtocolBalance(b)}
                  >
                    <div className="d-flex align-items-center p-3 protocol-balance-box-txt">
                      <img
                        src={require(`./../../assets/images/${b.protocolIcon}`)}
                      />
                      {b.protocolName !== "Dydx" && (
                        <span className="ml-2">{b.protocolName}</span>
                      )}
                    </div>
                    <div
                      className={`px-3 protocol-balance-box-amount ${
                        !Boolean(Number(b.total)) && "in-active"
                      }`}
                    >
                      <span>{`$ ${numberWithCommas(b.total.toFixed(3))}`}</span>
                    </div>
                    <Accordion isOpen={isOpen}>
                      <div className="position-summery-separator-div"></div>
                      <div className="row justify-content-center pl-3 pr-5 pl-lg-3 pr-lg-3 token-section">
                        {getTokenForProtocol(b.protocolId).map(
                          (protocol: any) => (
                            <div className="col-12 d-block justify-content-start">
                              {protocol.token &&
                                protocol.token.map((token: any, index: any) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-start mb-2"
                                  >
                                    <img
                                      src={require(`./../../assets/images/${token.tokenIcon}`)}
                                      alt=""
                                    />
                                    <p className="position-summery-p">
                                      {numberWithCommas(
                                        parseFloat(
                                          token.balance.investment
                                        ).toFixed(2)
                                      )}{" "}
                                      <span className="in-active">
                                        {token.tokenSymbol}
                                      </span>
                                      <br />
                                      <span className="position-summery-span">
                                        +$
                                        {numberWithCommas(
                                          parseFloat(
                                            token.balance.interest
                                          ).toFixed(2)
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                ))}
                            </div>
                          )
                        )}
                      </div>
                    </Accordion>
                  </div>
                ))}
              </div>
            )} */}
                        {/* {balances && (
              <div className="d-flex mt-2 mb-4 justify-content-center align-items-center">
                <div
                  className="d-flex balances-btn align-items-center justify-content-between"
                  onClick={() => setOpen(!isOpen)}
                >
                  <span className="balances-btn-text">
                    {isOpen ? "Hide Balances" : "All Balances"}
                  </span>
                  {isOpen ? (
                    <MdArrowDropUp className="icon" />
                  ) : (
                    <MdArrowDropDown className="icon" />
                  )}
                </div>
              </div>
            )} */}
                        {/* <div
              className={`row graph-div overflow-hidden ${
                totalBalance <= 0 ||
                !selectedProtocolBalance ||
                !Boolean(Number(selectedProtocolBalance.total))
                  ? "p-0"
                  : ""
              }`}
            >
              {totalBalance > 0 ? (
                selectedProtocolBalance &&
                Boolean(Number(selectedProtocolBalance.total)) ? (
                  <>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      {selectedProtocolBalance && (
                        <div className="d-flex align-items-center">
                          <img
                            src={require(`./../../assets/images/${selectedProtocolBalance.protocolIcon}`)}
                            className="protocol-icon mr-2"
                          />
                          <p className="graph-header m-0">USD Balance</p>
                        </div>
                      )}
                      <div className="d-flex flex-wrap align-items-center">
                        {graphFilterList &&
                          graphFilterList.map((filter) => {
                            return (
                              <div
                                className={`graph-filter-number-div${
                                  filter.key === chartSelectedFilter
                                    ? " active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setChartSelectedFilter(filter.key);
                                }}
                              >
                                {filter.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  
                    <div className="col-12 d-flex justify-content-lg-start mt-4">
                      {selectedProtocolBalance && (
                        <div className="d-flex">
                          <p className="graph-balance-p">
                            $
                            {numberWithCommas(
                              selectedProtocolBalance.total.toFixed(3)
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-12 d-flex justify-content-center justify-content-lg-start mt-4 px-2">
                    
                      <LendingChart
                        graphData={graphTransactions}
                        selectedProtocol={selectedProtocolBalance}
                      />
                    </div>
                  </>
                ) : (
                  renderEmptyScreen(true)
                )
              ) : (
                renderEmptyScreen(false)
              )}
            </div> */}
                        {/* Protocol and Balance row */}

                        {/* <div className="row mt-4">
              <div
                className="col-md-6 px-0 pr-xl-3 pr-3 btn-wrapper"
                onClick={() =>
                  user.walletAddress
                    ? setShowLendingModal("deposit")
                    : setOpenModal(0)
                }
              >
                <Button className="btn-lending-earning lending-btn shadow-none w-100">
                  <img
                    src={StartLending}
                    alt="Start Lending"
                    className="d-none d-sm-block btn-icon"
                  />
                  <div className="text-center text-sm-left w-100">
                    <div>Start Lending</div>
                    <div>Safely earn passive income</div>
                  </div>
                </Button>
              </div>
              <div
                className="col-md-6 px-0 pl-xl-3 pl-3 btn-wrapper"
                onClick={() =>
                  user.walletAddress
                    ? setShowLendingModal("withdraw")
                    : setOpenModal(0)
                }
              >
                <div className="">
                  <Button className="btn-lending-earning earning-btn shadow-none w-100">
                    <img
                      src={CollectEarnings}
                      alt="Collect Earnings"
                      className="d-none d-sm-block btn-icon"
                    />
                    <div className="text-center text-sm-left w-100">
                      <div>Collect Earnings</div>
                      <div>Cash out anytime</div>
                    </div>
                  </Button>
                </div>
              </div>
            </div> */}

                        {/* <div className="row justify-content-center mt-4">
              <div className="dashboard-card col-12 px-3 px-md-5 pt-4 pb-3 mt-lg-0">
                <RatesTable
                  onPressToken={setShowLendingModal}
                  setSelectedProtocol={setSelectedProtocol}
                  protocolsList={protocols}
                />
              </div>
            </div> */}
                        {/* Recent Activities div */}
                        {/* <div className="row justify-content-center mt-4">
              <div className="dashboard-card col-12 px-3 px-md-5 pt-4 pb-3 mt-5 mt-lg-0">
                <div className="mt-3 mb-5">
                  <p className="header">Recent Activity</p>
                  <p className="subheader">
                    View history and resolve pending actions here.
                  </p>
                </div>
                {activities && activities.length > 0 ? (
                  activities.map((activity: any, i: number) => {
                    return (
                      <div
                        className={`activity-row-div-${
                          (activities.length - i) % 2 === 0 ? "even" : "odd"
                        } row justify-content-start p-3 activity-table`}
                      >
                        <div className="col-8 col-xs-6 d-flex justify-content-between align-items-center activity-table-sm">
                          <div className="d-flex justify-content-start align-items-center activity-table">
                            <p className="activities-no-p">
                              {activities.length - i}
                            </p>
                            <div className="d-table align-items-center ml-2 ml-lg-4">
                              <p className="activity-heading-p ">
                                <span className="activityType ">
                                  {activity.transactionType === "tokenRecieved"
                                    ? "Fund Received"
                                    : activity.transactionType ===
                                      "walletCreation"
                                    ? "Wallet Creation"
                                    : activity.transactionType}
                                </span>{" "}
                                <span className="capitalize">
                                  {activity.transactionStatus}{" "}
                                </span>
                                {activity.transactionType === "walletCreation"
                                  ? ""
                                  : `- ${activity.amount}`}{" "}
                                {activity.tokenId
                                  ? activity.tokenId.tokenSymbol
                                  : ""}
                              </p>
                              <p
                                className={
                                  "activity-date-p pointer " +
                                  (activity.transactionStatus === "confirmed"
                                    ? "clickable"
                                    : "")
                                }
                                onClick={() => {
                                  if (
                                    activity.transactionStatus === "confirmed"
                                  ) {
                                    if (user.chainId === 42) {
                                      window.open(
                                        `${Config.etherscanKovanUrl}/${activity.transactionHash}`,
                                        "_blank"
                                      );
                                    } else {
                                      window.open(
                                        `${Config.etherscanMainUrl}/${activity.transactionHash}`,
                                        "_blank"
                                      );
                                    }
                                  }
                                }}
                              >
                                {timeConverter(activity.createdAt, 0)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-xs-6 d-flex justify-content-end align-items-center activity-img-sm">
                          {activity.protocolId &&
                            activity.protocolId.protocolId && (
                              <div className="d-flex justify-content-start align-items-center mr-4">
                                {activity.protocolId.protocolIcon && (
                                  <img
                                    src={require(`./../../assets/images/${activity.protocolId.protocolIcon}`)}
                                    alt=""
                                    className="activity-protocol-img"
                                  />
                                )}

                                <p className="activity-protocol-p ">
                                  {activity.protocolId.protocolName}
                                </p>
                              </div>
                            )}
                          {activity.transactionStatus === "confirmed" ? (
                            <img
                              src={celebrate}
                              alt="celebrate"
                              className="activity-img"
                            />
                          ) : activity.transactionStatus === "pending" ? (
                            <div className="loader">
                              <img
                                src={loader}
                                alt="loader"
                                style={{ width: "20px" }}
                              />
                            </div>
                          ) : activity.transactionStatus === "unsigned" &&
                            activity.transactionType === "walletCreation" ? (
                            <div className="row ">
                              <Button
                                className="w-100 btn-gradient-round "
                                onClick={() =>
                                  activityBtnClick(activity, "walletCreation")
                                }
                              >
                                <span>Create Wallet</span>
                              </Button>
                            </div>
                          ) : activity.transactionStatus === "pending" &&
                            activity.transactionType === "walletCreation" ? (
                            <div className="row ">
                              <div className="loader">
                                <img
                                  src={loader}
                                  alt="loader"
                                  style={{ width: "30px" }}
                                />
                              </div>
                            </div>
                          ) : activity.transactionStatus === "unsigned" &&
                            activity.transactionType === "deposit" ? (
                            <div className="row ">
                              <Button
                                className="w-100 btn-gradient-round btn-gradient-wrapper  btn-txt"
                                onClick={() =>
                                  activityBtnClick(activity, "deposit")
                                }
                              >
                                <span>Deposit</span>
                              </Button>
                            </div>
                          ) : activity.transactionStatus === "pending" &&
                            activity.transactionType === "deposit" ? (
                            <div className="row ">
                              <div className="loader">
                                <img
                                  src={loader}
                                  alt="loader"
                                  style={{ width: "30px" }}
                                />
                              </div>
                            </div>
                          ) : activity.transactionStatus === "unsigned" &&
                            activity.transactionType === "withdraw" ? (
                            <div className="row ">
                              {showConfirmSubscription ? (
                                <div className="loader">
                                  <img
                                    src={loader}
                                    alt="loader"
                                    style={{ width: "30px" }}
                                  />
                                </div>
                              ) : (
                                <SignTransactionButton
                                  title={"Sign"}
                                  loadingTitle={"Signing..."}
                                  loading={withdrawLoading}
                                  signTransactionBefore={() =>
                                    signTransactionBefore(activity)
                                  }
                                  signTransactionAfter={signTransactionAfter}
                                  signTransactionSuccess={
                                    signTransactionSuccess
                                  }
                                  signTransactionError={signTransactionError}
                                  transaction={activity}
                                  shouldSignTransactionFlag={true}
                                />
                              )}
                            </div>
                          ) : activity.transactionStatus === "pending" &&
                            activity.transactionType === "withdraw" ? (
                            <div className="row ">
                              <div className="loader">
                                <img
                                  src={loader}
                                  alt="loader"
                                  style={{ width: "30px" }}
                                />
                              </div>
                            </div>
                          ) : activity.transactionStatus === "failed" ? (
                            <div className="row ml-0">
                              <div className="error">
                                <img
                                  src={failed_error}
                                  alt="loader"
                                  style={{
                                    width: "30px",
                                    marginRight: "13px"
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={`activity-row-div-odd row justify-content-start p-3`}
                  >
                    <div className="col-7 d-flex justify-content-between align-items-center flex-inherit">
                      <div className="d-flex justify-content-start align-items-center flex-inherit">
                        <p className="activities-no-p">1</p>
                        <div className="d-table align-items-center ml-2 ml-lg-4">
                          <p className="activity-heading-p ">
                            <span className="activityType">
                              Welcome! Make your first deposit.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ml-auto d-flex justify-content-end align-items-center">
                      <Button
                        className="w-100 btn-gradient-round btn-gradient-wrapper btn-txt"
                        onClick={() =>
                          user.walletAddress
                            ? setShowLendingModal("deposit")
                            : setOpenModal(0)
                        }
                      >
                        <span>Deposit</span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
                    </div>
                    {/* <div className='col-12 col-lg-2 justify-content-center mr-lg-4 left-main-div h-100'>
            
            <div className='row total-balance-div mb-2'>
              <div className='col-12 d-block justify-content-start p-4'>
                <p className='total-balance-title mt-1'>Balance</p>
                {usdBalanceList &&
                  usdBalanceList.map((balance: any) => {
                    return (
                      balance.protocolName === 'All' && (
                        <p className='medium-heading pt-1'>
                          ${balance.usdBalance.toFixed(2)}
                        </p>
                      )
                    );
                  })}

                <p className='sm-light-text-p m-0 '>
                  This is your total balance across all DeFi assets.
                </p>
              </div>
            </div>
            
            <div className='row total-balance-div mb-4'>
              <div className='col-12 d-block justify-content-start p-0'>
                <Accordion title={'Positions Summary'}>
                  <div className='row justify-content-center pl-5 pr-5 pl-lg-3 pr-lg-3'>
                    {balances && balances.length > 0 ? (
                      balances.map((protocol: any) => {
                        return (
                          <>
                            <div className='col-12 d-block justify-content-start'>
                              <div className='d-flex justify-content-start align-items-center mb-3 mt-3'>
                                <img
                                  src={require(`./../../assets/images/${protocol.protocolIcon}`)}
                                  alt='protocol'
                                  className={`summery-protocol-img ${
                                    protocol.protocolName === 'Dydx' &&
                                    'summary-protocol-dydx-logo'
                                  }`}
                                />
                                <p className='position-summery-protocol-p'>
                                  {protocol.protocolName}
                                </p>
                              </div>
                              {protocol.token &&
                                protocol.token.map((token: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      className='d-flex justify-content-start align-items-center mb-2'
                                    >
                                      <img
                                        src={require(`./../../assets/images/${token.tokenIcon}`)}
                                        alt=''
                                        className='summery-t-img'
                                      />
                                      <p className='position-summery-p'>
                                        {token.tokenSymbol}:{' '}
                                        {parseFloat(
                                          token.balance.investment
                                        ).toFixed(2)}{' '}
                                        (
                                        {token.lendingRate && (
                                          <span className='position-summery-span '>
                                            +{token.lendingRate.toFixed(2)}%
                                          </span>
                                        )}
                                        )
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className='position-summery-separator-div'></div>
                          </>
                        );
                      })
                    ) : (
                      <p className='position-summery-protocol-p'>
                        No Investments
                      </p>
                    )}
                  </div>
                </Accordion>
              </div>
            </div>

            <div className='row total-balance-div mb-2'>
              <div className='col-12 d-block justify-content-start p-0'>
                <Accordion title={'DeFi Shortcuts'}>
                  <div className='row justify-content-center pl-3 pr-3'>
                    <div className='text-center text-lg-left p-2 mt-2'>
                      <p className='mid-light-title-p'>Withdraw All </p>
                      <p className='mid-light-grey-p mb-4'>
                        Withdraw all of your positions, from all DeFi Protocols,
                        at once.
                      </p>
                      <div className='d-flex justify-content-center'>
                        <Button
                          className='w-75 btn-blue btn-defi-shortcuts'
                          onClick={() => {
                            setOpenModal(2);
                          }}
                        >
                          <span>Close All Positions</span>
                        </Button>
                      </div>
                    </div>
                    <div className='position-summery-separator-div'></div>
                    <div
                      id='mid-light'
                      className='text-center text-lg-left p-2'
                    >
                      <p className='mid-light-title-p'>
                        Move Assets to the Best Performing Protocol
                      </p>
                      <p className='mid-light-grey-p'>
                        Redeposit all assets into the best performing protocol{' '}
                      </p>
                      <p className='position-summery-p'>
                        <span className='position-summery-span'>
                          Best performing
                        </span>
                      </p>
                      {user && user.bestProtocol && user.bestProtocol.protocol && (
                        <div className='d-flex justify-content-center justify-content-lg-start align-items-center mb-3 mt-3'>
                          <img
                            src={require(`./../../assets/images/${user.bestProtocol.icon}`)}
                            alt=''
                            className='summery-protocol-img'
                          />
                          <p className='position-summery-protocol-p'>
                            {user.bestProtocol.protocol}
                          </p>
                        </div>
                      )}
                      <div className='d-flex justify-content-center'>
                        <Button
                          className='w-75 btn-blue btn-defi-shortcuts'
                          onClick={() => {
                            setOpenModal(1);
                          }}
                        >
                          <span>Redeposit</span>
                        </Button>
                      </div>
                    </div>

                    <div className='position-summery-separator-div'></div>

                    <div
                      id='mid-light'
                      className='text-center text-lg-left p-2'
                    >
                      <p className='mid-light-title-p'>
                        Deposit, Mix, and Lend
                      </p>
                      <p className='mid-light-grey-p'>
                        Mix your assets with BL3ND3Ro to make your transactions
                        fully untraceable before you start lending.
                      </p>
                      <div className='d-flex justify-content-center'>
                        <Button className='w-75 btn-blue btn-defi-shortcuts'>
                          <span>Mix Assets</span>
                        </Button>
                      </div>
                    </div>

                    <div className='position-summery-separator-div'></div>

                    <div
                      id='mid-light'
                      className='text-center text-lg-left p-2'
                    >
                      <p className='mid-light-title-p'>
                        Deposit, Insure, and Lend
                      </p>
                      <p className='mid-light-grey-p'>
                        Insure your assets before you start lending.
                      </p>
                      <div className='d-flex justify-content-center'>
                        <Button className='w-75 btn-blue btn-defi-shortcuts'>
                          <span>Insurance</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div> */}
                </div>
                <div className="row mx-auto">
                    <div className="col-md-6 col-12 px-0">
                        <QuestionsAccordion />
                    </div>
                </div>
                {/* </div> */}
                {openModal === 0 && (
                    <ModalComponent
                        visible={true}
                        isWalletModal={openModal === 0 ? true : false}
                        onClose={() => {
                            setOpenModal(-1)
                            refetchAllApis()
                        }}
                    />
                )}
                {openModal === 1 && (
                    <ModalComponent
                        visible={true}
                        isDepositModal={openModal === 1 ? true : false}
                        pendingActivity={pendingActivity}
                        allActivities={activities}
                        onClose={() => {
                            refetchAllApis()
                        }}
                    />
                )}
                {openModal === 2 && (
                    <ModalComponent
                        visible={true}
                        isWithdrawModal={openModal === 2 ? true : false}
                        pendingActivity={pendingActivity}
                        withdrawData={withdrawData}
                        balancesList={balances}
                        onClose={() => {
                            refetchAllApis()
                        }}
                    />
                )}
                {/* </div> */}
                {showLendingModal && (
                    <>
                        <LendingModal
                            selectedId={
                                selectedProtocolBalance
                                    ? selectedProtocolBalance.protocolId
                                    : false
                            }
                            showLendingModal={showLendingModal}
                            setShowLendingModal={setShowLendingModal}
                            protocols={connectionProtocols}
                            tokens={tokens}
                            selectedToken={selectedToken}
                            gettingProtocols={gettingProtocols}
                            gettingTokens={gettingTokens}
                            balances={balances}
                            onGotoDashboard={refetchAllApis}
                            selectedProtocol={
                                selectedProtocol ? selectedProtocol : false
                            }
                            userTokenBalance={userTokenBalance}
                        />
                    </>
                )}
            </div>

            {/* </div> */}
        </>
    )
}
export default Dashboard
