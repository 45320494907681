import add_eth from 'app/assets/images/project/eth_small_blue.svg'
// import dai_icon from 'app/assets/images/project/dai_icon.svg';

export const supportedPools = [
    {
        pid: 1,
        address: '0xe805730341d3f49dd404947ccb6f9bd6f6e978ce',
        name: 'ADD-ETH UNI-V2 LP',
        multiplier: '1x',
        icon: add_eth,
        tokenAddress: '0x635d081fd8f6670135d8a3640e2cf78220787d56',
        apr: 6.43,
        earn: 'ADD',
        claimReward: 0,
    },
]
