/* eslint-disable */
import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { authContext } from '../../utils/context/AuthContext'
import { provider } from 'web3-core'
import connectors from '../../utils/connections/walletConnectors'
import Modal from 'react-modal'

// components
import { Button } from 'react-bootstrap'
import StakingModals from '../../shared/components/Staking'

// styles
import './style.scss'

// assets
import liquiditySummary from '../../assets/images/project/logo_bg_trans.svg'
import help from '../../assets/images/project/help.svg'
import usdt from '../../assets/images/project/tether_usdt.svg'
import addLiquidityIcon from '../../assets/images/project/addLiquidityIcon.svg'
import removeLiquidityIcon from '../../assets/images/project/removeLiquidityIcon.svg'
import swapIcon from '../../assets/images/project/swapIcon.svg'
import Eth from '../../assets/images/project/eth_add.svg'
import LiquidityAnalytics from 'app/shared/components/Staking/LiquidityAnalytics'
import { formattedNum } from 'app/utils/context/PairContext/functions'
import useAllPairs from 'app/hooks/useAllPairs'
import useBlock from 'app/hooks/useBlock'
import PairChart from 'app/shared/components/chart/PairChart'
import QuestionsAccordion from 'app/shared/components/QuestionsAccordion/QuestionsAccordion'
import AmountModal from 'app/shared/components/Staking/AmountModal'
import arrow_down from '../../assets/images/arrow_down.svg'

// utils
import { getContract, getWeb3 } from '../../utils/Utilities/erc20'
import {
    convertToNumber,
    getPendingReward,
    getAddPerBlock,
    getStartBlock,
    getEndBlock,
    stakeOrUnstake,
    truncateDecimalPoints,
    getUserInfo,
} from '../../utils/Utilities/transactionsUtils'
import { showNotification } from '../../utils/notifications/notificationService'
import Config from 'app/config/config'
import { supportedPools } from 'app/config/constants'
import StakingTour from './../../shared/components/Staking/Tour'
import DashboardHeader from '../../shared/components/Header/DashboardHeader'
interface IProps {
    pid: number
}
const customStyles = () => ({
    content: {
        top: '50%',
        left: '50%',
        zIndex: 999,
        padding: '0px',
        transform: 'translate(-50%, -50%)',
        height: '80%',
        background: 'none',
    },
})

const Staking: React.FC<IProps> = ({ pid }) => {
    // hooks
    const allPairs = useAllPairs()
    const {
        auth: user,
        selectedPair,
        setSelectedPair,
        setChangePercent,
    } = React.useContext(authContext)

    const context = useWeb3React<Web3Provider>()
    const { connector, account, activate, library } = context

    // States
    const [openModal, setOpenModal] = useState<any>(false)
    const [show, setShow] = useState(false)

    const handleClose = () => setOpenModal(false)
    const handleShow = () => setOpenModal(true)
    const [amountModal, setAmountModal] = useState<any>(false)
    const [balance, setBalance] = useState('0')
    const [totalSupply, setTotalSupply] = useState('0')
    const [lpBalance, setLpBalance] = useState('0')
    const [rewardAmount, setRewardAmount] = useState<any>(0)
    const [rewardPerBlock, setRewardPerBlock] = useState('0')
    const [blockNumber, setBlockNumber] = useState<any>('0')
    const [blockTime, setBlockTime] = useState<any>()
    const [isRewarding, setIsRewarding] = useState(false)
    const [isLiquidityRewarding, setIsLiquidityRewarding] = useState(false)
    const [totalRewardEarned, setTotalRewardEarned] = useState('0')
    const [endBlock, setEndBlock] = useState(0)
    const [blockDiff, setBlockDiff] = useState(0)
    const [startBlock, setStartBlock] = useState(0)

    const blockNo = useBlock()

    React.useEffect(() => {
        if (library) {
            getBlocksLeft()
        }
    }, [blockNo])

    const getBlocksLeft = async () => {
        const block = await getBlocksNumber()
        if (block) {
            setStartBlock(block.startBlockNumber)
            if (blockNo > block.startBlockNumber) {
                const result =
                    blockNo < block.endBlockNumber
                        ? block.endBlockNumber - blockNo
                        : 0
                setBlockNumber(result.toString())
            } else if (blockNo < block.startBlockNumber) {
                const result = block.startBlockNumber - blockNo
                setBlockNumber(result.toString())
            } else {
                setBlockNumber(0)
            }
        }
    }

    const handleStakeOrUnstake = async () => {
        setIsRewarding(true)
        setIsLiquidityRewarding(true)
        try {
            const masterchefContract = getContract(
                library.provider as provider,
                Config.masterchefAddress,
                true
            )
            const webInstance = getWeb3(library.provider as provider)
            const amountDeposited = webInstance.utils.toWei('0', 'ether')
            const tx = await stakeOrUnstake(
                masterchefContract,
                allPairs[selectedPair].pid,
                amountDeposited,
                account,
                'deposit'
            )

            if (tx && tx.status) {
                showNotification(`${'deposit'} successful!`, 'success')
            }
            setIsRewarding(false)
            setIsLiquidityRewarding(false)
        } catch (e) {
            setIsRewarding(false)
            setIsLiquidityRewarding(false)
        }
    }

    const secondsToDhm = (sec: any) => {
        console.log('SEC ', sec)

        const days = Math.floor(sec / (3600 * 24))
        const hours = Math.floor((sec % (3600 * 24)) / 3600)
        const minutes = Math.floor((sec % 3600) / 60)

        const timeObj = {
            days: days,
            hours: hours,
            minutes: minutes,
        }

        setBlockTime(timeObj)
    }

    const getTotalLps = async () => {
        const lp = getContract(
            library.provider as provider,
            allPairs[selectedPair].address,
            false
        )
        const webInstance = getWeb3(library.provider as provider)
        let balance = webInstance.utils.fromWei(
            await lp.methods.balanceOf(account).call()
        )
        setLpBalance(truncateDecimalPoints(balance))

        // setLpBalance(truncateDecimalPoints(convertToNumber(balance)))
    }

    const getBlocksNumber = async () => {
        const masterChefContract = getContract(
            library?.provider as provider,
            Config.masterchefAddress,
            true
        )
        const poolInfo = await masterChefContract.methods
            .poolInfo(allPairs[selectedPair].pid)
            .call()

        const startBlock = poolInfo.fromBlock
        const endBlock = poolInfo.toBlock
        let endToStartBlockDiff: number

        if (startBlock > blockNo) {
            endToStartBlockDiff = Number(startBlock) - Number(blockNo)
        } else {
            endToStartBlockDiff = Number(endBlock) - Number(blockNo)
        }

        setEndBlock(Number(endBlock))

        secondsToDhm(endToStartBlockDiff * 17)
        setBlockDiff(blockNo)

        const obj = {
            startBlockNumber: startBlock,
            endBlockNumber: endBlock,
        }
        return obj
    }

    const walletsImportObj = {
        MetaMask: connectors.Injected,
        Fortmatic: connectors.Fortmatic,
        Ledger: connectors.Ledger,
        Trezor: connectors.Trezor,
        Torus: connectors.Torus,
        Authereum: connectors.Authereum,
        SquareLink: connectors.SquareLink,
        WalletConnect: connectors.WalletConnect,
    }

    React.useEffect(() => {
        if (user.walletAddress) {
            if (user.accountType && !connector && !account) {
                if (user.accountType === 'METAMASK') {
                    activate(walletsImportObj['MetaMask'])
                }
            }
        }
    }, [user.walletAddress])

    const getBalance = async (masterChefContract: any) => {
        const webInstance = getWeb3(library.provider as provider)
        let balance = await getUserInfo(
            allPairs[selectedPair].pid,
            masterChefContract,
            account
        )
        const lp = getContract(
            library.provider as provider,
            allPairs[selectedPair].address,
            false
        )

        let totalSupply = await lp.methods.totalSupply().call()

        const change = balance > 0 ? (balance / totalSupply) * 100 : 0

        setChangePercent(change)

        balance = webInstance.utils.fromWei(balance.toString())

        setBalance(truncateDecimalPoints(balance))
    }

    const getRewardandBalanceAmount = async () => {
        const masterChefContract = getContract(
            library.provider as provider,
            Config.masterchefAddress,
            true
        )
        getBalance(masterChefContract)

        const pendingReward = await getPendingReward(
            allPairs[selectedPair].pid,
            masterChefContract,
            account
        )
        const converetdRewardAmount: number = convertToNumber(pendingReward)
        converetdRewardAmount > 0 &&
            setRewardAmount(truncateDecimalPoints(converetdRewardAmount))

        console.log('allPairs[selectedPair].pid', allPairs[selectedPair].pid)

        const poolDetail = await masterChefContract.methods
            .poolInfo(allPairs[selectedPair].pid)
            .call()
        console.log('poolDetail ', poolDetail)

        const addPerBlock = poolDetail.addPerBlock
        setRewardPerBlock(truncateDecimalPoints(convertToNumber(addPerBlock)))
    }

    const getTotalPoolRewardsEarned = () => {
        let totalPoolRewardsEarned = 0
        const masterChefContract = getContract(
            library.provider as provider,
            Config.masterchefAddress,
            true
        )
        supportedPools.forEach(async (p: any) => {
            const pendingReward = await getPendingReward(
                p.pid,
                masterChefContract,
                account
            )
            totalPoolRewardsEarned += convertToNumber(pendingReward)
            if (totalPoolRewardsEarned > 0) {
                setIsLiquidityRewarding(false)
            } else {
                setIsLiquidityRewarding(true)
            }
            setTotalRewardEarned(truncateDecimalPoints(totalPoolRewardsEarned))
        })
    }

    React.useEffect(() => {
        if (library) {
            getRewardandBalanceAmount()
            getTotalPoolRewardsEarned()
            getTotalLps()
        }
    }, [selectedPair, library])

    //   This is the function call to get addPerBlock

    // masterChef.methods.addPerBlock()

    // This is to get farming start block number

    // masterChef.methods.startBlock()

    // This is to get details block number when farming stops

    // masterChef.methods.endBlock()

    return (
        <>
            {/* <StakingTour /> */}
            {!openModal && (
                <div className="d-flex  align-items-center px-3">
                    <div className="add-xyz staking-ui mainContainerMarginTop">
                        <div className="d-flex justify-content-between title-div align-items-center">
                            <text className="staking-heading">Staking</text>
                            <div className="mt-sm-2">
                                <button
                                    onClick={() =>
                                        window.open(
                                            `https://app.uniswap.org/#/add/v2/${allPairs[selectedPair].tokenAddress}/ETH`,
                                            '_blank'
                                        )
                                    }
                                    className="small-btn-primary mr-3"
                                >
                                    + Add Liquidity
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            `https://app.uniswap.org/#/remove/v2/${allPairs[selectedPair].tokenAddress}/ETH`,
                                            '_blank'
                                        )
                                    }
                                    className="small-btn-primary transparent-btn"
                                >
                                    Remove Liquidity
                                </button>
                            </div>
                        </div>
                        <div className="d-flex mb-5 balance-pills mt-5">
                            <div className="p-3 balance-box d-flex flex-column mr-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Total Liquidity</span>
                                </div>
                                <div className="balance-box-amount d-flex justify-content-between">
                                    {/* <span>{`$ ${numberWithCommas(totalBalance.toFixed(3))}`}</span> */}
                                    <span>
                                        {' '}
                                        {allPairs[selectedPair].totalLiquidity}
                                    </span>
                                    <div className="ml-3 apy-text d-flex align-items-center">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        {allPairs[selectedPair].liquidityChange}
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Volume</span>
                                </div>
                                <div className="balance-box-amount d-flex justify-content-between">
                                    <span>{allPairs[selectedPair].volume}</span>
                                    <div className="ml-3 apy-text  d-flex align-items-center">
                                        <img
                                            src={
                                                // allPairs[selectedPair].volumeChange > 0 ?

                                                // require(`./../../assets/icons/Shape.svg`) :
                                                arrow_down
                                            }
                                        />
                                        {allPairs[selectedPair].volumeChange}
                                        {console.log(
                                            'volume',
                                            allPairs[selectedPair]
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Blocks Left</span>
                                </div>
                                <div className="balance-box-amount">
                                    <span>{`$ 1,890,467.34`}</span>
                                    <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        5,900
                                    </span>
                                </div>
                            </div>
                            <div className="p-3 balance-box d-flex flex-column mx-2 mb-3">
                                <div className="mb-2 balance-box-txt">
                                    <span>Total Amount Locked</span>
                                </div>
                                <div className="balance-box-amount">
                                    <span>{`$ 390.33`}</span>
                                    <span className="ml-3 apy-text">
                                        <img
                                            src={require(`./../../assets/icons/Shape.svg`)}
                                        />
                                        $1,890,467.34
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-5 mx-0">
                            <div className="staking-card col-12 col-lg-12 mt-4">
                                <div className="mt-4">
                                    <PairChart
                                        name={allPairs[selectedPair].name}
                                        address={allPairs[selectedPair].address}
                                        color={'rgba(39, 111, 255, 0.85)'}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-6">
              <div className="select-pool-header text-center text-lg-left mt-1 mt-lg-0">
                Select a Uniswap Pool:
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex justify-content-end align-items-center wrap-items">
                {allPairs.map((pair: any, ind: any) => {
                  return (
                    <Button
                      key={ind}
                      className={
                        pair.pid == selectedPair
                          ? "select-pool-button active mt-1 mt-lg-0 ml-lg-1"
                          : "select-pool-button mt-1 mt-lg-0 ml-lg-1"
                      }
                      onClick={() => {
                        setSelectedPair(pair.pid);
                      }}
                    >
                      <img src={pair.icon} alt="" className="pool-icon" />
                      <span>{pair.name}</span>
                    </Button>
                  );
                })}
              </div>
            </div>
          </div> */}

                        {/* <div className="row justify-content-around staking-card-round wrap-items py-4 mb-4 mt-4">
            <div className="staking-card-box">
              <div className="justify-content-between align-items-center w-100">
                <span className="staking-card-box-title">Total Liquidity</span>
                <div className="centered-view">
                  <span className="staking-card-box-value">
                    {allPairs[selectedPair].totalLiquidity}
                  </span>
                  <span className="staking-card-box-change">
                    {allPairs[selectedPair].liquidityChange}
                  </span>
                </div>
              </div>
            </div>
            <div className="staking-card-box">
              <div className="justify-content-between align-items-center w-100">
                <span className="staking-card-box-title">Volume</span>
                <div className="centered-view">
                  <span className="staking-card-box-value">
                    {allPairs[selectedPair].volume}
                  </span>
                  <span className="staking-card-box-change">
                    {allPairs[selectedPair].volumeChange}
                  </span>
                </div>
              </div>
            </div>
            <div className="staking-card-box">
              <div className="justify-content-between align-items-center w-100">
                <span className="staking-card-box-title">Fee</span>
                <div className="centered-view">
                  <span className="staking-card-box-value">
                    {allPairs[selectedPair].fees}
                  </span>
                  <span className="staking-card-box-change">
                    {allPairs[selectedPair].volumeChange}
                  </span>
                </div>
              </div>
            </div>
            <div className="staking-card-box">
              <div className="justify-content-between align-items-center w-100">
                <span className="staking-card-box-title">
                  Pooled{" "}
                  {allPairs[selectedPair].token0?.symbol === "WETH"
                    ? "ETH"
                    : allPairs[selectedPair].token0?.symbol ?? ""}
                </span>
                <div className="centered-view">
                  <span className="staking-card-box-value">
                    {allPairs[selectedPair].reserve0
                      ? formattedNum(allPairs[selectedPair].reserve0)
                      : ""}
                  </span>
                  <span className="staking-card-box-change positive">{""}</span>
                </div>
              </div>
            </div>
            <div className="staking-card-box">
              <div className="justify-content-between align-items-center w-100">
                <span className="staking-card-box-title">
                  Pooled{" "}
                  {allPairs[selectedPair].token1?.symbol === "WETH"
                    ? "ETH"
                    : allPairs[selectedPair].token1?.symbol ?? ""}
                </span>
                <div className="centered-view">
                  <span className="staking-card-box-value">
                    {allPairs[selectedPair].reserve1
                      ? formattedNum(allPairs[selectedPair].reserve1)
                      : ""}
                  </span>
                  <span className="staking-card-box-change">{""}</span>
                </div>
              </div>
            </div>
          </div> */}

                        {/* <div className="row justify-content-between flex-wrap wrap-items mb-5">
            <div
              className="pointer col-10 col-lg-6 px-0 pr-lg-3"
              onClick={() =>
                window.open(`https://app.uniswap.org/#/swap`, "_blank")
              }
            >
              <div className={"staking-token-card px-3 "}>
                <div className="d-flex flex-column justify-content-between">
                  <span className="get-token-text">Get Tokens</span>
                  <span className="swap-token-text">
                    Swap tokens on Uniswap
                  </span>
                </div>

                <div className="">
                  <img src={swapIcon} alt="" />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex row justify-content-between wrap-items p-0 m-0">
              <div className="col-10 col-lg-6 staking-liquidity-card  p-0 pr-lg-2">
                <div
                  className="pointer green-staking-box h-100 first-step"
                  onClick={() =>
                    window.open(
                      `https://app.uniswap.org/#/add/v2/${allPairs[selectedPair].tokenAddress}/ETH`,
                      "_blank"
                    )
                  }
                >
                  <div className="d-flex flex-column justify-content-between">
                    <span className="liquidity-top-text">Add Liquidity</span>
                    <span className="liquidity-bottom-text">
                      Earn rewards by adding liquidity on Uniswap
                    </span>
                  </div>
                  <div className="mx-2">
                    <img src={addLiquidityIcon} alt="" />
                  </div>
                </div>
              </div>

              <div className="col-10 col-lg-6 staking-liquidity-card p-0 pl-lg-2">
                <div
                  className="pointer orange-staking-box h-100"
                  onClick={() =>
                    window.open(
                      `https://app.uniswap.org/#/remove/v2/${allPairs[selectedPair].tokenAddress}/ETH`,
                      "_blank"
                    )
                  }
                >
                  <div className="d-flex flex-column justify-content-between">
                    <span className="liquidity-top-text">Remove Liquidity</span>
                    <span className="liquidity-bottom-text">
                      Pause earnings and remove liquidity
                    </span>
                  </div>
                  <div className="mx-2">
                    <img src={removeLiquidityIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

                        {/* <div className="row justify-content-between mb-5 flex-wrap wrap-items">
            <div className="col-12 col-lg-6 pr-lg-2 px-0">
              <div className={"staking-reward-card"}>
                <div className="d-flex flex-column align-items-center">
                  <div className="mt-4 mb-4">
                    <img
                      src={liquiditySummary}
                      alt="ADD.xyz Logo"
                      className="d-block mx-auto"
                    />
                  </div>
                  <span className="claim-text mb-2">{rewardAmount}</span>
                  <span className="add-earned-text mb-4">ADD</span>
                  <div className="mb-4">
                    <Button
                      className={
                        isRewarding
                          ? `claim-reward-btn ${isRewarding ? "disabled" : ""}`
                          : "claim-reward-btn"
                      }
                      disabled={(!rewardAmount && blockNo < endBlock) || (isRewarding && blockNo < endBlock)}
                      onClick={()=>
                        blockNo > endBlock
                          ? setOpenModal(4)
                          : handleStakeOrUnstake
                      }
                         >
                      <span> 
                        {blockNo > endBlock? 'Unstake' : isRewarding ? "Claiming Rewards" : "Claim Reward"}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 pl-lg-2 px-0">
              <div className="staking-reward-card">
                <div className="d-flex flex-column align-items-center">
                  <div className="mt-4 mb-4 col-12 d-flex justify-content-center position-relative">
                    <img src={Eth} alt="ADD.xyz Logo" />
                  </div>
                  <span className="claim-text mb-2">{lpBalance}</span>
                  <div className="mb-4">
                    <span className="add-earned-text">
                      {allPairs[selectedPair].name}
                    </span>
                  </div>
                  <div className="mb-4 d-flex justify-content-around w-100 wrap-items">
                    <Button
                      className="claim-reward-btn"
                      onClick={() => setOpenModal(4)}
                    >
                      <span>Unstake</span>
                    </Button>
                    <Button
                      className="stake-reward-btn second-step"
                      onClick={() => setOpenModal(4)}
                    >
                      <span>Stake</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

                        <LiquidityAnalytics
                            startBlock={startBlock}
                            currentBlockNumber={blockNo}
                            yourLiquidity={balance}
                            rewardsPerBlock={rewardPerBlock}
                            blockNumber={blockNumber}
                            blockTime={blockTime}
                            handleStakeOrUnstake={handleStakeOrUnstake}
                            isLiquidityRewarding={isLiquidityRewarding}
                            totalRewardEarned={totalRewardEarned}
                            endBlock={endBlock}
                            blockDiff={blockDiff}
                            setOpenModal={setOpenModal}
                            showAmountModal={setAmountModal}
                            show={setShow}
                        />
                        <div className="col-lg-7 col-md-8 col-sm-12 col-sm-12 text-left mb-5 p-0">
                            <QuestionsAccordion />
                        </div>

                        {/* <Button
            className="faq-button mt-5 mb-5 mx-auto"
            onClick={() =>
              window.open("https://about.add.xyz/staking-liquidity-dashboard")
            }
          >
            <div className="d-flex justify-content-center align-items-center">
              <img src={help} alt="" className="mr-1" />
              <span className="text-decoration-none faq-text ml-1">FAQ?</span>
            </div>
          </Button> */}
                    </div>
                </div>
            )}

            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body></Modal.Body>
                {console.log('open modal', openModal)}
          
            </Modal> */}
            <Modal
                ariaHideApp={false}
                isOpen={openModal ? true : false}
                // onRequestClose={closeModal}
                contentLabel="Wallet Modal"
                style={customStyles()}
                shouldCloseOnOverlayClick={false}
                className="lending-modals-ui lending-modal-card modal-style-lending  p-4"
            >
                <StakingModals
                    showSwapModal={openModal === 1}
                    showDepositModal={openModal === 2}
                    showWithdrawModal={openModal === 3}
                    showAddStakingModal={openModal === 4}
                    startBlock={startBlock}
                    currentBlockNumber={blockNo}
                    yourLiquidity={balance}
                    rewardsPerBlock={rewardPerBlock}
                    blockNumber={blockNumber}
                    blockTime={blockTime}
                    onBack={setOpenModal}
                    totalRewardEarned={totalRewardEarned}
                    getRewardandBalanceAmount={getRewardandBalanceAmount}
                    endBlock={endBlock}
                    blockDiff={blockDiff}
                />
            </Modal>

            {/* <Modal
                ariaHideApp={false}
                isOpen={openModal ? true : false}
                contentLabel="Wallet Modal"
                shouldCloseOnOverlayClick
                className=""
                style={customStyles}
            >
                <StakingModals
                    showSwapModal={openModal === 1}
                    showDepositModal={openModal === 2}
                    showWithdrawModal={openModal === 3}
                    showAddStakingModal={openModal === 4}
                    startBlock={startBlock}
                    currentBlockNumber={blockNo}
                    yourLiquidity={balance}
                    rewardsPerBlock={rewardPerBlock}
                    blockNumber={blockNumber}
                    blockTime={blockTime}
                    onBack={setOpenModal}
                    totalRewardEarned={totalRewardEarned}
                    getRewardandBalanceAmount={getRewardandBalanceAmount}
                    endBlock={endBlock}
                    blockDiff={blockDiff}
                />
            </Modal> */}

            {/* {openModal && (
                <StakingModals
                    showSwapModal={openModal === 1}
                    showDepositModal={openModal === 2}
                    showWithdrawModal={openModal === 3}
                    showAddStakingModal={openModal === 4}
                    startBlock={startBlock}
                    currentBlockNumber={blockNo}
                    yourLiquidity={balance}
                    rewardsPerBlock={rewardPerBlock}
                    blockNumber={blockNumber}
                    blockTime={blockTime}
                    onBack={setOpenModal}
                    totalRewardEarned={totalRewardEarned}
                    getRewardandBalanceAmount={getRewardandBalanceAmount}
                    endBlock={endBlock}
                    blockDiff={blockDiff}
                />
            )} */}
        </>
    )
}

export default Staking
