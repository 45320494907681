/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { authContext } from './../../../utils/context/AuthContext'
import { Dropdown } from 'react-bootstrap'

interface IProps {
    isDashboardPage?: boolean
    refetchTokens?: boolean
    setCurrencyInDeposit?: Function
    changeDetected?: Function
}

const GET_TOKENS = gql`
    query getTokens {
        getTokens {
            tokenSymbol
            tokenId
            tokenDecimals
            tokenName
            tokenIcon
            tokenType
            tokenAddress
        }
    }
`

const TokensDropdown: React.FunctionComponent<IProps> = (props) => {
    const { auth: user, setAuthStatus } = React.useContext(authContext)
    const client = useApolloClient()

    const [tokenArray, setTokenArray] = useState<any>([])
    const [selectedToken, setSelectedToken] = useState<any>({})

    // integrate get tokens api
    useEffect(() => {
        client
            .query({
                query: GET_TOKENS,
            })
            .then(({ data }: any) => {
                if (data) {
                    console.log('get tokens', data)
                    setTokenArray(data.getTokens)
                    setSelectedToken(data.getTokens[0])
                }
            })
    }, [client, props.refetchTokens])

    useEffect(() => {
        if (selectedToken) {
            if (props.isDashboardPage) {
                user.currency = selectedToken
                setAuthStatus(user)
                setTokenArray([...tokenArray])
                props.changeDetected && props.changeDetected()
                console.log('user', user)
            } else {
                props.setCurrencyInDeposit &&
                    props.setCurrencyInDeposit(selectedToken)
            }
        }
    }, [selectedToken])

    return (
        <>
            <div
                className={`${
                    props.isDashboardPage
                        ? 'justify-content-end w-50 '
                        : 'col-12 justify-content-center '
                } d-flex  text-center mt-3`}
            >
                <Dropdown
                    className="mx-1"
                    style={{
                        width: `${props.isDashboardPage ? '80%' : '38%'}`,
                    }}
                >
                    <Dropdown.Toggle
                        className="w-100 pl-3 pr-3  d-flex justify-content-between align-items-center mb-4 dropdown-with-img"
                        id="dropdown-basic"
                    >
                        <div className="d-flex  justify-content-between ">
                            {selectedToken && selectedToken.tokenId && (
                                <div className="d-flex text-dark p-1 align-items-center">
                                    {selectedToken.tokenIcon &&
                                    selectedToken.tokenIcon !== '' ? (
                                        <img
                                            src={require(`./../../../assets/images/${selectedToken.tokenIcon}`)}
                                            className="ml-2 p-1 pro_icosize"
                                            alt="protocol"
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <div className=" ml-1">
                                        <span
                                            className=""
                                            style={{
                                                color: 'white',
                                                fontSize: '18px',
                                            }}
                                        >
                                            {selectedToken.tokenSymbol}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border elementstyle w-100 dropdown-with-img">
                        {tokenArray.length > -1 &&
                            tokenArray.map((token: any, index: any) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        className="box w-100"
                                    >
                                        <div>
                                            {token &&
                                                selectedToken &&
                                                token.tokenId !==
                                                    selectedToken.tokenId && (
                                                    <div
                                                        className="d-flex text-dark p-1 "
                                                        onClick={() => {
                                                            setSelectedToken(
                                                                token
                                                            )
                                                        }}
                                                    >
                                                        {token.tokenIcon &&
                                                        token.tokenIcon !==
                                                            '' ? (
                                                            <img
                                                                src={require(`./../../../assets/images/${token.tokenIcon}`)}
                                                                className="ml-2 p-1 pro_icosize"
                                                                alt="protocol"
                                                                style={{
                                                                    width: '30px',
                                                                    height: '30px',
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <div className=" ml-1">
                                                            <span
                                                                className=""
                                                                style={{
                                                                    color: 'white',
                                                                    fontSize:
                                                                        '18px',
                                                                }}
                                                            >
                                                                {token.tokenSymbol &&
                                                                    token.tokenSymbol}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </Dropdown.Item>
                                )
                            })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

TokensDropdown.defaultProps = {}

export default TokensDropdown
