import React, { useState, useEffect } from 'react'

// components
import HowTo from '../../shared/components/BlenderHome/HowTo'
import ModalComponent from '../../shared/components/Modal/ModalComponent'
// contexts
import { authContext } from '../../utils/context/AuthContext'

// assets
import MixerImage from '../../assets/images/blender/mixer.svg'
import Layer from '../../assets/images/blender/layer.svg'
import LockImage from '../../assets/images/blender/lock.svg'
import Transfer from '../../assets/images/blender/transfer.svg'

// styles
import './style.scss'

interface IProps {
    history: any
}
const BlenderLanding: React.FC<IProps> = ({ history }) => {
    const { auth: user } = React.useContext(authContext)
    const [openModal, setOpenModal] = useState(-1)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const earnInstructions = [
        {
            title: 'Deposit your Funds',
            desc: 'Connect your Metamask or other supported wallets to our platform',
        },
        {
            title: 'Wait for a while',
            desc: 'Deposit supported cryptocurrencies and approve the transaction',
        },
        {
            title: 'Withdraw your Funds',
            desc: 'That’s it! You will start earning interest on your deposits.',
        },
    ]

    const renderConnectWalletButton = () => (
        <>
            {!user.walletAddress && (
                <button onClick={() => setOpenModal(0)} className="primary-btn">
                    Connect your Wallet
                </button>
            )}
        </>
    )

    const howToHeader = () => (
        <>
            How
            <br />
            <span>Bl3nd3r works</span>
            <br />
        </>
    )

    return (
        <div className="lending-page-ui">
            <h1 className="header">
                A Privacy Mixer to Keep Your Transactions Confidential and
                Secure
            </h1>
            <p className="sub-header">
                Bl3nd3r, a cryptocurrency privacy mixer that shields your
                transactions with the help of zero-knowledge proofs ensuring
                complete anonymity, confidentiality, and security to your crypto
                transactions.
            </p>

            <div className="d-flex flex-column justify-content-center align-items-center">
                {renderConnectWalletButton()}
                {user.walletAddress && (
                    <button
                        onClick={() => history.push('/blender')}
                        className="primary-btn"
                    >
                        <img
                            src={Transfer}
                            alt="transfer img"
                            className={'mr-2 mb-1'}
                        />
                        Mix Coin
                    </button>
                )}
                <img
                    src={MixerImage}
                    alt="blender img"
                    className="mixer-img img-fluid"
                />
            </div>

            <div className="middle-section mx-auto">
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-56 mr-lg-3">
                        <p className="middle-title mb-4">
                            Your Finances Deserve Privacy and Security
                        </p>
                        <p className="middle-description mb-4">
                            No one should be able to watch your financial
                            history and spending habits. To provide preeminent
                            confidentiality and safety without compromising any
                            benefits of tamper-proof decentralization, we have
                            built Bl3nd3r for you to protect your most important
                            data i.e., your financial transactions.
                        </p>
                    </div>
                    <div className="flex-0-0-44 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 ml-lg-3">
                        <img
                            src={Layer}
                            alt="Earn"
                            className="d-block img-fluid mx-auto"
                        />
                    </div>
                </div>
            </div>

            <HowTo headerText={howToHeader()} instructions={earnInstructions} />
            <div className="middle-section mx-auto">
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center w-100">
                    <div className="flex-0-0-44 pb-3 mb-4 pb-lg-0 mb-lg-0 w-100 ml-lg-3">
                        <img
                            src={LockImage}
                            alt="Earn"
                            className="d-block img-fluid mx-auto"
                        />
                    </div>
                    <div className="flex-0-0-56 mr-lg-3">
                        <p className="middle-title mb-4">
                            What are Zero- knowledge proofs?
                        </p>
                        <p className="middle-description mb-4">
                            Zero-knowledge proofs (ZKP) verify the data without
                            revealing any bits of the data. This is done with
                            the help of a ‘secret’. With Zero-knowledge Proofs,
                            the prover proves to the verifier that they possess
                            the secret without revealing the secret or conveying
                            any other information apart from the fact that they
                            know .
                        </p>
                    </div>
                </div>
            </div>
            {openModal === 0 && (
                <ModalComponent
                    visible
                    isWalletModal={openModal === 0 ? true : false}
                    onClose={() => setOpenModal(-1)}
                />
            )}
        </div>
    )
}

export default BlenderLanding
