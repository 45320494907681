import { useEffect, useState, useRef } from 'react'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import { getWeb3NoAccount } from 'app/utils/web3'
import { Web3Provider } from '@ethersproject/providers'
import { provider } from 'web3-core'

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the provider change
 */
const useWeb3 = () => {
    const context = useWeb3React<Web3Provider>()
    const { library } = context
    const refEth = useRef(library)
    const [web3, setweb3] = useState(
        library ? new Web3(library.provider as provider) : getWeb3NoAccount()
    )

    useEffect(() => {
        setweb3(
            library
                ? new Web3(library.provider as provider)
                : getWeb3NoAccount()
        )
    }, [library])

    return web3
}

export default useWeb3
