/* eslint-disable */
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import aave from './../../../assets/images/crypto/aaave-small.png'
import './DepositModal.scss'
import loader from './../../../assets/images/project/loader.png'
import copy from './../../../assets/images/project/copy.png'
import QRCode from 'qrcode.react'
import check from './../../../assets/images/project/check.png'
import CopyToClipboard from 'react-copy-to-clipboard'
import { authContext } from './../../../utils/context/AuthContext'
import { showNotification } from './../../../utils/notifications/notificationService'
import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
import SignTransactionButton from './../SignTransactionButton/signTransactionButton'
import { getSignableTransaction } from './../../../utils/Utilities/transactionsUtils'
import ProtocolsButtons from '../ProtocolsButtons/protocolsButtons'
import Config from './../../../config/config'
import TokensDropdown from '../TokensDropdown/tokensDropdown'
import { useWeb3React } from '@web3-react/core'

// dropdown vector assets
// import topVector from './../../../assets/images/project/vector-top.png'
// import bottomVector from './../../../assets/images/project/vector-bottom.png'

const UPDATE_TRANSACTION_SIGNING = gql`
    mutation updateTransaction(
        $transactionId: Int!
        $transactionHash: String!
    ) {
        updateTransaction(
            transactionId: $transactionId
            transactionHash: $transactionHash
        ) {
            transactions {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                amount
                transactionType
                transactionStatus
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`

const GET_ENCODED_ADD_INVESTMENT = gql`
    query getEncodedAddInvestment(
        $transactionId: Int!
        $protocolId: Int!
        $chainId: Int!
    ) {
        getEncodedAddInvestment(
            transactionId: $transactionId
            protocolId: $protocolId
            chainId: $chainId
        ) {
            transactionId
            transactionHash
            from
            targetAddress
            targetData
            amount
            transactionType
            transactionStatus
            createdAt
            tokenId {
                tokenSymbol
                tokenId
                tokenName
                tokenIcon
                tokenAddress
                tokenDecimals
            }
            protocolId {
                protocolName
                protocolId
                protocolKey
                protocolIcon
                protocolRatesKey
                protocolInvestContract
                protocolRegistryContract
            }
        }
    }
`

const SUBSCRIBE_PENDING_PAYMENTS = gql`
    subscription subscribePendingPayments($walletAddress: String) {
        subscribePendingPayments(walletAddress: $walletAddress) {
            transaction {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                transactionType
                transactionStatus
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
                amount
            }
            status {
                message
                status
            }
        }
    }
`

const SUBSCRIBE_CONFIRMED_PAYMENTS = gql`
    subscription subscribeConfirmedPayments($walletAddress: String) {
        subscribeConfirmedPayments(walletAddress: $walletAddress) {
            transaction {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                transactionType
                transactionStatus
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
                amount
            }
            status {
                message
                status
            }
        }
    }
`

const ADD_USER_MUTATION = gql`
    mutation addUser(
        $walletAddress: String!
        $accountType: AccountType!
        $chainId: Int
    ) {
        addUser(
            walletAddress: $walletAddress
            accountType: $accountType
            chainId: $chainId
        ) {
            walletAddress
            contractAddress
            accountType
            walletCreation
            userId
        }
    }
`
enum AccountTypeServer {
    METAMASK = 'METAMASK',
    FORTMATIC = 'FORTMATIC',
}
interface IProps {
    pendingActivity?: any
    allActivities: any[]
}
const DepositModal: React.FC<IProps> = (props) => {
    const context = useWeb3React()

    const { connector, chainId, account, activate, error } = context

    const [stepNo, setStepNo] = useState(1)
    const [transactionsList, setTransactionsList] = useState<any>([])
    const [signableTransaction, setSignableTransaction] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    // Show subscribeConfirmedPayments
    const [showConfirmSubscription, setShowConfirmSubscription] =
        useState<boolean>(false)
    // confirmed
    const { auth: user } = React.useContext(authContext)
    const client = useApolloClient()
    const [addUser, { data: addUserData }] = useMutation(ADD_USER_MUTATION)
    /**
     * If there are any pending activity call from dashboard first handle it
     * change step no accordingly
     * if the activity is wallet creation then show step 2
     * if activity is deposit then show step 3
     * todo: other case like first step
     */
    React.useEffect(() => {
        console.log('props.pendingActivity', props.pendingActivity)
        if (props.pendingActivity) {
            setSignableTransaction(props.pendingActivity)
            setStepAccordingToActivity(props.pendingActivity)
        }
    }, [props.pendingActivity])

    /**
     * setStep
     */
    const setStepAccordingToActivity = (activity: any) => {
        if (
            activity &&
            activity.transactionType === 'walletCreation' &&
            activity.transactionStatus === 'unsigned'
        ) {
            setSignableTransaction(activity)
            setStepNo(2)
        } else if (
            stepNo !== 2 &&
            activity.transactionType === 'deposit' &&
            activity.transactionStatus === 'unsigned'
        ) {
            setStepNo(3)
        } else if (
            activity.transactionType === 'walletCreation' &&
            activity.transactionStatus === 'confirmed'
        ) {
            setStepNo(3)
        } else if (
            activity.transactionType === 'deposit' &&
            activity.transactionStatus === 'confirmed'
        ) {
            setStepNo(5)
        }
    }
    /**
     * Get All activities from dashboard and show step accordingly
     */
    React.useEffect(() => {
        if (props.allActivities.length > 0) {
            console.log(
                'props.allActivities.length',
                props.allActivities.length
            )
            setTransactionsList(props.allActivities)
            getSignableTransaction(props.allActivities).then(
                (transactionObj: any) => {
                    console.log('transactionObj', transactionObj)
                    setSignableTransaction(transactionObj.transaction)
                    setStepAccordingToActivity(transactionObj.transaction)
                }
            )
        }
    }, [props.allActivities])

    // update transaction signings on confirmed payments return call
    const [updateTransactionSignings, { data: updateTransaction }] =
        useMutation(UPDATE_TRANSACTION_SIGNING)
    React.useEffect(() => {
        if (updateTransaction) {
            console.log('updateTransaction', updateTransaction)
        }
    }, [updateTransaction])
    // Pending payment subscriptions integration
    const { data: pendingPaymentData } = useSubscription(
        SUBSCRIBE_PENDING_PAYMENTS,
        {
            variables: { walletAddress: user.walletAddress },
        }
    )
    // COnfirmed payment subscriptions integration
    const { data: confirmPaymentData } = useSubscription(
        SUBSCRIBE_CONFIRMED_PAYMENTS,
        {
            variables: { walletAddress: user.walletAddress },
        }
    )
    // Listener for payment subscription
    React.useEffect(() => {
        console.log('pendingPaymentData', pendingPaymentData)
        console.log('user.walletAddress', user.walletAddress)
        console.log('confirmPaymentData', confirmPaymentData)
        setShowConfirmSubscription(false)
        if (pendingPaymentData && pendingPaymentData.subscribePendingPayments) {
            setTransactionList(
                pendingPaymentData.subscribePendingPayments.transaction
            )
            setStepAccordingToActivity(
                pendingPaymentData.subscribePendingPayments.transaction
            )
            if (
                pendingPaymentData.subscribePendingPayments.transaction
                    .transactionType === 'deposit'
            ) {
                try {
                    addUser({
                        variables: {
                            walletAddress: account,
                            accountType: AccountTypeServer.METAMASK,
                            chainId: user.chainId,
                        },
                    }).then((res) => {
                        if (res.data.addUser.walletCreation === 'confirmed') {
                            setSignableTransaction(
                                pendingPaymentData.subscribePendingPayments
                                    .transaction
                            )
                        }
                    })
                } catch (error) {}
            }
        }
        if (
            confirmPaymentData &&
            confirmPaymentData.subscribeConfirmedPayments
        ) {
            setTransactionList(
                confirmPaymentData.subscribeConfirmedPayments.transaction
            )
            setStepAccordingToActivity(
                confirmPaymentData.subscribeConfirmedPayments.transaction
            )
            if (stepNo === 4) {
                setStepNo(5)
            }
        }
    }, [pendingPaymentData, user.walletAddress, confirmPaymentData])

    /**
     * set transaction list
     */
    const setTransactionList = (transaction: any) => {
        if (transaction && transaction.subscribePendingPayments) {
            if (transactionsList.length > 0) {
                let isChangedFlag: boolean = false
                for (let i = 0; i < transactionsList.length; i++) {
                    //iterate through each object in an array
                    if (
                        JSON.stringify(transactionsList[i]) !==
                        JSON.stringify(transaction.subscribePendingPayments)
                    ) {
                        isChangedFlag = true
                    }
                }
                if (isChangedFlag) {
                    transactionsList.push(transaction.subscribePendingPayments)
                    setTransactionsList(transactionsList)
                }
            } else {
                transactionsList.push(transaction.subscribePendingPayments)
                setTransactionsList(transactionsList)
            }
        }
    }

    /**
     * On step change call getSignableTransaction
     * and get transaction in return which will be used again for signing that transaction
     */
    React.useEffect(() => {
        console.log('stepNo === ', stepNo)
        console.log('signableTransaction === ', signableTransaction)
        if (!signableTransaction.targetAddress) {
            console.log('transactionsList', transactionsList)
            getSignableTransaction(transactionsList).then(
                (transactionObj: any) => {
                    console.log('transactionObj', transactionObj)
                    setSignableTransaction(transactionObj.transaction)
                }
            )
        }
    }, [stepNo, transactionsList])

    /**
     * moveFundsToProtocol on step 3 clicked
     * after user selected in which protocol he wants to invest
     * call getEncodedInvestment on move to step 4
     */
    const moveFundsToProtocolClick = () => {
        console.log(
            'signableTransaction.transactionId',
            signableTransaction.transactionId
        )
        console.log('user.protocol.protocolId', user.protocol.protocolId)
        console.log('user.chainId', user.chainId)

        client
            .query({
                query: GET_ENCODED_ADD_INVESTMENT,
                variables: {
                    transactionId:
                        signableTransaction.transactionId &&
                        signableTransaction.transactionId,
                    protocolId:
                        user.protocol.protocolId && user.protocol.protocolId,
                    chainId: user.chainId,
                },
            })
            .then(({ data }: any) => {
                if (data) {
                    console.log(
                        'GET_ENCODED_ADD_INVESTMENT',
                        data.getEncodedAddInvestment
                    )
                    setSignableTransaction(data.getEncodedAddInvestment)
                    setStepNo(4)
                }
            })
    }

    // Sign transaction Functions/Events
    const signTransactionBefore = () => {
        setLoading(true)
        setShowConfirmSubscription(false)
        console.log('signTransactionBefore')
    }

    const signTransactionAfter = () => {
        setLoading(false)
        console.log('signTransactionAfter')
    }

    const signTransactionSuccess = async (signature: any) => {
        console.log('signTransactionSuccess', signature)
        if (signableTransaction) {
            const { transactionId: txnId } = signableTransaction
            if (signature && txnId) {
                setShowConfirmSubscription(true)
                // setStatus('processingTransaction');
                updateTransactionSignings({
                    variables: {
                        transactionId: txnId,
                        transactionHash: signature.hash,
                    },
                })
            } else {
                // setStatus('transactionFailed');
                // errorTransaction('Something went wrong!');
            }
        }
    }

    const signTransactionError = (message: string) => {
        console.log('signTransactionError')
        setShowConfirmSubscription(false)
    }
    console.log('signableTransaction ========>', signableTransaction)
    return (
        <div className="col-12 d-flex justify-content-center">
            <div className="row justify-content-center">
                {/* Deposit Funds page with input field */}
                {stepNo === 4 && (
                    <div className="col-12 col-lg-7">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center ">
                                <p className="modal-heading">Deposit Funds</p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-3">
                                <p className="modal-description-p-mid">
                                    If you have already deposited your funds,
                                    please check the “Recent Activity” section
                                    on your dashboard.
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2 mt-5">
                                <p className="modal-description-p-mid">
                                    Selected Protocol:
                                </p>
                                <div className="col-8 col-lg-6 d-flex align-items-center">
                                    {user.protocol && user.protocol.protocolId && (
                                        <Button className="w-100 btn-normal">
                                            <img
                                                src={require(`./../../../assets/images/${user.protocol.protocolIcon}`)}
                                                alt="protocol"
                                                className="btn-image"
                                            />{' '}
                                            {user.protocol.protocolName}
                                        </Button>
                                    )}

                                    <a
                                        href="/#"
                                        className="deposit-small-a ml-4"
                                        onClick={(e: any) => {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation()
                                            setStepNo(3)
                                        }}
                                    >
                                        Switch
                                    </a>
                                </div>
                            </div>
                            <div className="col-7 d-flex justify-content-center text-center mt-5">
                                <div
                                    className="d-flex justify-content-center  align-items-center balance-protocol-div-square w-75"
                                    style={{ height: '62px' }}
                                >
                                    <p
                                        className="m-0"
                                        style={{ fontSize: '20px' }}
                                    >
                                        {signableTransaction &&
                                            signableTransaction.amount &&
                                            signableTransaction.amount}{' '}
                                        {signableTransaction.tokenId &&
                                            signableTransaction.tokenId
                                                .tokenSymbol}
                                    </p>
                                </div>
                            </div>
                            {showConfirmSubscription ? (
                                <div className="row  mt-5">
                                    <div className="col-10 d-flex justify-content-center text-center mt-3">
                                        <p className="modal-description-p-mid">
                                            We are waiting for your Funds
                                            Deposit transaction confirmation.
                                            Check the “Recent Activity” section
                                            for updates.{' '}
                                        </p>
                                    </div>
                                    {confirmPaymentData &&
                                    confirmPaymentData.subscribeConfirmedPayments ? (
                                        ''
                                    ) : (
                                        <div className="col-2 d-flex justify-content-center align-items-center mt-3">
                                            <div className="loader">
                                                <img
                                                    src={loader}
                                                    alt="loader"
                                                    style={{ width: '30px' }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="col-9 col-lg-7 d-flex justify-content-center text-center mt-5 mb-5">
                                    <SignTransactionButton
                                        title={'Deposit Funds'}
                                        loadingTitle={'Deposit Funds...'}
                                        loading={loading}
                                        signTransactionBefore={
                                            signTransactionBefore
                                        }
                                        signTransactionAfter={
                                            signTransactionAfter
                                        }
                                        signTransactionSuccess={
                                            signTransactionSuccess
                                        }
                                        signTransactionError={
                                            signTransactionError
                                        }
                                        transaction={signableTransaction}
                                        shouldSignTransactionFlag={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Sit tight page */}
                {stepNo === 7 && (
                    <div className="col-12 col-lg-7">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">Sit tight...</p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-3">
                                <p className="modal-description-p-mid">
                                    We are waiting for your funds to arrive.
                                    Check the “Recent Activity” section for
                                    updates.{' '}
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center align-items-center pl-3 pr-3 pt-2 pb-2 mt-5 mb-5">
                                <div className="loader">
                                    <img
                                        src={loader}
                                        alt="loader"
                                        style={{ width: '80px' }}
                                    />
                                </div>
                            </div>
                            <div className="col-8 col-lg-5 d-flex justify-content-center text-center mt-5 mb-5">
                                <Button className="w-100 btn-gradient-round-half">
                                    <span>Deposit Funds</span>
                                </Button>
                                {/* <Button className="w-100 btn-gradient-round-half"><span>VIEW ETHERSCAN</span></Button>  */}
                            </div>
                        </div>
                    </div>
                )}
                {/* Success! page */}
                {stepNo === 5 && (
                    <div className="col-12 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">Success!</p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-3">
                                <p className="modal-description-p-mid">
                                    Your funds have been deposited to the
                                    selected protocol:
                                </p>
                            </div>
                            <div className="col-11 col-lg-10 d-flex justify-content-between align-items-center mt-5 mb-5 color-div">
                                {confirmPaymentData.subscribeConfirmedPayments && (
                                    <div className="d-flex justify-content-around  align-items-center">
                                        {confirmPaymentData
                                            .subscribeConfirmedPayments
                                            .transaction.protocolId && (
                                            <img
                                                src={require(`./../../../assets/images/${confirmPaymentData.subscribeConfirmedPayments.transaction.protocolId.protocolIcon}`)}
                                                alt="protocol"
                                                style={{ width: '16px' }}
                                            />
                                        )}
                                        {confirmPaymentData
                                            .subscribeConfirmedPayments
                                            .transaction.protocolId && (
                                            <p className="m-0 ml-3">
                                                {
                                                    confirmPaymentData
                                                        .subscribeConfirmedPayments
                                                        .transaction.protocolId
                                                        .protocolName
                                                }
                                            </p>
                                        )}
                                    </div>
                                )}
                                {confirmPaymentData.subscribeConfirmedPayments
                                    .transaction.amount && (
                                    <div className="d-flex justify-content-around  align-items-center balance-protocol-div">
                                        {confirmPaymentData
                                            .subscribeConfirmedPayments
                                            .transaction.tokenId && (
                                            <p className="m-0 balance-protocol-p">
                                                {
                                                    confirmPaymentData
                                                        .subscribeConfirmedPayments
                                                        .transaction.amount
                                                }{' '}
                                                {
                                                    confirmPaymentData
                                                        .subscribeConfirmedPayments
                                                        .transaction.tokenId
                                                        .tokenSymbol
                                                }
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-lg-8 d-flex justify-content-center text-center mt-5 mb-5">
                                {confirmPaymentData.subscribeConfirmedPayments
                                    .transaction.transactionHash && (
                                    <Button
                                        className="w-100 btn-gradient-round-half"
                                        onClick={() => {
                                            if (user.chainId === 42) {
                                                console.log(
                                                    'confirmPaymentData.subscribeConfirmedPayments.transaction.transactionHash',
                                                    confirmPaymentData
                                                        .subscribeConfirmedPayments
                                                        .transaction
                                                        .transactionHash
                                                )
                                                window.open(
                                                    `${Config.etherscanKovanUrl}/${confirmPaymentData.subscribeConfirmedPayments.transaction.transactionHash}`,
                                                    '_blank'
                                                )
                                            } else {
                                                window.open(
                                                    `${Config.etherscanMainUrl}/${confirmPaymentData.subscribeConfirmedPayments.transaction.transactionHash}`,
                                                    '_blank'
                                                )
                                            }
                                        }}
                                    >
                                        <span className="pt-3 pb-3">
                                            VIEW TRANSACTION HISTORY
                                        </span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {/* DEPOSIT FUNDS  */}
                {stepNo === 1 && (
                    <div className="col-12 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">DEPOSIT FUNDS</p>
                            </div>
                            <div className="col-12 col-lg-12 d-flex justify-content-center text-center mt-3">
                                <p className="modal-description-p-mid">
                                    Scan QR code OR copy and paste this address
                                    into your wallet to deposit funds.
                                </p>
                            </div>
                            <TokensDropdown
                                setCurrencyInDeposit={(e: any) =>
                                    console.log('in deposit modal currency', e)
                                }
                            />
                            <div className="col-12 d-flex justify-content-center text-center mt-1">
                                <div className="qr-code-div">
                                    <QRCode
                                        value={
                                            '0x87EBeA256B4e1f868b2be896276143Be7795784B'
                                        }
                                        size={170}
                                        renderAs={'svg'}
                                        bgColor={'#000000'}
                                        fgColor={'#FFFFFF'}
                                    />
                                </div>
                            </div>
                            <div className="col-11 col-lg-10 d-flex justify-content-between align-items-center mt-2 mb-2 mt-lg-5 mb-lg-3 color-gradient-div">
                                <div className="d-flex justify-content-between  align-items-center w-100">
                                    <div className="d-flex justify-content-start ">
                                        <p className="m-0 copy-text-deposit">
                                            {user && user.contractAddress}
                                        </p>
                                    </div>
                                    <CopyToClipboard
                                        onCopy={() => {
                                            showNotification(
                                                'Address copied',
                                                'success'
                                            )
                                        }}
                                        text={user && `${user.contractAddress}`}
                                    >
                                        <div className="d-flex justify-content-end pointer">
                                            <img
                                                src={copy}
                                                alt="copy"
                                                style={{
                                                    width: '16px',
                                                    float: 'right',
                                                }}
                                            />
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div className="col-10 d-flex justify-content-center text-center mt-2">
                                <p className="modal-description-p-mid ml-5">
                                    We are waiting for your funds to arrive.
                                    Check the “Recent Activity” section for
                                    updates.{' '}
                                </p>
                            </div>
                            {pendingPaymentData &&
                            pendingPaymentData.subscribePendingPayments ? (
                                ''
                            ) : (
                                <div className="col-2 d-flex justify-content-center align-items-center mt-3">
                                    <div className="loader">
                                        <img
                                            src={loader}
                                            alt="loader"
                                            style={{ width: '30px' }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {/* Create a Wallet  */}
                {stepNo === 2 && (
                    <div className="col-12 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">Create a Wallet</p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-2 mb-3">
                                <p
                                    className="modal-description-p-mid"
                                    style={{
                                        fontSize: '19px',
                                        fontWeight: 'normal',
                                        opacity: 0.8,
                                    }}
                                >
                                    Creating your wallet enables you navigate
                                    the DeFi space privately.{' '}
                                </p>
                            </div>
                            {/* <div className="col-12 col-lg-10 d-flex justify-content-center align-items-center mt-5 mb-2">
                                <div className="d-flex justify-content-center  align-items-center balance-protocol-div w-75" style={{ height: "62px" }} >
                                    <p className="m-0" style={{ fontSize: "34px" }}>{signableTransaction.amount && signableTransaction.amount} USDC</p>
                                </div>
                            </div> */}
                            <div className="col-12  d-flex justify-content-center align-items-center mt-2 mb-2 pr-0 ">
                                <div className="d-flex justify-content-center  align-items-center width-pro balance-protocol-div-square  text-center">
                                    <p
                                        className="m-0"
                                        style={{
                                            fontSize: '16px',
                                            padding: '10px',
                                        }}
                                    >
                                        Proceeding forward will initiate
                                        Ethereum network transaction that will
                                        allow ADD.XYZ to deploy a smart contract
                                        that will be responsible for handling
                                        your funds. You will be the owner of
                                        your smart wallet contract.
                                    </p>
                                </div>
                                <div style={{ width: '35px' }}></div>
                            </div>
                            {/* <div className="col-12 d-flex justify-content-center text-center mt-3 mb-5">
                                <p className="modal-description-p-mid" style={{ fontSize: "14px", fontWeight: 'normal' }}>Once your wallet is created and the contract is signed, you will be able to move your funds to a DeFi protocol of your choice.</p>
                            </div> */}
                            <div className="col-12 d-flex justify-content-center text-center mt-3">
                                <p
                                    className="modal-description-p-mid"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'normal',
                                        opacity: 0.5,
                                    }}
                                >
                                    As a new user, you will only have to do this
                                    once.
                                </p>
                            </div>
                            {showConfirmSubscription ? (
                                <div className="row">
                                    <div className="col-10 d-flex justify-content-center text-center mt-1 ">
                                        <p className="modal-description-p-mid ">
                                            We are waiting for your Wallet
                                            transaction confirmation. Check the
                                            “Recent Activity” section for
                                            updates.{' '}
                                        </p>
                                    </div>
                                    {pendingPaymentData &&
                                    pendingPaymentData.subscribePendingPayments ? (
                                        <div className="col-2 d-flex justify-content-center align-items-center mt-1">
                                            <div className="loader">
                                                <img
                                                    src={loader}
                                                    alt="loader"
                                                    style={{ width: '30px' }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                <div className="col-12 col-lg-10 d-flex justify-content-center text-center mt-2 mt-lg-4 mb-2">
                                    <SignTransactionButton
                                        title={'CREATE WALLET'}
                                        loadingTitle={'CREATING WALLET...'}
                                        loading={loading}
                                        signTransactionBefore={
                                            signTransactionBefore
                                        }
                                        signTransactionAfter={
                                            signTransactionAfter
                                        }
                                        signTransactionSuccess={
                                            signTransactionSuccess
                                        }
                                        signTransactionError={
                                            signTransactionError
                                        }
                                        transaction={signableTransaction}
                                        shouldSignTransactionFlag={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {/* Sign Smart Contract  */}
                {stepNo === 6 && (
                    <div className="col-12 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">
                                    Sign Smart Contract
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center justify-content-lg-end align-items-center mt-1 mb-2 pr-0 pr-lg-5">
                                <div
                                    className="d-flex justify-content-center  align-items-center balance-protocol-div-square w-75"
                                    style={{ height: '62px' }}
                                >
                                    <p
                                        className="m-0"
                                        style={{ fontSize: '20px' }}
                                    >
                                        113.4322 USDC
                                    </p>
                                </div>
                                <img
                                    src={check}
                                    alt="protocol"
                                    style={{
                                        width: '26px',
                                        marginLeft: '15px',
                                    }}
                                />
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center justify-content-lg-end align-items-center mt-4 mb-2 pr-0 pr-lg-5">
                                <div className="d-flex justify-content-center  align-items-center balance-protocol-div-square w-75 text-center">
                                    <p
                                        className="m-0"
                                        style={{
                                            fontSize: '16px',
                                            padding: '10px',
                                        }}
                                    >
                                        Proceeding forward will initiate
                                        Ethereum network transaction that will
                                        allow ADD.XYZ to deploy a smart contract
                                        that will be responsible for handling
                                        your funds. You will be the owner of
                                        your smart wallet contract.
                                    </p>
                                </div>
                                <div style={{ width: '35px' }}></div>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-3 mb-4">
                                <p
                                    className="modal-description-p-mid"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'normal',
                                        opacity: 0.5,
                                    }}
                                >
                                    As a new user, you will only have to do this
                                    once.
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center text-center mb-5">
                                {/* <Button className="w-100 btn-gradient-round-half"><span className="pt-3 pb-3">CREATE WALLET</span></Button>  */}
                                <Button className="w-100 btn-gradient-round-half">
                                    <span className="pt-3 pb-3 d-flex justify-content-end">
                                        CREATING WALLET...
                                        <div
                                            className="loader"
                                            style={{
                                                float: 'right',
                                                marginLeft: '87px',
                                            }}
                                        >
                                            <img
                                                src={loader}
                                                alt="loader"
                                                style={{
                                                    width: '22px',
                                                    float: 'right',
                                                }}
                                            />
                                        </div>
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {/* Choose a Protocol  */}
                {stepNo === 3 && (
                    <div className="col-12 col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">
                                    Choose a Protocol{' '}
                                </p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-1 mb-2">
                                <p
                                    className="modal-description-p-mid"
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'normal',
                                    }}
                                >
                                    Select a Protocol to move your funds to.
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center align-items-center mt-3 mb-2 ">
                                <div
                                    className="d-flex justify-content-center  align-items-center balance-protocol-div-square w-75"
                                    style={{ height: '62px' }}
                                >
                                    <p
                                        className="m-0"
                                        style={{ fontSize: '20px' }}
                                    >
                                        {signableTransaction &&
                                            signableTransaction.amount &&
                                            signableTransaction.amount}{' '}
                                        {signableTransaction.tokenId &&
                                            signableTransaction.tokenId
                                                .tokenSymbol}
                                    </p>
                                </div>
                                <img
                                    src={check}
                                    alt="protocol"
                                    style={{
                                        width: '26px',
                                        marginLeft: '15px',
                                    }}
                                />
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center justify-content-lg-end align-items-center mt-4 mb-2 mb-lg-5">
                                {/* protocol buttons div */}
                                <ProtocolsButtons
                                    isDepositModal={true}
                                ></ProtocolsButtons>
                            </div>

                            <div className="col-12 col-lg-10 mb-5 mt-2 mt-lg-5 ">
                                <Button
                                    className="w-100 btn-gradient-round-half"
                                    onClick={() => moveFundsToProtocolClick()}
                                >
                                    <span className="pt-3 pb-3">
                                        MOVE FUNDS TO PROTOCOL
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DepositModal
