// import { context } from 'react-bootstrap/esm/AccordionContext';
import connectors from './walletConnectors'
import { AccountType, Protocol } from './../enums/walletConnectionsEnum'

export const getProtocol = (protocol: any) => {
    const intProtocol = parseInt(protocol, 10)
    if (intProtocol === 0) {
        return Protocol.dydx
    } else if (intProtocol === 1) {
        return Protocol.compound
    } else if (intProtocol === 2) {
        return Protocol.fulcrum
    } else if (intProtocol === 3) {
        return Protocol.aave
    } else {
        return Protocol.dydx
    }
}

export const getAccountType = (connector: any) => {
    let type: any = null
    if (connector && connector === connectors.Injected) {
        type = AccountType.MetaMask
    } else if (connector && connector === connectors.Fortmatic) {
        type = AccountType.Fortmatic
    }
    // else if (connector && connector === connectors.Portis) {
    //   type = AccountType.Portis;
    // }
    else if (connector && connector === connectors.Ledger) {
        type = AccountType.Ledger
    } else if (connector && connector === connectors.Trezor) {
        type = AccountType.Trezor
    } else if (connector && connector === connectors.Torus) {
        type = AccountType.Torus
    } else if (connector && connector === connectors.Authereum) {
        type = AccountType.Authereum
    } else if (connector && connector === connectors.WalletConnect) {
        type = AccountType.WalletConnect
    } else if (connector && connector === connectors.SquareLink) {
        type = AccountType.SquareLink
    }
    return type
}
