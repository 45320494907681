import React from 'react'
import './Checkbox.scss'

interface IProps {
    title?: string
    checked: boolean
    // onChange?: () => void
    className?: string
    handleChange?: (value) => void
}
const Checkbox: React.FC<IProps> = ({
    title,
    checked,
    className,
    handleChange,
    // isChecked,
}) => {
    return (
        <div className={`checkbox-wrapper ${className}`}>
            <div className="form-check">
                <input
                    type="checkbox"
                    className={`form-check-input`}
                    checked={checked}
                    id="exampleCheck1"
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                />
                <label className="form-check-label">{title}</label>
            </div>
        </div>
    )
}

export default Checkbox
