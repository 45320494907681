/* eslint-disable */
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import aave from './../../../assets/images/crypto/aaave-small.png'
import openLogo from './../../../assets/images/project/open-logo.png'
import check from './../../../assets/images/project/check.png'
import { cutNumber } from './../../../utils/Utilities/transactionsUtils'
import Table from 'react-bootstrap/esm/Table'
import './WithdrawModal.scss'
import { authContext } from './../../../utils/context/AuthContext'
import {
    gql,
    useApolloClient,
    useMutation,
    useSubscription,
} from '@apollo/client'
import ProtocolsButtons from '../ProtocolsButtons/protocolsButtons'
import SignTransactionButton from '../SignTransactionButton/signTransactionButton'
import loader from './../../../assets/images/project/loader.png'
import Config from 'app/config/config'
import { showNotification } from './../../../utils/notifications/notificationService'

const GET_ENCODED_WITHDRAW_TRANSACTION = gql`
    query getEncodedWithdrawTransaction(
        $amount: Float!
        $tokenId: Int!
        $protocolId: Int!
        $contractAdress: String!
        $chainId: Int!
    ) {
        getEncodedWithdrawTransaction(
            amount: $amount
            tokenId: $tokenId
            protocolId: $protocolId
            contractAdress: $contractAdress
            chainId: $chainId
        ) {
            transactionId
            transactionHash
            from
            targetAddress
            targetData
            amount
            transactionType
            transactionStatus
            createdAt
            protocolId {
                protocolName
                protocolRatesKey
                protocolId
                protocolRatesKey
            }
            tokenId {
                tokenDecimals
                tokenSymbol
                tokenId
            }
        }
    }
`

const UPDATE_TRANSACTION_SIGNING = gql`
    mutation updateTransaction(
        $transactionId: Int!
        $transactionHash: String!
    ) {
        updateTransaction(
            transactionId: $transactionId
            transactionHash: $transactionHash
        ) {
            transactions {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                amount
                transactionType
                transactionStatus
                createdAt
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
            }
        }
    }
`

const SUBSCRIBE_CONFIRMED_PAYMENTS = gql`
    subscription subscribeConfirmedPayments($walletAddress: String) {
        subscribeConfirmedPayments(walletAddress: $walletAddress) {
            transaction {
                transactionId
                transactionHash
                from
                targetAddress
                targetData
                transactionType
                transactionStatus
                tokenId {
                    tokenSymbol
                    tokenId
                    tokenName
                    tokenIcon
                    tokenAddress
                    tokenDecimals
                }
                protocolId {
                    protocolName
                    protocolId
                    protocolKey
                    protocolIcon
                    protocolRatesKey
                    protocolInvestContract
                    protocolRegistryContract
                }
                amount
            }
            status {
                message
                status
            }
        }
    }
`

interface IProps {
    pendingActivity?: any
    withdrawData?: {
        protocolId: number
        tokenId: number
    }
    balancesList?: any
}
const WithdrawModal: React.FC<IProps> = (props) => {
    const [stepNo, setStepNo] = useState(1)
    const [withdrawAmount, setWithdrawAmount] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)
    const [signableTransaction, setSignableTransaction] = useState<any>({})

    const [balancesList, setBalancesList] = useState<any>(props.balancesList)
    // selected balance id for comparison
    const [selectedBalanceId, setSelectedBalanceId] = useState(-1)
    // selected balance amount to compare with user given amount
    // if user input is greater then user balance
    const [selectedBalanceAmount, setSelectedBalanceAmount] = useState(0)
    // Show subscribeConfirmedPayments
    const [showConfirmSubscription, setShowConfirmSubscription] =
        useState<boolean>(false)
    const { auth: user } = React.useContext(authContext)
    const client = useApolloClient()
    // Confirmed payment subscriptions integration
    const { data: confirmPaymentData } = useSubscription(
        SUBSCRIBE_CONFIRMED_PAYMENTS,
        {
            variables: { walletAddress: user.walletAddress },
        }
    )
    // Listener for payment subscription
    React.useEffect(() => {
        setShowConfirmSubscription(false)
        if (
            confirmPaymentData &&
            confirmPaymentData.subscribeConfirmedPayments
        ) {
            setStepNo(3)
        }
    }, [user.walletAddress, confirmPaymentData])
    // update transaction signings on confirmed payments return call
    const [updateTransactionSignings, { data: updateTransaction }] =
        useMutation(UPDATE_TRANSACTION_SIGNING)

    React.useEffect(() => {
        if (props.pendingActivity) {
            setSignableTransaction(props.pendingActivity)
        }
    }, [props.pendingActivity])
    const step1ButtonWithDrawClick = async () => {
        if (withdrawAmount) {
            if (withdrawAmount > selectedBalanceAmount) {
                showNotification(
                    `[Amount error]: Withdraw Amount should not be greater than balance`,
                    'error'
                )
                return
            }
            const { loading, data } = await client.query({
                query: GET_ENCODED_WITHDRAW_TRANSACTION,
                variables: {
                    amount: +withdrawAmount,
                    tokenId: selectedBalanceId,
                    protocolId: +user.protocol.protocolId,
                    contractAdress: user.contractAddress,
                    chainId: user.chainId,
                },
            })
            if (!loading && data) {
                setSignableTransaction(data.getEncodedWithdrawTransaction)
                return { data: data.getEncodedWithdrawTransaction, message: '' }
            }
        } else {
            showNotification(
                `[Amount error]: Withdraw Amount is Required`,
                'error'
            )
        }
    }

    // Sign transaction Functions/Events
    const signTransactionBefore = () => {
        setLoading(true)
        setShowConfirmSubscription(false)
    }

    const signTransactionAfter = () => {
        setLoading(false)
    }

    const signTransactionSuccess = async (signature: any) => {
        if (signableTransaction) {
            const { transactionId: txnId } = signableTransaction
            console.log('txnId', txnId)
            if (signature && txnId) {
                setShowConfirmSubscription(true)
                updateTransactionSignings({
                    variables: {
                        transactionId: txnId,
                        transactionHash: signature.hash,
                    },
                })
            } else {
            }
        }
    }

    const signTransactionError = (message: string) => {
        setShowConfirmSubscription(false)
    }

    const protocolChangeDetected = () => {
        setBalancesList([...balancesList])
    }

    React.useEffect(() => {}, [balancesList])

    // setStepNo(3)
    return (
        <div className="withdraw-modal col-12 d-flex justify-content-center">
            <div className="row justify-content-center">
                {/* Deposit Funds page with input field */}
                {stepNo === 1 && (
                    <div className="col-12 col-lg-7">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center ">
                                <p className="modal-heading">
                                    Withdraw your funds
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2 mt-5">
                                <p className="modal-description-p-mid">
                                    Selected Protocol:
                                </p>
                                <div className="d-flex align-items-center">
                                    {user.protocol && user.protocol.protocolId && (
                                        <Button className="w-100 btn-normal">
                                            <img
                                                src={require(`./../../../assets/images/${user.protocol.protocolIcon}`)}
                                                alt="protocol"
                                                className="btn-image"
                                            />{' '}
                                            {user.protocol.protocolName}
                                        </Button>
                                    )}
                                    <a
                                        href="#!"
                                        className="deposit-small-a ml-4"
                                        onClick={(e: any) => {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation()
                                            setStepNo(4)
                                        }}
                                    >
                                        Switch
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="table-responsive">
                                    <Table
                                        striped
                                        hover
                                        borderless
                                        className="table-style"
                                    >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>BALANCE</th>
                                                <th>WITHDRAW AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {balancesList &&
                                            balancesList.length > 0 ? (
                                                balancesList.map(
                                                    (protocolList: any) => {
                                                        return (
                                                            protocolList.protocolId ===
                                                                user.protocol
                                                                    .protocolId &&
                                                            protocolList.token.map(
                                                                (
                                                                    token: any
                                                                ) => {
                                                                    return (
                                                                        // {protocolList && protocolList.token.length > 0 && protocolList.token.map((token: any) => {
                                                                        <tr>
                                                                            <td>
                                                                                <img
                                                                                    src={require(`./../../../assets/images/${token.tokenIcon}`)}
                                                                                    alt="aave"
                                                                                    className="d-none d-md-block protocols-image"
                                                                                />
                                                                                <div>
                                                                                    <span className="ml-3">
                                                                                        {
                                                                                            token.tokenSymbol
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {cutNumber(
                                                                                    token
                                                                                        .balance
                                                                                        .investment,
                                                                                    8
                                                                                )}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    width: '204px',
                                                                                }}
                                                                            >
                                                                                <div className="d-flex justify-content-start align-items-center table-input-div">
                                                                                    <input
                                                                                        type="number"
                                                                                        id={
                                                                                            token.tokenId
                                                                                        }
                                                                                        className="table-input-tag"
                                                                                        value={
                                                                                            token.tokenId ===
                                                                                            selectedBalanceId
                                                                                                ? withdrawAmount
                                                                                                : ''
                                                                                        }
                                                                                        disabled={
                                                                                            selectedBalanceId ===
                                                                                            -1
                                                                                                ? false
                                                                                                : selectedBalanceId ===
                                                                                                  token.tokenId
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            console.log(
                                                                                                'e',
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                            console.log(
                                                                                                'id',
                                                                                                e
                                                                                                    .target
                                                                                                    .id
                                                                                            )
                                                                                            console.log(
                                                                                                'token.balance.investment',
                                                                                                token
                                                                                                    .balance
                                                                                                    .investment
                                                                                            )
                                                                                            setSelectedBalanceAmount(
                                                                                                +cutNumber(
                                                                                                    token
                                                                                                        .balance
                                                                                                        .investment,
                                                                                                    8
                                                                                                )
                                                                                            )
                                                                                            setWithdrawAmount(
                                                                                                parseFloat(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            )
                                                                                            if (
                                                                                                e
                                                                                                    .target
                                                                                                    .value ===
                                                                                                ''
                                                                                            ) {
                                                                                                setSelectedBalanceId(
                                                                                                    -1
                                                                                                )
                                                                                            }
                                                                                            if (
                                                                                                selectedBalanceId ===
                                                                                                parseInt(
                                                                                                    e
                                                                                                        .target
                                                                                                        .id
                                                                                                )
                                                                                            ) {
                                                                                            } else {
                                                                                                setSelectedBalanceId(
                                                                                                    parseInt(
                                                                                                        e
                                                                                                            .target
                                                                                                            .id
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <p
                                                                                        className="ml-1 mb-0"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                '9px',
                                                                                            color: '#BABABA',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setWithdrawAmount(
                                                                                                +cutNumber(
                                                                                                    token
                                                                                                        .balance
                                                                                                        .investment,
                                                                                                    8
                                                                                                )
                                                                                            )
                                                                                            console.log(
                                                                                                'token id ===>',
                                                                                                token.tokenId
                                                                                            )
                                                                                            setSelectedBalanceId(
                                                                                                token.tokenId
                                                                                            )
                                                                                            setSelectedBalanceAmount(
                                                                                                +cutNumber(
                                                                                                    token
                                                                                                        .balance
                                                                                                        .investment,
                                                                                                    8
                                                                                                )
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        MAX
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        // })
                                                                    )
                                                                }
                                                            )
                                                        )
                                                    }
                                                )
                                            ) : (
                                                <tr>
                                                    <td>
                                                        <p>
                                                            No balances to show
                                                        </p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {showConfirmSubscription ? (
                                <div className="row mt-5">
                                    <div className="col-10 d-flex justify-content-center text-center mt-3">
                                        <p className="modal-description-p-mid">
                                            We are waiting for your Funds
                                            Withdrawal Response. Check the
                                            “Recent Activity” section for
                                            updates.{' '}
                                        </p>
                                    </div>
                                    {confirmPaymentData &&
                                    confirmPaymentData.subscribeConfirmedPayments ? (
                                        ''
                                    ) : (
                                        <div className="col-2 d-flex justify-content-center align-items-center mt-3">
                                            <div className="loader">
                                                <img
                                                    src={loader}
                                                    alt="loader"
                                                    style={{ width: '30px' }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="col-9 col-lg-8 d-flex justify-content-center text-center mb-5 btn-withdraw-assets">
                                    {/*   <Button className="w-100 btn-gradient-round-half"
                                    onClick={step1ButtonWithDrawClick}><span>Withdraw Assets</span></Button> */}
                                    <SignTransactionButton
                                        title={'Withdraw Assets'}
                                        loadingTitle={'Withdraw Assets...'}
                                        loading={loading}
                                        signTransactionBefore={
                                            signTransactionBefore
                                        }
                                        signTransactionAfter={
                                            signTransactionAfter
                                        }
                                        signTransactionSuccess={
                                            signTransactionSuccess
                                        }
                                        signTransactionError={
                                            signTransactionError
                                        }
                                        getTransaction={
                                            step1ButtonWithDrawClick
                                        }
                                        shouldSignTransactionFlag={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {/* Withdraw all funds */}
                {stepNo === 2 && (
                    <div className="col-12 col-lg-7">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center ">
                                <p className="modal-heading">
                                    Withdraw all funds
                                </p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-3">
                                <p className="modal-description-p-mid">
                                    Withdraw all your funds from all protocols
                                    by selecting “Max” on all tabs. You can
                                    enter a specific amount if needed
                                </p>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="table-responsive">
                                    <Table
                                        striped
                                        hover
                                        borderless
                                        className="table-style"
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>BALANCE</th>
                                                <th>WITHDRAW AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img
                                                        src={aave}
                                                        alt="aave"
                                                        className="d-none d-md-block protocols-image"
                                                    />
                                                    <div>
                                                        <span className="ml-3">
                                                            USDC
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>12.34422</td>
                                                <td style={{ width: '204px' }}>
                                                    <div className="d-flex justify-content-start align-items-center table-input-div">
                                                        <input
                                                            type="text"
                                                            className="table-input-tag"
                                                        />{' '}
                                                        <p
                                                            className="ml-1 mb-0"
                                                            style={{
                                                                fontSize:
                                                                    '11px',
                                                                color: '#BABABA',
                                                            }}
                                                        >
                                                            MAX
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-9 col-lg-5 d-flex justify-content-center text-center mt-5 mb-5">
                                <Button className="w-100 btn-gradient-round-half">
                                    <span>Withdraw all assets</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {/* Withdraw Initiated */}
                {stepNo === 3 && (
                    <div className="col-12 col-lg-7">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center ">
                                <p className="modal-heading">
                                    Withdraw Initiated
                                </p>
                            </div>
                            <div className="col-12  col-lg-10 d-flex justify-content-center text-center mt-3 mb-5">
                                <p className="modal-description-p-mid">
                                    Your funds have been withdrawed from the
                                    selected protocol:
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col-11 col-lg-8 d-flex justify-content-between align-items-center mt-5 mb-5 color-div px-4">
                                        {confirmPaymentData.subscribeConfirmedPayments && (
                                            <div className="d-flex justify-content-around  align-items-center">
                                                {confirmPaymentData
                                                    .subscribeConfirmedPayments
                                                    .transaction.protocolId && (
                                                    <img
                                                        src={require(`./../../../assets/images/${confirmPaymentData.subscribeConfirmedPayments.transaction.protocolId.protocolIcon}`)}
                                                        alt="protocol"
                                                        style={{
                                                            width: '16px',
                                                        }}
                                                    />
                                                )}
                                                {confirmPaymentData
                                                    .subscribeConfirmedPayments
                                                    .transaction.protocolId && (
                                                    <p className="m-0 ml-3">
                                                        {
                                                            confirmPaymentData
                                                                .subscribeConfirmedPayments
                                                                .transaction
                                                                .protocolId
                                                                .protocolName
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        {confirmPaymentData
                                            .subscribeConfirmedPayments
                                            .transaction.amount && (
                                            <div className="d-flex justify-content-around  align-items-center balance-protocol-div">
                                                {confirmPaymentData
                                                    .subscribeConfirmedPayments
                                                    .transaction.tokenId && (
                                                    <p className="m-0 balance-protocol-p">
                                                        {confirmPaymentData.subscribeConfirmedPayments.transaction.amount.toFixed(
                                                            8
                                                        )}{' '}
                                                        {
                                                            confirmPaymentData
                                                                .subscribeConfirmedPayments
                                                                .transaction
                                                                .tokenId
                                                                .tokenSymbol
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-1 col-lg-2 mt-5 mb-5  d-flex align-items-center">
                                        <img
                                            src={openLogo}
                                            alt="protocol"
                                            style={{ width: '16px' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-9 col-lg-8 d-flex justify-content-center text-center mt-5 mb-5">
                                {confirmPaymentData.subscribeConfirmedPayments
                                    .transaction.transactionHash && (
                                    <Button
                                        className="w-100 btn-gradient-round-half"
                                        onClick={() => {
                                            if (user.chainId === 42) {
                                                window.open(
                                                    `${Config.etherscanKovanUrl}/${confirmPaymentData.subscribeConfirmedPayments.transaction.transactionHash}`,
                                                    '_blank'
                                                )
                                            } else {
                                                window.open(
                                                    `${Config.etherscanMainUrl}/${confirmPaymentData.subscribeConfirmedPayments.transaction.transactionHash}`,
                                                    '_blank'
                                                )
                                            }
                                        }}
                                    >
                                        <span className="pt-3 pb-3">
                                            VIEW TRANSACTION HISTORY
                                        </span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {/* Choose a Protocol  */}
                {stepNo === 4 && (
                    <div className="col-12 col-lg-8">
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center text-center mt-0 mt-lg-3">
                                <p className="modal-heading">
                                    Choose a Protocol{' '}
                                </p>
                            </div>
                            <div className="col-12 d-flex justify-content-center text-center mt-1 mb-2">
                                <p
                                    className="modal-description-p-mid"
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'normal',
                                    }}
                                >
                                    Select a Protocol to move your funds to.
                                </p>
                            </div>
                            <div className="col-12 col-lg-10 d-flex justify-content-center justify-content-lg-end align-items-center mt-4 mb-2 mb-lg-5">
                                {/* protocol buttons div */}
                                <ProtocolsButtons
                                    isDepositModal={true}
                                    changeDetected={protocolChangeDetected}
                                ></ProtocolsButtons>
                            </div>
                            <div className="col-12 col-lg-6 mb-5 mt-2 mt-lg-5 ">
                                <Button
                                    className="w-100 btn-gradient-round-half"
                                    onClick={() => setStepNo(1)}
                                >
                                    <span className="pt-3 pb-3">
                                        Select Protocol
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WithdrawModal
