import React, { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './AmountSelector.scss'

interface IProps {
    data: any
    setAmount: Function
    coin: string
}
const AmountSelector: React.FC<IProps> = (props) => {
    // States
    const [selectedAmount, setSelectedAmount] = useState(-1)
    const checkBackgroundStyle = (i: number) => {
        if (selectedAmount === i)
            return 'linear-gradient(141.09deg, #46EFFA 9.98%, #2000E3 94.32%)'
        else
            return 'linear-gradient(144.05deg, #3A3D41 -69.07%, #101113 122.22%)'
    }
    const bottomTextStyle = (i: number) => {
        if (selectedAmount === i) return '#2000E3'
    }
    const circleBorderStyle = (i: number) => {
        if (selectedAmount !== i) return '3px solid #333333'
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                paddingBottom: 15,
            }}
        >
            <div className="contentSection">
                <div style={{ width: '100%', position: 'absolute', top: 20 }}>
                    <ProgressBar now={0} className="progressBar" />
                </div>
                {props.data.length > -1 &&
                    props.data.map((amount: any, i: number) => {
                        return (
                            <div
                                className="step-section-div"
                                onClick={() => {
                                    props.setAmount(parseFloat(amount))
                                    setSelectedAmount(i)
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <text
                                        style={{
                                            background: checkBackgroundStyle(i),
                                            border: circleBorderStyle(i),
                                        }}
                                        className="circle-section-div"
                                    ></text>
                                    <text
                                        style={{
                                            color: bottomTextStyle(i),
                                        }}
                                        className="amount-bottom-text"
                                    >
                                        {`${amount} ${props.coin}`}
                                    </text>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
export default AmountSelector
