import { DEFAULT_USER_AUTH } from './../CustomTypes/TypeDeclaration'
import { UserAuth } from './../CustomTypes/UserType'

/** Return user auth from local storage value */
export const getStoredUserAuth = (): UserAuth => {
    const auth = window.localStorage.getItem('UserAuth')
    if (auth) {
        return JSON.parse(auth)
    }
    return DEFAULT_USER_AUTH
}

export const checkLocalStorage = (): boolean => {
    const auth = window.localStorage.getItem('UserAuth')
    if (auth) {
        const autObj = JSON.parse(auth)
        if (autObj && !autObj.isAuthenticated && autObj.walletAddress) {
            return true
        }
    }
    // const auth = JSON.parse(window.localStorage.getItem('UserAuth') || '');
    return false
}

export const checkLogin = (user: any) => {
    if (!user || (user && !user.walletAddress) || !user.isAuthenticated) {
        return false
    }
    return true
}
