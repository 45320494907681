import React from "react";
import "./ProposalsDetails.scss";
import ProposalSubHeader from "../../shared/components/ProposalSubHeader/ProposalSubHeader";
import InfoSection from "./sections/InfoSection/InfoSection";
import ProposalDetailSection from "./sections/ProposalDetail/ProposalDetail";
import ResultSection from "./sections/ResultSection/ResultSection";
import CastYourVoteSection from "./sections/CastYourVoteSection/CastYourVoteSection";
import { Spinner } from "react-bootstrap";
import Config from "app/config/config";
import axios from "axios";

interface IProps {}
const ProposalDetails: React.FC<IProps> = () => {
  const [isVoteModalOpen, setIsVoteModalOpen] = React.useState(false);
  const [proposalDetail, setProposalDetail] = React.useState<any>({
    proposalTitle: "",
  });
  const [governanceDetail, setGovernanceDetail] = React.useState({
    nickName: "",
    name: "",
  });
  const [governanceIsLoading, setGovernanceIsLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  const onModalStateChange = (modalState: boolean) => {
    setIsVoteModalOpen(modalState);
  };

  React.useEffect(() => {
    const proposalId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    let governanceId;
    axios
      .get(`${Config.governanceBaseUrl}/admin/proposal/${proposalId}`)
      .then((res) => {
        if (res && res.data) {
          const { code, data } = res.data;
          if (code === 200 && data && data.length !== 0) {
            setProposalDetail(data[0]);
            governanceId = data[0].contractAddress;
            setIsLoading(false);
            axios
              .get(
                `${Config.governanceBaseUrl}/admin/governance/${governanceId}`
              )
              .then((governanceRes) => {
                if (governanceRes && governanceRes.data) {
                  const { code, data } = governanceRes.data;
                  if (code === 200 && data && data.length !== 0) {
                    setGovernanceDetail(data[0]);
                  }
                  setGovernanceIsLoading(false);
                }
              });
          }
        }
      });
  }, []);

  return (
    <>
      <div
        className={`container main-div proposal-container ${
          isVoteModalOpen ? "blurred" : ""
        }`}
      >
        <div className="proposal-details-container">
          <ProposalSubHeader
            loading={governanceIsLoading}
            ticker={governanceDetail.nickName}
            governanceDetail={governanceDetail}
            title={`${governanceDetail.nickName}: ${proposalDetail.proposalTitle}`}
          ></ProposalSubHeader>
          {isLoading && (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {!isLoading && (
            <div className="row">
              <div className="col-xl-9 col-md-9 col-sm-12 col-xsm-12 d-flex flex-column">
                {/* proposal details section */}
                <ProposalDetailSection
                  proposalDetails={proposalDetail}
                ></ProposalDetailSection>
              </div>

              <div className="col-xl-3 col-md-3 col-sm-12 col-xsm-12 d-flex flex-column">
                {/* proposal info section */}
                <InfoSection
                  proposalDetails={proposalDetail}
                  governanceDetail={governanceDetail}
                ></InfoSection>

                {/* result section */}
                <ResultSection
                  proposalDetails={proposalDetail}
                  governanceDetail={governanceDetail}
                ></ResultSection>

                {/* voting list section */}
                {/* <VotingList></VotingList> */}

                {/* Cast your vote section */}
                {proposalDetail && proposalDetail.votingStatus && (
                  <CastYourVoteSection
                    proposalDetails={proposalDetail}
                    governanceDetail={governanceDetail}
                    isModalOpen={(e) => onModalStateChange(e)}
                  ></CastYourVoteSection>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProposalDetails;
