/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Tooltip,
    Area,
} from 'recharts'

// styles
import './LendingChart.scss'

const CustomizedDot = (props: any) => {
    const { cx, cy } = props

    return (
        <svg
            x={cx - 500}
            y={cy - 150}
            width="1000"
            height="300"
            viewBox="0 0 872 221"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M436 1L435.502 221"
                stroke="#D8D8D8"
                stroke-dasharray="4.4 4.4"
            />
            <path
                d="M0 112L872 111.006"
                stroke="white"
                stroke-dasharray="4.4 4.4"
            />
            <rect
                x="428"
                y="103"
                width="16"
                height="16"
                rx="8"
                fill="#5E5CE6"
                stroke="white"
                stroke-width="4"
            />
        </svg>
    )
}

const Dot = () => {
    return (
        <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="4.5"
                cy="4"
                r="4"
                fill="url(#paint0_linear_15051_31002)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_15051_31002"
                    x1="0.5"
                    y1="4"
                    x2="8.5"
                    y2="4"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00F5A0" />
                    <stop offset="1" stop-color="#00D9F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}

interface IProps {
    selectedProtocol: any
    graphData?: any
}
const LendingChart: React.FC<IProps> = ({ selectedProtocol, graphData }) => {
    const [protocolGraph, setProtocolGraph] = useState<any>(false)

    useEffect(() => {
        if (graphData && graphData.length > 0 && selectedProtocol) {
            const data = graphData.find(
                (d: any) =>
                    d.protocol.protocolName === selectedProtocol.protocolName
            )
            setProtocolGraph(data.graphData)
        }
    }, [graphData, selectedProtocol])

    return (
        <ResponsiveContainer width="100%" height={300}>
            {protocolGraph ? (
                <ComposedChart
                    data={protocolGraph}
                    margin={{ top: 5, bottom: 65 }}
                >
                    <defs>
                        <linearGradient id="area" x1="0" y1="0" x2="0" y2="1">
                            <stop
                                offset="5%"
                                stopColor="#276FFF"
                                stopOpacity={0.2}
                            />
                            <stop
                                offset="95%"
                                stopColor="#151719"
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                    </defs>
                    <Line
                        type="linear"
                        dataKey="x"
                        stroke="#286ffe"
                        strokeWidth={4}
                        dot={false}
                        activeDot={<CustomizedDot />}
                    />
                    <Tooltip
                        cursor={false}
                        content={(d: any) => {
                            return protocolGraph[d.label] ? (
                                <div id="tooltip">
                                    <Dot />
                                    <div>
                                        <p className="amount mb-1">{`$ ${protocolGraph[
                                            d.label
                                        ].x.toFixed(2)}`}</p>
                                        <p className="date mb-0">
                                            {protocolGraph[d.label].y}
                                        </p>
                                    </div>
                                </div>
                            ) : null
                        }}
                    />
                    <Area
                        type="linear"
                        dataKey="x"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#area)"
                    />
                </ComposedChart>
            ) : (
                <div className="w-100">
                    <hr className="hr-design-fx"></hr>
                    <p className="center line-h"></p>
                </div>
            )}
        </ResponsiveContainer>
    )
}
export default LendingChart
