import { useEffect, useState } from 'react'
import useRefresh from './useRefresh'
import useWeb3 from './useWeb3'

const useBlock = () => {
    const [block, setBlock] = useState(0)
    const { fastRefresh } = useRefresh()
    const web3 = useWeb3()

    useEffect(() => {
        const fetch = async () => {
            const blockNumber = await web3.eth.getBlockNumber()
            setBlock(blockNumber)
        }
        fetch()
    }, [fastRefresh, web3])

    return block
}

export default useBlock
