import React from 'react';
import "./CoinCard.scss";
import { useHistory } from "react-router-dom";

interface IProps {
  logo: string,
  title: string,
  text: string,
  governanceId: string;
};

const CoinCard: React.FC<IProps> = (props) => {
  const history = useHistory();
const navigateTo = () => history.push(`proposals/${props.governanceId}`);
  return (
    <>
    <div onClick={navigateTo} className="coin-card">
      <div className="coin-details">
        <img className="coin-img" src={props.logo} alt="" />
        <h2 className="coin-title">{props.title}</h2>
        <h2 className="coin-text">{props.text}</h2>
      </div>
    </div>
    </>
  );
};
export default CoinCard;
