import React from "react";
import "./ProposalSubHeader.scss";
import { useHistory } from "react-router-dom";
import backButtonIcon from "../../../assets/images/governance/icons/back-button.svg";
import rectangle1 from "../../../assets/images/governance/rectangle-1@2x.png";
import { Spinner } from "react-bootstrap";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Web3 from "web3";
declare const window: any;
interface IProps {
  title: string;
  ticker?: string;
  loading?: boolean;
  governanceDetail?: any;
}

const ProposalSubHeader: React.FC<IProps> = (props) => {
  const [accountBalance, setAccountBalance] = React.useState(0);

  React.useEffect(() => {
    initAccount();
  });

  async function initAccount() {
    const account: any = await getAccounts();
    if (account && account.length > 0) {
      getAccountBalance(account[0]);
    } else {
      openMetamask();
    }
  }

  function getABIOfContact(contractAddress) {
    try {
      const governanceDetails = props.governanceDetail;
      const url = `https://api${
        governanceDetails && governanceDetails.networkType === "kovan"
          ? "-kovan"
          : ""
      }.etherscan.io/api?module=contract&action=getabi&address=${contractAddress}&apikey=${"AGXPGZWEAEJAHASKNJYA81PAFXIVWRC4XG"}`;

      return fetch(url)
        .then((response) => response.json())
        .then(async (data) => {
          if (data && data.status === "1") {
            return data.result || null;
          }
          return null;
        })
        .catch((error) => {
          console.error("error: ", error);
          return null;
        });
    } catch (error) {
      console.error("error: ", error);
    }
  }

  async function getAccountBalance(userAddress) {
    const governanceDetails = props.governanceDetail;
    const contractAddress = props.governanceDetail
      ? props.governanceDetail.tokenContractAddress
      : "";

    //  const contractAddress = '0x0AaCfbeC6a24756c20D41914F2caba817C0d8521' //for testing
    if (!contractAddress) {
      return false;
    }
    const contractABI = await getABIOfContact(contractAddress);
    const signer = new ethers.providers.Web3Provider(
      window.ethereum
    ).getSigner();
    try {
      let tokenContract = await new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );
      // [governanceDetails.getBalanceMethod]
      // && governanceDetails.getBalanceMethod
      if (governanceDetails) {
        // const balanceDetails = await tokenContract.balanceOf(userAddress); //for testing
        const balanceDetails = await tokenContract[
          governanceDetails.getBalanceMethod
        ](userAddress);
        console.log("balanceDetails: ", balanceDetails);
        if (balanceDetails) {
          const convertBalance = balanceDetails._hex / 1;
          const decimal = props.governanceDetail.decimal || 18;
          let balance: any = Number(convertBalance) / Math.pow(10, decimal);
          setAccountBalance(balance);
        }
      }
    } catch (e) {
      const errorMessage =
        e.message || "Something went wrong. Please try again later.";
      // toast.error(errorMessage);
      console.log("e: ", e);
    }
  }

  const getAccounts = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      return accounts;
    } catch (e) {
      return [];
    }
  };

  const openMetamask = () => {
    const FALLBACK_WEB3_PROVIDER =
      process.env.REACT_APP_NETWORK || "https://dev.add.xyz/";
    return new Promise(async (resolve, reject) => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.enable();
          // Acccounts now exposed
          resolve(web3);
        } catch (error) {
          console.log("err", error);
          reject(error);
        }
      } else if (window.web3) {
        const web3 = window.web3;
        resolve(web3);
      }
      // Fallback to localhost; use dev console port by default...
      else {
        const provider = new Web3.providers.HttpProvider(
          FALLBACK_WEB3_PROVIDER
        );
        const web3 = new Web3(provider);
        alert("Please check metamask");
        resolve(web3);
      }
    });
  };

  const history = useHistory();
  const navigateToBack = () => history.goBack();
  return (
    <>
      <div className="proposal-header">
        <div className="left-content">
          <div onClick={navigateToBack} className="back-button">
            <img className="icon" src={backButtonIcon} alt="" />
            <span className="text">Back</span>
          </div>
          <div className="title-container">
            {props.loading && (
              <div className="spinner-container">
                <Spinner size="sm" animation="border" variant="primary" />
              </div>
            )}
            {!props.loading && (
              <h2 className="heading">{props.title ? props.title : ""}</h2>
            )}
          </div>
        </div>
        <div className="right-content">
          <div className="balance-content">
            <div className="balance-icon">
              {/* <img src={rectangle1} alt="" /> */}
              <span>{props.ticker ? props.ticker : ""}</span>
            </div>
            <h2 className="balance-amount">{accountBalance}</h2>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProposalSubHeader;
