import React from 'react'

// sections
import PressKits from './sections/PressKits'
import RoadMap from './sections/Roadmap'
import Footer from '../home/sections/footer/HomeFooter'

// styles
import './style.scss'
import 'react-vertical-timeline-component/style.min.css'

interface IProps {}
const RoadmapAndPressKits: React.FC<IProps> = () => {
    return (
        <div className="roadmap-presskit-ui col-12 p-0 m-0">
            <PressKits />
            <RoadMap />

            <Footer />
        </div>
    )
}

export default RoadmapAndPressKits
