/* eslint-disable */
import React from 'react'
import { Button } from 'react-bootstrap'

// assets
import ethSmall from '../../../../assets/images/project/eth_small_blue.svg'

const DepositLiquidity = () => {
    return (
        <>
            <div className="staking-modal-input">
                <div className="staking-modal-input-header d-flex justify-content-between">
                    <div>Input</div>
                    <div>Balance: 0.479735</div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50"></div>
                    <div className="d-flex justify-content-around w-50">
                        <Button className="staking-modal-input-max-action">
                            Max
                        </Button>
                        <img src={ethSmall} alt="logo" />
                    </div>
                </div>
            </div>

            <div className="my-4 text-center">+</div>

            <div className="staking-modal-input mb-3">
                <div className="staking-modal-input-header d-flex justify-content-between">
                    <div>Input</div>
                    <div>Balance: 0.479735</div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50"></div>
                    <div className="d-flex justify-content-around w-50">
                        <Button className="staking-modal-input-max-action">
                            Max
                        </Button>
                        <img src={ethSmall} alt="logo" />
                    </div>
                </div>
            </div>

            <div className="staking-modal-border mb-3">
                <div className="staking-modal-pool-prices-header mb-3">
                    Prices and pool share
                </div>

                <div className="staking-modal-border d-flex justify-content-around flex-wrap px-3 pb-3">
                    <div className="pt-3">
                        <div className="staking-modal-pool-prices-value">
                            91.2418
                        </div>
                        <div className="staking-modal-pool-prices-title">
                            PLT per ETH
                        </div>
                    </div>

                    <div className="pt-3">
                        <div className="staking-modal-pool-prices-value">
                            91.2418
                        </div>
                        <div className="staking-modal-pool-prices-title">
                            PLT per ETH
                        </div>
                    </div>

                    <div className="pt-3">
                        <div className="staking-modal-pool-prices-value">
                            91.2418
                        </div>
                        <div className="staking-modal-pool-prices-title">
                            PLT per ETH
                        </div>
                    </div>
                </div>
            </div>

            <Button className="staking-modal-btn mb-3">Supply</Button>
        </>
    )
}

export default DepositLiquidity
