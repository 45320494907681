import React, { useState } from 'react'
// import logo from "./../../../assets/images/project//logo.png";
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import header_logo from './../../../assets/images/project/new_header_logo.svg'
import connect_header from './../../../assets/images/project/connect_header.svg'
import lockIcon from './../../../assets/images/project/lockIcon.svg'
import slider_icon from './../../../assets/images/project/slider_icon.svg'
import manageAddress from './../../../assets/images/project/manageAddress.svg'
import manageAddress_1 from './../../../assets/images/project/manageAddress_1.svg'
import manageAddress_2 from './../../../assets/images/project/manageAddress_2.svg'
import qr_code from './../../../assets/images/project/qr_code.svg'
import online from './../../../assets/images/project/online.svg'
import settings from './../../../assets/images/project/settings.svg'
import Logout from './../../../assets/images/project/logout.svg'
import etherscan from './../../../assets/images/project/nav_dropdown_etherscan.svg'
import aboutus from './../../../assets/images/project/nav_dropdown_aboutus.svg'
// import home from './../../../assets/images/project/nav_dropdown_home.svg';
import presskit from './../../../assets/images/project/nav_dropdown_presskit.svg'
// import roadmap from './../../../assets/images/project/nav_dropdown_roadmap.svg';
import Warning from './../../../assets/images/project/warning.svg'
import Avatar from './../../../assets/images/project/user_avatar.svg'
import ArrowDown from './../../../assets/images/project/arrow_down.svg'

import './Header.scss'
import { useWeb3React } from '@web3-react/core'
import { authContext } from './../../../utils/context/AuthContext'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import ModalComponent from './../../../shared/components/Modal/ModalComponent'

interface IProps {}
const Header: React.FC<IProps> = () => {
    // const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false)
    const [settingsDropdown, setSettingsDropdown] = useState(false)
    const [openModal, setOpenModal] = useState(-1)

    // const handleClick = () => setClick(!click);
    // const closeMobileMenu = () => setClick(false);
    // useWeb3React context for deactivation
    const context = useWeb3React()
    const { deactivate } = context
    const history = useHistory()
    // useLocation to get URL
    const location = useLocation()
    /**
     * page steps
     * if 0 home selected
     * if 1 dashboard selected
     * if 2 blender selected
     * if 3 analytics selected
     */
    const [pageStep, setPageStep] = useState(0)
    const { auth: user, setUnauthStatus } = React.useContext(authContext)
    const logoutBtnClick = (e: any) => {
        console.log(dropdown)
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        deactivate()
        setUnauthStatus()
        window.location.href = '/home'
    }

    React.useEffect(() => {
        if (location.pathname === '/') {
            setPageStep(0)
        } else if (
            location.pathname === '/earn-crypto-lending' ||
            location.pathname === '/earn'
        ) {
            setPageStep(1)
        } else if (
            location.pathname === '/blender' ||
            location.pathname === '/blender-crypto-privacy-mixer'
        ) {
            setPageStep(2)
        } else if (location.pathname === '/analytics') {
            setPageStep(3)
        } else if (
            location.pathname === '/add-staking' ||
            location.pathname === '/staking'
        ) {
            setPageStep(4)
        } else if (location.pathname === '/roadmap-presskits') {
            setPageStep(5)
        } else if (location.pathname === '/mobile-money') {
            setPageStep(6)
        } else if (location.pathname === '/governance') {
            setPageStep(7)
        }
    }, [location])

    const onClickPrivateRoute = (route: string) => {
        // if (user.walletAddress) {
        //   history.push(route);
        // } else {
        //   setOpenModal(0);
        // }
        history.push(route)
    }

    return (
        <>
            {/* <div className="d-flex justify-content-between">
        <div className="col-12 col-lg-10 d-flex justify-content-center"> */}
            <nav
                className="navbar d-flex flex-column justify-content-between align-items-center"
                onMouseLeave={() => setDropdown(false)}
                style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    paddingTop: '1.2rem',
                }}
            >
                {/* <div className='beta-warning'>
          <div className="d-flex align-items-center">
            <img src={Warning} alt="Warning" className="mr-2" />
            <p className="m-0">Project is in Beta State: Use at your own risk.</p>
          </div>
        </div> */}
                <div
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{ flex: 1 }}
                >
                    <div className="" style={{ flex: 0.05 }}>
                        <NavLink
                            to="/"
                            className="navbar-logo"
                            //onClick={closeMobileMenu}
                        >
                            <img src={header_logo} alt="" />
                        </NavLink>
                    </div>
                    {/* <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div> */}
                    <div
                        className={`d-flex justify-content-center align-items-center nav-menu${
                            user.walletAddress ? '' : '-unauth'
                        }`}
                        style={{
                            flex: 0.9,
                        }}
                    >
                        {/* <NavDropdown
              title='ADD'
              id='nav-dropdown'
              className='nav-item-text nav-item-dropdown'
            >
              <NavDropdown.Item
                className='nav-item-text'
                eventKey='4.1'
                onClick={() => window.open('https://about.add.xyz')}
              >
                <div className='d-flex justify-content-between'>
                  <div>Gitbook</div>
                  <img src={aboutus} alt='about us' />
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item className='nav-item-text' eventKey='4.1'
              onClick={() => window.open('https://airtable.com/shrmp02tkqaUnZXmk')}
              >
                <div className='d-flex justify-content-between'>
                  <div>Feedback</div>
                </div>
              </NavDropdown.Item>

              <NavDropdown.Item
                className={
                  pageStep === 5 ? 'nav-item-text  active' : 'nav-item-text'
                }
                eventKey='4.2'
                onClick={() => history.push('/roadmap-presskits')}
              >
                <div className='d-flex justify-content-between'>
                  <div>Press Kit</div>
                  <img src={presskit} alt='presskit' />
                </div>
              </NavDropdown.Item>

              <NavDropdown.Item
                className='nav-item-text'
                eventKey='4.1'
                onClick={() =>
                  window.open(
                    'https://etherscan.io/token/0x635d081fd8f6670135d8a3640e2cf78220787d56'
                  )
                }
              >
                <div className='d-flex justify-content-between'>
                  <div>Etherscan</div>
                  <img src={etherscan} alt='etherscan' />
                </div>
              </NavDropdown.Item>
            </NavDropdown> */}
                        <div>
                            <text
                                className={
                                    pageStep === 0
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() => onClickPrivateRoute('/')}
                            >
                                Home
                            </text>
                        </div>
                        <div className="position-relative">
                            <text
                                className={
                                    pageStep === 4
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    onClickPrivateRoute('/add-staking')
                                }
                            >
                                +Staking
                            </text>
                        </div>
                        <div>
                            <text
                                className={
                                    pageStep === 1
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    onClickPrivateRoute('/earn-crypto-lending')
                                }
                            >
                                +Earn
                            </text>
                        </div>
                        <div>
                            <text
                                className={
                                    pageStep === 2
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    onClickPrivateRoute(
                                        '/blender-crypto-privacy-mixer'
                                    )
                                }
                            >
                                +Bl3nd3r
                            </text>
                        </div>
                        <div>
                            <text
                                className={
                                    pageStep === 7
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    onClickPrivateRoute('/governance')
                                }
                            >
                                Governance
                            </text>
                        </div>
                        <div>
                            <text
                                className={
                                    pageStep === 3
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    window.open('https://medium.com/addxyz')
                                }
                            >
                                Blog
                            </text>
                        </div>
                        <div>
                            <text
                                className={
                                    pageStep === 3
                                        ? 'nav-item-text  active'
                                        : 'nav-item-text'
                                }
                                onClick={() =>
                                    window.open('https://about.add.xyz')
                                }
                            >
                                About Us
                            </text>
                        </div>
                        {/* <div className='position-relative'>
              <text
                className={
                  pageStep === 7 ? "nav-item-text  active " : "nav-item-text"
                }
                onClick={() => onClickPrivateRoute("/governance")}
              >
                Governance
              </text>
            </div>
            <div className='position-relative'>
              <div className='coming-soon'>Coming soon</div>
              <text className='nav-item-text cursor-default'>Staking</text>
            </div>
            <div className='position-relative'>
              <div className='coming-soon'>Coming soon</div>
              <text className='nav-item-text cursor-default'>Swap</text>
            </div>
            <div className='position-relative'>
              <text
                className={
                  pageStep === 6 ? 'nav-item-text  active' : 'nav-item-text'
                }
                onClick={() => onClickPrivateRoute('/mobile-money')}
              >
                Mobile Money
              </text>
            </div> */}
                    </div>
                    <div
                        className="toggle-menu d-flex justify-content-center align-items-center"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 0.1,
                        }}
                    >
                        <img
                            src={slider_icon}
                            alt=""
                            className=""
                            onClick={() => setDropdown(!dropdown)}
                        />
                        {dropdown && (
                            <>
                                {user.walletAddress ? (
                                    <div
                                        className="menu-dropdown"
                                        style={{ height: '250px' }}
                                    >
                                        <div
                                            onClick={() => {
                                                history.push('/')
                                            }}
                                        >
                                            <text
                                                className={
                                                    pageStep === 0
                                                        ? 'dropdown-text  active'
                                                        : 'dropdown-text'
                                                }
                                            >
                                                Home
                                            </text>
                                        </div>
                                        <div
                                            onClick={() => {
                                                history.push(
                                                    '/earn-crypto-lending'
                                                )
                                            }}
                                        >
                                            <text
                                                className={
                                                    pageStep === 1
                                                        ? 'dropdown-text  active'
                                                        : 'dropdown-text'
                                                }
                                            >
                                                Lending
                                            </text>
                                        </div>
                                        <div
                                            onClick={() => {
                                                history.push('/blender')
                                            }}
                                        >
                                            <text
                                                className={
                                                    pageStep === 2
                                                        ? 'dropdown-text  active'
                                                        : 'dropdown-text'
                                                }
                                            >
                                                Bl3nd3r
                                            </text>
                                        </div>
                                        <div
                                            onClick={() => {
                                                history.push('/analytics')
                                            }}
                                        >
                                            <text
                                                className={
                                                    pageStep === 3
                                                        ? 'dropdown-text  active'
                                                        : 'dropdown-text'
                                                }
                                            >
                                                Analytics
                                            </text>
                                        </div>
                                        <div
                                            onClick={() =>
                                                history.push('/staking')
                                            }
                                        >
                                            <text className="dropdown-text">
                                                Staking
                                            </text>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="menu-dropdown">
                                        <div
                                            onClick={() =>
                                                window.open(
                                                    'https://about.add.xyz'
                                                )
                                            }
                                        >
                                            <text className="dropdown-text">
                                                About us
                                            </text>
                                        </div>
                                        <div
                                            onClick={() =>
                                                history.push(
                                                    '/roadmap-presskits'
                                                )
                                            }
                                        >
                                            <text
                                                className={
                                                    pageStep === 5
                                                        ? 'dropdown-text  active'
                                                        : 'dropdown-text'
                                                }
                                            >
                                                Roadmap & Press Kits
                                            </text>
                                        </div>
                                        <div>
                                            <text className="dropdown-text">
                                                Feedback
                                            </text>
                                        </div>
                                        <div
                                            onClick={() =>
                                                window.open(
                                                    'https://etherscan.io/token/0x635d081fd8f6670135d8a3640e2cf78220787d56'
                                                )
                                            }
                                        >
                                            <text className="dropdown-text">
                                                Etherscan
                                            </text>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {user.walletAddress ? (
                        <div
                            className="d-flex justify-content-end align-items-center nav-menu"
                            style={{ flex: 0.05 }}
                        >
                            <Button className="btn-gradient pointer mr-2">
                                <span className="d-flex align-items-center">
                                    <text className="">
                                        {user.walletAddress &&
                                            user.walletAddress.substr(0, 3) +
                                                '...' +
                                                user.walletAddress.substr(
                                                    user.walletAddress.length -
                                                        7
                                                )}
                                    </text>
                                    <img src={online} alt="" className="p-2" />
                                </span>
                            </Button>
                            {/* <img src={settings} alt="" className="pointer" /> */}

                            <div
                                className="d-flex justify-content-cent align-items-center nav-menu"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <img
                                        src={Avatar}
                                        alt="Avatar"
                                        className="mr-1"
                                    />
                                    <OverlayTrigger
                                        trigger="click"
                                        key="bottom"
                                        placement="bottom"
                                        rootClose
                                        overlay={
                                            <Popover id="popover-positioned-bottom">
                                                <Popover.Content className="header-popover-item">
                                                    <div
                                                        className="pointer d-flex justify-content-center align-items-center"
                                                        onClick={logoutBtnClick}
                                                    >
                                                        <img
                                                            src={Logout}
                                                            className="mr-2"
                                                        />
                                                        <p className="text-center m-0">
                                                            Logout
                                                        </p>
                                                    </div>
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <img
                                            src={ArrowDown}
                                            alt="Chevron Down"
                                            className="pointer"
                                        />
                                    </OverlayTrigger>
                                </div>

                                {settingsDropdown && (
                                    <div
                                        className="settings-dropdown"
                                        onMouseLeave={() =>
                                            setSettingsDropdown(false)
                                        }
                                    >
                                        <div
                                            className="d-flex row justify-content-between align-items-center w-100"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                className="d-flex justify-content-start"
                                                style={{ width: '80%' }}
                                            >
                                                <text className="settings-dropdown-text">
                                                    Combined Wallets View
                                                </text>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center"
                                                style={{ width: '20%' }}
                                            >
                                                <label
                                                    id="switch"
                                                    className="switch"
                                                >
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            className="d-flex row justify-content-between align-items-center w-100"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                className="d-flex justify-content-start"
                                                style={{ width: '80%' }}
                                            >
                                                <text className="settings-dropdown-text">
                                                    Manage Adresses
                                                </text>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center"
                                                style={{ width: '20%' }}
                                            >
                                                <img
                                                    src={manageAddress}
                                                    alt=""
                                                />
                                                <img
                                                    src={manageAddress_1}
                                                    alt=""
                                                />
                                                <img
                                                    src={manageAddress_2}
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="d-flex row justify-content-between align-items-center w-100"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                className="d-flex justify-content-start"
                                                style={{ width: '80%' }}
                                            >
                                                <text className="settings-dropdown-text">
                                                    Sync to Mobile Wallet{' '}
                                                </text>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center"
                                                style={{ width: '20%' }}
                                            >
                                                <img src={qr_code} alt="" />
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex row justify-content-between align-items-center w-100 "
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                            }}
                                            onClick={logoutBtnClick}
                                        >
                                            <div
                                                className="d-flex justify-content-start"
                                                style={{ width: '80%' }}
                                            >
                                                <text className="settings-dropdown-text">
                                                    Logout
                                                </text>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center"
                                                style={{ width: '20%' }}
                                            >
                                                <img
                                                    src={Logout}
                                                    alt=""
                                                    style={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <button
                            onClick={() => setOpenModal(0)}
                            className="primary-btn nav-menu-unauth"
                        >
                            Connect Wallet
                        </button>
                    )}

                    {/* <ul className={click ? "nav-menu active" : "nav-menu"}> */}
                    {/* <li className="nav-item">
            <NavLink
              to="/home"
              className="nav-NavLinks text-white"
              activeClassName="text-gradient"
              onClick={closeMobileMenu}
            >
              Home
          </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/dashboard"
              className="nav-NavLinks text-white"
              activeClassName="text-gradient"
              onClick={closeMobileMenu}
            >
              Dashboard
        </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/products"
              className="nav-NavLinks text-white"
              activeClassName="text-gradient"
              onClick={closeMobileMenu}
            >
              Products
          </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/blender"
              activeStyle={{ color: "red" }}
              className="nav-NavLinks text-white"
              activeClassName="text-gradient"
              onClick={closeMobileMenu}
            >
              Bl3nd3r
          </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact-us"
              className="nav-NavLinks text-white"
              activeClassName="text-gradient"
              onClick={closeMobileMenu}
            >
              Contact Us
          </NavLink>
          </li> */}
                    {/* {user.walletAddress && (
            <li className="nav-item">
              <NavLink
                to="#"
                className="nav-NavLinks text-white"
                activeClassName=""
                onClick={switchModal}
              >
                <div className="d-flex justify-content-around  align-items-center color-div">
                  <p className="color-div-p">
                    {user.walletAddress &&
                      user.walletAddress.substr(0, 8) +
                        "..." +
                        user.walletAddress.substr(
                          user.walletAddress.length - 5
                        )}
                  </p>
                  <div className="circle-div-green"></div>
                </div>
              </NavLink>
            </li>
          )} */}
                    {/* {user.walletAddress && (
            <li className="nav-item">
              <NavLink
                to="#"
                className="nav-NavLinks text-white"
                activeClassName=""
                onClick={logoutBtnClick}
              >
                <div className="d-flex justify-content-around  align-items-center color-div">
                  <p className="color-div-p" style={{ fontSize: "17px" }}>
                    logout
                  </p>
                  <div className="circle-div-red"></div>
                </div>
              </NavLink>
            </li>
          )} */}
                    {/* </ul> */}
                </div>
            </nav>
            {/*  </div>
        <div className="col-12 col-lg-2 d-flex justify-content-center">

        </div>
      </div> */}
            {openModal === 0 && (
                <ModalComponent
                    visible
                    isWalletModal={openModal === 0 ? true : false}
                    onClose={() => setOpenModal(-1)}
                />
            )}
        </>
    )
}
export default Header
