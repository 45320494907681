import React from 'react'

// assets
import connect_wallet from '../../../../assets/images/lending-home/connect_wallet.svg'
import deposit from '../../../../assets/images/lending-home/deposit.svg'
import earning from '../../../../assets/images/lending-home/earning.svg'

// styles
import './style.scss'

interface IProps {
    headerText: any
    instructions: any
}
const HowTo: React.FC<IProps> = ({ headerText, instructions }) => {
    const images = [connect_wallet, deposit, earning]

    return (
        <div className="instructions-wrapper d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
            <h1 className="pr-md-1 mr-md-5 pb-1 mb-4 pb-md-0 mb-md-0">
                {headerText}
            </h1>
            <div className="d-flex flex-column justify-content-center pl-md-1 ml-md-5">
                {instructions.map((ins, i: number) => (
                    <div className="d-flex align-items-center pb-2 pb-lg-3 mb-4">
                        <img src={images[i]} alt="" className="mr-3" />
                        <div>
                            <p className="instruction-title mb-0">
                                {ins.title}
                            </p>
                            <p className="instruction-desc mb-0">{ins.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HowTo
