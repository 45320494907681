import React from 'react'

interface IProps {
    isActive: string
}
const StakingIcon: React.FC<IProps> = ({ isActive }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_16336:1600)">
            <path
                d="M10.128 3.43467L11.8253 2.07733C12.218 1.764 12.3647 1.25533 12.1987 0.781333C12.0327 0.306667 11.6013 0 11.1 0H4.9C4.39867 0 3.96733 0.306667 3.80133 0.781333C3.63533 1.25533 3.782 1.76467 4.17467 2.07733L5.872 3.43467C3.75467 5.05533 2 8.63 2 11.5C2 13.7393 3.18467 16 5.83333 16H10.3333C12.5953 16 14 14.2753 14 11.5C14 8.63 12.2453 5.05533 10.128 3.43467Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M11.5 3H4.5C4.224 3 4 3.224 4 3.5C4 3.776 4.224 4 4.5 4H11.5C11.776 4 12 3.776 12 3.5C12 3.224 11.776 3 11.5 3Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M6.33333 9.16666C5.874 9.16666 5.5 8.79266 5.5 8.33333C5.5 7.874 5.874 7.5 6.33333 7.5C6.79266 7.5 7.16666 7.874 7.16666 8.33333C7.16666 8.79266 6.79266 9.16666 6.33333 9.16666Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M9.66634 12.4997C9.20701 12.4997 8.83301 12.1257 8.83301 11.6663C8.83301 11.207 9.20701 10.833 9.66634 10.833C10.1257 10.833 10.4997 11.207 10.4997 11.6663C10.4997 12.1257 10.1257 12.4997 9.66634 12.4997Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M6.89081 12.4399C6.55547 12.2872 6.40747 11.8926 6.56014 11.5572L8.2268 7.89056C8.3788 7.5559 8.77147 7.4059 9.10947 7.55923C9.4448 7.7119 9.5928 8.10656 9.44013 8.4419L7.77347 12.1086C7.6228 12.4419 7.22947 12.5932 6.89081 12.4399Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
        </g>
        <defs>
            <clipPath id="clip0_16336:1600">
                <rect width="16" height="16" stroke="white" />
            </clipPath>
        </defs>
    </svg>
)

export default StakingIcon
