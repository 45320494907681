import React, { useState } from 'react'
import {
    Area,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    AreaChart,
    BarChart,
    Bar,
} from 'recharts'
import {
    toK,
    toNiceDate,
    toNiceDateYear,
    formattedNum,
    getTimeframe,
} from 'app/utils/context/PairContext/functions'
import './PairChart.scss'
import Loader from 'react-loaders'
import { usePairChartData } from 'app/utils/context/PairContext/PairData'
import { timeframeOptions } from 'app/utils/context/PairContext/constants'
import useMedia from 'use-media'

const CHART_VIEW = {
    VOLUME: 'Volume',
    LIQUIDITY: 'Liquidity',
}
const Dot = () => {
    return (
        <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="4.5"
                cy="4"
                r="4"
                fill="url(#paint0_linear_15051_31002)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_15051_31002"
                    x1="0.5"
                    y1="4"
                    x2="8.5"
                    y2="4"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00F5A0" />
                    <stop offset="1" stop-color="#00D9F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const PairChart = ({ name, address, color }) => {
    const [chartFilter, setChartFilter] = useState(CHART_VIEW.LIQUIDITY)

    const [timeWindow, setTimeWindow] = useState(timeframeOptions.MONTH)

    const textColor = 'white'

    const chartOptionsList = [
        {
            name: `${name} Liquidity`,
            key: CHART_VIEW.LIQUIDITY,
        },
        {
            name: 'Volume',
            key: CHART_VIEW.VOLUME,
        },
    ]

    const graphFilterList = [
        {
            name: '1W',
            key: timeframeOptions.WEEK,
        },
        {
            name: '1M',
            key: timeframeOptions.MONTH,
        },
        {
            name: 'All',
            key: timeframeOptions.ALL_TIME,
        },
    ]

    // get data for pair, and rates
    let chartData = usePairChartData(address)

    const below1600 = useMedia({ maxWidth: '1600px' })
    const below1080 = useMedia({ maxWidth: '1080px' })

    let utcStartTime = getTimeframe(timeWindow)
    chartData = chartData?.filter((entry) => entry.date >= utcStartTime)

    if (chartData && chartData.length === 0) {
        return (
            <div>
                {/* <Loader type="ball-spin-fade-loader" active={true} /> */}
            </div>
        )
    }

    const aspect = below1080 ? 60 / 20 : below1600 ? 60 / 28 : 60 / 22

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <Dot />
                    <div>
                        <p className="tooltip-label mb-0">
                            {`$ ${formattedNum(payload[0].value)}`}
                        </p>
                        <p className="tooltip-date mb-0">
                            {toNiceDateYear(label)}
                        </p>
                    </div>
                </div>
            )
        }
        return null
    }

    return (
        <div className="w-100">
            {console.log('chart', chartData)}
            <div className="px-4 pb-5">
                <div className="row justify-content-end wrap-items">
                    {/* <div className="d-flex align-items-center">
                        {chartOptionsList.map((option, i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => setChartFilter(option.key)}
                                    className={
                                        option.key === chartFilter
                                            ? 'selected-chart-option-active mr-3 d-flex justify-content-around align-items-center'
                                            : 'selected-chart-option mr-3 d-flex justify-content-around align-items-center'
                                    }
                                >
                                    <span>{option.name}</span>
                                </div>
                            )
                        })}
                    </div> */}

                    <div className="d-flex flex-wrap align-items-center">
                        {graphFilterList &&
                            graphFilterList.map((filter, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={`${
                                            filter.key === timeWindow
                                                ? 'graph-filter-number-div-active'
                                                : 'graph-filter-number-div '
                                        }`}
                                        onClick={() =>
                                            setTimeWindow(filter.key)
                                        }
                                    >
                                        {filter.name}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>

            {chartFilter === CHART_VIEW.LIQUIDITY && (
                <ResponsiveContainer aspect={aspect}>
                    {chartData?.length > 0 ? (
                        <AreaChart
                            margin={{ top: 0, right: 20, bottom: 20, left: 0 }}
                            barCategoryGap={1}
                            data={chartData}
                        >
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor={color}
                                        stopOpacity={0.35}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor={color}
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                tickLine={false}
                                axisLine={false}
                                interval="preserveEnd"
                                tickMargin={14}
                                minTickGap={80}
                                tickFormatter={(tick) => toNiceDate(tick)}
                                dataKey="date"
                                tick={{ fill: textColor }}
                                type={'number'}
                                domain={['dataMin', 'dataMax']}
                            />
                            <YAxis
                                type="number"
                                orientation="right"
                                tickFormatter={(tick) => '$' + toK(tick)}
                                axisLine={false}
                                tickLine={false}
                                interval="preserveEnd"
                                minTickGap={80}
                                yAxisId={0}
                                tickMargin={16}
                                tick={{ fill: textColor }}
                            />
                            <Tooltip
                                cursor={true}
                                content={<CustomTooltip />}
                                wrapperStyle={{ top: -90, left: 10 }}
                            />
                            <Area
                                strokeWidth={2}
                                dot={false}
                                type="monotone"
                                name={' (USD)'}
                                dataKey={'reserveUSD'}
                                yAxisId={0}
                                stroke={'#5E5CE6'}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    ) : (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: 400 }}
                        >
                            <Loader
                                type="ball-spin-fade-loader"
                                active={true}
                            />
                        </div>
                    )}
                </ResponsiveContainer>
            )}
            {chartFilter === CHART_VIEW.VOLUME && (
                <ResponsiveContainer aspect={aspect}>
                    <BarChart
                        margin={{ top: 0, right: 20, bottom: 20, left: 10 }}
                        barCategoryGap={1}
                        data={chartData}
                    >
                        <XAxis
                            tickLine={false}
                            axisLine={false}
                            interval="preserveEnd"
                            minTickGap={80}
                            tickMargin={14}
                            tickFormatter={(tick) => toNiceDate(tick)}
                            dataKey="date"
                            tick={{ fill: textColor }}
                            type={'number'}
                            domain={['dataMin', 'dataMax']}
                        />
                        <YAxis
                            type="number"
                            axisLine={false}
                            tickMargin={16}
                            tickFormatter={(tick) => '$' + toK(tick)}
                            tickLine={false}
                            interval="preserveEnd"
                            orientation="right"
                            minTickGap={80}
                            yAxisId={0}
                            tick={{ fill: textColor }}
                        />
                        {chartData && (
                            <Tooltip
                                cursor={true}
                                content={<CustomTooltip />}
                                wrapperStyle={{ top: -70, left: 10 }}
                            />
                        )}
                        <Bar
                            name={'Volume'}
                            dataKey={'dailyVolumeUSD'}
                            fill={color}
                            opacity={'0.4'}
                            yAxisId={0}
                            stroke={color}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    )
}

export default PairChart
