import React from 'react'
import './statistics.scss'
import back from '../../../assets/images/project/back.png'
import statistics from '../../../assets/images/project/Statistics.png'
import LATEST_DEPOSITS from '../../../assets/images/project/LATEST_DEPOSITS.png'

interface IProps {
    visible: boolean
    setVisible: Function
}

const Statistics: React.FC<IProps> = (props) => {
    return (
        <div className="container pb-5">
            <div
                className="d-flex align-items-center mt-5 mb-5"
                onClick={() => props.setVisible(!props.visible)}
            >
                <img src={back} alt="<" className="pr-3" />
                <span className="back-style">Back</span>
            </div>
            <div className="row justify-content-center m-5">
                <img
                    src={statistics}
                    alt="Statistics"
                    className="col-lg-6 col-sm-12"
                />
            </div>
            <div className="row justify-content-center align-items-end mt-5 sub-heading">
                <div className="">
                    <img
                        src={LATEST_DEPOSITS}
                        alt="Latest Deposits"
                        className="m-3"
                    />
                    <div className="row justify-content-between m-3 w-100">
                        <div className="">
                            <div className="pb-2">
                                <span className="">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                            <div className="pb-2">
                                <span className="pt-3 pb-3">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                            <div className="pb-2">
                                <span className="pt-3 pb-3">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="">
                            <div className="pb-2">
                                <span className="">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                            <div className="pb-2">
                                <span className="pt-3 pb-3">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                            <div className="pb-2">
                                <span className="pt-3 pb-3">
                                    987 -
                                    <span className="text-gradient pr-1 pl-1">
                                        4 minutes ago
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="pb-2">
                <span className="pt-3 pb-3">
                  987 -
                  <span className="text-gradient pr-1 pl-1">4 minutes ago</span>
                </span>
              </div>
              <div className="pb-2">
                <span className="pt-3 pb-3">
                  987 -
                  <span className="text-gradient pr-1 pl-1">4 minutes ago</span>
                </span>
              </div>
              <div className="pb-2">
                <span className="pt-3 pb-3">
                  987 -
                  <span className="text-gradient pr-1 pl-1">4 minutes ago</span>
                </span>
              </div>
              <div className="pb-2">
                <span className="pt-3 pb-3">
                  987 -
                  <span className="text-gradient pr-1 pl-1">4 minutes ago</span>
                </span>
              </div>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics
