/* eslint-disable */
import assert from 'assert'
import mainConfig from './../../config/config'
import { config } from './config'
import { CURRENCIES } from '../enums/currencies'
import { MerkleTree } from './lib/MerkleTree'
const snarkjs = require('snarkjs')
const crypto = require('crypto')
const circomlib = require('circomlib')
const axios = require('axios')
const bigInt = snarkjs.bigInt
// import { useWeb3React } from '@web3-react/core';
// import  { ETC20Tornado } from './contracts/ERC20Tornado'
const Web3 = require('web3')
const buildGroth16 = require('websnark/src/groth16')
const websnarkUtils = require('websnark/src/utils')
const { toWei, fromWei, toBN, BN } = require('web3-utils')
// const program = require('commander')
declare let window: any

let web3: any,
    tornado: any,
    circuit: any,
    proving_key: any,
    groth16: any,
    erc20: any,
    senderAccount: any,
    netId: any
let MERKLE_TREE_HEIGHT: any
// , ETH_AMOUNT: any, TOKEN_AMOUNT: any, PRIVATE_KEY: any
try {
    console.log('window.web3.currentProvider', window.web3.currentProvider)
} catch (e) {
    console.log('matamask extension not found')
}

/** Whether we are in a browser or node.js */
// const inBrowser = (typeof window !== 'undefined')
let isLocalRPC = false
const noteNetId = mainConfig.chainId

const nbytes = crypto.randomBytes(128)
// console.log('nbytes ', nbytes)
/** Compute pedersen hash */
// const data = circomlib.pedersenHash.hash(nbytes)
// const pedersenHash = circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash())[0]
// console.log('pedersenHash ', pedersenHash)
/** BigNumber to hex string of specified length */
function toHex(number: any, length = 32) {
    const str =
        number instanceof Buffer
            ? number.toString('hex')
            : bigInt(number).toString(16)
    return '0x' + str.padStart(length * 2, '0')
}
export const connectWallet = () => {
    try {
        web3 = new Web3(window.web3.currentProvider, null, {
            transactionConfirmationBlocks: 1,
        })
    } catch (e) {
        console.log('matamask extension not found')
    }
}
/** Display ETH account balance */
export const getEthBalance = async (address: any, name: any) => {
    let balance = 0
    balance = parseFloat(web3.utils.fromWei(await web3.eth.getBalance(address)))
    console.log(`${name} ETH balance is`, balance)
    return balance
}

/** Display ERC20 account balance */
export const getERC20Balance = async (
    address: any,
    name: any,
    tokenAddress: any
) => {
    web3 = new Web3(window.web3.currentProvider, null, {
        transactionConfirmationBlocks: 1,
    })

    let balance = 0
    const ercAbi = await (
        await fetch('build/contracts/ERC20Mock.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    ).json()
    const erc20 = new web3.eth.Contract(ercAbi.abi, tokenAddress)
    balance = parseFloat(
        web3.utils.fromWei(await erc20.methods.balanceOf(address).call())
    )
    // const contractObj = new web3.eth.Contract(erc20ContractJson.abi, tokenAddress)
    // balance = await contractObj.methods.balanceOf(address).call()
    console.log(`${name} Token Balance is`, balance)
    return balance
}

export const init = async (
    currency: string,
    amount: number,
    mixture?: string
) => {
    console.log('init ', currency, amount, mixture)
    let contractJson: any, erc20ContractJson: any, tornadoAddress, tokenAddress
    // erc20tornadoJson
    // contractJson =  ethTornadoJSON
    erc20ContractJson = await (
        await fetch('build/contracts/ERC20Mock.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    ).json()
    contractJson = await (
        await fetch('build/contracts/ETHTornado.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    ).json()
    circuit = await (
        await fetch('build/circuits/withdraw.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
    ).json()
    proving_key = await (
        await fetch('build/circuits/withdraw_proving_key.bin')
    ).arrayBuffer()
    // proving_key = Buffer.from(proving_key)
    console.log('proving_key.buffer', proving_key)
    console.log('proving_key ', Buffer.from(proving_key))
    MERKLE_TREE_HEIGHT = 20
    // ETH_AMOUNT = 1e18
    // TOKEN_AMOUNT = 1e19
    senderAccount = (await web3.eth.getAccounts())[0]
    console.log('senderAccount ', senderAccount)
    netId = await web3.eth.net.getId()
    if (noteNetId && Number(noteNetId) !== netId) {
        throw new Error(
            'This note is for a different network. Specify the --rpc option explicitly'
        )
    }
    try {
        tornadoAddress =
            config[`netId${netId}`][mixture][currency].instanceAddress[amount]
        if (!tornadoAddress) {
            throw new Error()
        }
        tokenAddress =
            config[`netId${netId}`][`${mixture}`][`${currency}`].tokenAddress
    } catch (e) {
        // console.error('There is no such tornado instance, check the currency and amount you provide')
        console.log('error ', e)
        // process.exit(1)
    }
    console.log('tornadoAddress *** ', tornadoAddress)
    tornado = new web3.eth.Contract(contractJson.abi, tornadoAddress)
    erc20 =
        currency !== 'eth'
            ? new web3.eth.Contract(erc20ContractJson.abi, tokenAddress)
            : {}
}

export const withDrawal = async (parsedNote: any, receipent: string) => {
    try {
        const resultSet = await axios.post(mainConfig.tornadoUrl, {
            note: parsedNote.toString(),
            receipent,
        })
        return resultSet.data.txHash
    } catch (err) {
        const { response } = err
        if (response && response.data.message) {
            throw new Error(response.data.message)
        } else {
            throw new Error('There is some problem try again later')
        }
    }
}

/**
 * Parses Tornado.cash note
 * @param noteString the note
 */
export const parseNote = async (noteString: string, mixture?: string) => {
    try {
        const noteRegex =
            /(?<mixture>\w+)-(?<currency>\w+)-(?<amount>[\d.]+)-(?<netId>\d+)-0x(?<note>[0-9a-fA-F]{124})/g
        const match: any = noteRegex.exec(noteString)
        if (!match) {
            throw new Error('The note has invalid format')
        }

        const buf = Buffer.from(match.groups.note, 'hex')
        const nullifier = bigInt.leBuff2int(buf.slice(0, 31))
        const secret = bigInt.leBuff2int(buf.slice(31, 62))
        const deposit = createDeposit(nullifier, secret)
        const netId = Number(match.groups.netId)

        return {
            currency: match.groups.currency,
            amount: match.groups.amount,
            netId,
            deposit,
        }
    } catch (err) {
        console.log('verifyNote failed ', err)
        throw new Error('The note has invalid format')
    }
}
/**
 * Do an ETH withdrawal
 * @param noteString Note to withdraw
 * @param recipient Recipient address
 */
async function withdraw(
    deposit: any,
    currency: any,
    amount: any,
    recipient: any,
    relayerURL: any,
    refund = '0'
) {
    if (currency === 'eth' && refund !== '0') {
        throw new Error(
            'The ETH purchase is supposted to be 0 for ETH withdrawals'
        )
    }
    refund = toWei(refund)
    if (relayerURL) {
        if (relayerURL.endsWith('.eth')) {
            throw new Error(
                'ENS name resolving is not supported. Please provide DNS name of the relayer. See instuctions in README.md'
            )
        }
        const relayerStatus = await axios.get(relayerURL + '/status')
        const {
            relayerAddress,
            netId,
            gasPrices,
            ethPrices,
            relayerServiceFee,
        } = relayerStatus.data
        assert(
            netId === (await web3.eth.net.getId()) || netId === '*',
            'This relay is for different network'
        )
        console.log('Relay address: ', relayerAddress)
        const decimals = config[`netId${netId}`][currency].decimals
        const fee = calculateFee(
            gasPrices,
            currency,
            amount,
            refund,
            ethPrices,
            relayerServiceFee,
            decimals
        )
        if (fee.gt(fromDecimals(amount, decimals))) {
            throw new Error('Too high refund')
        }
        console.log('fee', fee)

        const { proof, args } = await generateProof(
            deposit,
            recipient,
            relayerAddress,
            fee,
            refund
        )

        console.log('Sending withdraw transaction through relay')
        try {
            console.log('contract ', tornado._address)
            console.log('proof ', proof)
            console.log('args ', args)
            const relay = await axios.post(relayerURL + '/relay', {
                contract: tornado._address,
                proof,
                args,
            })
            // if (netId === 1 || netId === 42) {
            //   console.log(`Transaction submitted through the relay. View transaction on etherscan https://${getCurrentNetworkName()}etherscan.io/tx/${relay.data.txHash}`)
            // } else {
            //   console.log(`Transaction submitted through the relay. The transaction hash is ${relay.data.txHash}`)
            // }

            return relay.data.txHash
        } catch (e) {
            if (e.response) {
                console.error(e.response.data.error)
            } else {
                console.error(e.message)
            }
        }
    }
}

// function getCurrentNetworkName() {
//   switch (netId) {
//     case 1:
//       return ''
//     case 42:
//       return 'kovan.'
//   }

// }

/**
 * Waits for transaction to be mined
 * @param txHash Hash of transaction
 * @param attempts
 * @param delay
 */
export const waitForTxReceipt = (
    txHash: string,
    attempts = 60,
    delay = 1000
) => {
    return new Promise((resolve, reject) => {
        const checkForTx = async (txHash: any, retryAttempt = 0) => {
            const result = await web3.eth.getTransactionReceipt(txHash)
            if (!result || !result.blockNumber) {
                if (retryAttempt <= attempts) {
                    setTimeout(
                        () => checkForTx(txHash, retryAttempt + 1),
                        delay
                    )
                } else {
                    reject(new Error('tx was not mined'))
                }
            } else {
                resolve(result)
            }
        }
        checkForTx(txHash)
    })
}

/**
 * For getting Relayer Service Fee
 */
export const getRelayerServiceFee = async () => {
    try {
        const status = await axios.get(mainConfig.fakeFairishUrl)
        return status.data.relayerServiceFee
    } catch (err) {
        return false
    }
}

function toObject(obj) {
    return JSON.parse(
        JSON.stringify(
            obj,
            (key, value) =>
                typeof value === 'bigint' ? value.toString() : value // return everything else unchanged
        )
    )
}

/**
 * Generate SNARK proof for withdrawal
 * @param deposit Deposit object
 * @param recipient Funds recipient
 * @param relayer Relayer address
 * @param fee Relayer fee
 * @param refund Receive ether for exchanged tokens
 */
async function generateProof(
    deposit: any,
    recipient: any,
    relayerAddress: any,
    fee: any,
    refund: any
) {
    // Compute merkle proof of our commitment
    const { root, path_elements, path_index } = await generateMerkleProof(
        deposit
    )

    // Prepare circuit input
    const input = {
        // Public snark inputs
        root: root,
        nullifierHash: deposit.nullifierHash,
        recipient: bigInt(recipient),
        relayer: bigInt(relayerAddress),
        fee: bigInt(fee),
        refund: bigInt(refund),

        // Private snark inputs
        nullifier: deposit.nullifier,
        secret: deposit.secret,
        pathElements: path_elements,
        pathIndices: path_index,
    }
    console.log('input data ', input)
    // console.log('Generating SNARK proof', groth16)
    // console.log('Generating SNARK proof', input)

    const axiosRequest = await axios.post(`http://localhost:3000/transaction`, {
        root,
        recipient,
        relayerAddress,
        deposit: toObject(deposit),
        fee: fee.toString(),
        refund,
        path_elements,
        path_index,
    })
    console.log('axiosRequest ', axiosRequest)

    // console.time('Proof time')
    const proofData = await websnarkUtils.genWitnessAndProve(
        groth16,
        input,
        circuit,
        proving_key
    )
    console.log('proofData ', proofData)
    // const { proof } = websnarkUtils.toSolidityInput(proofData)
    // console.timeEnd('Proof time')

    const args = [
        toHex(input.root),
        toHex(input.nullifierHash),
        toHex(input.recipient, 20),
        toHex(input.relayer, 20),
        toHex(input.fee),
        toHex(input.refund),
    ]

    return { proof: '', args }
}

// export const getDepositsList = () =>{
//   const tornadoAddress = config[`netId${netId}`][currency].instanceAddress[amount]
//   const contractAbi = await (await fetch('build/contracts/ETHTornado.json', {
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json'
//     }
//   })).json()
//   const tornadoContract = new web3.eth.Contract(contractAbi.abi, tornadoAddress)
//   const events = await tornadoContract.getPastEvents('Deposit', { fromBlock: 0, toBlock: 'latest' })
// }

/**
 * Generate merkle tree for a deposit.
 * Download deposit events from the tornado, reconstructs merkle tree, finds our deposit leaf
 * in it and generates merkle proof
 * @param deposit Deposit object
 */
async function generateMerkleProof(deposit: any) {
    // Get all deposit events from smart contract and assemble merkle tree from them
    console.log('Getting current state from tornado contract')
    const events = await tornado.getPastEvents('Deposit', {
        fromBlock: 0,
        toBlock: 'latest',
    })
    const leaves = events
        .sort(
            (a: any, b: any) =>
                a.returnValues.leafIndex - b.returnValues.leafIndex
        ) // Sort events in chronological order
        .map((e: any) => e.returnValues.commitment)
    const tree = new MerkleTree(MERKLE_TREE_HEIGHT, leaves)

    // Find current commitment in the tree
    const depositEvent = events.find(
        (e: any) => e.returnValues.commitment === toHex(deposit.commitment)
    )
    const leafIndex = depositEvent ? depositEvent.returnValues.leafIndex : -1

    // Validate that our data is correct
    const root = await tree.root()
    const isValidRoot = await tornado.methods.isKnownRoot(toHex(root)).call()
    // const isSpent = await tornado.methods.isSpent(toHex(deposit.nullifierHash)).call()
    // if(isValidRoot === false) throw 'Merkle tree is corrupted'
    // if(isSpent === true) throw 'The note is already spent'
    // if(leafIndex <= 0) throw 'The deposit is not found in the tree'

    // Compute merkle proof of our commitment
    return tree.path(leafIndex)
}
function calculateFee(
    gasPrices: any,
    currency: any,
    amount: any,
    refund: any,
    ethPrices: any,
    relayerServiceFee: any,
    decimals: any
) {
    const decimalsPoint =
        Math.floor(relayerServiceFee) === Number(relayerServiceFee)
            ? 0
            : relayerServiceFee.toString().split('.')[1].length
    const roundDecimal = 10 ** decimalsPoint
    const total = toBN(fromDecimals(amount, decimals))
    const feePercent = total
        .mul(toBN(relayerServiceFee * roundDecimal))
        .div(toBN(roundDecimal * 100))
    const expense = toBN(toWei(gasPrices.fast.toString(), 'gwei')).mul(
        toBN(5e5)
    )
    let desiredFee
    switch (currency) {
        case 'ETH': {
            desiredFee = expense.add(feePercent)
            break
        }
        default: {
            desiredFee = expense
                .add(toBN(refund))
                .mul(toBN(10 ** decimals))
                .div(toBN(ethPrices[currency.toLowerCase()]))
            desiredFee = desiredFee.add(feePercent)
            break
        }
    }
    return desiredFee
}

export const generateSecret = async (
    currency: any,
    amount: any,
    mixture: any
) => {
    try {
        await init(currency, amount, mixture)
        const nullifier = snarkjs.bigInt.leBuff2int(crypto.randomBytes(31)),
            secret = snarkjs.bigInt.leBuff2int(crypto.randomBytes(31))
        const deposit = createDeposit(nullifier, secret)
        const noteString = toHex(deposit.preimage, 62)
        const note = `${mixture}-${currency}-${amount}-${netId}-${noteString}`
        console.log('generatedSecret ', note)
        return { deposit, note }
    } catch (err) {
        console.log('err ', err)
        return false
    }
}
export const processConfirmDeposit = async (
    noteString: string,
    mixture: string
) => {
    const { currency, amount, netId, deposit } = await parseNote(
        noteString,
        mixture
    )
    await init(currency, amount, mixture)
    if (currency === CURRENCIES.ETH) {
        console.log(`tornado._address: ${tornado._address}`)
        console.log(`senderAccount: ${senderAccount}`)
        const value = fromDecimals(amount, 18)
        console.log('Submitting deposit transaction', value)
        const txDetails = await tornado.methods
            .deposit(toHex(deposit.commitment))
            .send({ value, from: senderAccount, gas: 2e6 })
        console.log('txDetails ', txDetails)
        return txDetails
    } else {
        const tokenAmount = fromDecimals(amount, 18)
        const allowance = await erc20.methods
            .allowance(senderAccount, tornado._address)
            .call({ from: senderAccount })
        console.log('Current allowance is', fromWei(allowance))
        if (toBN(allowance).lt(toBN(tokenAmount))) {
            console.log('Approving tokens for deposit')
            await erc20.methods
                .approve(tornado._address, tokenAmount)
                .send({ from: senderAccount, gas: 1e6 })
        }

        console.log('Submitting deposit transaction')
        const txDetails = await tornado.methods
            .deposit(toHex(deposit.commitment))
            .send({ from: senderAccount, gas: 2e6 })
        console.log('txDetails ', txDetails)
        return txDetails
    }
}
export const deposit = async (currency: any, amount: any) => {
    await init(currency, amount)
    const nullifier = snarkjs.bigInt.leBuff2int(crypto.randomBytes(31)),
        secret = snarkjs.bigInt.leBuff2int(crypto.randomBytes(31))
    const deposit = createDeposit(nullifier, secret)
    const note = toHex(deposit.preimage, 62)
    const noteString = `tornado-${currency}-${amount}-${netId}-${note}`
    console.log('noteString is here for you')
    console.log(`Your note: ${noteString}`)
    if (currency === 'eth') {
        console.log(`tornado._address: ${tornado._address}`)
        console.log(`senderAccount: ${senderAccount}`)

        await getEthBalance(tornado._address, 'Tornado')
        await getEthBalance(senderAccount, 'Sender account')
        const value = fromDecimals(amount, 18)
        console.log('Submitting deposit transaction', value)
        await tornado.methods
            .deposit(toHex(deposit.commitment))
            .send({ value, from: senderAccount, gas: 2e6 })
        await getEthBalance(tornado._address, 'Tornado')
        await getEthBalance(senderAccount, 'Sender account')
    } else {
        // a token
        await getEthBalance(tornado._address, 'Tornado')
        await getERC20Balance(senderAccount, 'Sender account', undefined)
        const decimals = config[`netId${netId}`][currency].decimals
        const tokenAmount = fromDecimals(amount, decimals)
        if (isLocalRPC) {
            console.log('Minting some test tokens to deposit')
            await erc20.methods
                .mint(senderAccount, tokenAmount)
                .send({ from: senderAccount, gas: 2e6 })
        }

        const allowance = await erc20.methods
            .allowance(senderAccount, tornado._address)
            .call({ from: senderAccount })
        console.log('Current allowance is', fromWei(allowance))
        if (toBN(allowance).lt(toBN(tokenAmount))) {
            console.log('Approving tokens for deposit')
            await erc20.methods
                .approve(tornado._address, tokenAmount)
                .send({ from: senderAccount, gas: 1e6 })
        }

        console.log('Submitting deposit transaction')
        await tornado.methods
            .deposit(toHex(deposit.commitment))
            .send({ from: senderAccount, gas: 2e6 })
        await getERC20Balance(tornado._address, 'Tornado', undefined)
        await getERC20Balance(senderAccount, 'Sender account', undefined)
    }

    return noteString
}
/**
 * Create deposit object from secret and nullifier
 */
function createDeposit(nullifier: any, secret: any) {
    const deposit: any = { nullifier, secret }
    deposit.preimage = Buffer.concat([
        deposit.nullifier.leInt2Buff(31),
        deposit.secret.leInt2Buff(31),
    ])
    deposit.commitment = circomlib.babyJub.unpackPoint(
        circomlib.pedersenHash.hash(deposit.preimage)
    )[0]
    deposit.commitmentHex = toHex(deposit.commitment)
    deposit.nullifierHash = circomlib.babyJub.unpackPoint(
        circomlib.pedersenHash.hash(deposit.nullifier.leInt2Buff(31))
    )[0]
    deposit.nullifierHex = toHex(deposit.nullifierHash)
    return deposit
}

function fromDecimals(amount: any, decimals: any) {
    amount = amount.toString()
    let ether = amount.toString()
    const base = new BN('10').pow(new BN(decimals))
    const baseLength = base.toString(10).length - 1 || 1

    const negative = ether.substring(0, 1) === '-'
    if (negative) {
        ether = ether.substring(1)
    }

    if (ether === '.') {
        throw new Error(
            '[ethjs-unit] while converting number ' +
                amount +
                ' to wei, invalid value'
        )
    }

    // Split it into a whole and fractional part
    const comps = ether.split('.')
    if (comps.length > 2) {
        throw new Error(
            '[ethjs-unit] while converting number ' +
                amount +
                ' to wei,  too many decimal points'
        )
    }

    let whole = comps[0]
    let fraction = comps[1]

    if (!whole) {
        whole = '0'
    }
    if (!fraction) {
        fraction = '0'
    }
    if (fraction.length > baseLength) {
        throw new Error(
            '[ethjs-unit] while converting number ' +
                amount +
                ' to wei, too many decimal places'
        )
    }

    while (fraction.length < baseLength) {
        fraction += '0'
    }

    whole = new BN(whole)
    fraction = new BN(fraction)
    let wei = whole.mul(base).add(fraction)

    if (negative) {
        wei = wei.mul(negative)
    }

    return new BN(wei.toString(10), 10)
}
