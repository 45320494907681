import React from 'react'
import './LoaderModal.scss'
import Modal from 'react-bootstrap/Modal'
import Loader from 'react-loaders'

interface IProps {
    show: boolean
    setShow?: Function
    isOptions?: Boolean
    title?: String
    message?: String
}

const LoaderModal: React.FC<IProps> = (props) => {
    return (
        <div>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={true}
                centered
                className="loader-style"
            >
                <div className="loader-main-section">
                    {props.isOptions && (
                        <>
                            <div className="row justify-content-center pb-3">
                                <text className="loader-title">
                                    {props.title}
                                </text>
                            </div>
                            <div className="row justify-content-center p-3">
                                <text>{props.message}</text>
                            </div>
                        </>
                    )}
                    <div className="row justify-content-center m-5">
                        <Loader type="ball-spin-fade-loader" active={true} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default LoaderModal
