import random from 'lodash/random'

import Config from 'app/config/config'

// Array of available nodes to connect to
export const nodes = Config.RPC_URL

const getNodeUrl = (): string => {
    const randomIndex = random(0, nodes.length - 1)
    return nodes[randomIndex]
    // return process.env.REACT_APP_NODE as string
}

export default getNodeUrl
