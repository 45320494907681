import Config from './../../config/config'

export const DEFAULT_USER_AUTH = {
    walletCreation: null,
    walletAddress: '',
    contractAddress: null,
    userId: 0,
    protocol: {},
    accountType: null,
    currency: {},
    chainId: Config.chainId,
    isAuthenticated: false,
    bestProtocol: {},
    connectionId: null,
}
