import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { Protocol } from './../enums/walletConnectionsEnum'

export const getTransactionData = async (
    transaction: any,
    user: any,
    library: any,
    gasPriceGiven: any,
    callback: Function
) => {
    debugger
    if (user && transaction && library) {
        const {
            targetAddress,
            targetData,
            createOnFailedDeposit = false,
        } = transaction

        const transactionEstimate = {
            to: targetAddress,
            data: targetData,
            from: user.walletAddress,
        }
        let gasPrice = null
        let gasLimit = null
        let estimateFailed = false
        try {
            if (!gasPriceGiven) {
                gasPrice = await library
                    .getSigner()
                    .provider.getGasPrice(transactionEstimate)
            }
            gasLimit = await library
                .getSigner()
                .provider.estimateGas(transactionEstimate)
        } catch (e) {
            estimateFailed = true
            console.log(e)
            callback()
        }

        if (!estimateFailed) {
            const transactionSend: any = {
                to: targetAddress,
                data: targetData,
            }
            if (gasPrice) {
                transactionSend.gasPrice = ethers.utils.parseUnits(
                    Number(
                        ethers.utils.formatUnits(gasPrice, 'gwei')
                    ).toString(),
                    'gwei'
                )
            } else {
                transactionSend.gasPrice = ethers.utils.parseUnits(
                    gasPriceGiven.toString(),
                    'gwei'
                )
            }

            if (gasLimit) {
                transactionSend.gasLimit = gasLimit.add(5000)
            }

            if (createOnFailedDeposit || !gasPriceGiven || !gasLimit) {
                transactionSend.gasLimit = 400000
                transactionSend.gasPrice = ethers.utils.parseUnits('20', 'gwei')
            }
            return transactionSend
        } else {
            return false
        }
    }
}

export enum ProtocolEnum {
    dydx,
    compound,
    fulcrum,
    aave,
}
export function cutNumber(number, digitsAfterDot) {
    const str = `${number}`

    return str.slice(0, str.indexOf('.') + digitsAfterDot + 1)
}
export const getSignableTransaction = async (transactions: any[]) => {
    return new Promise((resolve, reject) => {
        let selectedTransaction: any
        let isWalletCreated: boolean = false
        let walletCreationPending: boolean = false
        let isDepositPending: boolean = false
        // let isDepositCreated: boolean = false;
        if (transactions.length > 0) {
            for (let i = 0; i < transactions.length; i++) {
                if (
                    transactions[i].transactionType === 'walletCreation' &&
                    transactions[i].transactionStatus === 'pending'
                ) {
                    selectedTransaction = transactions[i]
                    walletCreationPending = true
                    break
                } else if (
                    transactions[i].transactionType === 'walletCreation' &&
                    transactions[i].transactionStatus === 'unsigned'
                ) {
                    selectedTransaction = transactions[i]
                    walletCreationPending = true
                    break
                } else if (
                    transactions[i].transactionType === 'walletCreation' &&
                    transactions[i].transactionStatus === 'confirmed'
                ) {
                    isWalletCreated = true
                } else if (
                    transactions[i].transactionType === 'deposit' &&
                    transactions[i].transactionStatus === 'pending'
                ) {
                    isDepositPending = true
                } else if (
                    transactions[i].transactionType === 'deposit' &&
                    transactions[i].transactionStatus === 'unsigned'
                ) {
                    selectedTransaction = transactions[i]
                } else if (
                    transactions[i].transactionType === 'deposit' &&
                    transactions[i].transactionStatus === 'confirmed'
                ) {
                    // isDepositCreated = true
                }
            }
        }

        if (walletCreationPending) {
            resolve({
                transaction: selectedTransaction,
                status: 'wallet-creation-pending',
                step: 2,
            })
        } else if (
            !isWalletCreated &&
            !walletCreationPending &&
            selectedTransaction
        ) {
            resolve({
                transaction: selectedTransaction,
                status: 'wallet-creation-unsigned',
                step: 2,
            })
        } else if (isDepositPending) {
            resolve({
                transaction: selectedTransaction,
                status: 'deposit-pending',
            })
        } else if (!isDepositPending && selectedTransaction) {
            resolve({
                transaction: selectedTransaction,
                status: 'deposit-unsigned',
            })
        }
    })
}

export const getProtocol = (protocol: any) => {
    const intProtocol = parseInt(protocol, 10)
    if (intProtocol === 0) {
        return Protocol.dydx
    } else if (intProtocol === 1) {
        return Protocol.compound
    } else if (intProtocol === 2) {
        return Protocol.fulcrum
    } else if (intProtocol === 3) {
        return Protocol.aave
    } else {
        return Protocol.dydx
    }
}

export const allowance = async (owner: any, spender: string, account: string) =>
    owner.methods.allowance(account, spender).call()

export const approve = async (
    lpContract: any,
    masterChefAddress: string,
    account: string
) =>
    lpContract.methods
        .approve(masterChefAddress, ethers.constants.MaxUint256)
        .send({ from: account })

export const getUserInfo = async (
    pid: number,
    masterChefContract: any,
    account: string
) => {
    try {
        const { amount } = await masterChefContract.methods
            .userInfo(pid, account)
            .call()

        return amount
    } catch (e) {
        console.log(e)
        return 0
    }
}

export const stakeOrUnstake = async (
    masterChefContract: any,
    pid: number,
    amount: string,
    account: string,
    transactionType: 'deposit' | 'withdraw'
) =>
    masterChefContract.methods[transactionType](pid, amount)
        .send({ from: account })
        .on('transactionHash', (tx: any) => tx)

export const convertToNumber = (num: number, pow: number = 18) =>
    num / Math.pow(10, pow)

export const getPendingReward = async (
    pid: number,
    masterChefContract: any,
    account: string
) => {
    const reward = await masterChefContract.methods
        .pendingAdds(pid, account)
        .call()
    return reward
}

export const getAddPerBlock = async (masterChefContract: any) => {
    const add = await masterChefContract.methods.addPerBlock().call()
    return add
}

export const getStartBlock = async (masterChefContract: any) => {
    const start = await masterChefContract.methods.startBlock().call()
    return start
}

export const getEndBlock = async (masterChefContract: any) => {
    const end = await masterChefContract.methods.endBlock().call()
    return end
}

export const truncateDecimalPoints = (num: number | string): string =>
    num.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0]
