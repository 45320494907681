import React from 'react'

interface IProps {
    isActive: string
}
const HistoryIcon: React.FC<IProps> = ({ isActive }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        stroke={isActive ? '#0094FF' : '#A1A3A5'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_16336:1622)">
            <path
                d="M1.5707 0C0.856368 0 0.248653 0.456531 0.0234501 1.09375C-0.0209288 1.21931 -0.00159674 1.35857 0.0753212 1.46729C0.152239 1.57601 0.277091 1.64062 0.410255 1.64062H3.21132C3.21132 0.734535 2.47679 0 1.5707 0Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M7.61656 12.3594C7.52365 13.0005 7.21395 13.568 6.77246 14.0001H12.1698C12.927 14.0001 13.5897 13.5873 13.9448 12.975C14.0184 12.8481 14.0187 12.6915 13.9454 12.5644C13.872 12.4373 13.7365 12.3594 13.5897 12.3594H7.61656V12.3594Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M10.5294 0H3.39196C3.66931 0.307535 4.46747 0.994656 3.77412 4.56263C3.31986 7.01307 2.37776 9.54732 2.39979 12.0325C2.44458 13.1239 3.33995 14 4.44218 14C5.57293 14 6.49296 13.08 6.49296 11.9492C6.49296 11.7225 6.67641 11.5391 6.90312 11.5391H10.9479C11.0088 10.0591 11.3837 8.57103 11.7474 7.12748C12.1625 5.47917 12.5801 3.75804 12.5801 2.05078C12.5801 0.920035 11.6601 0 10.5294 0V0ZM9.70462 9.02344H4.51609C4.28958 9.02344 4.10593 8.8398 4.10593 8.61328C4.10593 8.38677 4.28958 8.20312 4.51609 8.20312H9.70462C9.93114 8.20312 10.1148 8.38677 10.1148 8.61328C10.1148 8.8398 9.93116 9.02344 9.70462 9.02344ZM10.0946 7.38281H4.91687C4.69035 7.38281 4.50671 7.19917 4.50671 6.97266C4.50671 6.74614 4.69035 6.5625 4.91687 6.5625H10.0945C10.3211 6.5625 10.5047 6.74614 10.5047 6.97266C10.5047 7.19917 10.3211 7.38281 10.0946 7.38281ZM10.4953 5.74219H5.29501C5.06849 5.74219 4.88485 5.55855 4.88485 5.33203C4.88485 5.10552 5.06849 4.92188 5.29501 4.92188H10.4953C10.7219 4.92188 10.9055 5.10552 10.9055 5.33203C10.9055 5.55855 10.7219 5.74219 10.4953 5.74219ZM10.8082 4.10156H5.57889C5.35237 4.10156 5.16873 3.91792 5.16873 3.69141C5.16873 3.46489 5.35237 3.28125 5.57889 3.28125H10.8083C11.0348 3.28125 11.2184 3.46489 11.2184 3.69141C11.2184 3.91792 11.0348 4.10156 10.8082 4.10156Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
        </g>
        <defs>
            <clipPath id="clip0_16336:1622">
                <rect
                    width="14"
                    height="14"
                    stroke={isActive ? '#0094FF' : '#A1A3A5'}
                />
            </clipPath>
        </defs>
    </svg>
)

export default HistoryIcon
