import React from 'react'

interface IProps {
    isActive: string
}
const PrivacyIcon: React.FC<IProps> = ({ isActive }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        stroke={isActive ? '#0094FF' : '#A1A3A5'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_16336:1593)">
            <path
                d="M8.03052 5.1177C9.52808 5.1177 10.9933 4.79031 12.1561 4.1957C12.1649 4.19131 12.1803 4.18386 12.2021 4.17361C12.4587 4.05361 12.7285 3.90066 13.0042 3.72073C12.7601 1.78298 12.2178 0.624535 11.3539 0.188866C10.2968 -0.344215 9.13245 0.376732 8.43677 0.807518C8.3031 0.890282 8.12903 0.99807 8.03052 1.04556C7.93213 0.99807 7.75793 0.89016 7.62439 0.807518C6.92871 0.376732 5.7644 -0.344337 4.70728 0.188866C3.85303 0.619652 3.31335 1.75735 3.06543 3.65566C3.29456 3.82375 3.53882 3.98769 3.73315 4.10488C4.92566 4.75796 6.45154 5.1177 8.03052 5.1177Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M2.91418 8.97302C4.28833 9.51965 6.10522 9.82068 8.03015 9.82068C9.9552 9.82068 11.7721 9.51965 13.1462 8.97302C14.657 8.37195 15.4891 7.5188 15.4891 6.57092C15.4891 6.0481 15.2311 5.27759 14.0015 4.57581C13.8955 4.51538 13.7856 4.45813 13.6703 4.4032C13.3038 4.65283 12.9424 4.86279 12.5994 5.02332C12.5914 5.0271 12.5852 5.03003 12.5806 5.0321C11.2697 5.7019 9.69629 6.05591 8.03015 6.05591C6.2926 6.05591 4.60339 5.65356 3.27368 4.92297C3.24597 4.90771 2.85706 4.66882 2.45654 4.37207C2.31665 4.4364 2.18506 4.50378 2.05896 4.57581C0.829346 5.27771 0.571289 6.0481 0.571289 6.57092C0.571289 7.51892 1.40344 8.37195 2.91418 8.97302Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
            <path
                d="M15.0204 12.0023H14.4617C14.0262 10.9562 12.9934 10.2188 11.7915 10.2188C10.583 10.2188 9.54565 10.9641 9.11438 12.0192C8.41296 11.6594 7.57117 11.6608 6.87 12.0275C6.4408 10.968 5.40112 10.2188 4.18945 10.2188C2.98755 10.2188 1.95471 10.9562 1.51929 12.0023H0.968994C0.709961 12.0023 0.5 12.2123 0.5 12.4713C0.5 12.7303 0.709961 12.9403 0.968994 12.9403H1.30371C1.30042 12.9963 1.29858 13.0527 1.29858 13.1096C1.29858 14.7036 2.59546 16.0005 4.18945 16.0005C5.78357 16.0005 7.08032 14.7036 7.08032 13.1096C7.08032 13.0745 7.07947 13.0396 7.07825 13.0048C7.61279 12.5864 8.36597 12.5835 8.90332 12.9897C8.90161 13.0295 8.90063 13.0695 8.90063 13.1096C8.90063 14.7036 10.1974 16.0005 11.7915 16.0005C13.3855 16.0005 14.6823 14.7036 14.6823 13.1096C14.6823 13.0527 14.6804 12.9963 14.6772 12.9403H15.0204C15.2794 12.9403 15.4894 12.7303 15.4894 12.4713C15.4894 12.2123 15.2794 12.0023 15.0204 12.0023Z"
                stroke={isActive ? '#0094FF' : '#A1A3A5'}
            />
        </g>
        <defs>
            <clipPath id="clip0_16336:1593">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default PrivacyIcon
