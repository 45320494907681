import Web3 from 'web3'
import { provider } from 'web3-core'
import { AbiItem } from 'web3-utils'
import ERC20ABI from './erc20.json'
import MasterChefABI from './mchef.abi.json'

export const getContract = (
    providerUrl: provider,
    address: string,
    isMasterChef: boolean
) => {
    const web3 = new Web3(providerUrl)
    const contract = new web3.eth.Contract(
        (isMasterChef ? MasterChefABI : (ERC20ABI.abi as unknown)) as AbiItem,
        address
    )
    return contract
}

export const getWeb3 = (providerUrl: provider) => {
    const web3 = new Web3(providerUrl)
    return web3
}
